
export const TASKS_FLOW_SCREENS_DATA_TYPES = {
    oneItem: 'oneItem',
    multipleItems: 'multipleItems',
    date: 'date',
    interval: 'interval',
    user: 'user',
    docsPicker: 'docsPicker',
    docsViewer: 'docsViewer',
    docs: 'docs',
}