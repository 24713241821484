import { CELL_TYPES } from "../../../utils/types";
import { CELL_STYLE_TEMPLATES } from "../../../components/table/tableRelatedTypes";

const {
  shortText,
  longText
} = CELL_TYPES;

export const RESULTS_TABLE_COLUMNS = [
  { title: "Activity", name: "activity", cellType: longText },
  { title: "Start date", name: "startDate", cellType: shortText },
  { title: "End Date", name: "endDate", cellType: shortText },
];


const defaultStyle = {
  paddingLeft: 5,
  paddingRight: 5,
  paddingTop: 5,
  paddingBottom: 5,
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  justifyItems: "center",
  display: "flex",
  height: 70,
  width: 100,
  minWidth: 100,
  maxWidth: 400,
};
export const ACTIVITIES_TABLE_COLUMNS = [
  {
    columnTitle: "Activity number",
    attributeName: "activityIndicator",
    headStyleTemplate: CELL_STYLE_TEMPLATES.head,
    cellContainerStyle: defaultStyle,
  },
  {
    columnTitle: "Description",
    attributeName: "subactivityName",
    headStyleTemplate: CELL_STYLE_TEMPLATES.head,
    cellContainerStyle: {...defaultStyle, minWidth: 300, maxWidth: 300},
    customComponentConfig: null,
    cellStyleTemplate: CELL_STYLE_TEMPLATES.questionner,
  },
  {
    columnTitle: "NCR",
    attributeName: "ncr",
    headStyleTemplate: CELL_STYLE_TEMPLATES.head,
    cellContainerStyle: defaultStyle,
    customComponentConfig: null,
    cellStyleTemplate: CELL_STYLE_TEMPLATES.questionner,
  },
  {
    columnTitle: "NCR Status",
    attributeName: "ncrStatus",
    headStyleTemplate: CELL_STYLE_TEMPLATES.head,
    cellContainerStyle: {...defaultStyle, minWidth: 120, maxWidth: 120},
    customComponentConfig: null,
    cellStyleTemplate: CELL_STYLE_TEMPLATES.questionner,
  },
  {
    columnTitle: "Start date",
    attributeName: "startDate",
    headStyleTemplate: CELL_STYLE_TEMPLATES.head,
    cellContainerStyle: {...defaultStyle, minWidth: 200, maxWidth: 200},
    customComponentConfig: null,
    cellStyleTemplate: CELL_STYLE_TEMPLATES.questionner,
  },
  {
    columnTitle: "End date",
    attributeName: "endDate",
    headStyleTemplate: CELL_STYLE_TEMPLATES.head,
    cellContainerStyle: {...defaultStyle, minWidth: 200, maxWidth: 200},
    customComponentConfig: null,
    cellStyleTemplate: CELL_STYLE_TEMPLATES.questionner,
  },
];
