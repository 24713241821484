import React from "react";
import AccountMobile from "../screens/Account";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Login from "../screens/login";
import Header from "../components/header";
import MainBottomNavigator from "../navigation/MainBottomNavigator";
import TasksMainFlowNavigator from "../screens/tasks/main-flow/TasksMainFlowNavigator";
import FlowStatus from "../screens/tasks/main-flow/FlowStatus";
import Settings from "../screens/tasks/settings";
import Tasks from "../screens/tasks";
import Users from "../screens/users";
import FileLibrary from "../screens/FileLibrary";
import TwoFactorAuth from "../screens/login/TwoFactorAuth";
import Notifications from "../screens/notifications/Notifications";
import Questionnaires from "../screens/questionnaires/Questionnaires";
import TasksMobileDetails from "../screens/tasks/TasksMobileDetails";
import { useSelector } from "react-redux";
import ResolveFlowNavigator from "./ResolveFlowNavigator";
import { withDesktopLayout } from "../containers/Layout";
import { useAcrossDevices } from "../containers/DeviceTypeContainers";
import { ASSETS_TYPES, i18n } from "../utils/utils";
import Configure2FA from "../screens/users/Configure2FA";
import {

  EXPO_PUBLIC_APP_ENV,
} from "@env";
const Stack = createNativeStackNavigator();

const defaultHeader = (title, renderBackBtn, renderPlusBtn) => {
  return {
    header: (navigationProps) => {
      return (
        <Header
          title={title}
          renderBackBtn={renderBackBtn}
          renderPlusBtn={renderPlusBtn}
          {...navigationProps}
        />
      );
    },
  };
};
const isDevelopment = EXPO_PUBLIC_APP_ENV === "development";
console.log("🚀 ~ file: RootStackNavigator.js:44 ~ isDevelopment:", isDevelopment);
const isStaging = EXPO_PUBLIC_APP_ENV === "staging";
console.log("🚀 ~ file: RootStackNavigator.js:46 ~ EXPO_PUBLIC_APP_ENV:", EXPO_PUBLIC_APP_ENV);
console.log("🚀 ~ file: RootStackNavigator.js:45 ~ isStaging:", isStaging);

function RootStackNavigator() {
  const authSlice = useSelector((state) => state?.auth);

  const user = authSlice?.user;
  const token = authSlice?.token;
  const loggedInWith2FA = Boolean(authSlice?.loggedIn);

  const isAccountActive = user?.status !== "disabled";

  const is2FABlocked = user?.twoFactorAuthStatus === "blocked";
  const isLoggedInWithToken = token !== null;

  const { isDesktop, isMobile, isTablet } = useAcrossDevices();



  const headerOptionsTasksMainFlow = isDesktop
    ? {
        header: (navigationProps) => (
          <Header
            renderBackBtn={true}
            title={i18n.t("screens.taskDetails.title")}
            {...navigationProps}
          />
        ),
      }
    : { headerShown: false };

  const optionsTasks =
    isDesktop || isTablet
      ? {
          header: (navigationProps) => (
            <Header
              title={i18n.t("screens.tasks.title")}
              renderBackBtn={false}
              renderPlusBtn={true}
              {...navigationProps}
            />
          ),
        }
      : { headerShown: false };

  // const ÷≥÷

  const routeMap = {
    inactive: "Configure2FA",
    active: "TwoFactorAuth",
  };

  const getInitialRouteBeforeLoginWithToken = (token, user) => {
    const isLoggedInAndAccountIsNotDisabled =
      token && user?.status !== "disabled";

    if (isLoggedInAndAccountIsNotDisabled) {
      //skip the 2FA in development
      // if (isDevelopment || isStaging) {
      //   return "Tasks";
      // }
      return routeMap[user?.twoFactorAuthStatus];
    }
    return "Login";
  };

  const initialRouteBeforeLogin = getInitialRouteBeforeLoginWithToken(
    token,
    user
  );

  const getInitialRouteAfterLogin = (user) => {
    if (loggedInWith2FA) {
      return "Tasks";
    } else {
      //skip the 2FA in development
      // if (isDevelopment || isStaging) {
      //   return "Tasks";
      // } else {
      //   return routeMap[user?.twoFactorAuthStatus];
      // }

      return routeMap[user?.twoFactorAuthStatus];
    }
  };

  const renderScreenGroup2FA = () => {
    return (
      <Stack.Group initialRouteName={initialRouteAfterLogin}>
        {initialRouteAfterLogin === "TwoFactorAuth" ? (
          <Stack.Screen
            options={{
              headerShown: false,
            }}
            name="TwoFactorAuth"
            component={TwoFactorAuth}
          />
        ) : (
          <Stack.Screen
            options={{
              headerShown: false,
            }}
            name="Configure2FA"
            component={Configure2FA}
          />
        )}
      </Stack.Group>
    );
  };

  const renderScreenGroupAfterLoginWith2FA = () => {
    return (
      <Stack.Group initialRouteName="Tasks">
        <Stack.Screen
          name="Tasks"
          options={optionsTasks}
          //update this
          component={
            isMobile
              ? MainBottomNavigator
              : withDesktopLayout(Tasks, false, true, isMobile, isDesktop)
          }
        />
        <Stack.Screen
          name="TasksFlow"
          options={headerOptionsTasksMainFlow}
          component={withDesktopLayout(
            TasksMainFlowNavigator,
            true,
            true,
            isMobile,
            isDesktop
          )}
        />
        <Stack.Screen
          name="Status"
          options={headerOptionsTasksMainFlow}
          component={withDesktopLayout(
            FlowStatus,
            false,
            false,
            isMobile,
            isDesktop
          )}
        />

        <Stack.Screen
          name="TasksDetails"
          options={{
            header: (navigationProps) => (
              <Header
                renderBackBtn={true}
                title={i18n.t("screens.taskDetails.title")}
                {...navigationProps}
              />
            ),
          }}
          component={TasksMobileDetails}
        />

        <Stack.Screen
          name="ResolveFlow"
          options={{ headerShown: false }}
          component={ResolveFlowNavigator}
        />

        <Stack.Screen
          name="Settings"
          options={defaultHeader()}
          component={withDesktopLayout(
            Settings,
            false,
            true,
            isMobile,
            isDesktop
          )}
        />

        <Stack.Screen
          name="Users"
          options={defaultHeader()}
          component={withDesktopLayout(Users, false, true, isMobile, isDesktop)}
        />
        <Stack.Screen
          name="Questionnaires"
          // options={defaultHeader()}
          component={withDesktopLayout(
            Questionnaires,
            false,
            true,
            isMobile,
            isDesktop
          )}
          initialParams={{
            isPage: true,
          }}
          options={{
            header: (navigationProps) => (
              <Header
                title={i18n.t("screens.questionnaires.title")}
                renderBackBtn={false}
                {...navigationProps}
              />
            ),
          }}
        />
        <Stack.Screen
          name="FormA"
          initialParams={{ fileCategory: ASSETS_TYPES.formA }}
          // options={defaultHeader()}
          options={{
            header: (navigationProps) => (
              <Header
                title={i18n.t("screens.formA.title")}
                renderBackBtn={false}
                {...navigationProps}
              />
            ),
          }}
          component={withDesktopLayout(
            FileLibrary,
            false,
            true,
            isMobile,
            isDesktop
          )}
        />
        <Stack.Screen
          name="OtherDocuments"
          initialParams={{ fileCategory: ASSETS_TYPES.otherDocs }}
          // options={defaultHeader()}
          options={{
            header: (navigationProps) => (
              <Header
                title={i18n.t("screens.formA.title")}
                renderBackBtn={false}
                {...navigationProps}
              />
            ),
          }}
          component={withDesktopLayout(
            FileLibrary,
            false,
            true,
            isMobile,
            isDesktop
          )}
        />
        <Stack.Screen
          name="FormB"
          initialParams={{ fileCategory: ASSETS_TYPES.formB }}
          options={{
            header: (navigationProps) => (
              <Header
                title={i18n.t("screens.formA.title")}
                renderBackBtn={false}
                {...navigationProps}
              />
            ),
          }}
          component={withDesktopLayout(
            FileLibrary,
            false,
            true,
            isMobile,
            isDesktop
          )}
        />
        <Stack.Screen
          name="Notifications"
          options={{
            header: (navigationProps) => (
              <Header
                title={i18n.t("screens.notifications.titleMobile")}
                renderBackBtn={false}
                {...navigationProps}
              />
            ),
          }}
          component={withDesktopLayout(
            Notifications,
            false,
            true,
            isMobile,
            isDesktop
          )}
        />
        <Stack.Screen
          options={{
            headerShown: false,
          }}
          name="ChangeDevice"
          initialParams={{ mode: "change-device" }}
          component={Configure2FA}
        />

        <Stack.Screen
          options={{
            header: (navigationProps) => (
              <Header
                title={i18n.t("screens.account.title")}
                {...navigationProps}
              />
            ),
          }}
          initialParams={{ mode: "change-device" }}
          name="Account"
          component={AccountMobile}
        />
      </Stack.Group>
    );
  };

  const initialRouteAfterLogin = getInitialRouteAfterLogin(user);

  const isLoggedInWithTokenAndAccountIsActive =
    isLoggedInWithToken && isAccountActive && !is2FABlocked;

  const isLoggedInWith2FA = loggedInWith2FA
  // const isLoggedInWith2FA = isDevelopment || isStaging ? true : loggedInWith2FA;

  return (
    <Stack.Navigator initialRouteName={initialRouteBeforeLogin}>
      {!isLoggedInWithTokenAndAccountIsActive ? (
        // No token found, user isn't signed in
        <Stack.Screen
          name="Login"
          component={Login}
          options={{
            title: "Sign in",
            headerShown: false,
            // When logging out, a pop animation feels intuitive
            // You can remove this if you want the default 'push' animation
            //   animationTypeForReplace: state.isSignout ? "pop" : "push",
          }}
        />
      ) : !isLoggedInWith2FA ? (
        renderScreenGroup2FA()
      ) : (
        renderScreenGroupAfterLoginWith2FA()
      )}
    </Stack.Navigator>
  );
}

RootStackNavigator.propTypes = {};

export default RootStackNavigator;
