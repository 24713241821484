import React, { Suspense } from "react";
import {
  Text,
  Box,
  Center,
  HStack,
  Button,
  VStack,
  Toast,
  useTheme,
} from "native-base";
import {Platform } from 'react-native'
import NavBtn from "../../../components/shared/NavBtn";
import { TASKS_FLOW_SCREENS_DATA_TYPES } from "../../../../assets/constants/tasksFlowDataType";
import SelectMultifunctional from "../../../components/SelectMultifunctional";
import DateSelect from "./shared/DateSelect";
import LoadingData from "../../../components/LoadingData";
import { TASKS_STATUS_TYPES } from "../tasksStatusTypes";
import FlowNavigationDesktop from "./FlowNavigationDesktop";
import DocsPicker from "./shared/DocsPicker";
import {
  su,
  useAcrossDevices,
  ASSETS_TYPES,
  DOCS_PICKER_MODES,
  MAIN_FLOW_STEPS,
  getUserFullName,
  TASKS_FLOW_MODES,
  displayServerErrors,
  FLOW_STATUS_MODES,
  SUBHEADER_HEIGHT,
  dataUrItoBlob,
  HEADER_HEIGHT,
  windowHeight,
  i18n,
} from "../../../utils/utils";
import { RowCardMobileVariants } from "../../../components/table/rows/rowCardMobileVariants";
import { useDispatch, useSelector } from "react-redux";
import { InstanceTasksMainFlowModel } from "./TaskFlowObj";
import {
  setFlowAttributeValue,
  setDataSource,
  setIsFetchingData,
  setIsCurrent,
  setQuestionnairesFetched,
  setAttributeAcknowledged,
} from "../../../features/tasksSlice";
import {
  useLazyGetDocOfProjectsQuery,
  useLazyGetAreasOfProjectQuery,
  useLazyGetDisOfAreaQuery,
  useLazyGetPnOfDisQuery,
  useLazyGetActivitiesQuery,
  useLazyGetQuestionnersOfPnQuery,
  useLazyGetAllProjectsQuery,
  useLazyGetAllUsersQuery,
  useAddTaskMutation,
  useEditTaskMutation,
} from "../../../services/apiService";
import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import FlowProgressTablet from "./progress/FlowProgressTablet";
import _ from "lodash";

const FlowProgressMobileBox = React.lazy(() =>
  import("./progress/FlowProgressMobileBox")
);

export const flowScreens = InstanceTasksMainFlowModel.getFlowNavigatorConfig();

export const getScreenInfo = (flowState) => {
  const flowData = flowState?.data;
  const acknowledgeMode = flowState.mode === TASKS_FLOW_MODES.acknowledgeMode;

  const CURRENT_FLOW_SCREENS = acknowledgeMode
    ? InstanceTasksMainFlowModel.getFlowNavigatorConfig(true)
    : flowScreens;

  const getCurrentScreen = () => {
    let currentScreenKey = null;
    let index = 0;
    let previousNav = CURRENT_FLOW_SCREENS[index-1]?.name
    const defaultScreen = {
      ...CURRENT_FLOW_SCREENS[0],
      screenName: CURRENT_FLOW_SCREENS[0].name,
      screenNumber: index,
      variant: CURRENT_FLOW_SCREENS[0].inputDataType,
      previousNav: previousNav,
    };

    for (const [key] of Object.entries(flowData)) {
      if (flowData[key]?.isCurrent === true) {
        currentScreenKey = key;
        break;
      }
      //TODO: investigate why it is behave different based on platform
      previousNav = CURRENT_FLOW_SCREENS[Platform.OS === "web" ? index:index-1]?.name
      // previousNav = CURRENT_FLOW_SCREENS[index-1]?.name
      index++;
    }

    const currentScreen = CURRENT_FLOW_SCREENS.find(
      (item) => item.name === currentScreenKey
    );

    if (currentScreen) {
      return {
        ...currentScreen,
        screenName: currentScreen.name,
        screenNumber: index ,
        variant: currentScreen.inputDataType,
        previousNav: previousNav,
      };
    }

    return defaultScreen;
  };

  return { flowData, acknowledgeMode, currentScreen: getCurrentScreen() };
};

const FlowInput = () => {
  const navigation = useNavigation();
  const { colors } = useTheme();

  const { isMobile, isTablet, isDesktop } = useAcrossDevices();
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.auth.user);
  const flowState = useSelector((state) => state.tasks.tasksMainFlow);
  const { currentScreen, flowData, acknowledgeMode } = getScreenInfo(flowState);
  const { variant, title, nextNav, screenName, screenNumber, previousNav } =
  currentScreen;

  const [getActivities] = useLazyGetActivitiesQuery();
  const [getAreasOfProject] = useLazyGetAreasOfProjectQuery();
  const [getDisOfArea] = useLazyGetDisOfAreaQuery();
  const [getDocOfProjects] = useLazyGetDocOfProjectsQuery();
  const [getQuestionnersOfPn, getQuestionnersOfPnRequest] =
    useLazyGetQuestionnersOfPnQuery();
  const [getPnOfDis] = useLazyGetPnOfDisQuery();
  const [getProjects, projectsListRequest] = useLazyGetAllProjectsQuery();
  const [getUsers, getUsersRequest] = useLazyGetAllUsersQuery();
  const [addTask, addTaskRequest] = useAddTaskMutation();
  const [editTask, editTaskRequest] = useEditTaskMutation();

  const setNavigation = async () => {
    if (!nextNav) {
      return;
    }
    console.log("set navigation");
    const headerColor = colors.primary["100"];

    const options = isDesktop
      ? {
          headerShown: false,
        }
      : {
          title: acknowledgeMode
            ? i18n.t(`screens.mainTasksFlow.flowTitles.acknowledge`)
            : i18n.t(`screens.mainTasksFlow.flowTitles.newTask`),
          headerStyle: {
            backgroundColor: headerColor,
          },
          headerTitleAlign: "center",
          headerTintColor: colors.base["0"],
          headerTitleStyle: {
            fontWeight: "bold",
            width: "100%",
          },
          headerRight: () =>
            renderNavButton({
              nextNav,
              onPress: onNext,
              variant: "right",
              skipNavigation: true,
            }),
          headerLeft: () =>
            renderNavButton({
              previousNav,
              onPress: onBack,
              variant: "left",
              skipNavigation: true,
            }),
        };
    navigation.setOptions(options);
  };

  React.useEffect(() => {
    async function setProjectsAndUsersDataSource() {
       if (acknowledgeMode) {
         if (
          !flowState.questionnairesFetched &&
          flowData?.pn?.value &&
          !getQuestionnersOfPnRequest.isSuccess &&
          getQuestionnersOfPnRequest?.isUninitialized &&
          !getQuestionnersOfPnRequest.isLoading &&
          !getQuestionnersOfPnRequest.isFetching
        ) {

          const response = await getQuestionnersOfPn(flowData?.pn?.value);
           if (displayServerErrors(response)) {
            return;
          }

          dispatch(
            setDataSource({
              attribute: "questionner",
              value: response.data.docs,
            })
          );
          dispatch(setQuestionnairesFetched(true));
        }
        return;
      } else {
        if (
          flowData?.project?.dataSource?.length === 0 &&
          !projectsListRequest.isLoading &&
          !projectsListRequest.isFetching
        ) {

          try {
            const projects = await getProjects();
            dispatch(
              setDataSource({
                attribute: "project",
                value: projects.data.docs,
              })
            );
          } catch (err) {
            Toast.show({
              closeAll: true,
              description: i18n.t("errorsMsg.generalError"),
            });
          }
        }

        if (
          flowData.assignTo.dataSource.length === 0 &&
          !getUsersRequest.isSuccess &&
          !getUsersRequest.isLoading
        ) {
          getUsers({ forTasks: true })
            .then((response) => {
              if (displayServerErrors(response)) {
                return;
              }
              if (response?.data?.docs) {
                const userList = response.data.docs.map((item) => {
                  let copyItem = _.cloneDeep(item);
                  copyItem.fullName = getUserFullName(copyItem);
                  return copyItem;
                });

                dispatch(
                  setDataSource({
                    attribute: "assignTo",
                    value: userList,
                  })
                );
              }
            })
            .catch(() => {
              Toast.show({
                closeAll: true,
                description: i18n.t("errorsMsg.generalError"),
              });
            });
        }
      }
    }

    setProjectsAndUsersDataSource();
  
    setNavigation();
  }, [nextNav]);

  if (projectsListRequest.isLoading || projectsListRequest.isFetching) {
    return (
      <LoadingData
        isFetching={projectsListRequest.isFetching}
        isLoading={projectsListRequest.isLoading}
      />
    );
  }

  const goToNextStep = (nextNav, params = {}) => {
    if (nextNav === "Status" || nextNav === "status") {
      navigation.replace("Status", params);
    } else {
      dispatch(
        setIsCurrent({
          step: nextNav,
        })
      );
    }
  };

  const onNextAcknowledge = async (nextNav, isConfirm = false) => {
    if (nextNav === MAIN_FLOW_STEPS.status) {
      const validation = validateFlow(flowData);
      if (validation === "12." && isConfirm) {
        Toast.show({
          closeAll: true,
          duration: 7000,
          description: i18n.t(
            `screens.mainTasksFlow.messages.pressAgainForAknowledge`
          ),
        });
        return;
      }
      if (validation !== true) {
        Toast.show({
          closeAll: true,
          description: `${i18n.t(
            `screens.mainTasksFlow.warnings.missingSteps`
          )} ${validation}`,
        });
        return;
      }
      editTask({
        id: flowData.taskInfo.id,
        status: TASKS_STATUS_TYPES.confirmationRequired,
        submissionDate: flowData.submissionDate.value,
        questionner: flowData.questionner.value.id,
        activity: flowData.activity.value.id,
        plannedStartDate: flowData.startEndDate.value[0],
        plannedEndDate: flowData.startEndDate.value[1],
      })
        .then((response) => {
          if (displayServerErrors(response)) {
            return;
          }
          if (response?.data?.doc) {
            goToNextStep("Status", {
              mode: FLOW_STATUS_MODES.acknowledge,
              status: response.error ? "fail" : "success",
              taskId: response?.data?.doc?.id,
            });
          }
        })
        .catch(() => {
          Toast.show({
            closeAll: true,
            description: i18n.t("errorsMsg.generalError"),
          });
        });

      return;
    }
    dispatch(
      setIsCurrent({
        step: nextNav,
      })
    );
  };
  const onBack = (previousNav) => {
    if (previousNav) {
      dispatch(
        setIsCurrent({
          step: previousNav,
        })
      );
    } else {
      navigation.navigate('Tasks')
    }
  };
  const onConfirm = (nextNav, screenName) => {
    dispatch(
      setAttributeAcknowledged({
        step: screenName,
      })
    );
    onNextAcknowledge(nextNav, true);
  };

  const onNextNewTask = async (nextNav, screenName) => {
    // use did not selected an option in current screen
    const optionalFields = [
      MAIN_FLOW_STEPS.formA,
      MAIN_FLOW_STEPS.formB,
      MAIN_FLOW_STEPS.docs,
    ];
    if (
      flowData[screenName]?.value === null &&
      !optionalFields.includes(screenName)
    ) {
      Toast.show({
        closeAll: true,
        description: i18n.t("screens.mainTasksFlow.warnings.noDataSelected"),
      });
      return;
    }

    const emptyDataSourceTags = [
      MAIN_FLOW_STEPS.startEndDate,
      MAIN_FLOW_STEPS.docs,
      MAIN_FLOW_STEPS.submissionDate,
      ...optionalFields,
    ];

    if (
      flowData[nextNav] &&
      flowData[nextNav]?.dataSource &&
      flowData[nextNav]?.dataSource.length === 0 &&
      !emptyDataSourceTags.includes(nextNav)
    ) {
      Toast.show({
        closeAll: true,
        description: i18n.t("screens.mainTasksFlow.warnings.notEnoughData"),
      });

      return;
    }
    if (
      (flowData[nextNav]?.dataSource &&
        flowData[nextNav]?.dataSource.length !== 0) ||
      emptyDataSourceTags.includes(nextNav)
    ) {
      goToNextStep(nextNav);
      return;
    }

    if (nextNav === MAIN_FLOW_STEPS.status) {
      const validation = validateFlow(flowData);
      if (validation !== true) {
        const errorMsg = `${i18n.t(
          `screens.mainTasksFlow.warnings.missingSteps`
        )} ${validation}`;
        Toast.show({ closeAll: true, description: errorMsg });
        return;
      }

      const task = await createNewTask();

      if (task) {
        dispatch(setIsFetchingData(true));

        addTask(task)
          .then((response) => {
            dispatch(setIsFetchingData(false));
            if (displayServerErrors(response)) {
              return;
            }

            if (response?.data) {
              goToNextStep("Status", {
                mode: FLOW_STATUS_MODES.newTask,
                status: response.error ? "fail" : "success",
                taskId: task.id,
              });
            }
          })
          .catch(() => {
            dispatch(setIsFetchingData(false));

          });

        return;
      } else {
        Toast.show({
          closeAll: true,
          duration: 7000,
          status: "error",
          description: i18n.t("errorsMsg.generalError"),
          isClosable: true,
        });
        return;
      }
    }

    Toast.show({
      closeAll: true,
      status: "error",
      description: i18n.t("errorsMsg.generalError"),
      isClosable: true,
    });
  };

  const createNewTask = async () => {
    if (!flowData) {
      return;
    }
    const project = flowData.project.value.id;
    const area = flowData.area.value.id;
    const dis = flowData.dis.value.id;
    const pn = JSON.stringify(flowData.pn.value.map((item) => item.id));
    const questionner = flowData.questionner.value.id;
    // must pe an array because how data are dclared in be
    const activity = flowData.activity.value.id;
    const plannedStartDate = flowData.startEndDate.value[0];
    const plannedEndDate = flowData.startEndDate.value[1];
    const submissionDate = flowData.submissionDate.value;
    const status = TASKS_STATUS_TYPES.unacknowledged;
    const assignTo = flowData.assignTo.value.id;
    const formA = flowData.formA.value?.id;
    const formB = flowData.formB.value?.id;
    const files = flowData.docs.value;

    const form = new FormData();
    form.append("project", project);
    form.append("activity", activity);
    form.append("area", area);
    form.append("pn", pn);
    form.append("dis", dis);
    if (formA) {
      form.append("formA", formA);
    }
    if (formB) {
      form.append("formB", formB);
    }
    form.append("createdBy", loggedUser.id);

    form.append("assignTo", assignTo);
    form.append("status", status);
    form.append("submissionDate", submissionDate);
    form.append("plannedEndDate", plannedEndDate);
    form.append("plannedStartDate", plannedStartDate);
    form.append("questionner", questionner);

    if (files && files.length > 0) {
      files.forEach((file) => {
        form.append("file", dataUrItoBlob(file.uri), file.name);
      });
    }

    return form;
  };
  const onNext = (nextNav, screenName) => {
    let displayWarning = false;
    if (acknowledgeMode) {
      onNextAcknowledge(nextNav, false);
    } else {
      displayWarning = onNextNewTask(nextNav, screenName);
    }
    if (displayWarning === true) {
      Toast.show({
        closeAll: true,
        description: i18n.t(`screens.mainTasksFlow.warnings.noDataSelected`),
      });
    }
  };

  const onPressConfirm = () => {
    if (!nextNav) {
      return;
    }
    onConfirm(nextNav, screenName);
  };

  const dataSource = flowData?.[screenName].dataSource;

  const loadDataRelatedToProject = async (project) => {
    const projectId = project?.id;

    if (!projectId) {
      Toast.show({
        closeAll: true,
        description: i18n.t(`screens.mainTasksFlow.warnings.projectRequired`),
      });
      return;
    }

    //check if users has project manager and team leader
    if (!project?.teamLeader?.id || !project?.manager?.id) {
      Toast.show({
        closeAll: true,
        description: i18n.t(
          `screens.mainTasksFlow.warnings.noProjectManagement`
        ),
      });
      return;
    }

    try {
      dispatch(setIsFetchingData(true));
      const projectAreas = await getAreasOfProject({ projectId });
      let areasData = _.isUndefined(projectAreas.data?.docs)
        ? projectAreas.data?.doc
        : projectAreas.data?.docs;
      areasData = _.cloneDeep(areasData);
      if (areasData.length === 0) {
        Toast.show(i18n.t(`screens.mainTasksFlow.warnings.noAreas`));
      }

      dispatch(setDataSource({ attribute: "area", value: areasData }));
    } catch (err) {
      Toast.show(i18n.t(`screens.mainTasksFlow.warnings.noAreas`));
    }

    try {
      dispatch(setIsFetchingData(true));
      const projectFormAFiles = await getDocOfProjects({
        assetType: ASSETS_TYPES.formA,
        projectId,
      });
      let formAData = _.isUndefined(projectFormAFiles.data?.docs)
        ? projectFormAFiles.data?.doc
        : projectFormAFiles.data?.docs;
      formAData = _.cloneDeep(formAData);
      if (formAData.length === 0) {
        Toast.show({
          closeAll: true,
          duration: 7000,
          description: i18n.t(`screens.mainTasksFlow.warnings.noFormAFiles`),
        });
      }
      dispatch(setDataSource({ attribute: "formA", value: formAData }));
    } catch (err) {
      Toast.show({
        closeAll: true,
        description: i18n.t(`screens.mainTasksFlow.warnings.noFormAFiles`),
      });
    }

    try {
      dispatch(setIsFetchingData(true));
      const projectFormBFiles = await getDocOfProjects({
        assetType: ASSETS_TYPES.formB,
        projectId,
      });
      let formBData = _.isUndefined(projectFormBFiles.data?.docs)
        ? projectFormBFiles.data?.doc
        : projectFormBFiles.data?.docs;
      formBData = _.cloneDeep(formBData);
      if (formBData.length === 0) {
        Toast.show({
          closeAll: true,
          duration: 7000,
          description: i18n.t(`screens.mainTasksFlow.warnings.noFormBFiles`),
        });
      }
      dispatch(setDataSource({ attribute: "formB", value: formBData }));
    } catch (err) {
      Toast.show({
        closeAll: true,
        description: i18n.t(`screens.mainTasksFlow.warnings.noFormBFiles`),
      });
    }
  };

  const branchesElementsTypeMap = {
    dis: {
      getChildren: getDisOfArea,
      params: {
        projectId: flowData?.project?.value?.id,
        areaId: flowData?.area?.value?.id,
      },
    },
    pn: {
      getChildren: getPnOfDis,
      params: {
        projectId: flowData?.project?.value?.id,
        areaId: flowData?.area?.value?.id,
        disId: flowData?.dis?.value?.id,
        pnId: flowData?.pn?.value?.id,
      },
    },
    questionner: {
      getChildren: getQuestionnersOfPn,
      params: {
        projectId: flowData?.project?.value?.id,
        areaId: flowData?.area?.value?.id,
        disId: flowData?.dis?.value?.id,
        pnId: flowData?.pn?.value?.id,
      },
    },
    activity: {
      getChildren: getActivities,
      params: {
        projectId: flowData?.project?.value?.id,
        areaId: flowData?.area?.value?.id,
        disId: flowData?.dis?.value?.id,
        pnId: flowData?.pn?.value?.id,
        questionnaireId: flowData?.questionner?.value?.id,
      },
    },
  };
  const STEPS_UPDATABLE_IN_ACKNOWLEDGE_MODE = [
    MAIN_FLOW_STEPS.submissionDate,
    MAIN_FLOW_STEPS.startEndDate,
    MAIN_FLOW_STEPS.questionner,
    MAIN_FLOW_STEPS.activity,
  ];

  const validateFlow = (flowData) => {
    let result = true;

    const data = _.cloneDeep(flowData);
    delete data.taskInfo;

    const doNotValidate = ["formA", "formB", "docs"];

    Object.entries(data).forEach((item, index) => {
      if (doNotValidate.includes(item[0])) {
        return;
      }

      if (item[1].status !== "completed") {
        if (result === true) {
          result = "";
        }
        result += `${index + 1}, `;
      }
    });

    if (result === true) {
      return true;
    }

    if (result.endsWith(", ")) {
      result = result.slice(0, -2);
      result += ".";
    }

    return result;
  };
  const onSelect = async (value) => {
    if (value) {
      if (
        acknowledgeMode &&
        !STEPS_UPDATABLE_IN_ACKNOWLEDGE_MODE.includes(screenName)
      ) {
        return;
      }
      dispatch(
        setFlowAttributeValue({
          step: screenName,
          value: value,
          nextNav: nextNav,
        })
      );

      if (screenName === "project") {
        await loadDataRelatedToProject(value);
        return;
      }

      const nextEl = branchesElementsTypeMap[nextNav];
      if (!nextEl) {
        return;
      }

      let params = nextEl?.params;
      if (nextNav === "questionner") {
        if (!_.isArray(value)) {
          return;
        }
        params = { ...params, queryParams: value };
      }

      if (screenName === "project") {
        params = { projectId: value.id };
      }
      if (screenName === "dis") {
        params = { disId: value.id };
      }

      if (screenName === "pn") {
        params = value;
      }
      if (screenName === "area") {
        params = { areaId: value.id };
      }
      if (screenName === "questionner") {
        params = { questionnaireId: value.id };
      }

      if (!_.isFunction(nextEl.getChildren)) {
        console.warn("Next element doesnt have method to get childrens .");
        return;
      }
      dispatch(setIsFetchingData(true));
      nextEl.getChildren(params).then((r) => {
        let data = _.isUndefined(r.data?.docs) ? r.data?.doc : r.data?.docs;
        data = _.cloneDeep(data);

        if (nextNav === "formA" || nextNav === "formB") {
          data = data.map((item) => {
            let itemCopy = item;
            itemCopy.name = itemCopy.assetName;
            return itemCopy;
          });
        }
        if (r?.data?.totalDocs === 0) {
          Toast.show({
            closeAll: true,
            description: i18n.t("screens.mainTasksFlow.warnings.notEnoughData"),
            duration: 6000,
          });
        }
        if (nextNav === "assignTo") {
          data = data.map((item) => {
            let itemCopy = item;
            itemCopy.fullName = `${itemCopy.firstName} ${itemCopy.lastName} `;
            return itemCopy;
          });
        }
        dispatch(setDataSource({ attribute: nextNav, value: data }));
      });
    }
  };

  const filesAtr = [MAIN_FLOW_STEPS.formA, MAIN_FLOW_STEPS.formB];
  const atrSelect = filesAtr.includes(screenName) ? "assetName" : "name";
  const isInputDisabled = acknowledgeMode
    ? !STEPS_UPDATABLE_IN_ACKNOWLEDGE_MODE.includes(screenName)
    : false;

  const getDefaultValue = (screenName) => {
    if (acknowledgeMode) {
      const skipScreens = [
        MAIN_FLOW_STEPS.questionner,
        MAIN_FLOW_STEPS.activity,
      ];
      const dateScreens = [
        MAIN_FLOW_STEPS.startEndDate,
        MAIN_FLOW_STEPS.submissionDate,
      ];
      if (
        skipScreens.includes(screenName) ||
        dateScreens.includes(screenName)
      ) {
        return flowData[screenName].value;
      }

      return dataSource;
    }

    return flowData[screenName].value;
  };
  const renderNavButton = ({
    onPress,
    nextNav,
    previousNav,
    isLoading = "false",
    variant,
    skipNavigation,
  }) => (
    <NavBtn
      previousNav={previousNav}
      onPress={onPress}
      nextNav={nextNav}
      isLoading={isLoading}
      variant={variant}
      skipNavigation={skipNavigation}
    />
  );
  const defaultValueCalc = getDefaultValue(screenName);
  const flowProps = {
    extraData: screenName,
    nextNav,
    onPress: onSelect,
    disabled: isInputDisabled,
    defaultValue: defaultValueCalc,
  };

  const flowComponentsMap = {
    [TASKS_FLOW_SCREENS_DATA_TYPES.oneItem]: {
      cmp: SelectMultifunctional,
      props: {
        ...flowProps,
        dataSource,
        attribute: atrSelect,
        keyAttribute: "id",
      },
    },
    [TASKS_FLOW_SCREENS_DATA_TYPES.multipleItems]: {
      cmp: SelectMultifunctional,
      props: {
        ...flowProps,
        dataSource,
        multi: true,
        keyAttribute: "id",
      },
    },
    [TASKS_FLOW_SCREENS_DATA_TYPES.date]: {
      cmp: DateSelect,
      props: {
        ...flowProps,
        acknowledgeMode: acknowledgeMode,
      },
    },
    [TASKS_FLOW_SCREENS_DATA_TYPES.interval]: {
      cmp: DateSelect,
      props: {
        ...flowProps,
        acknowledgeMode: acknowledgeMode,
        multi: true,
      },
    },
    [TASKS_FLOW_SCREENS_DATA_TYPES.user]: {
      cmp: SelectMultifunctional,
      props: {
        ...flowProps,
        acknowledgeMode: acknowledgeMode,
        attribute: "fullName",
        keyAttribute: "id",
        dataSource,
        variant: RowCardMobileVariants.showUser,
      },
    },
    [TASKS_FLOW_SCREENS_DATA_TYPES.docsViewer]: {
      cmp: DocsPicker,
      props: {
        ...flowProps,
        // renderAddMoreBtn: true,
        renderAddMoreBtn: acknowledgeMode,
        onSelect: onSelect,
        dataSource,
        mode: DOCS_PICKER_MODES.display,
      },
    },
    [TASKS_FLOW_SCREENS_DATA_TYPES.docsPicker]: {
      cmp: DocsPicker,
      props: {
        ...flowProps,
        renderAddMoreBtn: acknowledgeMode,
        onSelect: onSelect,
        dataSource,
        mode: DOCS_PICKER_MODES.pick,
      },
    },
  };

  const renderConfirmBtn = () => {
    return (
      <Button
        style={{ width: 139, height: 47 }}
        startIcon={
          isMobile ? null : <AntDesign name="check" size={18} color="black" />
        }
        variant="outline"
        onPress={onPressConfirm}
        isLoading={
          flowState.isFetchingData ||
          addTaskRequest.isLoading ||
          editTaskRequest.isLoading
        }
        _hover={{ bg: isMobile ? "base.70" : "success.40" }}
        _pressed={{
          bg: isMobile ? "primary.70" : "success.40",
          color: "base.0",
          tintColor: "base.0",
          _text: {
            color: "base.0",
          },
        }}
        borderColor={isMobile ? "primary.70" : "success.40"}
        alignItems="center"
        _text={{
          color: isMobile ? "primary.70" : "success.40",
        }}
      >
        {isMobile
          ? i18n.t(`menus.tasksFlow.confirm`).toUpperCase()
          : i18n.t(`menus.tasksFlow.confirm`)}
      </Button>
    );
  };

  const paddingMainContent = windowHeight > 860 ? su(10, 35, 35) : 10;
  const heightMainContent =
    windowHeight - paddingMainContent - SUBHEADER_HEIGHT - HEADER_HEIGHT - 200;
  return (
    <Box
      justifyContent={isMobile ? "flex-start" : "flex-start"}
      h="full"
      style={{ flex: 1, paddingTop: isDesktop ? 10 : 0, maxHeight: 793 }}
      w="full"
      bg="base.0"
    >
      {isTablet && (
        <Center mt={2}>
          <FlowProgressTablet
            currentScreenNumber={screenNumber}
            screenName={screenName}
            acknowledgeMode={acknowledgeMode}
            navigation={navigation}
            flowData={flowData}
          />
        </Center>
      )}
      {isMobile && (
        <Center style={{ marginBottom: su(10, 21, 21) }}>
          <Text
            lineHeight="lg"
            style={{ fontSize: 14 }}
            color="base.90"
            fontWeight="semibold"
          >
            {title.toUpperCase()}
          </Text>
        </Center>
      )}
      <Center w="full">
        {isDesktop && (
          <FlowNavigationDesktop
            title={title}
            name={screenName}
            nextNav={nextNav}
            previousNav={previousNav}
            isLoading={flowState.isFetchingData}
            onConfirm={onConfirm}
            onNext={onNext}
            onBack={onBack}
            acknowledgeMode={acknowledgeMode}
          />
        )}
        {isTablet && acknowledgeMode && (
          <Center
            w="full"
            mt={4}
            style={{ marginBottom: 21, width: su(300, 600, 700) }}
          >
            <HStack w="full" justifyContent="space-between">
              <Text
                lineHeight="lg"
                // letterSpacing="lg"
                style={{ fontSize: 14 }}
                color="base.90"
                fontWeight="semibold"
              >
                {title.toUpperCase()}
              </Text>
              <Box>{renderConfirmBtn()}</Box>
            </HStack>
          </Center>
        )}
      </Center>

      <Center>
        <Box
          style={{
            minHeight: 200,
            height: heightMainContent,
            maxHeight: 670,
            width: su(300, 600, 700),
            maxWidth: 672,
          }}
          borderColor="neutral.20"
          borderWidth="1"
          alignItems="center"
          alignContent="center"
        >
          <Center
            style={{
              maxWidth: 600,

              paddingTop: paddingMainContent,
              paddingBottom: paddingMainContent,
            }}
            flex={1}
          >
            {/* {flowComponentsMap[variant]} */}
            {flowComponentsMap?.[variant]?.cmp &&
              flowComponentsMap[variant]?.props &&
              React.createElement(flowComponentsMap[variant].cmp, {
                ...flowComponentsMap[variant].props,
              })}
          </Center>
        </Box>
      </Center>

      {isMobile && (
        <VStack w="full" position="absolute" bottom={0} space={3} pb={5}>
          {acknowledgeMode && (
            <Box w="full" alignItems="center">
              {renderConfirmBtn()}
            </Box>
          )}
          <Suspense>
            <FlowProgressMobileBox
              currentScreenNumber={screenNumber}
              screenName={screenName}
              navigation={navigation}
            />
          </Suspense>
        </VStack>
      )}
    </Box>
  );
};

export default FlowInput;
