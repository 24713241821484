import FileDone from "../icons/file-done";
import React from "react";
import {
  AntDesign,
  FontAwesome5,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import AccountMenu from "../../src/components/header/AccountMenu";
import { flowScreens } from "../../src/screens/tasks/main-flow/TasksMainFlowNavigator";

export const MAIN_FLOW_MENU = flowScreens.map((item) => {
  return {
    as: MaterialCommunityIcons,
    hideForLimitedUserClass: false,
    path: item.name,
    name: item.name,
  };
});

export const DESKTOP_MENU = [
  {
    title: "Add new task",
    as: AntDesign,
    name: "pluscircleo",
    hideForLimitedUserClass: true,
    path: "TasksFlow",
  },
  {
    title: "Tasks Status",
    as: FontAwesome5,
    name: "tasks",
    hideForLimitedUserClass: false,
    path: "Tasks",
  },

  {
    title: "Team members",
    as: FontAwesome5,
    name: "users",
    userClass: "super",
    hideForLimitedUserClass: true,
    path: "Users",
  },
  {
    title: "ITPs",
    as: FontAwesome5,
    name: "file-excel",
    userClass: "super",
    hideForLimitedUserClass: false,
    path: "Questionnaires",
  },
  {
    title: "Form A",
    as: FontAwesome5,
    name: "file-pdf",
    userClass: "super",
    hideForLimitedUserClass: false,
    path: "FormA",
  },
  {
    title: "Form B",
    as: FontAwesome5,
    name: "file-alt",
    userClass: "super",
    hideForLimitedUserClass: false,
    path: "FormB",
  },
  {
    title: "Other documents",
    as: FontAwesome5,
    name: "file-alt",
    userClass: "super",
    hideForLimitedUserClass: false,
    path: "OtherDocuments",
  },
];
export const DESKTOP_HEADER_MENU = [
  {
    title: "Settings",
    as: AntDesign,
    name: "setting",
    hideForLimitedUserClass: true,
    path: "Settings",
  },
  {
    title: "Notifications",
    as: AntDesign,
    name: "bells",
    hideForLimitedUserClass: false,
    path: "Notifications",
  },
  {
    title: "Account",
    icon: <AccountMenu />,
    size: "auto",
    hideForLimitedUserClass: false,
    path: "Account",
  },
  {
    title: "Logout",
    as: AntDesign,
    name: "logout",
    hideForLimitedUserClass: false,
  },
];

export const TABLET_MENU = [
  {
    title: "Tasks",
    icon: <FileDone size={20} fill="white" />,
    hideForLimitedUserClass: false,
    path: "Tasks",
  },
  {
    title: "Approve",
    as: AntDesign,
    name: "bells",
    hideForLimitedUserClass: false,
    path: "Notifications",
  },
  {
    title: "ITP",
    as: AntDesign,
    name: "file1",
    hideForLimitedUserClass: false,
    path: "Questionnaires",
  },

  {
    title: "Account",
    path: "Account",
    hideForLimitedUserClass: false,
  },
];

export const MOBILE_MENU = TABLET_MENU.slice(
  TABLET_MENU.length,
  TABLET_MENU.length
);
