import React from "react";
import { Button, Text, AlertDialog, Box, Divider, HStack, Toast } from "native-base";
import { i18n, TREE_LEVELS } from "../../../utils/utils";
import InputTreeItem from "./InputTreeItem";
import {
  useDeleteAreaMutation,
  useDeleteProjectMutation,
  useEditProjectMutation,
  useEditAreaMutation,
  useEditDisMutation,
  useDeleteDisMutation,
  useEditPnMutation,
  useDeletePnMutation,
  useEditQuestionnerMutation,
  useDeleteQuestionnerMutation,
  useDeleteAssetMutation,
  useEditAssetMutation,
} from "../../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, setTitle } from "../../../features/uxSlice";
import _ from "lodash";
const MODES = { edit: "edit", delete: "delete", default: "default" };

const SettingsItemsMenu = ({ data }) => {
  const [mode, setMode] = React.useState(MODES.default);
  const currentPage = useSelector((state) => state.settings.currentPage);
  const [deleteProject, deleteProjectRequest] = useDeleteProjectMutation();
  const [editProject] = useEditProjectMutation();
  const [deleteArea] = useDeleteAreaMutation();
  const [editArea] = useEditAreaMutation();
  const [editDis] = useEditDisMutation();
  const [deleteDis] = useDeleteDisMutation();
  const [editPn] = useEditPnMutation();
  const [editQuestionner] =
    useEditQuestionnerMutation();
  const [deleteQuestionner] =
    useDeleteQuestionnerMutation();
  const [deleteAsset] = useDeleteAssetMutation();
  const [editAsset] = useEditAssetMutation();

  const [deletePn] = useDeletePnMutation();

  const dispatch = useDispatch();

  const enableDeleteMode = () => {
    setMode(MODES.delete);
  };
  const cancel = () => {
    setMode(MODES.default);
    dispatch(setTitle(tiles[MODES.default]));
  };

  const title = data?.title || "";
  const sb = "screens.settings";

  const elementsMap = {
    [TREE_LEVELS.projects]: {
      elementTypeString: i18n.t(`${sb}.buttons.elProject`),
      editMethod: editProject,
      deleteMethod: deleteProject,
      itemName: i18n.t(`treeItems.project.one`),
    },
    [TREE_LEVELS.areas]: {
      elementTypeString: i18n.t(`${sb}.buttons.elArea`),
      editMethod: editArea,
      deleteMethod: deleteArea,
      itemName: i18n.t(`treeItems.area.one`),
    },
    [TREE_LEVELS.dis]: {
      elementTypeString: i18n.t(`${sb}.buttons.elDis`),
      editMethod: editDis,
      deleteMethod: deleteDis,
      itemName: i18n.t(`treeItems.project.elDis`),
    },
    [TREE_LEVELS.pn]: {
      elementTypeString: i18n.t(`${sb}.buttons.elPn`),
      itemName: i18n.t(`treeItems.project.elPn`),
      editMethod: editPn,
      deleteMethod: deletePn,
    },
    [TREE_LEVELS.questionnaires]: {
      elementTypeString: i18n.t(`${sb}.buttons.elQuestionnaire`),
      itemName: i18n.t(`treeItems.questionnaire.one`),
      editMethod: editQuestionner,
      deleteMethod: deleteQuestionner,
    },
    [TREE_LEVELS.formA]: {
      elementTypeString: i18n.t(`${sb}.buttons.elFormA`),
      itemName: i18n.t(`treeItems.formA`),
      editMethod: editAsset,
      deleteMethod: deleteAsset,
    },
    [TREE_LEVELS.formB]: {
      elementTypeString: i18n.t(`${sb}.buttons.elFormB`),
      itemName: i18n.t(`treeItems.formB`),
      editMethod: editAsset,
      deleteMethod: deleteAsset,
    },
    [TREE_LEVELS.otherDocs]: {
      elementTypeString: i18n.t(`${sb}.buttons.elRelatedDocs`),
      itemName: i18n.t(`treeItems.otherDocs`),
      editMethod: editAsset,
      deleteMethod: deleteAsset,
    },
    [TREE_LEVELS.default]: { elementTypeString: i18n.t(`${sb}.buttons.el`) },
  };
  const elementType = elementsMap[data?.elementType]?.elementTypeString
    ? elementsMap[data?.elementType]?.elementTypeString
    : elementsMap[TREE_LEVELS.default]?.elementTypeString;

  const tiles = {
    [MODES.default]: `Operations on the ${elementType}: ${title}`,
    [MODES.edit]: i18n.t(`${sb}.buttons.editName`, { elementType }),
    [MODES.delete]: i18n.t(`${sb}.buttons.deleteItem`, { elementType }),
  };

  const deleteElement = async () => {
    if (!data?.elementType) {
      return;
    }
    const el = elementsMap[data?.elementType];

    if (!el) {
      return;
    }

    const requestParams = {
      id: data?.key,
      parentKeys: data?.parentKeys,
    };

    el?.deleteMethod(requestParams)
      .then((response) => {
        dispatch(closeModal());

        if (response.error?.data?.errors) {
          response.error?.data?.errors.forEach((err) => {
              Toast.show({closeAll: true,
              description: "Request failed. " + err.message,
            });
          });
          return;
        }

        if (response?.error?.data?.message) {
            Toast.show({closeAll: true,
            description: response.error.data.message,
          });
          return;
        }
        if (response.data) {
            Toast.show({closeAll: true,
            description: "The deletion of the item was completed successfully.",
          });
          return;
        }
      })
      .catch(() => {
          Toast.show({closeAll: true,
          description: i18n.t(`errorsMsg.generalError`),
        });
      });
  };

  const editElement = async (newName) => {
    if (!data?.elementType || !_.isString(newName)) {
      return;
    }

    const el = elementsMap[data?.elementType];
    if (!el) {
      return;
    }

    const assets = [
      TREE_LEVELS.formA,
      TREE_LEVELS.formB,
      TREE_LEVELS.otherDocs,
    ];
    const reqParams = assets.includes(data?.elementType)
      ? {
          id: data?.key,
          assetName: newName,
          projectId: data?.projectId,
          assetType: data?.elementType,
          page: currentPage,
          parentKeys: data?.parentKeys,
        }
      : {
          id: data?.key,
          name: newName,
          projectId: data?.projectId,
          assetType: data?.elementType,
          page: currentPage,
          parentKeys: data?.parentKeys,
        };

    elementsMap[data?.elementType]
      ?.editMethod(reqParams)
      .then((response) => {
        dispatch(closeModal());
        if (response.error?.data?.errors) {
          response.error?.data?.errors.forEach((err) => {
              Toast.show({closeAll: true,
              description: "Request failed. " + err.message,
            });
          });
          return;
        }

        if (response?.error?.data?.message) {
            Toast.show({closeAll: true,
            description: response.error.data.message,
          });
          return;
        }
        if (response?.data) {
            Toast.show({closeAll: true,
            description: "Item was renamed succefully.",
          });
          return;
        }
      })
      .catch(() => {
          Toast.show({closeAll: true,
          description: i18n.t(`errorsMsg.generalError`),
        });
      });
  };

  const enableEditMode = () => {
    setMode(MODES.edit);
    dispatch(setTitle(tiles[MODES.edit]));
  };

  const activeModesContent = {
    [MODES.default]: (
      <HStack alignItems="center" alignContent="center" justifyItems="center">
        <Text bold>Available actions: </Text>
        <Divider orientation="vertical" mx={2} color="amber.100" />
        <Button.Group px={10} space={2}>
          <Button colorScheme="primary" onPress={enableEditMode}>
            {i18n.t("screens.settings.buttons.editName", { elementType })}
          </Button>
          <Button onPress={enableDeleteMode} colorScheme="danger">
            {i18n.t("screens.settings.buttons.deleteItem", { elementType })}
          </Button>
        </Button.Group>
      </HStack>
    ),
    [MODES.edit]: (
      <InputTreeItem
        cancelHandler={cancel}
        isInputDisplayed={true}
        value={title}
        onSubmit={editElement}
      />
    ),
    [MODES.delete]: (
      <Text>{i18n.t(`${sb}.dialogsContent.deleteWarning`, { title })}</Text>
    ),
  };
  return (
    <AlertDialog.Content>
      <AlertDialog.Body w="lg">
        <Box>{activeModesContent[mode]}</Box>
      </AlertDialog.Body>
      <AlertDialog.Footer>
        {mode === MODES.delete && (
          <Button.Group>
            <Button onPress={cancel}>{i18n.t(`${sb}.buttons.cancel`)}</Button>
            <Button
              isDisabled={!deleteProjectRequest.isUninitialized}
              isLoading={deleteProjectRequest.isLoading}
              onPress={deleteElement}
              colorScheme="danger"
            >
              {i18n.t(`${sb}.buttons.deletePermanently`)}
            </Button>
          </Button.Group>
        )}
      </AlertDialog.Footer>
    </AlertDialog.Content>
  );
};

export default SettingsItemsMenu;
