// const prefix = Linking.createURL("/");

export const appRoutes = {
  screens: {
    PreviewComponents: "PreviewComponents",
    Login: "login",
    Configure2FA: "configure2FA",
    ChangeDevice: "change-device",

    Tasks: {
      path: "tasks",
      screens: {
        TasksMobile: {
          path: "tasks-mobile",
        },
      },
    },
    TasksSelector: {
      path: "tasks-selector",
      screens: {
        Project: { path: "project" },
        Area: { path: "area" },
        Dis: { path: "dis" },
        Pn: { path: "pn" },
        Questionner: { path: "questionner" },
        Activity: { path: "activity" },
      }
    },
    TasksFlow: {
      path: "tasks-flow",
      screens: {
        Project: { path: "project" },
        Area: { path: "area" },
        Dis: { path: "dis" },
        Pn: { path: "pn" },
        Questionner: { path: "questionner" },
        Activity: { path: "activity" },
        StartEndDate: { path: "startEndDate" },
        FormA: { path: "formA" },
        Docs: { path: "docs" },
        FormB: { path: "formB" },
        SubmissionDate: { path: "submissionDate" },
        AssignTo: { path: "assignTo" },
        Status: { path: "status" },
        Main: { path: "main" },
      },
    },

    Settings: "settings",
    FormA: "formA",
    FormB: "formB",
    Questionnaires: "Questionnaires",
    TaskMobile: "TaskMobile",

    Users: "users",
    Notifications: "notifications",

    ReportError: "report-error",

    Test: "test",
    Status: "status",
    ResolveFlow: {path: 'resolve-flow', screens: {
      ResultsTable: "results-table",
      Report:{ path: "report" },
      ReportError:{ path: "report-error" },
      ResolveTask:{ path: "resolve-task" },
    }}
  },
};

export const PERSISTENCE_KEY = "NAVIGATION_STATE_V1";
export const linking = {
  prefixes: ["anuuapp://", "https://localhost:19006/"],
  config: appRoutes,
};
