import React from "react";
import { Icon, useTheme, VStack, Badge, Text } from "native-base";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { AntDesign, FontAwesome5 } from "@expo/vector-icons";
import { userClasses } from "../../assets/constants/userClasses";
import NotificationsMobile from "../screens/notifications/NotificationsMobile";
import QuestionnairesScreen from "../screens/questionnaires/Questionnaires";
import AccountMobile from "../screens/Account";
import Header from "../components/header";
import { getBottomMenuHeight } from "../utils/utils";
import TasksSelector from "../screens/tasks/TasksSelector";
import { useSelector } from "react-redux";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useGetNotificationsCountQuery } from "../services/apiService";
import { i18n } from "../utils/utils";

const Tab = createBottomTabNavigator();

function MainBottomNavigator() {
  const { colors } = useTheme();
  const insets = useSafeAreaInsets();

  const notificationsCount = useSelector(
    (state) => state.ux.notificationsCount
  );
  const authSlice = useSelector((state) => state.auth);
  useGetNotificationsCountQuery(null, {
    skip: authSlice?.user === null,
  });
  const accesss = authSlice.access;
  const isAbleToSeeNotifications =
    accesss?.collections?.notifications?.read?.permission === true;

  const screenOptions = {
    tabBarActiveTintColor: colors.primary["50"],
    tabBarInactiveTintColor: "white",
    tabBarLabelStyle: {
      fontFamily: "Roboto_400Regular",
      fontSize: 8,
      // letterSpacing: 0.2,
      lineHeight: 20,
    },
    tabBarStyle: {
      backgroundColor: colors.primary["100"],
      height: getBottomMenuHeight(insets?.top),
      paddingTop: 16,

      paddingRight: 28,
      paddingLeft: 28,
    },
    tabBarLabelPosition: "below-icon",
  };
  return (
    <Tab.Navigator
      initialRouteName="TasksSelector"
      screenOptions={screenOptions}
    >
      <Tab.Screen
        name="TasksSelector"
        component={TasksSelector}
        initialParams={{ userClass: userClasses.super }}
        options={{
          tabBarLabel: i18n.t("components.mobileBottomMenu.tasks"),
          title: i18n.t("screens.tasks.title"),
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
            <Icon as={FontAwesome5} name="tasks" color={color} size={size} />
          ),
        }}
      />
      {isAbleToSeeNotifications && (
        <Tab.Screen
          name="NotificationsMobile"
          component={NotificationsMobile}
          options={{
            tabBarLabel: i18n.t("components.mobileBottomMenu.notifications"),
            title: i18n.t("screens.notifications.titleMobile"),
            header: (navigationProps) => (
              <Header
                title={i18n.t("screens.notifications.titleMobile")}
                {...navigationProps}
              />
            ),
            tabBarIcon: ({ color, size }) => (
              <>
                {notificationsCount > 0 ? (
                  <VStack
                    style={{ height: 52, width: 62 }}
                    alignItems="center"
                    display="flex"
                  >
                    <Badge
                      bg="accent.100"
                      rounded="full"
                      mb={-2}
                      zIndex={1}
                      variant="solid"
                      display="flex"
                      alignSelf="flex-end"
                      alignItems="center"
                      alignContent="center"
                      textAlign="center"
                      justifyItems="center"
                      w="5"
                      h="5"
                      p={0}
                      justifyContent="center"
                      _text={{
                        fontSize: 8,
                      }}
                    >
                      <VStack
                        alignContent="center"
                        justifyContent="center"
                        display="flex"
                        justifyItems={"center"}
                        alignItems="center"
                      >
                        <Text color="base.0" lineHeight="xs" fontSize={16}>
                          {notificationsCount > 9 ? "9+" : notificationsCount}
                        </Text>
                      </VStack>
                    </Badge>
                    <Icon
                      as={AntDesign}
                      name="bells"
                      color={color}
                      size={size}
                    />
                  </VStack>
                ) : (
                  <Icon as={AntDesign} name="bells" color={color} size={size} />
                )}
              </>
            ),
          }}
        />
      )}
      <Tab.Screen
        name="Questionnaires"
        component={QuestionnairesScreen}
        options={{
          tabBarLabel: i18n.t("components.mobileBottomMenu.surveys"),
          header: (navigationProps) => (
            <Header
              title={i18n.t("screens.questionnaires.title")}
              {...navigationProps}
            />
          ),
          tabBarIcon: ({ color, size }) => (
            <Icon
              as={AntDesign}
              name="file1"
              pl={0}
              size={size}
              color={color}
            />
          ),
        }}
      />
      <Tab.Screen
        name="Account"
        component={AccountMobile}
        options={{
          tabBarLabel: i18n.t("components.mobileBottomMenu.account"),
          header: (navigationProps) => (
            <Header
              title={i18n.t("screens.account.title")}
              {...navigationProps}
            />
          ),
          tabBarIcon: ({ color, size }) => (
            <Icon as={AntDesign} name="user" color={color} size={size} />
          ),
        }}
      />
    </Tab.Navigator>
  );
}

MainBottomNavigator.propTypes = {};

export default MainBottomNavigator;
