import React from "react";
import { IconButton, useTheme, HStack, Text, Pressable } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { su } from "../../utils/utils";
import { useNavigation, CommonActions } from "@react-navigation/native";
import _ from "lodash";
const NavBtn = ({
  variant = "right",
  nextNav = null,
  onPress,
  previousNav = null,
  skipNavigation = false,
  renderText = false,
  isDisabled = false,
  textProp=null,
  isLoading
}) => {
  const { colors } = useTheme();
  const navigation = useNavigation();

  const getText = () =>{
    if(textProp===null){
      return variant === "right" ? "Next" : "Previous";
    }
    return textProp;
  }

  const text = getText();

  const iconName = variant === "right" ? "arrowright" : "arrowleft";

  const onPressBtn = () => {
    if (onPress) {
      const params = variant === "right" ? nextNav : previousNav;
      onPress(params);
    }

    if (skipNavigation) {
      return;
    }

    if (variant === "right") {
      if (_.isString(nextNav)) {
        navigation.navigate(nextNav);
      } else {
        navigation.navigate(...nextNav);
      }
    } else {
      navigation.dispatch(CommonActions.goBack());
    }
  };
const color = !isDisabled ? "base.0":"neutral.50"
  return (
    <Pressable
      style={{
        marginLeft: su(14, 35),

        marginRight: su(14, 35),
        paddingRight:variant === "right" ? su(1,0,0):0
      }}
      isDisabled={isDisabled}
      onPress={onPressBtn}
    >
      <HStack  space={2}>
        {renderText && variant === "right" && (
          <Text color={color}>{text}</Text>
        )}
        <IconButton
          title="Info"
          color={color}
          borderWidth="1"
          onPress={onPressBtn}
          borderRadius="sm"
          isLoading={isLoading}
          borderStyle="solid"
          borderColor={colors.base[0]}
          style={{
            width: 26,
            height: 26,
          }}
          mr="2"
          _icon={{
            as: AntDesign,
            name: iconName,
            width: su("13"),
            height: su("13"),
            color: colors.base["0"],
          }}
        />
        {renderText && variant === "left" && <Text color="base.0">{text}</Text>}
      </HStack>
    </Pressable>
  );
};

export default NavBtn;
