import React from "react";
import TaskFilterScreen from './TasksFilterScreen';
import Header from "../../components/header";
import {
  useGetProjectsQuery,

} from "../../services/apiService";
import { createStackNavigator } from "@react-navigation/stack";
import { filtersList } from './taskFilters.constant';

const Stack = createStackNavigator();

const TasksSelector = () => {
 useGetProjectsQuery('1');

  return (
    <Stack.Navigator
      style={{ backgroundColor: "black" }}
      initialRouteName={"project"}
    >
      <Stack.Group>
        {filtersList.map((item, index) => {
          const { title, name } = item;
          const header = (navigationProps) => (
            <Header
              title={title}
              renderBackBtn={index !== 0}
              {...navigationProps}
            />
          );

          return (
            <Stack.Screen
              key={name}
              name={name}
              options={{
                title,
                header,
              }}
              initialParams={{
                screenName: item?.name,
              }}
              component={TaskFilterScreen}
            />
          );
        })}
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default TasksSelector;
