import { Button, Modal, Center, Toast, Text } from "native-base";
import React from "react";
import { KeyboardAvoidingView, Platform } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../features/uxSlice";
import ViewMediaCollection from "../components/ViewMediaCollection";
import AddImage from "../screens/tasks/resolve-task/AddImage";
import ReportError from "../screens/tasks/ReportError";
import Questionnaires from "../screens/questionnaires/Questionnaires";
import Report from "../screens/tasks/results/FinalReport";
import UserModal from "../screens/users/UserModal";
import LocationHistory from "../screens/tasks/tasks-table-components/LocationHistory";
import {
  USER_MODAL_MODES,
  MODAL_CONTENT_TYPES,
  i18n,
  su,
  REPORT_ERROR_MODES,
  displayServerErrors,
} from "../utils/utils";
import ChangePassword from "../screens/users/ChangePassword";
import Configure2FA from "../screens/users/Configure2FA";
import EditTaskModal from "../screens/tasks/tasks-table-components/EditTaskModal";
import SettingsItemsMenu from "../screens/tasks/settings/SettingsItemsMenu";
import {
  useDeleteTaskMutation,
  useEditTaskMutation,
  useCreateUserMutation,
  useResetPasswordMutation,
  useEditUserMutation,
} from "../services/apiService";
import Warning from "./Warning";
import _ from "lodash";



const ModalRich = ({ onSave = () => {}, preventClose = false }) => {
  const [deleteTask] = useDeleteTaskMutation();
  const [editUser] = useEditUserMutation();
  const [editTask] = useEditTaskMutation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [createUser] = useCreateUserMutation();
  const [resetPassword] = useResetPasswordMutation();
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.ux.modal);

  const { isOpen, contentBody, data } = modalState;

  const modalMap = {
    images: {
      cmp: <AddImage />,
      title: i18n.t("modalTitles.addImage"),
      hideFooter: true,
    },
    [MODAL_CONTENT_TYPES.questionner]: {
      cmp: <Questionnaires />,
      title: i18n.t("modalTitles.viewQuestionnaire"),
      hideFooter: true,
    },
    [MODAL_CONTENT_TYPES.reportError]: {
      cmp: (
        <ReportError
          task={modalState?.data?.task}
          mode={REPORT_ERROR_MODES.view}
          data={modalState?.data}
        />
      ),
      title: i18n.t("modalTitles.reportError"),
      hideFooter: true,
    },
    [MODAL_CONTENT_TYPES.finalReport]: {
      cmp: <Report reportId={modalState.data} />,
      title: i18n.t("modalTitles.finalError"),
      hideFooter: true,
    },
    [MODAL_CONTENT_TYPES.locationHistory]: {
      cmp: <LocationHistory data={modalState.content} />,
      title: i18n.t("modalTitles.locationHistory"),
      hideFooter: true,
    },
    [MODAL_CONTENT_TYPES.docs]: {
      cmp: <ViewMediaCollection />,
      title: i18n.t("modalTitles.viewDocs"),
      hideFooter: true,
    },
    [MODAL_CONTENT_TYPES.viewMediaCollection]: {
      cmp: <ViewMediaCollection />,
      title: i18n.t("modalTitles.viewDocs"),
      hideFooter: true,
    },
    [MODAL_CONTENT_TYPES.newUserModal]: {
      cmp: <UserModal />,
      title: i18n.t("modalTitles.newUser"),
      hideFooter: false,
      onSubmit: createUser,
      params: modalState.content,
    },
    [MODAL_CONTENT_TYPES.editUserModal]: {
      cmp: <UserModal toast={Toast} mode={USER_MODAL_MODES.editUser} />,
      title: i18n.t("modalTitles.editUser"),
      hideFooter: false,
      onSubmit: editUser,
      params: modalState.content,
    },
    [MODAL_CONTENT_TYPES.viewAccountModal]: {
      cmp: <UserModal mode={USER_MODAL_MODES.viewAccount} />,
      title: i18n.t("modalTitles.accountDetails"),
      hideFooter: true,
    },
    [MODAL_CONTENT_TYPES.changePassword]: {
      cmp: <ChangePassword />,

      title: i18n.t("modalTitles.changePassword"),
      hideFooter: false,
      params: modalState.content,
      onSubmit: resetPassword,
    },
    [MODAL_CONTENT_TYPES.configure2fa]: {
      cmp: <Configure2FA mode={"change-device"} />,
      title: i18n.t("modalTitles.configure2fa"),
      hideFooter: true,
    },
    [MODAL_CONTENT_TYPES.settingsElementActions]: {
      cmp: <SettingsItemsMenu toast={Toast} data={modalState?.data} />,
      hideFooter: true,
      submitLabel: "",
    },
    [MODAL_CONTENT_TYPES.warningIrreversibleProcess]: {
      cmp: <Warning toast={Toast} />,
      hideFooter: false,
      submitLabel: "Yes, I am sure.",
      onSubmit: deleteTask,
      params: modalState?.content,
    },
    [MODAL_CONTENT_TYPES.editTask]: {
      cmp: (
        <EditTaskModal
          task={modalState?.content?.task}
          selectedAttribute={modalState?.content?.selectedAttribute}
          elementType={modalState?.content?.elementType}
        />
      ),
      hideFooter: false,
      submitLabel: "Yes, I am sure.",
      onSubmit: editTask,
      params: modalState?.content,
    },
  };

  const modalTitle = !modalState?.title
    ? modalMap[contentBody]?.title
    : modalState.title;

  const close = () => {
    dispatch(closeModal());
  };

  const onPressSave = () => {
    const actionsBeforeClose = () => {
      onSave();
      if (!preventClose) {
        close();
      }
    };
    if (
      !_.isUndefined(modalMap[contentBody]?.onSubmit) &&
      _.isFunction(modalMap[contentBody]?.onSubmit)
    ) {
      let params = _.cloneDeep(modalMap[contentBody]?.params);
      if (contentBody === MODAL_CONTENT_TYPES.editUserModal) {
        if (params?.reportsTo && !_.isString(params.reportsTo)) {
          params.reportsTo = params?.reportsTo.id;
        }
      }
      setIsLoading(true);

      modalMap[contentBody]
        ?.onSubmit(params)
        .then((response) => {
        console.log("🚀 ~ file: ModalRich.js:182 ~ .then ~ response:", response);
    
          setIsLoading(false);

          const isResponseWithErros = displayServerErrors(response);

          if (isResponseWithErros) {
            actionsBeforeClose();
            return;
          }
          if (response?.data) {
              Toast.show({closeAll: true,
              description: response?.data?.message || "Your request has been successfully completed.",
            });
            actionsBeforeClose();
            return;
          }
        })
        .catch(() => {});
      setIsLoading(false);
      actionsBeforeClose();

      return;
    }
    actionsBeforeClose();
  };

  const contentComponent = !_.isUndefined(modalMap[contentBody]?.cmp)
    ? React.cloneElement(modalMap[contentBody]?.cmp, { data })
    : <><Text>Something went wrong..</Text> </>;

    if(!isOpen){
      return null;
    }

  return (
    <KeyboardAvoidingView
      h={{
        base: "400px",
        lg: "auto",
      }}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      
     {isOpen &&      <Modal
        size={modalState?.size || "lg"}
        overlayVisible={true}
        
        isOpen={isOpen}
        px={4}
        onClose={close}
        _backdrop={{
          bg: "base.90",
          opacity: 0.6,
        }}
      >
        <Modal.Content>
          <Modal.Header>
            {modalTitle}<Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Center>{contentComponent}</Center>
          </Modal.Body>
          {!modalMap[contentBody]?.hideFooter && (
            <Modal.Footer>
              <Button.Group space={2}>
                <Button
                  mt={4}
                  bg="accent.40"
                  alignItems="center"
                  justifyContent="center"
                  mx={6}
                  isLoading={isLoading}
                  isDisabled={
                    contentBody ===
                    MODAL_CONTENT_TYPES.warningIrreversibleProcess
                      ? false
                      : !modalState?.content?.isValid
                  }
                  mr={4}
                  style={{ height: su(42, 42, 32), width: 210 }}
                  onPress={onPressSave}
                >
                  {modalState.submitLabel
                    ? modalState.submitLabel

                    : i18n.t('screens.users.buttons.save')}
                </Button>
              </Button.Group>
            </Modal.Footer>
          )}
        </Modal.Content>
      </Modal>} 

    </KeyboardAvoidingView>
  );
};

export default ModalRich;
