import React from "react";
import { Tree } from "antd";
import {
  i18n,
  KEY_MEMBER_TYPES,
  MODAL_CONTENT_TYPES,
  TREE_LEVELS,
  ASSETS_TYPES,
  dataUrItoBlob,
  displayServerErrors,
  sortListAlphabeticallyByTag,
} from "../../../utils/utils";
import { Toast, Spinner, Box, HStack, Text } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { openModalAndSetContent } from "../../../features/uxSlice";
import Empty from "../../../components/Empty";
import InputTreeItem from "./InputTreeItem";
import "react-native-get-random-values";
import SelectMultifunctional from "../../../components/SelectMultifunctional";
import {
  useAddProjectMutation,
  useLazyGetAreasOfProjectQuery,
  useAddAreaMutation,
  useAddDisMutation,
  useLazyGetDisOfAreaQuery,
  useAddPnMutation,
  useLazyGetPnOfDisQuery,
  useAddQuestionnaireMutation,
  useAddAssetMutation,
  useLazyGetQuestionnersOfPnQuery,
  useEditProjectMutation,
  useLazyGetActivitiesQuery,
  useLazyGetDocOfProjectsQuery,
  useGetAllUsersQuery,
  useGetProjectsQuery,
} from "../../../services/apiService";
import {
  setCurrentPage,
  setExpandedKeys,
} from "../../../features/settingsSlice";

import LoadingData from "../../../components/LoadingData";
import LayoutPagination from "../../../containers/LayoutPagination";
import _ from "lodash";

const { DirectoryTree } = Tree;
const sb = "screens.settings";
function Settings() {
  //projects
  const currentPage = useSelector((state) => state.settings.currentPage);
  const projectsListRequest = useGetProjectsQuery(currentPage, {});
  //mutations
  const [addProject, addNewProjectRequest] = useAddProjectMutation();
  const [editProject] = useEditProjectMutation();
  const [addDis, addDisRequest] = useAddDisMutation();
  const [addArea, addAreaRequest] = useAddAreaMutation();
  const [addPn, addPnRequest] = useAddPnMutation();
  const [addQuestionnaire, addQuestionnaireRequest] =
    useAddQuestionnaireMutation();
    const [addAsset, addAssetRequest] = useAddAssetMutation();


  const forceUpdate = React.useReducer((bool) => !bool)[1];


  useGetAllUsersQuery();

  //request on expand
  const [getDisOfArea, getDisOfAreaRequest] = useLazyGetDisOfAreaQuery();
  const [getActivities, getActivitiesRequest] = useLazyGetActivitiesQuery();
  const [getDocOfProjects, getDocOfProjectsRequest] =
    useLazyGetDocOfProjectsQuery();
  const [getQuestionnersOfPn, getQuestionnersOfPnRequest] =
    useLazyGetQuestionnersOfPnQuery();
  const [getAreasOfProject, getAreasOfProjectRequest] =
    useLazyGetAreasOfProjectQuery();
  const [getPnOfDis] = useLazyGetPnOfDisQuery();
  // requests loaded on component load

  const storeState = useSelector((state) => state);
  const settingsSlice = useSelector((state) => state.settings);
  const projects = useSelector(
    (state) => state.settings.projects?.[currentPage]
  );
  const isLoading = addQuestionnaireRequest?.isLoading ||
  addAssetRequest?.isLoading ||
  addNewProjectRequest?.isLoading ||
  addAreaRequest?.isLoading ||
  addPnRequest?.isLoading ||
  addDisRequest.isLoading ||
  getDisOfAreaRequest.isLoading ||
  getActivitiesRequest.isLoading ||
  getAreasOfProjectRequest.isLoading ||
  getQuestionnersOfPnRequest.isLoading ||
  getDocOfProjectsRequest.isLoading;

  const users = storeState.users.allUsers;
  const authSlice = storeState.auth;

  const loggedUser = authSlice.user;
  const access = authSlice.access;

  const expandedKeys = settingsSlice.expandedKeys;

  const canCreateNewProjects =
    access?.collections?.projects?.create?.permission === true;

  const dispatch = useDispatch();

  const branchesElementsTypeMap = {
    [TREE_LEVELS.projects]: {
      string: i18n.t(`${sb}.buttons.elProject`),
      getChildren: getAreasOfProject,
    },
    [TREE_LEVELS.areas]: {
      string: i18n.t(`${sb}.buttons.elArea`),
      getChildren: getDisOfArea,
    },
    [TREE_LEVELS.dis]: {
      string: i18n.t(`${sb}.buttons.elDis`),
      getChildren: getPnOfDis,
    },
    [TREE_LEVELS.pn]: {
      string: i18n.t(`${sb}.buttons.elPn`),
      getChildren: getQuestionnersOfPn,
    },
    [TREE_LEVELS.questionnaires]: {
      string: i18n.t(`${sb}.buttons.elQuestionnaire`),
      getChildren: getActivities,
    },
    [TREE_LEVELS.formA]: {
      string: i18n.t(`${sb}.buttons.elFormA`),
      getChildren: getDocOfProjects,
    },
    [TREE_LEVELS.formB]: {
      string: i18n.t(`${sb}.buttons.elFormB`),
      getChildren: getDocOfProjects,
    },
    [TREE_LEVELS.otherDocs]: {
      string: i18n.t(`${sb}.buttons.elRelatedDocs`),
      getChildren: getDocOfProjects,
    },
    [TREE_LEVELS.default]: { string: i18n.t(`${sb}.buttons.el`) },
  };

  const onExpand = async (keys, info) => {
    if (!info?.expanded) {
      const newKeys = keys.filter((key) => key !== info.node.key);

      dispatch(setExpandedKeys(newKeys));
      return;
    }

    dispatch(setExpandedKeys(keys));

    const elId = info.node.key;
    const elType = info.node.elementType;

    if (!elType) {
      Toast.show({
        id: _.uniqueId("toast"),
        close: true,
        description: "An unexpected error occurred.",
      });
      return;
    }

    const serverReqParameters = {
      [TREE_LEVELS.projects]: {
        projectId: elId,
      },
      [TREE_LEVELS.areas]: {
        projectId: info.node?.parentKeys?.projectKey,
        areaId: info.node?.parentKeys?.areaKey,
      },
      [TREE_LEVELS.dis]: {
        projectId: info.node?.parentKeys?.projectKey,
        areaId: info.node?.parentKeys?.areaKey,
        disId: info?.node.parentKeys?.disKey,
        parent: elId,
      },
      [TREE_LEVELS.pn]: {
        projectId: info.node?.parentKeys?.projectKey,
        areaId: info.node?.parentKeys?.areaKey,
        disId: info?.node.parentKeys?.disKey,
        pnId: info?.node.parentKeys?.pnKey,
        parent: elId,
        requestParam: [{ id: elId }],
      },
      [TREE_LEVELS.questionnaires]: {
        projectId: info.node?.parentKeys?.projectKey,
        areaId: info.node?.parentKeys?.areaKey,
        disId: info?.node.parentKeys?.disKey,
        pnId: info?.node.parentKeys?.pnKey,
        questionnaireId: elId,
      },
      [TREE_LEVELS.formA]: {
        projectId: info.node?.parentKeys?.projectKey,
        assetType: elType,
      },
      [TREE_LEVELS.formB]: {
        projectId: info.node?.parentKeys?.projectKey,
        assetType: elType,
      },
      [TREE_LEVELS.otherDocs]: {
        projectId: info.node?.parentKeys?.projectKey,
        assetType: elType,
      },
      [TREE_LEVELS.keyPeoples]: {
        projectId: info.node?.parentKeys?.projectKey,
        assetType: elType,
      },
    };

    if (
      !branchesElementsTypeMap[elType] ||
      !_.isFunction(branchesElementsTypeMap[elType]?.getChildren)
    ) {
      return;
    }

    await branchesElementsTypeMap[elType].getChildren(
      serverReqParameters[elType]
    );
  };

  const onRightClick = (element) => {
    // we dont show dialog for buttons
    //make sure the node on that the click right was made is also the selected element;
    if (
      element?.event?.target &&
      _.isFunction(element?.event?.target?.clicks)
    ) {
      element?.event?.target?.click();
    }
    const item = element?.node;

    if (!item) {
      return;
    }

    const excludeKey = [
      TREE_LEVELS.keyPeoples,
      TREE_LEVELS.formA,
      TREE_LEVELS.formB,
    ];
    if (
      item?.isBtn ||
      excludeKey.includes(item.key) ||
      !_.isString(item?.elementType) ||
      !_.isString(item?.key) ||
      (!_.isUndefined(item?.isCategoryLabel) && item?.isCategoryLabel === true)
    ) {
      return;
    }

    const elementType = branchesElementsTypeMap[item?.elementType].string
      ? branchesElementsTypeMap[item?.elementType].string
      : branchesElementsTypeMap[TREE_LEVELS.default].string;

    const MODES = { edit: "edit", delete: "delete", default: "default" };

    const tiles = {
      [MODES.default]: i18n.t(`${sb}.buttons.defaultTitleModal`, {
        elementType,
      }),
      [MODES.edit]: i18n.t(`${sb}.buttons.editName`, { elementType }),
      [MODES.delete]: i18n.t(`${sb}.buttons.deleteItem`, { elementType }),
    };
    dispatch(
      openModalAndSetContent({
        contentBody: MODAL_CONTENT_TYPES.settingsElementActions,
        title: tiles[MODES.default],
        size: "lg",
        data: {
          key: item?.key,
          title: item?.title,
          elementType: item?.elementType,
          projectId: item?.parentKeys?.projectId,
          parentKeys: item?.parentKeys,
        },
      })
    );
  };

  const setProjectRole = (
    selectData,
    parentKeys,
    extraData,
    selectedUserId
  ) => {
    let userRoleNewValue = selectedUserId;
    if (extraData === KEY_MEMBER_TYPES.others) {
      userRoleNewValue = [];
      if (!selectData || !_.isArray(selectData)) {
        return;
      }

      userRoleNewValue = selectData.map((item) => {
        return item.id;
      });
    }

    editProject({
      id: parentKeys?.projectKey,
      projectId: parentKeys?.projectKey,
      [extraData]: userRoleNewValue,
      page: currentPage,
      skipInvalidation: true,
    })
      .then((r) => {
        if (displayServerErrors(r)) {
          return;
        }
        if (r?.data?.message) {
          Toast.show({
            id: _.uniqueId("toast"),
            close: true,
            description: r.data.message,
          });
          forceUpdate();
        }
      })
      .catch(() => {
        Toast.show({
          id: _.uniqueId("toast"),
          close: true,
          description: i18n.t(`errorsMsg.generalError`),
        });
      });
  };

  const addNewProject = (input) => {
    addProject({ name: input, createdBy: loggedUser.id })
      .then((r) => {
        if (displayServerErrors(r)) {
          return;
        }
        if (r?.data?.message) {
          Toast.show({
            id: _.uniqueId("toast"),
            close: true,
            description: r.data.message,
          });
          return;
        }
      })
      .catch(() => {
        Toast.show({
          id: _.uniqueId("toast"),
          close: true,
          description: i18n.t(`errorsMsg.generalError`),
        });
      });
  };

  const addNewArea = (value, parentKeys) => {
    addArea({
      name: value,
      projectId: parentKeys?.projectKey,
      createdBy: loggedUser.id,
    })
      .then((response) => {
        if (displayServerErrors(response)) {
          return;
        }
        if (response.data) {
          Toast.show({
            id: _.uniqueId("toast"),
            close: true,
            description: response.data.message,
          });
          return;
        }
      })
      .catch(() => {
        Toast.show({
          id: _.uniqueId("toast"),
          close: true,
          description: i18n.t(`errorsMsg.generalError`),
        });
      });
  };

  const addNewDis = (value, parentKeys) => {
    addDis({
      name: value,
      areaId: parentKeys.areaKey,
      projectId: parentKeys?.projectKey,
      createdBy: loggedUser.id,
    })
      .then((response) => {
        if (displayServerErrors(response)) {
          return;
        }
        if (response?.data) {
          Toast.show({
            id: _.uniqueId("toast"),
            close: true,
            description: response.data.message,
          });
          return;
        }
      })
      .catch(() => {
        Toast.show({
          id: _.uniqueId("toast"),
          close: true,
          description: i18n.t(`errorsMsg.generalError`),
        });
      });
  };

  const addNewPn = (value, parentKeys) => {
    addPn({
      name: value,
      areaId: parentKeys.areaKey,
      projectId: parentKeys?.projectKey,
      disId: parentKeys?.disKey,
      createdBy: loggedUser.id,
    })
      .then((response) => {
        if (displayServerErrors(response)) {
          return;
        }
        if (response?.data) {
          Toast.show({
            id: _.uniqueId("toast"),
            close: true,
            description: response.data.message,
          });
          return;
        }
      })
      .catch(() => {
        Toast.show({
          id: _.uniqueId("toast"),
          close: true,
          description: i18n.t(`errorsMsg.generalError`),
        });
      });
  };

  const getFormData = (value, parentId, assetType, projectId, createdById) => {
    const form = new FormData();
    const singleFile = value?.file;
    const nameKey =
      assetType == ASSETS_TYPES.questionner ? "name" : "assetName";
    form.append(nameKey, value?.text);
    form.append("assetType", assetType);
    form.append("createdBy", createdById);
    form.append("assetFileType", "document");
    if (assetType == ASSETS_TYPES.questionner) {
      form.append("pnId", parentId);
    }
    const parent =
      assetType == ASSETS_TYPES.questionner
        ? parentId
        : JSON.stringify({ relationTo: "projects", value: projectId });

    const parentKey = "parent";
    form.append(parentKey, parent);

    form.append("file", dataUrItoBlob(singleFile.uri), singleFile.name);

    return form;
  };

  const addNewQuestionnaire = (value, parentKeys) => {
    addNewAsset(value, parentKeys, ASSETS_TYPES.questionner, true);
  };

  const addNewAsset = (value, parentKeys, extraData, isQuestionner = false) => {
    const assetType = extraData;
    const parentId = isQuestionner ? parentKeys?.pnKey : parentKeys?.projectKey;

    const formData = getFormData(
      value,
      parentId,
      assetType,
      parentKeys?.projectKey,
      loggedUser.id
    );
    if (assetType === ASSETS_TYPES.questionner) {
      addQuestionnaire({
        form: formData,
        areaId: parentKeys.areaKey,
        projectId: parentKeys?.projectKey,
        disId: parentKeys?.disKey,
        pnId: parentKeys?.pnKey,
      })
        .then((response) => {
          if (displayServerErrors(response)) {
            return;
          }
          if (response?.data) {
            Toast.show({
              id: _.uniqueId("toast"),
              close: true,
              description: response.data.message,
            });
            return;
          }
        })
        .catch(() => {
          Toast.show({
            id: _.uniqueId("toast"),
            close: true,
            description: i18n.t(`errorsMsg.generalError`),
          });
        });
    } else {
      addAsset({
        form: formData,
        areaId: parentKeys.areaKey,
        projectId: parentKeys?.projectKey,
        disId: parentKeys?.disKey,
        pnId: parentKeys?.pnKey,
        assetType: assetType,
      })
        .then((response) => {
          if (displayServerErrors(response)) {
            return;
          }
          if (response?.data) {
            Toast.show({
              id: _.uniqueId("toast"),
              close: true,
              description: response.data.message,
            });
            return;
          }
        })
        .catch(() => {
          Toast.show({
            id: _.uniqueId("toast"),
            close: true,
            description: i18n.t(`errorsMsg.generalError`),
          });
        });
    }
  };
  const getDefaultKeyPeople = (project) => {
    if (!project || !_.isArray(project[KEY_MEMBER_TYPES.others])) {
      return undefined;
    }

    const list = project[KEY_MEMBER_TYPES.others];

    return list;
  };

  const createConfigAddBtn = ({
    method,
    label,
    parentKeys = null,
    multi,
    inputType = "text",
    defaultValue,
    dataSource,
    attribute,
    keyAttribute,
    extraData,
    isLoading = false,
    key,
  }) => {
    const getDefaultHeight = () => {
      if (multi && _.isArray(defaultValue) && defaultValue.length > 1) {
        if (defaultValue.length <= 2) {
          return (defaultValue.length - 1) * 80;
        }
        if (defaultValue.length > 2) {
          return (defaultValue.length - 1) * 30;
        }
        return "auto";
      }
    };
    const boxHeightValue = getDefaultHeight();
    const map = {
      text: (
        <InputTreeItem
          isLoading={isLoading}
          onSubmit={method}
          label={label}
          parentKeys={parentKeys}
        />
      ),
      file: (
        <InputTreeItem
          isLoading={isLoading}
          onSubmit={method}
          label={label}
          parentKeys={parentKeys}
          uploadFile={true}
          extraData={extraData}
        />
      ),
      select: (
        <HStack maxWidth="md" space={2}>
          <Box w="1/2">
            <Text>{label}: </Text>
          </Box>
          <Box w="1/2">
            <SelectMultifunctional
              onPress={method}
              label={label}
              parentKeys={parentKeys}
              multi={multi}
              defaultValue={defaultValue}
              dataSource={dataSource}
              attribute={attribute}
              keyAttribute={keyAttribute}
              extraData={extraData}
            />
          </Box>
        </HStack>
      ),
    };

    return {
      title: map[inputType],
      isLeaf: true,
      showIcon: false,
      icon: () => null,
      isBtn: true,
      key,
      style: { height: boxHeightValue },
    };
  };

  const getProjectKeyPeoplesElementsConfig = (project) => {
    const key = project.id;
    return [
      {
        method: setProjectRole,
        label: i18n.t(`${sb}.buttons.setProjectManager`),
        parentKeys: { projectKey: key },
        multi: false,
        inputType: "select",
        key: key + "setProjectRole",
        defaultValue: project?.manager,
        dataSource: users,
        attribute: "fullName",
        keyAttribute: "id",

        extraData: KEY_MEMBER_TYPES.manager,
      },
      {
        method: setProjectRole,
        label: i18n.t(`${sb}.buttons.setTeamLeader`),
        parentKeys: { projectKey: key },
        multi: false,
        inputType: "select",
        key: key + "setTeamLeader",
        defaultValue: project?.teamLeader,
        dataSource: users,
        attribute: "fullName",
        keyAttribute: "id",
        extraData: KEY_MEMBER_TYPES.teamLeader,
      },
      {
        method: setProjectRole,
        label: i18n.t(`${sb}.buttons.setOtherKeyPeoples`),
        parentKeys: { projectKey: key },
        multi: true,
        inputType: "select",
        key: key + "keyPeople",
        defaultValue: getDefaultKeyPeople(project),
        dataSource: users,
        attribute: "fullName",
        keyAttribute: "id",

        extraData: KEY_MEMBER_TYPES.others,
      },
    ].map((item) => createConfigAddBtn(item));
  };

  const getDocChildrens = (project, assetType) => {
    let children = [];

    if (_.isString(assetType) && _.isArray(project[assetType])) {
      children = project[assetType].map((item) => {
        return {
          key: item?.id,
          title: item?.assetName,
          isLeaf: true,
          elementType: item?.assetType,
          parentKeys: { projectKey: project.id },
        };
      });
    }
    return children;
  };

  const getProjectOtherDocs = (project) => {
    let formAChilden = getDocChildrens(project, ASSETS_TYPES.formA);
    let formBChilden = getDocChildrens(project, ASSETS_TYPES.formB);
    let otherDocsChildren = getDocChildrens(project, ASSETS_TYPES.otherDocs);

    return [
      {
        key: project.id + "formA",
        title: i18n.t(`${sb}.treeItemsTitles.formA`),
        isLeaf: false,
        isCategoryLabel: true,
        elementType: TREE_LEVELS.formA,
        parentKeys: { projectKey: project.id },
        children: [
          ...formAChilden,
          createConfigAddBtn({
            method: addNewAsset,
            label: i18n.t(`${sb}.buttons.newFormA`),
            parentKeys: { projectKey: project.id },
            multi: false,
            extraData: ASSETS_TYPES.formA,
            inputType: "file",
            key: project.id + "formAbtn",
            isLoading: isLoading
          }),
        ],
      },
      {
        key: project.id + "formB",
        title: i18n.t(`${sb}.treeItemsTitles.formB`),
        elementType: TREE_LEVELS.formB,
        parentKeys: { projectKey: project.id },
        isLeaf: false,
        isCategoryLabel: true,
        children: [
          ...formBChilden,
          createConfigAddBtn({
            method: addNewAsset,
            label: i18n.t(`${sb}.buttons.newFormB`),
            extraData: ASSETS_TYPES.formB,
            parentKeys: { projectKey: project.id },
            multi: false,
            inputType: "file",
            key: project.id + "formBbtn",
            isLoading: isLoading
          }),
        ],
      },
      {
        key: project.id + "otherDocs",
        elementType: TREE_LEVELS.otherDocs,
        parentKeys: { projectKey: project.id },
        title: i18n.t(`${sb}.treeItemsTitles.otherDocs`),
        isLeaf: false,
        isCategoryLabel: true,

        children: [
          ...otherDocsChildren,
          createConfigAddBtn({
            method: addNewAsset,
            label: i18n.t(`${sb}.buttons.newDoc`),
            extraData: ASSETS_TYPES.otherDocs,
            parentKeys: { projectKey: project.id },
            multi: false,
            inputType: "file",
            key: project.id + "btn",
            isLoading: isLoading
          }),
        ],
      },
    ];
  };
  const generateTree = (data) => {
    if (!data || !_.isArray(data) || data.length === 0) {
      return [];
    }
    const projectsTree = data.map((project) => {
      const addNewAreaBtnEl = createConfigAddBtn({
        method: addNewArea,
        label: i18n.t(`${sb}.buttons.newArea`),
        parentKeys: { projectKey: project.id },
        multi: false,
        inputType: "text",
        key: project.id + "ar",
        isLoading: isLoading
      });

      const keyPeoplesElements = getProjectKeyPeoplesElementsConfig(project);

      const keyPeoples = {
        key: `${project.id}-${TREE_LEVELS.keyPeoples}`,
        title: i18n.t(`${sb}.buttons.keyPeoples`),
        isLeaf: false,
        children: [...keyPeoplesElements],
        elementType: TREE_LEVELS.keyPeoples,
      };
      const docs = {
        key: `${project.id}-${TREE_LEVELS.otherDocs}`,
        title: i18n.t(`${sb}.buttons.docs`),
        elementType: TREE_LEVELS.default,
        isLeaf: false,
        children: [...getProjectOtherDocs(project)],
      };
      const projectChildrens = [addNewAreaBtnEl, keyPeoples, docs];

      if (
        !_.isArray(project[TREE_LEVELS.areas]) ||
        project[TREE_LEVELS.areas].length === 0
      ) {
        return {
          key: project.id,
          title: project.name,
          elementType: TREE_LEVELS.projects,
          isLeaf: false,
          children: projectChildrens,
          parentKeys: {
            projectId: project.id,
          },
        };
      }

      let projectChildren = project?.[TREE_LEVELS.areas]?.map((area) => {
        const areaItemDefault = {
          key: area.id,
          title: area?.name,
          isLeaf: false,
          elementType: TREE_LEVELS.areas,
          parentKeys: { projectKey: project.id, areaKey: area.id },
          children: [
            createConfigAddBtn({
              method: addNewDis,
              label: i18n.t(`${sb}.buttons.newDis`),
              parentKeys: { projectKey: project.id, areaKey: area.id },
              multi: false,
              inputType: "text",
              key: area.id + "-new-btn",
              isLoading: isLoading
            }),
          ],
        };

        if (!_.isArray(area?.[TREE_LEVELS.dis])) {
          return areaItemDefault;
        }
        const areaChildren = area[TREE_LEVELS.dis]?.map((dis) => {
          const disItemDefault = {
            key: dis.id,
            title: dis.name,
            parentKeys: {
              projectKey: project.id,
              areaKey: area.id,
              disKey: dis.id,
            },
            elementType: TREE_LEVELS.dis,
            isLeaf: false,
            children: [
              createConfigAddBtn({
                method: addNewPn,
                label: i18n.t(`${sb}.buttons.newPn`),
                parentKeys: {
                  projectKey: project.id,
                  areaKey: area.id,
                  disKey: dis.id,
                },
                multi: false,
                inputType: "text",
                key: dis.id + "-new-btn",
                isLoading: isLoading
              }),
            ],
          };

          if (!_.isArray(dis[TREE_LEVELS.pn])) {
            return disItemDefault;
          }
          const disChildren = dis[TREE_LEVELS.pn]?.map((pn) => {
            const pnItemDefault = {
              key: pn?.id,
              title: pn.name,
              elementType: TREE_LEVELS.pn,
              parentKeys: {
                projectKey: project.id,
                areaKey: area.id,
                disKey: dis.id,
                pnKey: pn.id,
              },
              isLeaf: false,
              children: [
                createConfigAddBtn({
                  method: addNewQuestionnaire,
                  label: i18n.t(`${sb}.buttons.newQuestionner`),
                  parentKeys: {
                    projectKey: project.id,
                    areaKey: area.id,
                    disKey: dis.id,
                    pnKey: pn.id,
                  },
                  multi: false,
                  inputType: "file",
                  extraData: ASSETS_TYPES.questionner,
                  key: pn.id + "-new-btn",
                  isLoading: isLoading
                }),
              ],
            };
            if (!_.isArray(pn[TREE_LEVELS.questionnaires])) {
              return pnItemDefault;
            }

            const pnChildren = pn[TREE_LEVELS.questionnaires]?.map((q) => {
              return {
                key: q.id,
                title: q.name,
                isLeaf: true,
                elementType: TREE_LEVELS.questionnaires,
                parentKeys: {
                  projectKey: project.id,
                  areaKey: area.id,
                  disKey: dis.id,
                  pnKey: pn.id,
                  questionnaireKey: q.id,
                },
              };
            });

            const pnChildrenSortedAlphabetically =
              sortListAlphabeticallyByTag(pnChildren);
            return {
              ...pnItemDefault,
              children: [
                ...pnChildrenSortedAlphabetically,
                ...pnItemDefault.children,
              ],
            };
          });

          const disChildrenSortAlphabetically =
            sortListAlphabeticallyByTag(disChildren);

          return {
            ...disItemDefault,
            children: [
              ...disChildrenSortAlphabetically,
              ...disItemDefault.children,
            ],
          };
        });

        const areaChildrenSorted = sortListAlphabeticallyByTag(areaChildren);

        return {
          ...areaItemDefault,
          children: [...areaChildrenSorted, ...areaItemDefault.children],
        };
      });

      projectChildren = sortListAlphabeticallyByTag(projectChildren);

      projectChildren.push(addNewAreaBtnEl, keyPeoples, docs);
      const projectAsTreeItem = {
        key: project.id,
        title: project.name,
        isLeaf: false,
        elementType: TREE_LEVELS.projects,
        children: projectChildren,
        parentKeys: {
          projectId: project.id,
        },
      };

      return projectAsTreeItem;
    });

    const newProjectBtn = createConfigAddBtn({
      method: addNewProject,
      label: i18n.t(`${sb}.buttons.newProject`),
      multi: false,
      inputType: "text",
      key: _.uniqueId("btn-new-prj"),
      isLoading: isLoading
    });

    const result = projectsTree;

    if (canCreateNewProjects) {
      result.push(newProjectBtn);
    }

    return result;
  };

  const treeData = generateTree(projects);

  if (
    projectsListRequest.isLoading ||
    projectsListRequest.isFetching ||
    projectsListRequest.isError
  ) {
    return (
      <LoadingData
        isFetching={projectsListRequest.isFetching}
        isLoading={projectsListRequest.isLoading}
        isError={projectsListRequest.isError}
        error={projectsListRequest.error}
      />
    );
  }
  if (!treeData || treeData.length === 0) {
    return (
      <Empty
        NewItemComponent={
          <InputTreeItem
            label={i18n.t(`${sb}.buttons.newProject`)}
            onSubmit={addNewProject}
          />
        }
      />
    );
  }

  const setPage = (page) => {
    dispatch(setCurrentPage(page));
  };


  return (
    <LayoutPagination
      currentPage={projectsListRequest?.data?.page}
      setPage={setPage}
      refetch={projectsListRequest?.refetch}
      totalPages={projectsListRequest?.data?.totalPages}
    >
      <Box display="flex" flexDir="row-reverse" w="full" bg="base.0">
        <Box w="4" mx={2} py={2}>
          {isLoading&& (
            <Spinner color="primary.100" />
          )}
        </Box>
        <Box w="full">
          <DirectoryTree
            onRightClick={onRightClick}
            onExpand={onExpand}
            selectable={false}
            defaultExpandedKeys={expandedKeys}
            treeData={treeData}
          />
        </Box>
      </Box>
    </LayoutPagination>
  );
}

Settings.propTypes = {};

export default Settings;
