import { Platform } from "react-native";
import React from "react";
import * as DocumentPicker from "expo-document-picker";
import _ from "lodash";
import ViewMediaCollection from "../../../../components/ViewMediaCollection";
import {
  DOCS_PICKER_MODES,
  formatDocAssetsListForDisplay,
  readFiles,
} from "../../../../utils/utils";

const DocsPicker = ({
  onSelect,
  defaultValue = null,
  dataSource = null,
  mode = DOCS_PICKER_MODES.display,
}) => {
  const pickFile = async () => {
    try {
      const filesInput = await DocumentPicker.getDocumentAsync({
        type: [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //docx
          "application/msword", //doc
        ],
        multiple: Platform.OS === "web",
        copyToCacheDirectory: false,
      });

      const files = await readFiles(filesInput.output);

      onSelect(files);
    } catch (err) {
      console.log(err);
    }
  };
  const formatDocsListForDisplay = (data) => {
    if (!data || !_.isArray(data) || data.length === 0) {
      return [];
    }

    return data.map((item) => {
      let r = {};
      let itemC = Object.assign({}, item);

      const filename = itemC.name;
      if (!filename) {
        return [];
      }

      const lastPoint = filename?.lastIndexOf(".");

      r.type = "document";
      r.uri = ``;
      r.filename = filename;
      r.ext = filename.substring(lastPoint + 1, filename.length);
      r.id = itemC.id;

      return r;
    });
  };

  const formatedDataSource =
    mode === DOCS_PICKER_MODES.pick
      ? formatDocsListForDisplay(defaultValue)
      : formatDocAssetsListForDisplay(dataSource, "document");

  return (
    <ViewMediaCollection
      renderAddMoreBtn={mode === DOCS_PICKER_MODES.pick}
      onAddMore={pickFile}
      items={formatedDataSource}
    />
  );
};

export default React.memo(DocsPicker);
