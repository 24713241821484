import { Box, HStack, Text, Pressable } from "native-base";
import React from "react";
import _ from "lodash";
import { formatAndTruncateText } from "../../../utils/utils";
const CellMultipleItems = ({ data, onExpand, isExpanded }) => {
  if (!data || !_.isArray(data)) {
    return null;
  }
  const MAX_CHARS_PER_ITEM = 35;
  let showViewMore = false;
  const getText = () => {
    let text = "";

    data.forEach((item, index) => {
      if (text.length < MAX_CHARS_PER_ITEM) {
        const name = item?.name ? item?.name : "";
        const newLine = text.length == 0 ? name : `${text} ${item.name} `;

        if (newLine.length <= MAX_CHARS_PER_ITEM) {
          text = newLine;
        } else {
          if (index === 0) {
            text = _.truncate(newLine, {
              length: MAX_CHARS_PER_ITEM,
              omission: "..",
            });
          }
          showViewMore = true;
          return;
        }
      }
    });

    return text;
  };

  const getTextFull = () => {
    let textExp = "";

    data.forEach((item, index) => {
      // daca nu e nimic, atunci pun primul element,
      //altfel pun textul existent si urm elements
      if (index == 0) {
        textExp = `${item.name}`;
      } else {
        textExp = `${textExp} ${item.name}`;
      }
    });
    return textExp;
  };

  const getCellContent = () => {
    if (isExpanded) {
      return getTextFull();
    }
    return getText();
  };
  const text = formatAndTruncateText(getCellContent(), isExpanded);

  return (
    <Box justifyContent="center" px={3} h="full" w="full">
      <HStack
        flexWrap="wrap"
        space={2}
        alignContent="flex-end"
        justifyContent="center"
      >
        <Text>{text}</Text>

        {showViewMore && (
          <Pressable alignSelf="flex-end" onPress={onExpand}>
            <Text color="primary.100" fontSize="sm" underline={true}>
              {isExpanded ? "View less" : "View all"}
            </Text>
          </Pressable>
        )}
      </HStack>
    </Box>
  );
};

export default CellMultipleItems;
