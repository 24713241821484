import React from "react";
import {
  Box,
  Text,
  Pressable,
  Icon,
  HStack,
  VStack,
  Button
} from "native-base";
import UserAvatar from "../../components/UserAvatar";
import { SwipeListView } from "react-native-swipe-list-view";
import { AntDesign } from "@expo/vector-icons";
import { NOTIFICATIONS_STATUS_TYPES } from "./notificationsStatusTypes";
import {
  su,
  DATE_FORMAT,
  i18n,
} from "../../utils/utils";
import { useSelector } from "react-redux";
import LoadingData from "../../components/LoadingData";
import { useGetNotificationsQuery, api } from "../../services/apiService";
import ViewReportButton from "./ViewReportButton";
import dayjs from "dayjs";
import ModalRich from "../../components/ModalRich";
import { useNotificationsActions } from "./NotificationsHelpers";
import _ from "lodash";
import ReachedFooter from "../../components/ReachedFooter";
import { NOTIFICATIONS_BG_BY_STATUS_TYPE } from "../../components/table/cell/helpers";
import { useDispatch } from "react-redux";
const SN = "screens.notifications.";
const NotificationsHiddenBtns = ({ data, closeRow }) => {
  const {
    handleApprove,
    handleReject,
    handleMarkRead,
    markNotificationRequest,
  } = useNotificationsActions(data.item);
  const [btnLoading, setBtnsLoading] = React.useState({
    approve: false,
    reject: false,
    read: false,
  });

  const element = data.item;
  const isRejected = element?.status === NOTIFICATIONS_STATUS_TYPES.rejected;
  const isRead = element?.status === NOTIFICATIONS_STATUS_TYPES.read;
  const isApproved = element?.status === NOTIFICATIONS_STATUS_TYPES.approved;
  const elResolved = isApproved || isRejected || isRead;

  const deny = (rowMap) => {
    setBtnsLoading({
      approve: false,
      reject: true,
      read: false,
    });
    closeRow(rowMap);

    handleReject();
  };
  const approve = (rowMap) => {
    setBtnsLoading({
      approve: true,
      reject: false,
      read: false,
    });
    closeRow(rowMap);
    handleApprove();
  };
  const markRead = (rowMap) => {
    setBtnsLoading({
      approve: false,
      reject: false,
      read: true,
    });
    closeRow(rowMap);
    handleMarkRead();
  };

  const getOpacity = (btnState) => {
    if (elResolved && !btnState) {
      return 0.4;
    } else {
      return 1;
    }
  };
  const hasErroReport = element?.regardingReport ? true : false;

  return (
    <HStack style={{ maxHeight: 122 }} flex="1" pl="2">
      <Button
        style={{ width: 74, opacity: hasErroReport ? 1 : 0.1 }}
        ml="auto"
        cursor="pointer"
        bg="error.30"
        justifyContent="center"
        onPress={deny}
        isLoading={markNotificationRequest.isLoading && btnLoading.reject}
        isDisabled={!hasErroReport}
        _pressed={{
          opacity: 0.5,
        }}
        opacity={getOpacity(isRejected)}
      >
        <VStack alignItems="center" space={2}>
          <Icon as={AntDesign} color="white" size={5} name="close" />

          <Text
            letterSpacings="xl"
            lineHeights="md"
            fontSize={12}
            fontWeight="medium"
            color="base.0"
          >
            {isRejected
              ? i18n.t(`${SN}buttons.denied`)
              : i18n.t(`${SN}buttons.deny`)}
          </Text>
        </VStack>
      </Button>
      <Button
        style={{ width: 78, opacity: hasErroReport ? 1 : 0.1 }}
        cursor="pointer"
        bg="success.40"
        justifyContent="center"
        onPress={approve}
        isLoading={markNotificationRequest.isLoading && btnLoading.approve}
        isDisabled={!hasErroReport}
        _pressed={{
          opacity: 0.5,
        }}
        opacity={getOpacity(isApproved)}
      >
        <VStack alignItems="center" space={2}>
          <Icon as={AntDesign} color="white" size={5} name="check" />
          <Text
            letterSpacings="xl"
            lineHeights="md"
            fontSize={12}
            fontWeight="medium"
            color="base.0"
          >
            {isApproved
              ? i18n.t(`${SN}buttons.approved`)
              : i18n.t(`${SN}buttons.approve`)}
          </Text>
        </VStack>
      </Button>
      <Button
        style={{ width: 74 }}
        cursor="pointer"
        bg="warning.40"
        justifyContent="center"
        onPress={markRead}
        _pressed={{
          opacity: 0.5,
        }}
        isLoading={markNotificationRequest?.isLoading && btnLoading.read}
        opacity={getOpacity(isRead)}
      >
        <VStack alignItems="center" space={2}>
          <Icon as={AntDesign} color="white" size={5} name="eyeo" />

          <Text
            letterSpacings="xl"
            lineHeights="md"
            fontSize={12}
            fontWeight="medium"
            color="base.0"
          >
            {!isRead
              ? i18n.t(`${SN}buttons.read`)
              : i18n.t(`${SN}buttons.unread`)}
          </Text>
        </VStack>
      </Button>
    </HStack>
  );
};
const NotificationsMobile = () => {
  const [page, setPage] = React.useState("1");
  const [isLoading, setIsLoading] = React.useState(false);
 const loggedUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const notificationsListReq = useGetNotificationsQuery({
    page: page,
    createdFor: loggedUser?.id,
  });

  const onRefresh = () => {
    dispatch(
      api.util.invalidateTags([
        { type: "Notifications", page: 1 },
        "NotificationsCount",
        { type: "Projects", page: 1 },
        "Notifications",
      ])
    );
  };

  const notifications = useSelector(
    (state) => state.notifications.notificationsMobileList
  );
  const onEndReached = () => {

    if (notificationsListReq.data?.hasNextPage ) {
      setPage(notificationsListReq.data?.nextPage.toString());
      setIsLoading(true)
      return;
    } 

    setIsLoading(false)

  };
  if (
    (notificationsListReq.isLoading ||
      notificationsListReq.isFetching ||
      notificationsListReq.isError) &&
    page === "1"
  ) {
    return (
      <LoadingData
        refetch={onRefresh}
        isFetching={notificationsListReq.isFetching}
        isLoading={notificationsListReq.isLoading}
        isError={notificationsListReq.isError}
        error={notificationsListReq.error}
      />
    );
  }
  const renderItem = ({ item, index }) => {
    return (
      <Pressable key={item?.id + index} style={{ height: 122 }}>
        <Box
          style={{ height: 122 }}
          key={item?.id + index}
          bg={NOTIFICATIONS_BG_BY_STATUS_TYPE[item.status]?.bg}
        >
          <HStack
            fontWeight="1"
            justifyContent="space-between"
            style={{ height: 122 }}
          >
            <Box
              bg={NOTIFICATIONS_BG_BY_STATUS_TYPE[item.status]?.accent}
              borderLeftRadius="md"
              style={{ width: su(8), height: "100%" }}
              h="full"
              w="full"
            />
            <HStack
              w="full"
              h="full"
              alignContent="space-between"
              alignItems="space-between"
              justifyContent="space-between"
            >
              <VStack
                h="full"
                w="2/3"
                py="2"
                style={{ paddingTop: 17, paddingLeft: 17 }}
                alignItems="flex-start"
                justifyItems="space-between"
                justifyContent="space-between"
                alignContent="space-between"
              >
                <Text
                  fontSize={14}
                  lineHeight="md"
                  fontWeight="medium"
                  color="base.100"
                  _dark={{
                    color: "warmGray.200",
                  }}
                >
                  {_.truncate(item.title, { length: 30 })}
                </Text>

                <HStack space={2} alignItems="center">
                  <UserAvatar size="small" user={item?.createdBy} />
                  <Text
                    fontSize={12}
                    lineHeight="sm"
                    color="neutral.70"
                    _dark={{
                      color: "warmGray.50",
                    }}
                  >
                    {item.createdBy.firstName} {item.createdBy.lastName}
                  </Text>
                </HStack>
              </VStack>

              <VStack
                w="1/3"
                py="1"
                px={3}
                alignItems="flex-end"
                justifyItems="space-between"
                justifyContent="space-between"
                alignContent="space-between"
                h="full"
              >
                <Box px={1}>
                  <Text
                    fontSize={12}
                    color="base.100"
                    _dark={{
                      color: "warmGray.50",
                    }}
                    alignSelf="flex-start"
                  >
                    {dayjs(item.createdAt).format(DATE_FORMAT)}
                  </Text>
                </Box>
                <ViewReportButton element={item} />
              </VStack>
            </HStack>
          </HStack>
        </Box>
      </Pressable>
    );
  };

  const renderHiddenItem = (data) => {
    const closeRow = (rowMap) => {
      const rowKey = String(data?.id);

      if (rowKey && rowMap[rowKey]) {
        rowMap[rowKey].closeRow();
      }
    };
    return (
      <NotificationsHiddenBtns key={data?.id} closeRow={closeRow} data={data} />
    );
  };

  const ListFooterComponent = () => {
    
    const request = {
      ...notificationsListReq,
      isLoading: isLoading
    }
    return(


      <ReachedFooter request={request} />


    
  );};
  const ItemSeparatorComponent = () => <Box h={2} />;
  const keyExtractor = (item) => item?.id;

  return (
    // <GestureHandlerRootView>
    <Box bg="white" flex="1" px={3} py={2} mt={3}>
      <SwipeListView
        data={notifications}
        renderItem={renderItem}
        renderHiddenItem={renderHiddenItem}
        rightOpenValue={-222}
        ItemSeparatorComponent={ItemSeparatorComponent}
        previewOpenValue={-40}
        previewOpenDelay={3000}
        onEndReached={onEndReached}
        onRefresh={onRefresh}
        refreshing={notificationsListReq.isFetching}
        swipeToOpenPercent={1}
        // onEndReachedThreshold={notifications.length <10 ? 0: 0.2}
        keyExtractor={keyExtractor}
        useAnimatedList={true}
        tension={1}
        // disableRightSwipe={true}
        // friction={0.001}
        ListFooterComponent={ListFooterComponent}
      />

      <ModalRich isMobile={true} />
    </Box>
    // </GestureHandlerRootView>
  );
};

export default NotificationsMobile;
