import React from "react";
import { Text } from "native-base";
import { useSelector } from "react-redux";
import { DATE_FORMAT_WITH_TIME, i18n,windowHeight,windowWidth } from "../../../utils/utils";
import daysjs from "dayjs";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { EXPO_PUBLIC_GOOGLE_MAPS_WEB_API_KEY } from "@env";

const LocationHistory = () => {
  const maps = useSelector((state) => state.ux.modal.content?.locationHistory);
  const [selectedPark, setSelectedPark] = React.useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: EXPO_PUBLIC_GOOGLE_MAPS_WEB_API_KEY,
  });

  if (!maps || maps === null || maps.length === 0) {
    return null;
  }

  const center = {
    lat: maps[Math.round(maps.length / 2)].latitude,
    lng: maps[Math.round(maps.length / 2)].longitude,
  };

  if (!isLoaded) {
    return (
      <Text color="primary.100">
        {i18n.t("screens.tasks.tableActions.noLocationAvailable")}
      </Text>
    );
  }
  const defaultMapOptions = {
    fullscreenControl: false,
  };
  return (
    <GoogleMap
      center={center}
      state={maps}
      fullScreenControl={false}
      options={defaultMapOptions}
      mapContainerStyle={{
        width: windowWidth-100,
        height: windowHeight-300
      }}
      zoom={30}
    >
      {maps.map((item, index) => {
        const pinColor = {
          0: "blue",
          [item.length - 1]: "green",
        };

        const title = (index + 1).toString();

        const date = daysjs(Number(item.timestamp)).format(
          DATE_FORMAT_WITH_TIME
        );
        const position = {
          lat: item.latitude,
          lng: item.longitude,
          title: title,
          date: date,
        };
        return (
          <Marker
            key={index + "pin"}
            color={pinColor[index]}
            position={position}
            onClick={() => {
              setSelectedPark(position);
            }}
            icon={{
              url: require("../../../../assets/map-pin.png"),
            }}
            title={title}
            // label={date}
          >
            {title}
          </Marker>
        );
      })}
      {selectedPark && (
        <InfoWindow
          onCloseClick={() => {
            setSelectedPark(null);
          }}
          position={{
            lat: selectedPark.lat,
            lng: selectedPark.lng,
          }}
        >
          <div>
            <p>{selectedPark.title}</p>
            <p>{selectedPark.date}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};


export default LocationHistory;
