import { VStack, Center } from "native-base";
import React from "react";
import Pagination from "../components/Pagination";

const LayoutPagination = (props) => {
  return (
    <VStack space={2}>
      {props.children}
      <Center>
        <Pagination {...props} />
      </Center>
    </VStack>
  );
};

export default LayoutPagination;
