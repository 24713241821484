import { configureStore } from "@reduxjs/toolkit";
import tasksSlice from "./features/tasksSlice";
import usersSlice from "./features/usersSlice";
import settingsSlice from "./features/settingsSlice";
import uxSlice from "./features/uxSlice";
import authReducer from "./features/authSlice";
import notificationsSlice from "./features/notificationsSlice";
import { api } from "./services/apiService";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import localForage from "localforage";
import { persistStore, persistReducer } from "redux-persist";
// import ExpoFileSystemStorage from "redux-persist-expo-filesystem"

const authPersistConfig = {
  key: "auth",
  storage: Platform.OS === "web" ? localForage : AsyncStorage,
};
const tasksPersistConfig = {
  key: "tasks",
  storage: Platform.OS === "web" ? localForage : AsyncStorage,
  whitelist: ["tasksMainFlow"],
};

const store = configureStore({
  reducer: {
    tasks:  persistReducer(tasksPersistConfig, tasksSlice),
    settings:  settingsSlice,
    users: usersSlice,
    ux: uxSlice,
    notifications: notificationsSlice,
    auth: persistReducer(authPersistConfig, authReducer),
    api: api.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      serializableCheck: false,
      immutableCheck: false,
    }).concat(api.middleware),
});
export default store;
export const persistorStore = persistStore(store);
