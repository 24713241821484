import React from "react";
import Table, { createTableBluePrint } from "../../components/table";
import { TABLE_COLUMNS } from "./tableColumns";
import { MODAL_CONTENT_TYPES } from "../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { openModalAndSetContent } from "../../features/uxSlice";
import { useGetUsersQuery } from "../../services/apiService";
import LayoutPagination from "../../containers/LayoutPagination";
import LoadingData from "../../components/LoadingData";
import Empty from "../../components/Empty";
import {
  useRoute,
} from "@react-navigation/native";
import _ from 'lodash';
const Users = () => {
  const route = useRoute();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(route?.params?.page || "1");

  const userList = useSelector((state) => state.users.users);
  const users = userList?.[Number(page)];

  const usersListRequest = useGetUsersQuery(route?.params?.page || "1");
  if (
    usersListRequest.isLoading ||
    usersListRequest.isFetching ||
    usersListRequest.isError
  ) {
    return (
      <LoadingData
        isFetching={usersListRequest.isFetching}
        isLoading={usersListRequest.isLoading}
        isError={usersListRequest.isError}
        error={usersListRequest.error}
      />
    );
  }

  if (!users || users.length === 0) {
    return <Empty />;
  }

  const editUser = (value) => {
    const user = _.cloneDeep(value);
    if (user?.avatarPhoto) {
      delete user?.avatarPhoto;
    }

    dispatch(
      openModalAndSetContent({
        contentBody: MODAL_CONTENT_TYPES.editUserModal,
        data: { ...user, page: page },
        size: "full",
      })
    );
  };
  const bluePrint = createTableBluePrint(users, TABLE_COLUMNS, "id");
  return (
    <LayoutPagination
      currentPage={usersListRequest?.data?.page}
      setPage={setPage}
      refetch={usersListRequest.refetch}
      totalPages={usersListRequest?.data?.totalPages}
      
    >
      <Table
        freezeFirstColumn={false}
        rowsExpandFeature={false}
        data={users}
        numRowStep={users.length <=6?users.length:6}
        tableHeadConfig={TABLE_COLUMNS}
        onPressRow={editUser}
        bluePrint={bluePrint}
      />

 
    </LayoutPagination>
  );
};

export default Users;
