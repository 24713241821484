import React, { Suspense } from "react";
import {
  Animated,
  ActivityIndicator,
  FlatList,
  ScrollView,
  StyleSheet,
  View,
  Pressable,
  PanResponder,
  Platform,
} from "react-native";
import PropTypes from "prop-types";
import { HStack } from "native-base";
import { su, windowWidth, CONTENT_CONTAINER_HEIGHT } from "../../utils/utils";
import { CELL_STYLE_TEMPLATES, CELL_CONTENT_TYPES } from "./tableRelatedTypes";
import Cell from "./cell/CellV2";
import _ from "lodash";
import { CELL_TYPES } from "../../utils/types";
import { colors } from "../../../assets/configs/colors";

const NUM_ROWS_STEP = 10;
const CELL_WIDTH = 200;

const HEIGHT = CONTENT_CONTAINER_HEIGHT;
const FlatListAnimated = Animated.createAnimatedComponent(FlatList);
const getWidthContainer = (variant) => {
  if (
    variant === CELL_TYPES.multipleItems ||
    variant === CELL_TYPES.headLong ||
    variant === CELL_TYPES.longText
  ) {
    return 328;
  } else {
    if (variant === CELL_TYPES.customComponent) {
      return 368;
    }
    return 250;
  }
};

const colorBase10 = colors.base[10];

const styles = StyleSheet.create({
  container: {
    backgroundColor: colorBase10,
    maxHeight: HEIGHT,
    height: HEIGHT,
    maxWidth: windowWidth,
    overflowX: "scroll",
  },
  text: {
    fontSize: 16,
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: su(8),
    paddingBottom: su(8),
  },
  header: {
    flexDirection: "row",
  },
  identity: {
    position: "absolute",
    backgroundColor: colorBase10,
    // width: CELL_WIDTH,
  },
  body: { marginLeft: CELL_WIDTH },

  column: { flexDirection: "column" },
});

class Sheet extends React.Component {
  pan = new Animated.ValueXY();
  previousLeft = 0;
  scrollCurrentIndex = 0;

  panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: () => true,
    onPanResponderMove: (e, gestureState) => {
      const isScrollingRight = gestureState.dx > 0;

      const getNewPosition = () => {
        let scrollP = this.scrollIndicatorRef.current.getBoundingClientRect().x;

        const position =
          (this.state.completeScrollBarWidth /
            this.state.visibleScrollBarWidth) *
            (scrollP-this.scrollIndicatorSize) +this.scrollIndicatorSize;

            if(scrollP <this.state.visibleScrollBarWidth ){
              if(isScrollingRight){
                return position;
              }
              return position/2-this.scrollIndicatorSize;
            }

            // if(isScrollingRight)

        // if (position > this.state.completeScrollBarWidth) {
        //   return this.state.completeScrollBarWidth;
        // } else if (position < 0) {
        //   return 0;
        // }
      return  position;
    
    };

      const position = getNewPosition();
      this.bodyFlatListRef.scrollToOffset({
        offset: position <100?0:position,
        animated: false,
      });

      Animated.event([null, { dx: this.pan.x }],{ useNativeDriver: false})(e, gestureState);
    },
    onPanResponderRelease: (event, gestureState) => {
      this.pan.extractOffset();

      this.previousLeft += gestureState.dx;
      this.previousRight += gestureState.dx - this.scrollIndicatorSize;
    },
  });

  state = {
    count:
      this.props.list.length < NUM_ROWS_STEP
        ? this.props.list.length
        : NUM_ROWS_STEP,
    loading: false,
    countLoading: 0,
    index: 0,
    cellsExpandControl: [],
    completeScrollBarWidth: 1,
    visibleScrollBarWidth: 0,
    scrollIndicatorSize: 0,
    scrollIndex: 0,
  };
  previousRight = 1;

  difference =
    this.state.visibleScrollBarWidth > this.scrollIndicatorSize
      ? this.state.visibleScrollBarWidth - this.scrollIndicatorSize
      : 1;
  interpolateX = this.pan.x;
  constructor(props) {
    super(props);

    this.headerScrollViewRef = null;
    this.isPressable = _.isFunction(this.props.onPressRow);
    this.numCols = this.props.tableColumnsConfig.length;
    this.scrollPosition = new Animated.Value(0);
    this.bodyFlatListRef = new Animated.ValueXY();
    this.scrollIndicator = new Animated.Value(0);
    this.scrollIndicatorRef = React.createRef();
    // this.bodyFlatListRef = this.bodyFlatListRef.bind(this);

    this.scrollEvent = Animated.event(
      [{ nativeEvent: { contentOffset: { x: this.scrollPosition } } }],
      { useNativeDriver: false }
    );
    this.scrollIndicatorSize = 260;

    this.initCellsExpandControlState();
  }



  componentDidUpdate(prevProps, prevState, snapshot) {
    this.scrollIndicatorSize = this.getScrollIndicatorSize();

    this.difference =
      this.state.visibleScrollBarWidth > this.scrollIndicatorSize
        ? this.state.visibleScrollBarWidth - this.scrollIndicatorSize
        : 1;
    this.interpolateX = this.pan.x.interpolate({
      inputRange: [0, this.difference],
      outputRange: [0, this.difference],
      extrapolate: "clamp",
    });
  }
  getScrollIndicatorSize() {
    return this.state.completeScrollBarWidth > this.state.visibleScrollBarWidth
      ? (this.state.visibleScrollBarWidth * this.state.visibleScrollBarWidth) /
          this.state.completeScrollBarWidth
      : this.state.visibleScrollBarWidth;
  }

  initCellsExpandControlState() {
    this.props.list.forEach((val, index) => {
      const col = [];
      this.state.cellsExpandControl.push([]);
      for (let i = 0; i < this.numCols; i++) {
        col.push([]);
        this.state.cellsExpandControl[index].push(false);
      }
      return col;
    });
  }

  scrollLoad = () => {
    if (this.props.list.length >= this.state.count) {
      this.setState({
        loading: false,
        count: this.state.count + NUM_ROWS_STEP,
      });
    } else {
      this.setState({ loading: false });
    }
  };

  handleScrollEndReached = () => {
    console.log("end reached");
    if (!this.state?.loading && this.props.list.length >= NUM_ROWS_STEP) {
      this.setState({ loading: true }, () => setTimeout(this.scrollLoad, 10));
    }
  };

  onPressCell(cell) {
    if (this.isPressable) {
      let payload = cell?.parentObj;

      if (this.props.isEditModeActive === true) {
        payload = {
          task: cell?.parentObj,
          selectedAttribute: cell?.attributeObj,
        };
      }

      this.props.onPressRow(payload);
    }
  }

  formatCell(cell) {
    const handleOnPress = () => this.onPressCell(cell);
    let isExpanded = true;
    if (cell?.col && cell?.row) {
      isExpanded = this.state.cellsExpandControl[cell?.col][cell?.row];
    }

    const renderExpander = cell?.expander || false;

    const renderCell = () => {
      if(!cell?.key){

        console.log("🚀 ~ file: SheetV2.js:247 ~ Sheet ~ renderCell ~ cell?.key:", cell?.key);
      }
      return (
        <Suspense          key={cell?.key }>
          <Cell
            key={cell?.key }
            value={cell?.value}
            customComponentConfig={cell?.customComponentConfig}
            styleTemplate={cell?.styleTemplate}
            contentType={cell?.contentType}
            containerStyle={cell?.containerStyle}
            isExpanded={isExpanded}
          />
        </Suspense>
      );
    };
    const renderCellWithExpander = () => {
      return (
        <HStack     key={cell?.key + "e"} borderWidth={1} space={0} p={0} m={0}>
          {renderExpander && (
            <Suspense>
              <Cell
                key={cell?.key + "e"}
                styleTemplate={CELL_CONTENT_TYPES.expand}
                contentType={CELL_CONTENT_TYPES.expand}
                isExpanded={isExpanded}
              />
            </Suspense>
          )}

          {renderCell()}
        </HStack>
      );
    };

    if (renderExpander) {
      return renderCellWithExpander();
    }
    if (this.isPressable) {
      const onPress = () => {
        handleOnPress(cell.parentObj);
      };

      return (
        <Pressable key={cell?.key} onPress={onPress}>
          {renderCell()}
        </Pressable>
      );
    } else return renderCell();
  }
  onExpand(i) {
    this.state.cellsExpandControl[i].forEach((item, index) => {
      this.state.cellsExpandControl[i][index - 1] =
        !this.state.cellsExpandControl[i][index - 1];
    });

    this.forceUpdate();
  }

  createColumnCell = (columnConfig, currentColumnIndex, stateIndex) => {
    const attributeName = columnConfig?.attributeName;
    const dataObject = this.props.list[stateIndex];

    const cellValue = dataObject?.[attributeName];

    const cellStyleTemplate =
      CELL_STYLE_TEMPLATES?.[dataObject?.status] ||
      columnConfig?.cellTemplateType;

    const key = columnConfig?.attributeName + dataObject?.id + stateIndex;

    const cellConfig = this.createCellConfig({
      value: cellValue,
      contentType: columnConfig?.cellContentType,
      styleTemplate: cellStyleTemplate,
      customComponentConfig: columnConfig?.customComponentConfig,
      key,
      dataObject,
      stateIndex,
      containerStyle: columnConfig?.cellContainerStyle,
      columnIndex: currentColumnIndex,
    });

    return cellConfig;
  };

  formatColumn = (section) => {
    let cells = [];

    // const currentColumnIndex =  section.index
    const currentColumnIndex = this.props.freezeFirstColumn
      ? section.index + 1
      : section.index;
    const currentColumnConfig =
      this.props.tableColumnsConfig?.[currentColumnIndex];

    for (let i = 0; i < this.state.count; i++) {
      const cellConfig = this.createColumnCell(
        currentColumnConfig,
        currentColumnIndex,
        i
      );

      if (cellConfig) {
        cells.push(this.formatCell(cellConfig));
      }
    }

    return <View key={"column"} style={{ ...styles.column, borderWidth: 0 }}>{cells}</View>;
  };

  createCellConfig = ({
    value,
    contentType,
    styleTemplate,
    customComponentConfig,
    key,
    dataObject = {},
    stateIndex,
    containerStyle,
    columnIndex,
  }) => {
    const isExpanded =
      this.state.cellsExpandControl?.[stateIndex]?.[columnIndex];
    const expandMethod = this.onExpand.bind(this, stateIndex);
    return {
      value,
      contentType,
      styleTemplate,
      containerStyle,
      customComponentConfig: {
        ...customComponentConfig,
        rowObjectData: dataObject,
      },
      isExpanded,
      key,
      expandMethod,
    };
  };
  shouldComponentUpdate(nextProps, nextState) {
    if(this.state.loading !== nextState.loading){
      return true;
    }
    const result = !_.isEqual(this.props.dataGrid, nextProps.dataGrid);
    return result;
  }

  formatHeader() {
    let cols = [];

    const startAt = this.props.freezeFirstColumn ? 1 : 0;
    const firstColumnConfig = this.props.tableColumnsConfig?.[0];
    for (let colIndex = startAt; colIndex < this.numCols; colIndex++) {
      const cellContent = this.props.tableColumnsConfig?.[colIndex];

      const cellConfig = this.createCellConfig({
        value: cellContent?.columnTitle,
        contentType: CELL_CONTENT_TYPES?.text,
        styleTemplate: cellContent?.headStyleTemplate,
        customComponentConfig: cellContent?.customComponentConfig,
        key: cellContent?.attributeName + colIndex,
        dataObject: {},
        stateIndex: 0,
        containerStyle: cellContent?.cellContainerStyle,
        columnIndex: colIndex,
      });

      if (cellConfig) {
        cols.push(this.formatCell({ ...cellConfig }));
      }
    }

    const firstCellConfig = this.createCellConfig({
      value: firstColumnConfig?.columnTitle,
      contentType: CELL_CONTENT_TYPES?.text,
      styleTemplate: firstColumnConfig?.headStyleTemplate,
      customComponentConfig: firstColumnConfig?.customComponentConfig,
      key: firstColumnConfig?.attributeName + '-key',
      dataObject: {},
      stateIndex: 0,
      containerStyle: firstColumnConfig?.cellContainerStyle,
      columnIndex: 0,
    });

    const renderFirstFreezedColumn = this.props.freezeFirstColumn
      ? this.formatCell(firstCellConfig)
      : null;

    const listKey = (item, index) => "kj" + index.toString();

    return (
      <View
        style={{
          ...styles.header,

          paddingLeft:
            this.props.rowsExpandFeature && this.props.freezeFirstColumn
              ? 82
              : 0,
        }}
      >
        {renderFirstFreezedColumn}
        <ScrollView
          ref={(ref) => (this.headerScrollViewRef = ref)}
          horizontal={true}
          showsHorizontalScrollIndicator={true}
          persistentScrollbar={false}
          scrollEnabled={false}
          scrollEventThrottle={16}
        >
          {cols}
        </ScrollView>
      </View>
    );
  }

  formatIdentityColumn() {
    let cells = [];

    const firstColumnConfig = this.props.tableColumnsConfig?.[0];

    for (let i = 0; i < this.state.count; i++) {
      const cellConfig = this.createColumnCell(firstColumnConfig, 0, i);
      cells.push(this.formatCell({ ...cellConfig, expander: true }));
    }

    return (
      <View key={`${i}-ic`} style={styles.identity}>
        {cells}
      </View>
    );
  }

  formatBody() {
    // iterate all column and create cells
    ///create columns
    let data = [];

    //format each cell of each row

    const startAt = this.props.freezeFirstColumn ? 1 : 0;
    for (let i = startAt; i < this.numCols; i++) {
      data.push({});
    }

    const styleFlatList = this.props.freezeFirstColumn
      ? { marginLeft: getWidthContainer(CELL_TYPES.basic) + 82 }
      : {};

    const listKey = (item, index) => {
      return `key${index.toString()}`
    }
    return (
      <View>
        <View position="absolute">
          {this.props.freezeFirstColumn && this.formatIdentityColumn()}
        </View>

        <FlatListAnimated
          ref={(ref) => (this.bodyFlatListRef = ref)}
          style={styleFlatList}
          horizontal={true}
          showsHorizontalScrollIndicator={Platform.OS !== "web"}
          persistentScrollbar={Platform.OS === "web"}
          data={data}
          onContentSizeChange={(width) => {
            this.setState({ completeScrollBarWidth: width });
          }}
          onLayout={({
            nativeEvent: {
              layout: { height, width },
            },
          }) => {
            this.setState({ visibleScrollBarWidth: width });
          }}
          keyExtractor={listKey}
          renderItem={this.formatColumn}
          stickyHeaderIndices={[0]}
          onScroll={this.scrollEvent}
          scrollEventThrottle={16}
          extraData={this.state}
          getItemLayout={(data, index) => ({
            length: 69,
            offset: 69 * index,
            index,
          })}
          initialNumToRender={this.props.dataGrid?.length}
          maxToRenderPerBatch={this.numCols}
        />
      </View>
    );
  }

  formatRowForSheet = (section) => {
    let { item } = section;

    return item.render;
  };



  componentDidMount() {
    this.listener = this.scrollPosition.addListener((position) => {
      if (!position?.value) {
        return;
      }
      if (this.headerScrollViewRef) {
        this.headerScrollViewRef.scrollTo({
          x: position.value,
          animated: false,
        });
        this.scrollCurrentIndex = position.value;
      }
    });
    


    // this.bodyFlatListRef.extractOffset();
    this.handleScrollEndReached();
  }

  render() {
    let body = this.formatBody();
    let data = [{ key: "body", render: body }];
    const getStylesContainer = () => {
      let actuallyUsedStyle = styles.container;
      if (this.props.width !== undefined) {
        actuallyUsedStyle = { ...styles, width: this.props.width };
      }

      if (this.props.height !== undefined) {
        actuallyUsedStyle = { ...actuallyUsedStyle, height: this.props.height,maxHeight: this.props.height, container:{
          ...styles.container,height: this.props.height,maxHeight: this.props.height 
        } };
      }
      return actuallyUsedStyle;
    };

    const stylesContainer = getStylesContainer();
    const listKey = (item, index) => "p" + index.toString();
    return (
      <View style={stylesContainer}>
        {this.formatHeader()}
        <FlatList
          style={stylesContainer}
          data={data}
          nestedScrollEnabled
   
          renderItem={this.formatRowForSheet}
          onEndReached={this.handleScrollEndReached}
          listKey={listKey}
          onEndReachedThreshold={0.5}
          showsVerticalScrollIndicator={true}
          // scrollEnabled={this.props.height === undefined && Platform.OS === "web" ? false : true}
          // maxToRenderPerBatch={this.numCols}

        />

        <View style={{ height: 10 }}>
          {this.state?.loading && <ActivityIndicator />}
        </View>

        {Platform.OS === "web" && this.props.showCustomHorizontalScroll && (
          <View
            style={{
              width: this.state.visibleScrollBarWidth,
              height: 14,
              backgroundColor: colors.base[20],
              borderRadius: 8,
              padding: 4,
            }}
          >
            <Animated.View
              {...this.panResponder.panHandlers}
              style={{
                height: 10,
                marginBottom: 20,
                borderRadius: 8,
                backgroundColor: colors.neutral[50],
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                width: this.scrollIndicatorSize,
                transform: [
                  {
                    translateX: this.interpolateX,
                  },
                ],
              }}
            >
              <View
                ref={this.scrollIndicatorRef}
                style={{
                  backgroundColor: colors.neutral[70],
                  height: 10,
                  width: 0,
                  marginRight: 3,
                }}
              />
            </Animated.View>
          </View>
        )}
      </View>
    );
  }
}
const getCustomComponentConfig = (component) => {
  if (!component) {
    return null;
  }
  return {
    component: component,
    rowObjectData: {},
  };
};

Sheet.propTypes = {
  tableColumnsConfig: PropTypes.array,
  freezeFirstColumn: PropTypes.bool,
  rowsExpandFeature: PropTypes.bool,
  list: PropTypes.array.isRequired,
  headStyleTemplate: PropTypes.string,
  width: PropTypes.number,
  showCustomHorizontalScroll: PropTypes.bool,
};

export default Sheet;

export const createTableColumnConfig = (
  columnTitle,
  attributeName,
  headStyleTemplate,
  cellContentType,
  cellStyleTemplate,
  customComponent,
  cellContainerStyle
) => {
  const customComponentConfig = getCustomComponentConfig(customComponent);
  return {
    columnTitle,
    attributeName,
    headStyleTemplate,
    cellContentType,
    cellStyleTemplate,
    customComponentConfig,
    cellContainerStyle,
  };
};
