import { createSlice, current } from "@reduxjs/toolkit";
import {
  TASKS_MAIN_FLOW_SCREEN_STATUS,
  TASKS_FLOW_MODES,
  RESOLVE_ANSWERS_TYPES,
  getExtensionFromFilename,
  getUserFullName,
  concatArraysByIdAndMergeMostRecent,
} from "../utils/utils";
import { TASKS_STATUS_TYPES } from "../screens/tasks/tasksStatusTypes";
import TaskModel from "../models/taskModel";
import { api } from "../services/apiService";
import _ from "lodash";
import update from "immutability-helper";
import { revertAll } from "../utils/utils";

const NEW_TASK_FLOW_INITIAL_STATE = {
  project: {
    status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
    value: null,
    dataSource: [],
    isCurrent: true,
  },
  area: {
    status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
    value: null,
    dataSource: [],
    isCurrent: false,
  },
  dis: {
    status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
    value: null,
    dataSource: [],
    isCurrent: false,
  },
  pn: {
    status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
    value: null,
    dataSource: [],
    isCurrent: false,
  },
  questionner: {
    status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
    value: null,
    dataSource: [],
    isCurrent: false,
  },
  activity: {
    status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
    value: null,
    dataSource: [],
    isCurrent: false,
  },
  startEndDate: {
    status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
    value: null,
    dataSource: [],
    isCurrent: false,
  },
  formA: {
    status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
    value: null,
    dataSource: [],
    isCurrent: false,
  },
  docs: {
    status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
    value: null,
    dataSource: [],
    isCurrent: false,
  },
  formB: {
    status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
    value: null,
    dataSource: [],
    isCurrent: false,
  },
  submissionDate: {
    status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
    value: null,
    dataSource: [],
    isCurrent: false,
  },
  assignTo: {
    status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
    value: null,
    dataSource: [],
    isCurrent: false,
  },
};
const prepareAnswersList = (task) => {
  if (!task?.activity || !_.isArray(task.pn) || !task?.activity?.questions) {
    return {};
  }
  const activityClone = _.cloneDeep(task?.activity);

  const initialAnswer = task.pn.map((item) => ({
    pn: item.id,
    answer: RESOLVE_ANSWERS_TYPES.waiting,
    errorReport: null,
  }));

  if (
    !_.isArray(activityClone.questions) ||
    activityClone.questions.length === 0
  ) {
    return [];
  }

  return activityClone.questions.map((q) => {
    const answerHasBenInit =
      _.isArray(q.answers) && q.answers.length === task.pn.length;

    const initAnswer = answerHasBenInit ? q.answers : initialAnswer;

    return {
      ...q,
      answers: initAnswer,
    };
  });
};
const prepareDocsForDisplay = (data) => {
  if (!data || !_.isArray(data)) {
    return [];
  }

  const result = data.map((item) => {
    const filename = item.assetName;
    return {
      id: item?.id,
      filename,
      type: item?.assetFileType,
      uri: item?.url,
      ext: getExtensionFromFilename(filename),
      ...item,
    };
  });
  return result;
};

const findLastUpdatedQuestion = (questions) => {
  if (!_.isArray(questions)) {
    return 0;
  }
  //remove proxy from affecticing the state
  const copyQuestions = _.cloneDeep(questions);
  let atLeastOnAnswer = false;
  for(const q of copyQuestions){
      q.answers.forEach((item)=>{
        if(item.answer !== 'waiting'){
          atLeastOnAnswer = true;
          return;
        }
      })
      if(atLeastOnAnswer){
        break;
      }
    }
    console.log("🚀 ~ file: tasksSlice.js:156 ~ findLastUpdatedQuestion ~ atLeastOnAnswer:", atLeastOnAnswer);
    const questionsSortedByUpdatedAt = copyQuestions.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );
  const index = questions.findIndex(
    (item) => item.id === questionsSortedByUpdatedAt[0]?.id
  );
  if (index === -1) {
    return 0;
  }
  if(atLeastOnAnswer){

    return index;
  }
  return 0;
};


const initialState = {
  // tasks: TASKS_TABLE_MOCK_DATA,
  tasks: [],
  tasksMobileList: [],
  tasksMainFlow: {
    mode: TASKS_FLOW_MODES.newTaskMode,
    data: NEW_TASK_FLOW_INITIAL_STATE,
    isFetchingData: false,
    questionnairesFetched: false,
  },
  tasksResolveFlow: {
    task: {},

    currentQuestionIndex: 0,
  },
};
export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(revertAll, () => initialState);
    builder.addMatcher(api.endpoints.getTask.matchFulfilled, (state, req) => {
      const { payload } = req;
      if (!payload || !req?.meta?.arg?.originalArgs?.isResolve) {
        return;
      }
      const task = payload;
      state.tasksResolveFlow.task = task;

      state.tasksResolveFlow.currentQuestionIndex = 0;
      if (!_.isArray(task.activity.questions)) {
        return;
      }
      if (
        Boolean(task?.userMustSubmitErrorReport === true) &&
        (_.isString(task?.questionMustBeReported) ||
          _.isObject(task?.questionMustBeReported))
      ) {
        const questionMustBeReportedIndex = task.activity.questions.findIndex(
          (item) => item.id === task?.questionMustBeReported.id
        );
        if (questionMustBeReportedIndex) {
          state.tasksResolveFlow.currentQuestionIndex;
        }
      }
      const activityClone = _.cloneDeep(task?.activity);

      const activityInit = update(activityClone, {
        questions: {
          $set: prepareAnswersList(task),
        },
      });

      state.tasksResolveFlow.task.activity = activityInit;
    });

    builder.addMatcher(
      api.endpoints.getTasksOfQuestionner.matchFulfilled,
      (state, req) => {
        const { payload } = req;
        if (payload?.docs) {
          const updatedTasks = (state.tasksMobileList =
            concatArraysByIdAndMergeMostRecent(
              current(state.tasksMobileList),
              payload?.docs
            ));

          state.tasksMobileList = updatedTasks.map((item) => {
            let itemCopy = _.cloneDeep(item);
            if (itemCopy?.questionner?.tableColumns) {
              delete itemCopy?.questionner?.tableColumns;
              delete itemCopy?.questionner?.tableRows;
            }
            return itemCopy;
          });
        }
      }
    );

    builder.addMatcher(api.endpoints.getTasks.matchFulfilled, (state, req) => {
      const { payload } = req;

      if (payload?.docs) {
        state.tasks[payload.page] = payload?.docs.map((item) => {
          if (item?.questionner?.tableColumns) {
            delete item?.questionner?.tableColumns;
            delete item?.questionner?.tableRows;
          }
          item.assignTo = {
            ...item.assignTo,
            fullName: getUserFullName(item.assignTo),
          };

          return item;
        });
      }
    });
  },
  reducers: {
    setIsFetchingData: (state, action) => {
      state.tasksMainFlow.isFetchingData = action.payload;
    },
    setQuestionnairesFetched: (state) => {
      state.tasksMainFlow.questionnairesFetched = true;
    },
    setIsCurrent: (state, action) => {
      Object.keys(state.tasksMainFlow.data).forEach((item) => {
        if (
          state.tasksMainFlow.data[item] &&
          state.tasksMainFlow.data[item].isCurrent === true
        ) {
          state.tasksMainFlow.data[item].isCurrent = false;
        }
      });
      if (
        action.payload.step &&
        state.tasksMainFlow.data[action.payload.step]
      ) {
        state.tasksMainFlow.data[action.payload.step].isCurrent = true;
      }
    },

    setAttributeAcknowledged: (state, action) => {
      state.tasksMainFlow.data[action.payload.step].status =
        TASKS_MAIN_FLOW_SCREEN_STATUS.completed;
    },
    setFlowAttributeValue: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      state.tasksMainFlow.data[action.payload.step].value =
        action.payload.value;

      state.tasksMainFlow.data[action.payload.step].status =
        TASKS_MAIN_FLOW_SCREEN_STATUS.completed;

      if (action.payload.step === "project") {
        state.tasksMainFlow.data.area.dataSource = action.payload.value.areas;

        // state.tasksMainFlow.data.assignTo = state.
      }

      if (action.payload.step === "area") {
        state.tasksMainFlow.data.dis.dataSource = action.payload.value.dis;
      }
      if (action.payload.step === "dis") {
        state.tasksMainFlow.data.pn.dataSource = action.payload.value.pn;
      }

      if (action.payload.step === "pn") {
        state.tasksMainFlow.data.questionner.dataSource = action.payload.value;
      }
      if (action.payload.step === "questionner") {
        // state.tasksMainFlow.data.activities.dataSource =
        //   action.payload.value;
        state.tasksMainFlow.data.activity.dataSource =
          action.payload.value.activity;

        state.tasksMainFlow.data.activity.value = null;
      }
      if (action.payload.step === "startEndDate") {
        if (!_.isArray(action.payload.value)) {
          return;
        }
        if (action.payload.value[0] === null) {
          state.tasksMainFlow.data.startEndDate.value = [
            state.tasksMainFlow.data.startEndDate.dataSource[0],
            action.payload.value[1],
          ];
        } else if (action.payload.value[1] === null) {
          state.tasksMainFlow.data.startEndDate.value = [
            action.payload.value[0],
            state.tasksMainFlow.data.startEndDate.dataSource[1],
          ];
        } else if (
          action.payload.value[0] !== null &&
          action.payload.value[1] !== null
        ) {
          state.tasksMainFlow.data.startEndDate.value = action.payload.value;
        }
      }
    },
    setMode: (state, action) => {
      state.tasksMainFlow.mode = action.payload;
    },
    initNewTask: (state) => {
      state.tasksMainFlow.mode = TASKS_FLOW_MODES.newTaskMode;
      state.tasksMainFlow.data = NEW_TASK_FLOW_INITIAL_STATE;
    },
    setDataSource: (state, action) => {
      state.tasksMainFlow.data[action.payload.attribute].dataSource =
        action.payload.value;
      state.tasksMainFlow.isFetchingData = false;

      // state.tasksMainFlow.data.project.dataSource = state.projects.projects;
    },
    startAcknowledgeTask: (state, action) => {
      const task = _.cloneDeep(action.payload);
      const pn = task.pn.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });

      const assignTo = task.assignTo;
      assignTo.fullName = getUserFullName(assignTo);
      state.tasksMainFlow.mode = TASKS_FLOW_MODES.acknowledgeMode;
      state.tasksMainFlow.questionnairesFetched = false;
      state.tasksMainFlow.data = {
        taskInfo: {
          id: task.id,
        },
        project: {
          status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
          value: { id: task.project?.id, name: task.project?.name },
          dataSource: [{ id: task.project?.id, name: task.project?.name }],
          isCurrent: true,
        },
        area: {
          status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
          value: { id: task.area?.id, name: task.area?.name },
          dataSource: [{ id: task.area?.id, name: task.area?.name }],
          isCurrent: false,
        },
        dis: {
          status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
          value: { id: task.dis?.id, name: task.dis?.name },
          dataSource: [{ id: task.dis?.id, name: task.dis?.name }],
          isCurrent: false,
        },
        pn: {
          status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
          value: pn,
          dataSource: pn,
          isCurrent: false,
        },
        questionner: {
          status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
          value: { id: task.questionner?.id, name: task.questionner?.name },
          dataSource: [
            { id: task.questionner?.id, name: task.questionner?.name },
          ],
          isCurrent: false,
        },
        activity: {
          status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
          value: [{ id: task.activity?.id, name: task.activity?.name }],
          dataSource:
            task?.questionner?.activities &&
            _.isArray(task?.questionner?.activities)
              ? task.questionner.activities.map((item) => {
                  return {
                    id: item?.id,
                    name: item?.name,
                  };
                })
              : [],
          isCurrent: false,
        },
        startEndDate: {
          status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
          value: [task.plannedStartDate, task.plannedEndDate],
          dataSource: [task.plannedStartDate, task.plannedEndDate],
          isCurrent: false,
        },
        formA: {
          status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
          value: task.formA,
          dataSource: task.formA ? [task.formA] : null,
          isCurrent: false,
        },
        docs: {
          status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
          value: prepareDocsForDisplay(task.otherDocs),
          dataSource: prepareDocsForDisplay(task.otherDocs),
          isCurrent: false,
        },
        formB: {
          status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
          value: task.formB,
          dataSource: task.formB ? [task.formB] : null,
          isCurrent: false,
        },
        submissionDate: {
          status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
          value: task.submissionDate,
          dataSource: [task.submissionDate],
          isCurrent: false,
        },
        assignTo: {
          status: TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
          value: assignTo,
          dataSource: [assignTo],
          isCurrent: false,
        },
      };
    },
    startResolveTask: (state, action) => {
      const task = action.payload;
      const activityClone = _.cloneDeep(task?.activity);

      const activityInit = update(activityClone, {
        questions: {
          $set: prepareAnswersList(task),
        },
      });
      state.tasksResolveFlow.task = { ...task, activity: activityInit };


      state.tasksResolveFlow.currentQuestionIndex = findLastUpdatedQuestion(
        activityClone.questions
      );
    },
    nextQuestionToResolve: (state) => {
      if (
        state.tasksResolveFlow.currentQuestionIndex + 1 <
        state.tasksResolveFlow.task.activity.questions.length
      )
        state.tasksResolveFlow.currentQuestionIndex =
          state.tasksResolveFlow.currentQuestionIndex + 1;
    },
    previousQuestionToResolve: (state) => {
      if (state.tasksResolveFlow.currentQuestionIndex > 0) {
        state.tasksResolveFlow.currentQuestionIndex =
          state.tasksResolveFlow.currentQuestionIndex - 1;
      }
    },

    updateQuestion: (state, action) => {
      const { answers, questionIndex } = action.payload;
      const edittedQustions = update(
        state.tasksResolveFlow.task.activity.questions,
        {
          [questionIndex]: { answers: { $set: answers } },
        }
      );
      state.tasksResolveFlow.task.activity.questions = edittedQustions;
    },
    registerResponse: (state, action) => {
      state.tasksResolveFlow.task = action.payload;
    },

    setCurrentQuestion: (state, action) => {
      const questionIndex =
        state.tasksResolveFlow.task.activity.questions.findIndex(
          (q) => q.id === action.payload.id
        );
      if (questionIndex !== -1) {
        state.tasksResolveFlow.currentQuestionIndex = questionIndex;
      }
    },

    addTask: (state) => {
      const flowData = current(state.tasksMainFlow.data);
      const project = flowData.project.value.name;

      const area = flowData.area.value.name;
      const dis = flowData.dis.value.name;
      const pn = flowData.pn.value;
      const questionner = flowData.questionner.value.name;
      const activity = flowData.activity.value.name;
      const plannedStartDate = flowData.startEndDate.value[0];
      const plannedEndDate = flowData.startEndDate.value[1];
      const submissionDate = flowData.submissionDate.value;
      const status = TASKS_STATUS_TYPES.unacknowledged;
      const assignTo = flowData.assignTo.value;
      const formA = flowData.formA.value;
      const formB = flowData.formB.value;
      const docs = flowData.docs.value;

      const task = new TaskModel(
        project,
        area,
        dis,
        pn,
        questionner,
        activity,
        plannedStartDate,
        "",
        plannedEndDate,
        status,
        submissionDate,
        status,
        assignTo,
        "",
        "",
        "",
        formA,
        docs,
        formB
      );
      state.tasksMobileList.push(task);
      state.tasks[1].push(task);
      state.tasksMainFlow.data = NEW_TASK_FLOW_INITIAL_STATE;
    },
  },
});

export const {
  setIsCurrent,
  setAttributeAcknowledged,
  setFlowAttributeValue,
  setMode,
  setDataSource,
  addTask,
  startAcknowledgeTask,
  initNewTask,
  startResolveTask,
  nextQuestionToResolve,
  previousQuestionToResolve,
  registerResponse,
  updateQuestion,
  setQuestionnairesFetched,
  setIsFetchingData,
  setCurrentQuestion,
} = tasksSlice.actions;

export default tasksSlice.reducer;
