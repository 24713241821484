import React from "react";
import { Icon, Box, IconButton, Center, Image, Heading } from "native-base";
import UploadLogoButton from "../UploadLogoButton";
import ReportRow from "./ReportRow";
import PropTypes from "prop-types";
import { windowWidth } from "../../utils/utils";
import InputReport from "./InputReport";
import { AntDesign } from "@expo/vector-icons";
import { REPORT_FIELDS } from '../../screens/tasks/results/fieldTypes';
const ReportsLogoRow = ({
  uploadLabel1 = "Upload Logo 1",
  uploadLabel2 = "Upload Logo 2",
  projectNameLabel = "Project Name",
  onPressUpload1 = () => {},
  onPressUpload2 = () => {},
  onChangeProjectName = () => {},
  removeLogo1 = () => {},
  removeLogo2 = () => {},
  isMobile = false,
  isDesktop = false,
  data = null,
  projectNameValue = "",
  isDisabled = false,
  errorMessageProjectName,
}) => {
  const containerWidth = isMobile ? windowWidth - 80 : 240;

  const renderLogo = (item, removeLogo, onPressUpload, label) => {
    const logoUrl = item?.uri;
    const imageContainerWidth = isMobile ? windowWidth - 90 : 100;
    return (
      <Box  p={1}>
        <Center>
          {logoUrl && (
            <Box style={{ width: imageContainerWidth, height: 100 }}>
              <Image
                style={{
                  flex: 1,
                  // width: imageContainerWidth,
                  height: 100,
                  resizeMode: "contain",
                }}
                source={{
                  uri: logoUrl,
                }}
                alt="logo"
              />
            </Box>
          )}
          {logoUrl && !isDisabled && (
            <IconButton
              color="accent.100"
              onPress={removeLogo}
              style={{ maxWidth: 30, }}
              _pressed={{ background: "neutral.30" }}
              icon={<Icon as={AntDesign} name="delete" color="accent.100" />}
              size="lg"
            />
          )}
        </Center>

        {!logoUrl && !isDisabled && (
          <UploadLogoButton
            isMobile={isMobile}
            onPress={onPressUpload}
            label={label}
          />
        )}
      </Box>
    );
  };
  const logo1RenderedValue = data?.[0];

  const logo2RenderedValue = data?.[1];

  return (
    <ReportRow isDesktop={isDesktop} isMobile={isMobile}>
      {renderLogo(
        logo1RenderedValue,
        removeLogo1,
        onPressUpload1,
        uploadLabel1
      )}
      <Box  p={1} width={containerWidth}>
        {isDisabled && (
          <Heading
            style={{ textTransform: "uppercase" }}
            fontSize={20}
            color="primary.100"
            textAlign="center"
          >
            {projectNameValue}
          </Heading>
        )}

        {!isDisabled && (
            <Box mb={8}> 
            <InputReport
              onChangeText={onChangeProjectName}
              width={containerWidth}
              inputName={REPORT_FIELDS.projectName}
              placeholder={projectNameLabel}
              isDisabled={isDisabled}

              value={projectNameValue}
              errorMessage={errorMessageProjectName}
            />
            </Box>

        )}
      </Box>

      {renderLogo(
        logo2RenderedValue,
        removeLogo2,
        onPressUpload2,
        uploadLabel2
      )}
    </ReportRow>
  );
};
ReportsLogoRow.propTypes = {
  uploadLabel1: PropTypes.string,
  uploadLabel2: PropTypes.string,
  projectNameLabel: PropTypes.string,
  onPressUpload1: PropTypes.func,
  onPressUpload2: PropTypes.func,
  onChangeProjectName: PropTypes.func,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  data: PropTypes.array,
  projectNameValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  errorMessageProjectName: PropTypes.string,
};

export default ReportsLogoRow;
