import React from "react";
import { Box, Icon, Text, Button, VStack, HStack } from "native-base";
import { MaterialCommunityIcons, AntDesign } from "@expo/vector-icons";
import { useDispatch } from "react-redux";
import { initNewTask } from "../../../features/tasksSlice";
import {
  su,
  useIsDesktop,
  FLOW_STATUS_MODES,
  i18n,
} from "../../../utils/utils";
const flowOperationResultType = {
  success: "success",
  fail: "fail",
};

//Finished Add New Task
//Finished Acknowledge Task
//Must wait until erro report is approved
//Must wait until final report is approved
//Must submit final report for "NO" answer given previously
const SN = 'screens.flowStatus.';

const FlowStatus = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const mode = route?.params?.mode || FLOW_STATUS_MODES.newTask;
  const status = route?.params?.status || flowOperationResultType.success;
  
  // React.useEffect(() => {
  //   function init() {
  //     if (!route?.params || !mode || !status) {
  //       navigation.replace("Tasks");
  //     }
  //   }
  //   init();
  // }, []);




  const isDesktop = useIsDesktop();


  const createNewTask = () => {
    dispatch(initNewTask());
    navigation.replace("TasksFlow", {
      mode: FLOW_STATUS_MODES.newTask,
      screen: "Project",
    });
  };
  const flowMap = {
    [FLOW_STATUS_MODES.newTask]: {
      title:
        status === flowOperationResultType.success
          ? i18n.t(`${SN}successNewTask`) 
          :  i18n.t(`${SN}failNewTask`) ,
      labelPrimary: i18n.t(`${SN}buttons.goToTasks`),
      labelSecondary:  i18n.t(`${SN}buttons.newTask`) ,
      methodPrimary: () => {
        navigation.replace("Tasks");
      },
      methodSecondary: createNewTask,
    },
    [FLOW_STATUS_MODES.acknowledge]: {
      title:
        status === flowOperationResultType.success
          ? i18n.t(`${SN}successAcknowledge`)
          :i18n.t(`${SN}failNewTask`),
      labelPrimary: i18n.t(`${SN}buttons.startTask`),
      labelSecondary: i18n.t(`${SN}buttons.goToTasks`) ,
      methodPrimary: () => {
        navigation.replace("ResolveFlow", {
          screen: "ResolveTask",
          params: { taskId: route.params.taskId },
        });
      },
      methodSecondary: () => {
        navigation.replace("Tasks");
      },
    },
  };

  return (
    <Box
      h="full"
      w="full"
      bg="base.0"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
    >
      <VStack
        borderWidth="1"
        borderColor="neutral.20"
        my={4}
        py={10}
        px={[10, 20]}
        mx={4}
        borderRadius="md"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        space={16}
      >
        <VStack
          space={[8, 16, 14]}
          p={[8, 16, 8]}
          alignItems="center"
          alignContent="center"
        >
          <Box>
            <Icon
              as={
                status == flowOperationResultType.success
                  ? MaterialCommunityIcons
                  : AntDesign
              }
              size={16}
              color={
                status == flowOperationResultType.success
                  ? "accent.80"
                  : "error.30"
              }
              name={
                status == flowOperationResultType.success
                  ? "checkbox-marked-circle"
                  : "warning"
              }
            />
          </Box>
          <Text
            w="full"
            lineHeight="md"
            fontSize={su(18, 20)}
            color="primary.90"
          >
            {flowMap[mode].title}
          </Text>
        </VStack>

        <HStack
          flexDirection={isDesktop ? "row" : "column"}
          space={[16, 16, 8]}
          w="full"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Button
            style={{ width: 126, height: su(45, 45, 32) }}
            bg="accent.40"
            borderRadius="sm"
            borderColor="neutral.40"
            borderWidth="1"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            _pressed={{
              bg: "accent.50",
              color: "base.0",
            }}
            _hover={{ bg: "accent.50", color: "base.0" }}
            onPress={flowMap[mode].methodPrimary}
            mt={2}
          >
            <Text w="full" h="full" fontSize={su(14, 16)} color="base.0">
              {flowMap[mode].labelPrimary}
            </Text>
          </Button>

          {mode !== FLOW_STATUS_MODES.report && (
            <Button
              bg="base.0"
              _pressed={{
                bg: "primary.30",
                tintColor: "primary.30",
              }}
              _hover={{ bg: "primary.30", tintColor: "primary.30" }}
              style={{ width: 126, height: su(45, 45, 32) }}
              borderRadius="sm"
              borderColor="neutral.40"
              borderWidth="1"
              onPress={flowMap[mode].methodSecondary}
              mt={2}
            >
              <Text w="full" h="full" fontSize={su(14, 16)} color="primary.100">
                {flowMap[mode].labelSecondary}
              </Text>
            </Button>
          )}
        </HStack>
      </VStack>
    </Box>
  );
};

export default FlowStatus;
