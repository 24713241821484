import { FormControl, Input, Text } from "native-base";
import React from "react";
import PropTypes from "prop-types";
const InputReport = ({
  placeholder = "",
  width = 200,
  errorMessage = null,
  onChangeText = () => {},
  value = "",
  isDisabled = false,
  alignItems = "flex-start",
  inputName,
  onBlur = () => {},
}) => {
  if (isDisabled) {
    return (
      <Text color="primary.100">{placeholder}: <Text bold> {value} </Text></Text>
    );
  }
  const onChange = (value) => {
    onChangeText(value, inputName);
  };
  return (
    <FormControl
      isDisabled={isDisabled}
      isInvalid={!!errorMessage}
      width={width}
      p={0}
      key={placeholder}
      style={{ alignItems: alignItems, minWidth: 280 }}
    >
      <FormControl.Label key={placeholder + "label"}>
        <Text fontSize={14} fontWeight="xl" color="primary.100">
          {placeholder}:
        </Text>
      </FormControl.Label>
      <Input
        fontSize="md"
        isDisabled={isDisabled}
        onChangeText={onChange}
        onBlur={onBlur}
        width={width}

        style={{height: 45}}
        placeholder={placeholder}
        key={placeholder + "input"}
      />
      <FormControl.ErrorMessage key={placeholder + "msg"}>
        {errorMessage}
      </FormControl.ErrorMessage>
    </FormControl>
  );
};

InputReport.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.number,
  errorMessage: PropTypes.string,
  onChangeText: PropTypes.func,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  inputName: PropTypes.string,
};
export default InputReport;
