import React from "react";
import { Button, Text, HStack, Spinner, Spacer } from "native-base";
import { i18n } from "../../utils/utils";

import { NOTIFICATIONS_STATUS_TYPES } from "./notificationsStatusTypes";
import { useNotificationsActions } from "./NotificationsHelpers";

const styleRejected = {
  borderColor: "accent.100",
  borderWidth: 1,
  borderStyle: "solid",
  bgColor: "error.10",
  height: 30,
  padding: 1,
  margin: 0,
};
const styleAccepted = {
  borderColor: "#36B37E",
  borderWidth: 1,
  borderStyle: "solid",
  backgroundColor: "#EEFBF6",
  height: 30,
  padding: 1,
  margin: 0,
};


const SN = 'screens.notifications.';
const ActionButtons = ({ element }) => {
  const isRejected = element?.status === NOTIFICATIONS_STATUS_TYPES.rejected;
  const isApproved = element?.status === NOTIFICATIONS_STATUS_TYPES.approved;
  const isUnread = element?.status === NOTIFICATIONS_STATUS_TYPES.unread;
  const elResolved = isApproved || isRejected;
  const {
    handleApprove,
    handleReject,
    handleMarkRead,
    markNotificationRequest,
  } = useNotificationsActions(element);
  const hasErroReport = element?.regardingReport ? true : false;
  return (
    <HStack
      // h="full"
      style={{ alignItems: "center" }}
      mr={elResolved ? 6 : 4}
      // borderWidth="1"
      padding="0"
      margin="0"
      space={2}
    >
      {hasErroReport && (
        <>
          <Button
            onPress={handleApprove}
            _important={isApproved ? styleAccepted : {}}
            variant={isApproved ? "outline" : "ghost"}
          >
            <Text
              bold
              textAlign="center"
              color="accent.90"
              style={{
           
                lineHeight: 22,
                fontSize: 14,
            
              }}
            >
              {isApproved ? i18n.t(`${SN}buttons.approved`) : i18n.t(`${SN}buttons.approve`) }
            </Text>
          </Button>
          <Button
            // style={isRejected ? styleRejected : {}}
            onPress={handleReject}
            _important={isRejected ? styleRejected : {}}
            _pressed={{
              borderColor: "accent.100",
              borderWidth: 1,
              bgColor: "error.10",
              height: 49,
            }}
            variant={isRejected ? "outline" : "ghost"}
          >
            <Text
              bold
              textAlign="center"
              color="accent.90"
              style={{
                color: "#DE350B",
                lineHeight: 22,
                fontSize: 14,
                // color: "#36B37E",
              }}
            >
              {isRejected ? i18n.t(`${SN}buttons.rejected`) :  i18n.t(`${SN}buttons.reject`)}
            </Text>
          </Button>
        </>
      )}

      {!elResolved && (
        <Button
          style={isRejected ? styleRejected : {}}
          _text={{ color: "base.90" }}
          variant="ghost"
          onPress={handleMarkRead}
        >
          {isUnread ? i18n.t(`${SN}buttons.markRead`)  : i18n.t(`${SN}buttons.markUnread`) }
        </Button>
      )}
      <Spacer />
      {markNotificationRequest.isLoading && <Spinner color="primary.100" />}
    </HStack>
  );
};

export default ActionButtons;
