import { Image, Box, Text, Center, Divider, VStack } from "native-base";
import React from "react";
import { i18n, EMPTY_MODE_TYPES } from "../utils/utils";
import PropTypes from "prop-types";
const Empty = ({ NewItemComponent, mode = EMPTY_MODE_TYPES.default, message = null }) => {
  const messageMap = {
    [EMPTY_MODE_TYPES.default]: i18n.t("empty.noData"),
    [EMPTY_MODE_TYPES.selectFile]: i18n.t("empty.selectFile"),
  };

  return (
    <Box bg="base.0" h="full" w="full">
      <Center>
        <Image
          p={4}
          mt={5}
          mb={5}
          size="lg"
          alt="Folder img"
          source={require("../../assets/icons/folder.png")}
        />
        <Divider variant="bottom" color="primary.100" />
        <Box
          minHeight={100}
          maxHeight={300}
          //   borderWidth="1"
          borderStyle="solid"
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          borderColor="primary.100"
          p={4}
        >
          <VStack
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            w="full"
          >
            <Text mt={4}> {message !== null ? message: messageMap[mode]} </Text>
            <Divider variant="bottom" color="primary.100" />
            {NewItemComponent && (
              <>
                {NewItemComponent}
            
              </>
            )}
          </VStack>
        </Box>
      </Center>
    </Box>
  );
};

Empty.propTypes = {
  NewItemComponent: PropTypes.element,
  mode: PropTypes.string,
  message: PropTypes.string,

};

export default Empty;
