import React from "react";
import PropTypes from "prop-types";
import {
  Text,
  Pressable,
  Input,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  Icon,
  Button,
  FormControl,
  View,
  Toast,
} from "native-base";
import { i18n, ASSETS_TYPES } from "../../../utils/utils";
import { AntDesign } from "@expo/vector-icons";
import * as Yup from "yup";
import { useFormik } from "formik";
import * as DocumentPicker from "expo-document-picker";

const ValidationSchema = {
  onlyText: Yup.object().shape({
    text: Yup.string().min(3).max(100).required("Required"),
  }),
  file: Yup.object().shape({
    text: Yup.string().min(3).max(100).required("Required"),
    file: Yup.object().defined("File missing.").required("File missing."),
  }),
};
const InputTreeItem = ({
  onSubmit = () => {},
  label = "",
  parentKeys,
  value,
  cancelHandler = () => {},
  uploadFile = false,
  extraData = null,
  isLoading = false,
}) => {

  const [showInput, setShowInput] = React.useState(Boolean(value));

  const displayInput = () => {
   if (isLoading) {
      Toast.show({
        description: "Wait until previous request is completed",
        closeAll: true,
        close: true,
      });
      return;
    }
    setShowInput(true);
  };

  const cancel = () => {
    setShowInput(false);
    cancelHandler();
  };

  const {
    submitForm,
    handleChange,
    setFieldValue,
    errors,
    isValid,
    values,
    // isValidating
  } = useFormik({
    initialValues: {
      text: "" || value,
      file: "",
    },
    validationSchema: uploadFile
      ? ValidationSchema.file
      : ValidationSchema.onlyText,
    onSubmit: async (values, { resetForm }) => {
      if (isValid) {
        const params = !uploadFile
          ? [values?.text, parentKeys]
          : [values, parentKeys, extraData];

        onSubmit(...params);
        setShowInput(false);
        resetForm();
      }
    },
  });

  const pickFiles = async () => {
    let allowedFileTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //docx
      "application/msword", //doc
    ];

    if (
      extraData &&
      extraData !== null &&
      extraData === ASSETS_TYPES.questionner
    ) {
      allowedFileTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
    }

    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: allowedFileTypes,
        multiple: false,
        copyToCacheDirectory: false,
      });

      if (result.type === "success") {
        setFieldValue("file", result, true);
      }
    } catch (e) {
      console.warn("e: ", e);
    }
  };

  return (
    <View
      style={{
        width: 460,
        backgroundColor: "white",
      }}
      bg="base.0"
    >
      {showInput ? (
        <FormControl
          style={{
            maxWidth: 600,
            minWidth: 460,
            width: 460,
            backgroundColor: "white",
          }}
          alignContent="center"
          alignItems="flex-start"
          flexDirection={"column"}
          isInvalid={"text" in errors}
        >
          <InputGroup size="lg">
            <InputLeftAddon key="inputLeft">
              <Button
                onPress={cancel}
                rightIcon={
                  <Icon
                    as={AntDesign}
                    color="primary.10"
                    size={3}
                    name="close"
                  />
                }
              />
            </InputLeftAddon>
            <Input
              key="input"
              style={{ height: 30, width: 200 }}
              defaultValue={values["text"]}
              onBlur={handleChange("text")}
              // onKeyPress={submitOnEnter}
              type="text"
              size="md"
              mr={3}
              placeholder={i18n.t(`screens.settings.buttons.placeHolderInput`)}
            />

            <InputRightAddon
              style={{ width: uploadFile ? 200 : 120 }}
              gap={2}
              flexDirection="row"
              key="inputRight"
            >
              {uploadFile && (
                <Button
                  key="btn-upload"
                  onPress={pickFiles}
                  rightIcon={
                    <Icon
                      as={AntDesign}
                      color="primary.10"
                      size={3}
                      name="upload"
                    />
                  }
                >
                  {i18n.t(`screens.settings.buttons.selectFile`)}
                </Button>
              )}

              <Button
                key="btn-done"
                isLoading={isLoading}
                isDisabled={!isValid}
                onPress={submitForm}
                rightIcon={
                  <Icon
                    as={AntDesign}
                    color="primary.10"
                    size={3}
                    name="check"
                  />
                }
              >
                {i18n.t(`screens.settings.buttons.done`)}
              </Button>
            </InputRightAddon>
          </InputGroup>
          <FormControl.ErrorMessage>{errors["text"]}</FormControl.ErrorMessage>
          <FormControl.ErrorMessage>{errors["file"]}</FormControl.ErrorMessage>
        </FormControl>
      ) : (
        <Pressable
          key={parentKeys?.projectKey + "input"}
          onPress={displayInput}
          variant="unstyled"
        >
          <Text>{label} </Text>
        </Pressable>
      )}
    </View>
  );
};
InputTreeItem.propTypes = {
  isLoading: PropTypes.bool,
  uploadFile: PropTypes.bool,
  cancelHandler: PropTypes.func,
  label: PropTypes.string,
  onSubmit: PropTypes.func,
  parentKeys: PropTypes.object,
  value: PropTypes.string,
};
export default React.memo(InputTreeItem, () => {

 return true;
});
// export default InputTreeItem;
