import { Avatar, Text, Box } from "native-base";
import React from "react";
import { su } from "../utils/utils";

const UserAvatar = ({ user, size = "big" }) => {
  if (!user) {
    return null;
  }

  const nameInitials =
    `${user?.firstName?.[0]}${user?.lastName?.[0]}`.toUpperCase();
  const sourceAtr = user?.avatarPhoto?.url
    ? { source: { uri: user?.avatarPhoto?.url } }
    : {};

  const getAvatarStyle = () => {
    if (size === "big") {
      return {
        height: su(50, 50),
        width: su(50, 50),
      };
    }
    if (size === "small") {
      return {
        height: su(30, 30),
        width: su(30, 30),
      };
    }
  };

  const getFontSize = () => {
    if (size === "big") {
      return su("lg", "xl","3xl");
    }
    if (size === "medium") {
      return su("sm", "sm");
    }
    if (size === "small") {
      return su("xs", "xs");
    }
  };

  return (
    <Avatar
      bg="white"
        borderWidth={1}
      // color="primary.100"

      // borderWidth="1"
      // borderColor="red"
      borderColor="neutral.50"
      padding={"2px"}
      // display="flex"
      style={getAvatarStyle()}
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      justifyItems="center"
      {...sourceAtr}
    >
      <Box
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        justifyItems="center"
        alignSelf="center"
        // borderWidth="1"
        style={{width: '100%',height: '100%'}}
        pl={0.5}
        pt={1}
      >
        <Text
          textAlign="center"
          color="primary.100"
          letterSpacings={size === "big" ? "3xl" : "md"}
          bold
          
          lineHeight="xs"
          fontSize={getFontSize()}
        >
          {nameInitials}
        </Text>
      </Box>
    </Avatar>
  );
};

export default UserAvatar;
