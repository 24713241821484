import React from "react";
import { HStack, VStack, View } from "native-base";
import SideMenu from "../navigation/SideMenu/SideMenu/SideMenu";
import SubHeader from "../components/Subheader";
import { useWindowDimensions } from "react-native";
import FlowProgressDesktop from "../screens/tasks/main-flow/progress/FlowProgressDesktop";
import { windowHeight } from "../utils/utils";
import { flowScreens } from "../screens/tasks/main-flow/TasksMainFlowNavigator";
import ModalRich from "../components/ModalRich";
const LayoutDesktop = (props) => {
  const {
    children,
    replaceSideMenuWithProgress,
    displaySideMenu = true,
    isDesktop,
  } = props;

  const screenName = children?.props?.route?.name;
  if (!isDesktop) {
    return children;
  }

  const renderSideMenu = () => {
    if (!displaySideMenu) {
      return null;
    }
    return replaceSideMenuWithProgress ? (
      <FlowProgressDesktop data={flowScreens} />
    ) : (
      <SideMenu />
    );
  };

  const { width } = useWindowDimensions();

  const windowWidth = displaySideMenu ? width - 207 : width;
  const contentHeight = windowHeight - 200;
  if (isDesktop) {
    return (
      <HStack flexDirection="row" bg="base.10">
        {renderSideMenu()}
        <VStack
          space={2}
          bg="base.10"
          style={{
            width: windowWidth,
            height: contentHeight,
          }}
        >
          <SubHeader routeName={screenName} />
          <View
            bg="base.10"
            style={{ marginLeft: 42, marginRight: 42, height: contentHeight }}
          >
            {children}
          </View>
        </VStack>
      </HStack>
    );
  }
};

export default LayoutDesktop;

export const withDesktopLayout = (
  WrappedComponent,
  replaceSideMenuWithProgress = false,
  displaySideMenu = true,
  isMobile,
  isDesktop
) => {
  /* eslint-disable react/display-name */

  return (props) => (
    <>
      <LayoutDesktop
        isDesktop={isDesktop}
        displaySideMenu={displaySideMenu}
        replaceSideMenuWithProgress={replaceSideMenuWithProgress}
      >
        <WrappedComponent {...props} />
      </LayoutDesktop>
      <ModalRich isMobile={isMobile} />
    </>
  );
};
