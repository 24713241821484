import React from "react";
import { DATE_FORMAT } from "../../../../utils/utils";
import { HStack, VStack, Text } from "native-base";
import { Platform } from "react-native";
import DateSelectNative from "./DateSelectNative";
import DatePicker from "antd/lib/date-picker";
import dayjs from "dayjs";
import _ from "lodash";
const DateSelect = ({
  multi = false,
  onPress,
  defaultValue = new Date(),
}) => {
  const onChangeDate = (value) => {
    if (!value) {
      return;
    }
    let selectedData = {};
    if (multi && _.isArray(value) && value.length > 0) {
      selectedData = value.map((d) => {
        if (d !== null) {
          if (Platform.OS !== "web") {
            d = dayjs(d);
          } else {
            d = dayjs(d.toDate()).format(DATE_FORMAT);
          }
        }
        return d;
      });
    } else {
      let valueCopy = value;
      if (Platform.OS !== "web") {
        valueCopy = dayjs(valueCopy[0]);
      }
      selectedData = dayjs(valueCopy.toDate()).format(DATE_FORMAT);
    }
    onPress(selectedData);
  };

  const renderWebPicker = () => {
    if (multi) {
      let defaultVal = defaultValue;

      if (defaultValue && _.isArray(defaultValue)) {
        defaultVal = defaultValue.map((item) => dayjs(item));
      } else {
        defaultVal = [null, null];
      }

      const onChangeStartDate = (date) => {
        onChangeDate([date, defaultVal[1]]);
      };
      const onChangeEndDate = (date) => {
        onChangeDate([defaultVal[0], date]);
      };

      return (
        <HStack space={1}>
          <VStack>
            <Text color="primary.100"> Select start date </Text>
            <DatePicker
              style={{
                width: 190,
              }}
              onChange={onChangeStartDate}
              placeholder="Select start date"
              picker="day"
              defaultValue={defaultValue?.[0] ? dayjs(defaultValue?.[0]) : null}
            />
          </VStack>

          <VStack>
            <Text textAlign="right" color="primary.100">
              Select end date{" "}
            </Text>
            <DatePicker
              style={{
                width: 190,
              }}
              onChange={onChangeEndDate}
              placeholder="Select end date"
              picker="day"
              defaultValue={defaultValue?.[1] ? dayjs(defaultValue?.[1]) : null}
            />
          </VStack>
        </HStack>
      );
    }
    return (
      <DatePicker
        style={{
          width: 400,
        }}
        onChange={onChangeDate}
        picker="day"
        defaultValue={defaultValue ? dayjs(defaultValue) : null}
      />
    );
  };

  if (Platform.OS === "web") {
    return renderWebPicker();
  } else {
    return (
      <DateSelectNative
        defaultValue={defaultValue}
        setDate={onChangeDate}
        multi={multi}
      />
    );
  }
};

export default DateSelect
