import React from "react";
import { Text, Box, VStack, Center, ScrollView } from "native-base";
import DocViewer, { DocViewerRenderers, } from "@cyntler/react-doc-viewer";
import PreviewFileHeader from "./PreviewFileHeader";
import { useSelector } from "react-redux";
import { CONTENT_CONTAINER_HEIGHT } from "../utils/utils";

const PreviewFile = ({ file, showHeader = true }) => {
  console.log("🚀 ~ file: PreviewFile.web.js:9 ~ PreviewFile ~ file:", file);
  const token = useSelector((state) => state.auth.token);

  if (!file) {
    return <Text>The file cannot be loaded. </Text>;
  }

  const getDocUrl = (file) => {
  
    if (file?.pdfVersionUrl && file?.pdfVersionUrl !== "") {
      return [
        {
          uri: file?.pdfVersionUrl,
        },
      ];
    }
    return [
      {
        uri: file?.uri || file.url,
      },
    ];
  };

  const docs = getDocUrl(file);
  const headers = {
    Authorization: `JWT ${token}`,
  };

  const renderCustomHeader = () =>
    showHeader && <PreviewFileHeader file={file} />;

  return (
    <Box
      justifyContent="center"
      alignContent="center"
      justifyItems="center"
    >
      <ScrollView>
        <Center>
          <VStack>
            <Box
              style={{ width: 700, maxHeight: 1200 }}
              h="full"
              justifyContent="center"
              alignContent="center"
              justifyItems="center"
            >
              <DocViewer
                prefetchMethod="GET"
                config={{
                  header: {
                    disableFileName: true,
                    overrideComponent: renderCustomHeader,
                  },
                }}
                requestHeaders={headers}
                style={{ width: "100%", height: CONTENT_CONTAINER_HEIGHT }}
                pluginRenderers={DocViewerRenderers}
                documents={docs}
              />
            </Box>
          </VStack>
        </Center>
      </ScrollView>
    </Box>
  );
};

export default PreviewFile;
