import React from "react";
import Report from "../screens/tasks/results/FinalReport";
import ReportError from "../screens/tasks/ReportError";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { withDesktopLayout } from "../containers/Layout";
import { useAcrossDevices } from "../containers/DeviceTypeContainers";
import ResolveTask from "../screens/tasks/resolve-task";

import NavBtn from "../components/shared/NavBtn";
import Header from "../components/header/Header";

const Stack = createNativeStackNavigator();

const ResolveFlowNavigator = () => {
  const { isDesktop } = useAcrossDevices();
  const defaultHeader = {
    header: (navigationProps) => <Header {...navigationProps} />,
  };
  const headerOptionsResults = (nextNav = null) =>
    isDesktop
      ? defaultHeader
      : {
          headerStyle: {
            title: "Resolve Task",
            backgroundColor: "#091E42",
            color: "white",
          },
          headerTitleAlign: "center",
          headerTitleStyle: {
            color: "white",
          },
          headerRight: nextNav
            ? () => <NavBtn nextNav={nextNav} variant="right" />
            : null,
          headerLeft: () => <NavBtn variant="left" />,
        };

  return (
    <Stack.Navigator initialRouteName="ResolveTask">
      <Stack.Group>
        <Stack.Screen
          name="ResolveTask"
          options={headerOptionsResults("ResolveTask")}
          component={withDesktopLayout(ResolveTask, false, false)}
        />

        <Stack.Screen
          name="Report"
          options={headerOptionsResults()}
          component={withDesktopLayout(Report, false, false)}
        />
        <Stack.Screen
          name="ReportError"
          options={headerOptionsResults()}
          component={withDesktopLayout(ReportError, false, false)}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default ResolveFlowNavigator;
