import { Text, Pressable } from "native-base";
import React from "react";

import { useNavigation } from "@react-navigation/native";

const ViewReportButton = ({ element }) => {

  const navigation = useNavigation();

  if (
    !element ||
    !element?.regardingReport
  ) {
    return null;
  }

  const isErrorReport = element?.regardingReport?.relationTo === "error-report";

  const openReport = () => {
    const screen = isErrorReport ? "ReportError" : "Report";
    const reportId = element.regardingReport.id;
    navigation.navigate("ResolveFlow", {
      screen: screen,
      params:{ reportId: reportId, fromNotifications: true }
    });

  };

  return (
    <Pressable  onPress={openReport}>
      <Text color="primary.60" underline={true}>
        View report
      </Text>
    </Pressable>
  );
};

export default ViewReportButton;
