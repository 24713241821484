import { View, Text } from 'react-native'
import React from 'react'

const Warning = () => {
  return (
    <View>
      <Text>Are you sure you want to delete this task?</Text>
    </View>
  )
}

export default Warning