export const filtersList = [
    {
      title: "Select project",
      name: "project",
      nextScreen: "area",
    },
    {
      title: "Select area",
      name: "area",
      nextScreen: "dis",
    },
    {
      title: "Select dis",
      name: "dis",
      nextScreen: "pn",
    },
    {
      title: "Select pn",
      name: "pn",
      nextScreen: "questionner",
    },
    {
      name: "questionner",
      title: "Select ITP",
      nextScreen: "activity",
    },
    {
      title: "Select activity",
      name: "activity",
      nextScreen: "TasksListMobile",
    },
  ];