import {
  Roboto_100Thin,
  Roboto_100Thin_Italic,
  Roboto_300Light,
  Roboto_300Light_Italic,
  Roboto_400Regular,
  Roboto_400Regular_Italic,
  Roboto_500Medium,
  Roboto_500Medium_Italic,
  Roboto_700Bold,
  Roboto_700Bold_Italic,
  Roboto_900Black,
  Roboto_900Black_Italic,
} from "@expo-google-fonts/roboto";

export const familyFonts = {
  Roboto_100Thin,
  Roboto_100Thin_Italic,
  Roboto_300Light,
  Roboto_300Light_Italic,
  Roboto_400Regular,
  Roboto_400Regular_Italic,
  Roboto_500Medium,
  Roboto_500Medium_Italic,
  Roboto_700Bold,
  Roboto_700Bold_Italic,
  Roboto_900Black,
  Roboto_900Black_Italic,
}

 const familyFontsGroups = {
  Roboto: {
    100: {
      normal: "Roboto_100Thin",
      italic: "Roboto_100Thin_Italic",
    },
    300: {
      normal: "Roboto_300Light",
      italic: "Roboto_300Light_Italic",
    },
    400: {
      normal: "Roboto_400Regular",
      italic: "Roboto_400Regular_Italic",
    },
    500: {
      normal: "Roboto_500Medium",
      italic: "Roboto_500Medium_Italic",
    },
    700: {
      normal: "Roboto_700Bold",
      italic: "Roboto_700Bold_Italic",
    },
    900: {
      normal: "Roboto_900Black",
      italic: "Roboto_900Black_Italic",
    }
  }
};

const typography =  {
  letterSpacings: {
    "xs": "-0.05em",
    "sm": 0,
    "md": 0.15,
    "lg": 0.2,
    "xl": 0.3,
    "2xl":0.5,
    "3xl":0.8,
  },
  lineHeights: {
    "2xs": "1em",
    "xs": 18,
    "sm": 22,
    "md": 24,
    "lg": 27,
    "xl": 30,
    "2xl": "2em",
    "3xl": "2.5em",
    "4xl": "3em",
    "5xl": "4em",
  },
  fontWeights: {
    thin: 100,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 500,
    bold: 700,
    black: 900,
  },
}


 const spacing = {
  px: 1,
  1: 4,
  2: 8,
  3: 12,
  3.5:14,
  4: 16,
  5: 20,
  6: 24,
  7: 28,
  8: 32,
  9: 36,
  10: 40,
  11: 47,// unique
  12: 48,
  16: 64,
  68: 68, // unique
  20: 80,
  24: 96,
  32: 128,
  40: 160,
  48: 192,
  56: 224,
  64: 256,
  72: 288,
  80: 320,
  96: 384,
};

export const fontConfig = {
  fontConfig:familyFontsGroups,
  fonts: {
    heading: "Roboto",
    body: "Roboto",
    mono: "Roboto",
  },
  ...spacing,
  ...typography
}