import { TASKS_STATUS_TYPES } from "../../../screens/tasks/tasksStatusTypes";
export const ROW_STYLE_TYPES = {
  head: "head",
  basic:"basic",
  warning:"warning",
  valid:"valid",
  error:"error",
  unread:"unread",
  ...TASKS_STATUS_TYPES,
};
