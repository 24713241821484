import { createSlice, current } from "@reduxjs/toolkit";
import "react-native-get-random-values";
import {
  ASSETS_TYPES,
  checkRequestPayload,
  TREE_LEVELS
} from "../utils/utils";
import { api } from "../services/apiService";
import _ from "lodash";

const modalInitialState = {
  isOpen: false,
  contentBody: "",
  data: null,
  content: { isValid: true },
  title: null,
  size: "lg",
  submitLabel: "",
  wasOpened: false,
};

const toastInitialState = {
  title: "",
  variant: "solid",
  description: "",
  isClosable: true,
  showMessage: false,
};

export const uxSlice = createSlice({
  name: "ux",
  initialState: {
    modal: modalInitialState,
    Toast: toastInitialState,
    isEditModeActive: false,
    notificationsCount: 0,
    viewFilesPages: {
      [ASSETS_TYPES.questionner]: null,
      [ASSETS_TYPES.formA]: null,
      [ASSETS_TYPES.formB]: null,
      [ASSETS_TYPES.otherDocs]: null,
    },
    tasksFilters: {
      project: { index: null, id: null },
      area: { index: null, id: null },
      dis: { index: null, id: null },
      pn: { index: null, id: null },
      questionner: { index: null, id: null },
      activity: { index: null, id: null },
      data: {
        projects: [],
      },
    },
  },
  reducers: {
    setTaskFilter: (state, action) => {
      if (
        !action.payload ||
        !action.payload.attributeName ||
        !action.payload.attributeValue
      ) {
        return;
      }

      const { attributeName, attributeValue } = action.payload;
      state.tasksFilters[attributeName] = attributeValue;
    },
    setSelectedFile: (state, action) => {
      state.viewFilesPages[action.payload.fileCategory] =
        action.payload.selectedItem;
    },

    showMessage: (state, action) => {
      state.Toast = {
        ...toastInitialState,
        ...action.payload,
        showMessage: true,
      };
    },
    resetToast: (state) => {
      state.Toast = toastInitialState;
    },
    setContent: (state, action) => {
      state.modal.content = { ...state.modal.content, ...action?.payload };
    },
    openModal: (state, action) => {
      state.modal.isOpen = true;
      state.modal.title = action.payload;
    },
    setTitle: (state, action) => {
      state.modal.title = action.payload;
    },
    setShowModal: (state, action) => {
      state.modal.isOpen = action.payload;
    },
    closeModal: (state) => {
      state.modal = modalInitialState;
    },

    openModalAndSetContent: (state, action) => {
      state.modal.isOpen = true;
      state.modal.content = action.payload.content || {};
      state.modal.contentBody = action.payload.contentBody;
      state.modal.title = action.payload?.title || "";
      state.modal.size = action.payload?.size || "";
      state.modal.submitLabel = action.payload?.submitLabel || null;
      state.modal.onSubmit = action.payload?.onSubmit || null;

      if (!action.payload.data) {
        state.modal.data = null;
        return;
      }
      state.modal.data = action.payload.data;
    },
    setEditModeStatus: (state) => {
      state.isEditModeActive = !state.isEditModeActive;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getProjects.matchFulfilled, (state, req) => {
        const { payload } = req;
        if (payload?.docs && payload.page) {
          const currentProjectsList = current(state.tasksFilters.data.projects);

          const merged = _.merge(
            _.keyBy(currentProjectsList, "id"),
            _.keyBy(payload?.docs, "id")
          );
          const values = _.values(merged);

          state.tasksFilters.data.projects = values.map(item=>{
            let itemCopy = item;
            itemCopy = { ...itemCopy, [TREE_LEVELS.areas]: [] };
            return itemCopy;
          });
        }
      })
      .addMatcher(
        api.endpoints.getNotificationsCount.matchFulfilled,
        (state, req) => {
          const { payload } = req;
          if(Number(payload?.count) !== state.notificationsCount ){
            state.notificationsCount = Number(payload?.count)||0
          }



        })
      .addMatcher(
        api.endpoints.getAreasOfProject.matchFulfilled,
        (state, req) => {
          const { payload } = req;

          const parentsIds = checkRequestPayload(req);

          if (parentsIds === null) {
            return;
          }

          const { projectId } = parentsIds;
          const currentProjectIndex = current(
            state.tasksFilters.data.projects
          ).findIndex((item) => item.id === projectId);

          if (currentProjectIndex === -1) {
            return;
          }

          const projectAreas = payload?.docs.map((item) => {
            let newItem = _.cloneDeep(item);
            newItem.dis = [];
            return newItem;
          });

          const currentProjectsList = state.tasksFilters.data.projects;
          const currentProject =  currentProjectsList?.[currentProjectIndex];
          if (
            currentProject&&
            currentProject?.areas
          ) {
            currentProjectsList[currentProjectIndex].areas = projectAreas;
          }
        }
      )
      .addMatcher(api.endpoints.getDisOfArea.matchFulfilled, (state, req) => {
        const originalArgs = checkRequestPayload(req);
        if (originalArgs === null) {
          return;
        }
        const currentProjectsList = state.tasksFilters.data.projects;
        const { projectId, areaId } = originalArgs;
        const currentProjectIndex = current(currentProjectsList).findIndex(
          (item) => item.id === projectId
        );
        if (currentProjectIndex === -1) {
          return;
        }
        const currentProject = currentProjectsList?.[
          currentProjectIndex
        ];


        const areaIndex = currentProject.areas.findIndex((item) => item.id === areaId);

        if (areaIndex === -1) {
          return;
        }

        const disItems = req.payload.docs.map((item) => {
          let newItem = _.cloneDeep(item);
          newItem.pn = [];
          return newItem;
        });

        currentProject.areas[areaIndex].dis =
          disItems;
      })
      .addMatcher(
        api.endpoints.getTasksOfDis.matchFulfilled,
        (state, req) => {
          const parentsIds = checkRequestPayload(req, false);
          if (parentsIds === null) {
            return;
          }
          const { projectId, areaId, disId} = parentsIds;
          const currentProjectsList = state.tasksFilters.data.projects;
          const currentProjectIndex = current(currentProjectsList).findIndex(
            (item) => item.id === projectId
          );
  
          if (currentProjectIndex === -1) {
            return;
          }
  
     
          const currentProject = currentProjectsList?.[
            currentProjectIndex
          ];
   
          const areaIndex = currentProject.areas.findIndex((item) => item.id === areaId);
          if (areaIndex === -1) {
            return;
          }
  
          const currentArea = currentProject.areas[areaIndex];
          const disIndex = currentArea.dis.findIndex((item) => item.id === disId);
  
          if (disIndex === -1) {
            return;
          }

          const currentDis = currentArea.dis[disIndex];
 
          currentDis.pn = req.payload.docs.map(item=>{
            let newItem = _.cloneDeep(item);
            let pnGroupName =  ''
            newItem.pn.forEach((pn,index)=>{
              if(index===0){

                pnGroupName = `${pnGroupName} ${pn.name}` 
              } else {

                pnGroupName = ` ${pnGroupName} ${pn.name}` 
              }
            })
            newItem.name = pnGroupName;
            newItem.questionnaires = [newItem.questionner];
                return newItem;
          })
          
        })

      .addMatcher(
        api.endpoints.getQuestionnersOfPn.matchFulfilled,
        (state, req) => {
          const parentsIds = checkRequestPayload(req, false);
          if (parentsIds === null) {
            return;
          }
          const { projectId, areaId, disId, pnId } = parentsIds;
          const currentProjectsList = state.tasksFilters.data.projects;
          const currentProjectIndex = current(currentProjectsList).findIndex(
            (item) => item.id === projectId
          );
  
          if (currentProjectIndex === -1) {
            return;
          }
  
          const currentProject = currentProjectsList?.[
            currentProjectIndex
          ];
          const areaIndex = currentProject.areas.findIndex((item) => item.id === areaId);
  
          if (areaIndex === -1) {
            return;
          }
  
          const currentArea = currentProject.areas[areaIndex];
          const disIndex = currentArea.dis.findIndex((item) => item.id === disId);
  
          if (disIndex === -1) {
            return;
          }

          const currentDis = currentArea.dis[disIndex];
          
          const pnIndex = currentDis.pn.findIndex((item) => item.id === pnId);

          if(pnIndex===-1){
            return;
          }

          const currentPn = currentDis.pn[pnIndex];
          currentPn.questionnaires = req.payload.docs.map(item=>{

            let itemCopy = _.cloneDeep(item);
            if(itemCopy?.tableColumns){
              delete itemCopy?.tableColumns;
              delete itemCopy?.tableRows;
            }
            return itemCopy;
          });
          
        })
      ;
  },
});

export const {
  setSelectedFile,
  setContent,
  resetToast,
  showMessage,
  openModal,
  setTitle,
  closeModal,
  setShowModal,
  openModalAndSetContent,
  setEditModeStatus,
  setTaskFilter,
  setExpandedKeys,
} = uxSlice.actions;

export default uxSlice.reducer;
