import { useMediaQuery } from "native-base";
import { Platform } from "react-native";



// Business requirements
// on Mobile the device is locked on PORTRAIT MODE / BP maxWidth: 480
// on Tablets the device is locked on LANDSCAPE MODE / BP maxWidth: 480
// on DESKTOP the device is  LANDSCAPE MODE by default
// BP = Breaking point
const orientation = [
  {
    orientation: "landscape",
  },
  {
    orientation: "portrait",
  },
];
export const mobileMediaQueryL = { maxWidth: 812 };
export const tabletMediaQueryL = { maxWidth: 1366 }; // ipad pro width (bigget tablet from Apple)
export const desktopMediaQueryL = { minWidth: 13367 }; // 

export const mobileMediaQueryP = { maxWidth: 480};
export const tabletMediaQueryP = {maxWidth: 834, minWidth: 481 };
export const desktopMediaQueryP = { minWidth: 835 };



// responsivness strategy

/// isMobile? 
// Portrait: isMobile <576
// Landscape: 
export const useAcrossDevices = () => {
  const mediaQueries = [
    mobileMediaQueryP,
    mobileMediaQueryL,
    tabletMediaQueryP,
    tabletMediaQueryL,
    desktopMediaQueryP,
    desktopMediaQueryL,
    ...orientation,
  ];
       /* eslint-disable no-unused-vars*/
  const [
    isMobileP,
    isMobileL,
    isTabletP,
    isTabletL,
    isDesktopP,
    isDesktopL,
    isLandscape,
  ] = useMediaQuery(mediaQueries);

const isWeb = Platform.OS === "web";
const isNative = Platform.OS === "ios" || Platform.OS === "android";


  const isMobile = isWeb ? false :isMobileP;

  // console.log("🚀 ~ useAcrossDevices ~ isMobile", isMobile);
  const isTablet = isTabletL && isNative && !isMobile;


  // console.log("🚀 ~ useAcrossDevices ~ isTablet", isTablet);
  const isDesktop = isDesktopL ? true: isWeb;

  // console.log("🚀 ~ useAcrossDevices ~ isDesktop", isDesktop);
  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};


export const useIsTablet = () => {
  const { isTablet } = useAcrossDevices();

  return isTablet;
};

export const useIsDesktop = () => {
  const { isDesktop } = useAcrossDevices();
  return isDesktop;
};

export const useIsMobile = () => {
  const { isMobile } = useAcrossDevices();
  return isMobile;
};

export const Tablet = ({ children }) => {
  const isTablet = useIsTablet();
  return isTablet ? children : null;
};

export const Desktop = ({ children }) => {
  const isDesktop = useIsDesktop();
  return isDesktop ? children : null;
  // return isDesktop && Platform.OS === 'web'? children : null;
};

export const Mobile = ({ children }) => {
  const isMobile = useIsMobile();

  return isMobile ? children : null;
};
export const NativeDevices = ({ children }) => {
  const [isNotMobile] = useMediaQuery({ maxWidth: 1023 });
  return isNotMobile && (Platform.OS === "ios" || Platform.OS === "android")
    ? children
    : null;
};

export const designResolutionDesktop = {
  width: 1440,
  height: 1024,
};
export const designResolutionTablet = {
  width: 1194,
  height: 867,
};

export const designResolutionMobile = {
  width: 390,
  height: 844,
};
