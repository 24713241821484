import { Button, Text, Icon } from "native-base";
import React from "react";
import {
  su,
  useAcrossDevices,
  RESOLVE_ANSWERS_TYPES,
  RESOLVE_TASK_FLOW_MODES,
  i18n,
} from "../../../utils/utils";
import { AntDesign } from "@expo/vector-icons";
import _ from "lodash";
const getBtnColors = (selectedAnswer, answer, color, isMobile) => {
  const isSelected = selectedAnswer && selectedAnswer === answer;

  const btnBig = isSelected ? "primary.100" : color;

  const btnMobile = !isSelected ? "base.0" : color;
  const btnBg = isMobile ? btnMobile : btnBig;
  const btnText = isMobile ? (isSelected ? "base.0" : color) : "base.0";
  return [btnBg, btnText];
};

const ResponseBtns = ({
  selectedPN,
  inputText,
  saveAnswer,
  flowType,
  question,
  isLoading,
}) => {
  const { isMobile } = useAcrossDevices();
  const [btnLoading, setBtnsLoading] = React.useState({
    yes: false,
    no: false,
    na: false,
  });
  const answerYes = () => {
    setBtnsLoading({
      no: false,
      na: false,
      yes: true,
    });
    saveAnswer(RESOLVE_ANSWERS_TYPES.yes);
  };
  const answerNo = () => {
    saveAnswer(RESOLVE_ANSWERS_TYPES.no);
    setBtnsLoading({
      no: true,
      na: false,
      yes: false,
    });
  };
  const answerNa = () => {
    setBtnsLoading({
      no: false,
      na: true,
      yes: false,
    });
    saveAnswer(RESOLVE_ANSWERS_TYPES.na);
  };
  const answerInput = () => {
    if (inputText === "") {
      return;
    }
    setBtnsLoading({
      no: false,
      na: false,
      yes: true,
    });
    saveAnswer(inputText);
  };

  let selectedAnswer = null;
  if (selectedPN && selectedPN.length === 1) {
    const answerIndex = question.answers.findIndex((item) => {
      const elPn = _.isString(item.pn) ? item.pn : item.pn.id;
      if (elPn === selectedPN[0]) {
        return item;
      }
    });

    if (answerIndex !== -1) {
      selectedAnswer = question.answers[answerIndex].answer;
    }
  }

  const [yesBtnBg, yesBtnText] = getBtnColors(
    selectedAnswer,
    RESOLVE_ANSWERS_TYPES.yes,
    "accent.80",
    isMobile
  );

  const [naBtnBg, naBtnText] = getBtnColors(
    selectedAnswer,
    RESOLVE_ANSWERS_TYPES.na,
    "accent.60",
    isMobile
  );

  const [noBtnBg, noBtnText] = getBtnColors(
    selectedAnswer,
    RESOLVE_ANSWERS_TYPES.no,
    "accent.100",
    isMobile
  );

  const submitBtnBg = isMobile
    ? "base.0"
    : selectedAnswer &&
      selectedAnswer !== RESOLVE_ANSWERS_TYPES.na &&
      selectedAnswer !== RESOLVE_ANSWERS_TYPES.waiting
    ? "primary.100"
    : "accent.80";

  return (
    <Button.Group
      px={4}
      py={4}
      alignItems={"center"}
      justifyContent="center"
      justifyItems="center"
      isDisabled={selectedPN && selectedPN.length === 0}
      w="full"
      style={{ height: 70 }}
      bg={!isMobile ? "base.10" : "base.0"}
      space={!isMobile ? 2 : 6}
    >
      <Button
        variant={isMobile ? "outline" : "solid"}
        borderRadius={"md"}
        borderWidth={isMobile ? 1 : 0}
        borderColor="accent.80"
        isLoading={btnLoading.yes && isLoading}
        onPress={
          flowType === RESOLVE_TASK_FLOW_MODES.question
            ? answerYes
            : answerInput
        }
        key="btn1"
        style={{
          height: 50,
          width: su(50, 155, 155),
        }}
        bg={
          flowType === RESOLVE_TASK_FLOW_MODES.question ? yesBtnBg : submitBtnBg
        }
        _hover={{
          bg: "accent.80",
          opacity: 90,
          color: 'base.0'
        }}
        _pressed={{
          bg: "accent.80",
          opacity: 90,
          color: 'base.0'
        }}
      >
        {isMobile ? (
          <Icon as={AntDesign} name="check" size={7} color={yesBtnText} />
        ) : (
          <Text color={yesBtnText} fontWeight="semibold">
            {flowType === RESOLVE_TASK_FLOW_MODES.question
              ? i18n.t("screens.resolveTask.buttons.yes")
              : i18n.t("screens.resolveTask.buttons.submitResponse")}
          </Text>
        )}
      </Button>
      {flowType === RESOLVE_TASK_FLOW_MODES.question && (
        <Button
          variant={isMobile ? "outline" : "solid"}
          borderRadius={"md"}
          onPress={answerNo}
          key="btn2"
          borderWidth={isMobile ? 1 : 0}
          borderColor="accent.100"
          isLoading={btnLoading.no && isLoading}
          style={{
            height: 50,
            width: su(50, 155, 155),
          }}
          bg={noBtnBg}
          _hover={{
            bg: isMobile ? "base.0" : "accent.100",
            opacity: 90,
            color: 'base.0'
          }}
          _pressed={{
            bg: isMobile ? "error.20" : "accent.100",
            opacity: 90,
            color: 'base.0'
          }}
        >
          {isMobile ? (
            <Icon size={7} color={noBtnText}  as={AntDesign} name="close" />
          ) : (
            <Text color={noBtnText} fontWeight="semibold">
              {i18n.t("screens.resolveTask.buttons.no")}
            </Text>
          )}
        </Button>
      )}
      <Button
        variant={isMobile ? "outline" : "solid"}
        borderRadius={"md"}
        key="btn3"
        onPress={answerNa}
        isLoading={btnLoading.na && isLoading}
        borderWidth={isMobile ? 1 : 0}
        borderColor="accent.60"
        style={{
          height: 50,
          width: su(52, 155, 155),
        }}
        bg={naBtnBg}
        _hover={{
          bg: "accent.50",
          opacity: 90,
        }}
        _pressed={{
          bg: "warning.30",
          opacity: 90,
        }}
      >
        <Text color={naBtnText} fontWeight={isMobile ? "bold" : "semibold"}>
          {i18n.t("screens.resolveTask.buttons.na")}
        </Text>
      </Button>
    </Button.Group>
  );
};

export default ResponseBtns;
