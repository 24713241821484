import IconMenuBtn from "../shared/IconMenuBtn";
import PropTypes from "prop-types";
import React from "react";
import { HStack, Box, Divider, Text, Avatar, VStack, Badge } from "native-base";
import AccountMenu from "../../components/header/AccountMenu";
import {
  DESKTOP_HEADER_MENU,
  TABLET_MENU,
  MOBILE_MENU,
} from "../../../assets/constants/menuItems";
import { su } from "../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { logOutUserHook } from "../../features/authSlice";
import { Pressable } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useGetNotificationsCountQuery, api } from "../../services/apiService";
const RightSideHeader = ({ isDesktop, isTablet, isMobile, renderPlusBtn }) => {
  const [logOutUser, { isLoading }] = logOutUserHook();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const authSlice = useSelector((state) => state.auth);
  const isModalOpen = useSelector((state) => state?.ux?.modal?.isOpen);

  const notificationsCount = useSelector(
    (state) => state.ux.notificationsCount
  );
  const accesss = authSlice.access;
  console.log("🚀 ~ file: RightSideHeader.js:28 ~ RightSideHeader ~ accesss:", accesss);

  
  const loggedUser = useSelector((state) => state.auth.user);
  useGetNotificationsCountQuery(null, {
    skip: isModalOpen || loggedUser === null,
  });
  const isAbleToCreateTasks =
    accesss?.collections?.tasks?.create?.permission === true;
  const isAbleToEditProjects =
    accesss?.collections?.projects?.update?.permission === true;

  const isAbleToSeeNotifications =
    accesss?.collections?.notifications?.read?.permission === true;

  const firstLetter = loggedUser?.firstName?.[0];

  const goTo = (path) => {
    dispatch(api.util.invalidateTags(["NotificationsCount"]));
    navigation.replace(path);
  };

  const desktopElements = () => {
    return (
      <HStack alignItems="center" space={3}>
        {DESKTOP_HEADER_MENU.map((item, index) => {
          if (item.path === "Notifications" && !isAbleToSeeNotifications) {
            return null;
          }
          if (
            item.path === "Settings" &&
            !isAbleToEditProjects &&
            loggedUser.classType !== "m0"
          ) {
            return null;
          }
          if (item.title === "Account") {
            return (
              <AccountMenu
                height={su(32, 40, 24)}
                data={loggedUser}
                isDesktop={isDesktop}
                goTo={item?.path}
                key={`header-menu-${index}`}
              />
            );
          }

          if (item.path === "Notifications") {
            if (notificationsCount > 0) {
              return (
                <VStack
                  style={{ height: su(44, 46, 46), width: 45 }}
                  alignItems="center"
                  justifyItems="center"
                  display="flex"
                  key={`header-menu-${index}`}
                >
                  <Badge
                    bg="accent.100"
                    rounded="full"
                    mb={-3}
                    zIndex={1}
                    borderWidth={1}
                    variant="solid"
                    alignSelf="flex-end"
                    alignItems="center"
                    alignContent="center"
                    textAlign="center"
                    display="flex"
                    justifyItems="center"
                    w="4"
                    h="4"
                    p={0}
                    justifyContent="center"
                    _text={{
                      fontSize: 7,
                    }}
                  >
                    <Text
                      style={{ fontSize: notificationsCount > 9 ? 10 : 11 }}
                      color="base.0"
                    >
                      {notificationsCount > 9 ? "9+" : notificationsCount}
                    </Text>
                  </Badge>

                  <IconMenuBtn
                    key={`header-menu-${index}`}
                    size={su(14, 5, 5)}
                    {...item}
                    _onPress={item.name == "logout" ? logOutUser : undefined}
                  />
                </VStack>
              );
            }
          }
          return (
            <IconMenuBtn
              size={su(14, 5, 5)}
              _onPress={item?.title === "Logout" ? logOutUser : undefined}
              goTo={item?.path}
              isLoading={item?.title === "Logout" ? isLoading : false}
              key={`header-menu-${index}`}
              {...item}
            />
          );
        })}
      </HStack>
    );
  };
  const mobileElements = () => {
    return (
      <HStack alignItems="center" space={8}>
        {MOBILE_MENU.map((item, index) => {
          //hide the plus btn
          if (!renderPlusBtn && item.name === "plus") {
            return null;
          }

          return (
            <IconMenuBtn
              _onPress={item?.title === "Logout" ? logOutUser : undefined}
              size={6}
              key={`header-menu-${index}`}
              {...item}
            />
          );
        })}
      </HStack>
    );
  };

  const tabletElements = () => {
    return (
      <HStack alignContent="center" alignItems="center" space={6}>
        {TABLET_MENU.map((item, index) => {
          //hide the plus btn
          if (item.path === "Tasks") {
            item.path = "Tasks";
          }

          if (item.path === "Notifications" && !isAbleToSeeNotifications) {
            return null;
          }

          if (item.path === "TasksFlow" && !isAbleToCreateTasks) {
            return null;
          }

          const onPress = () => {
            goTo(item.path);
            dispatch(api.util.invalidateTags(["NotificationsCount"]));
            dispatch(api.util.invalidateTags([{type: "Notifications", page: 1}]));
          };

          if (item.path === "Account") {
            return (
              <Pressable
                key="acccount"
                onPress={onPress}
                overflow="hidden"
                maxW="96"
                p="2"
              >
                <Avatar bg="white" padding={"2px"} size={7}>
                  <Text fontSize="md" color="primary.100">
                    {firstLetter}
                  </Text>
                </Avatar>
              </Pressable>
            );
          }

          if (item.path === "Notifications") {
            if (notificationsCount > 0) {
              return (
                <VStack
                  style={{ height: 80, width: 50 }}
                  alignItems="center"
                  key={`header-menu-${index}`}
                  space={1}
                >
                  <Badge
                    bg="accent.100"
                    rounded="full"
                    mb={-2.5}
                    zIndex={1}
                    variant="solid"
                    alignSelf="flex-end"
                    alignItems="center"
                    alignContent="center"
                    textAlign="center"
                    justifyItems="center"
                    display="flex"
                    w="6"
                    h="6"
                    p={0}
                    justifyContent="center"
                  >
                    <VStack
                      alignContent="center"
                      justifyContent="center"
                      display="flex"
                      justifyItems={"center"}
                      alignItems="center"
                      mb={1}
                    >
                      <Text
                        style={{ fontSize: notificationsCount > 9 ? 12 : 13 }}
                        color="base.0"
                      >
                        {notificationsCount > 9 ? "9+" : notificationsCount}
                      </Text>
                    </VStack>
                  </Badge>

                  <IconMenuBtn
                    size={6}
                    key={`header-menu-${index}`}
                    {...item}
                    _onPress={item.name == "logout" ? logOutUser : undefined}
                  />
                </VStack>
              );
            }
          }

          if (index == TABLET_MENU.length - 2) {
            return (
              <HStack
                alignContent="center"
                alignItems="center"
                key={`header-menu-${index}`}
                space={6}
              >
                <Divider
                  bg="neutral.40"
                  size={0.5}
                  height={5}
                  orientation="vertical"
                />

                <IconMenuBtn h={4} w={4} size={6} {...item} />
              </HStack>
            );
          }

          return (
            <IconMenuBtn
              h={4}
              w={4}
              size={6}
              key={`header-menu-${index}`}
              {...item}
              _onPress={item.name == "logout" ? logOutUser : undefined}
            />
          );
        })}
      </HStack>
    );
  };

  return (
    <Box alignItems="space-around" mr={0}>
      {isDesktop && desktopElements()}
      {isTablet && tabletElements()}
      {isMobile && mobileElements()}
    </Box>
  );
};

// write prop types here
RightSideHeader.propTypes = {
  isDesktop: PropTypes.bool,
  isTablet: PropTypes.bool,
  isMobile: PropTypes.bool,
  renderPlusBtn: PropTypes.bool,
};

export default RightSideHeader;
