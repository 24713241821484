import {
  CELL_CONTENT_TYPES,
  CELL_STYLE_TEMPLATES,
  TASKS_BG_COLORS_BY_STATUS,
} from "../tableRelatedTypes";
import { su, DATE_FORMAT } from "../../../utils/utils";
import _ from "lodash";
import dayjs from "dayjs";
import { NOTIFICATIONS_STATUS_TYPES } from "../../../screens/notifications/notificationsStatusTypes";


const defaultTextStyle = {
  // ...defaultTextPadding,
  letterSpacing: "lg",
  lineHeight: "xs",
  fontFamily: "heading",
  color: "primary.100",
  textAlign: "center",
  fontSize: "lg",
  fontWeight: "400",
};

const defaultBorderBottom = {
  borderBottomWidth: 0,
  borderBottomColor: "base.20",
};
const defaultContainerAlignment = {
  justifyContent: "center",
  alignItems: "center",
};

const defaultContainerStyle = {
  ...defaultBorderBottom,
  ...defaultContainerAlignment,
  // style: defaultCellSizes,
};
const MAX_CHARACTER_LENGTH_COLLAPSED = 120;
       /* eslint-disable no-unused-vars*/
export const LIST_OF_STYLE_TEMPLATES = Object.entries(CELL_STYLE_TEMPLATES).map(
  ([key, value]) => value
);
export const LIST_OF_CONTENT_TYPES = Object.entries(CELL_CONTENT_TYPES).map(
  ([key, value]) => value
);

const defaultTemplateConfig = {
  text: defaultTextStyle,
  container: { ...defaultContainerStyle, bg: "neutral.10" },
};

export const NOTIFICATIONS_BG_BY_STATUS_TYPE = {
  [NOTIFICATIONS_STATUS_TYPES.read]: { bg: "primary.30", accent: "primary.60" },
  [NOTIFICATIONS_STATUS_TYPES.unread]: { bg: "table.40", accent: "table.50" },
  [NOTIFICATIONS_STATUS_TYPES.approved]: { bg: "table.10", accent: "table.20" },
  [NOTIFICATIONS_STATUS_TYPES.rejected]: { bg: "table.70", accent: "table.80" },
};

export const MAP_CELL_COLOR_BY_STATUS = {
  ...TASKS_BG_COLORS_BY_STATUS,
  [CELL_STYLE_TEMPLATES.head]: "neutral.20",
  [CELL_STYLE_TEMPLATES.headQ]: "primary.100",
  [CELL_STYLE_TEMPLATES.default]: "neutral.10",
  [CELL_STYLE_TEMPLATES.unread]: "table.40",
  [CELL_STYLE_TEMPLATES.warning]: "warning.30",
  [CELL_STYLE_TEMPLATES.error]: "error.20",
  [CELL_STYLE_TEMPLATES.basic]: "neutral.20",
  [NOTIFICATIONS_STATUS_TYPES.read]:
    NOTIFICATIONS_BG_BY_STATUS_TYPE[NOTIFICATIONS_STATUS_TYPES.read].bg,
  [NOTIFICATIONS_STATUS_TYPES.unread]:
    NOTIFICATIONS_BG_BY_STATUS_TYPE[NOTIFICATIONS_STATUS_TYPES.unread].bg,
  [NOTIFICATIONS_STATUS_TYPES.rejected]:
    NOTIFICATIONS_BG_BY_STATUS_TYPE[NOTIFICATIONS_STATUS_TYPES.rejected].bg,
  [NOTIFICATIONS_STATUS_TYPES.approved]:
    NOTIFICATIONS_BG_BY_STATUS_TYPE[NOTIFICATIONS_STATUS_TYPES.approved].bg,
};
export const CELL_TEMPLATES_STYLE_MAP_OVERWRITE = {
  [CELL_STYLE_TEMPLATES.head]: {
    text: { ...defaultTextStyle,fontSize: "sm", fontWeight: "500" },
    container: {
      ...defaultBorderBottom,
      ...defaultContainerAlignment,
   
      bg: "neutral.20",
    },
  },
  [CELL_STYLE_TEMPLATES.defaultLong]: {
    text: { ...defaultTextStyle, fontWeight: "500" },
    container: {    style: {
      minHeight: su(69, 69, 56),
      maxHeight: su(172, 172, 209),
      width: 500,
    }, bg: "neutral.30" },
  },
  [CELL_STYLE_TEMPLATES.headQ]: {
    text: { fontWeight: "500", color: "base.0" },
    container: {       ...defaultBorderBottom,
      ...defaultContainerAlignment,
   
      bg: "primary.100",},
  },
  [CELL_STYLE_TEMPLATES.rejected]: {
    text: defaultTextStyle,
    container: {
      ...defaultContainerStyle,
      bg: MAP_CELL_COLOR_BY_STATUS[CELL_STYLE_TEMPLATES.rejected],
    },
  },
  [CELL_STYLE_TEMPLATES.approved]: {
    text: defaultTextStyle,
    container: {
      ...defaultContainerStyle,
      bg: MAP_CELL_COLOR_BY_STATUS[CELL_STYLE_TEMPLATES.approved],
    },
  },
  [CELL_STYLE_TEMPLATES.read]: {
    text: defaultTextStyle,
    container: {
      ...defaultContainerStyle,
      bg: MAP_CELL_COLOR_BY_STATUS[CELL_STYLE_TEMPLATES.read],
    },
  },
  [CELL_STYLE_TEMPLATES.unread]: {
    text: defaultTextStyle,
    container: {
      ...defaultContainerStyle,
      bg: MAP_CELL_COLOR_BY_STATUS[CELL_STYLE_TEMPLATES.unread],
    },
  },
  [CELL_STYLE_TEMPLATES.unread]: {
    text: defaultTextStyle,
    container: {
      ...defaultContainerStyle,
      bg: MAP_CELL_COLOR_BY_STATUS[CELL_STYLE_TEMPLATES.unread],
    },
  },
  [CELL_STYLE_TEMPLATES.questionner]: {
    text: {
      letterSpacing:su("xs","sm","sm"),
      lineHeight: "xs",
      fontFamily: "heading",
      color: "primary.100",
      textAlign: "center",
      // fontSize:12,
      fontWeight: "300",
    },
    container: {
      alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          justifyItems: 'center',
      bg: MAP_CELL_COLOR_BY_STATUS[CELL_STYLE_TEMPLATES.default],
    },
  },
};
const getCellTemplatesMap = () => {
  let TEMPLATES_STYLE_MAP = {};
  LIST_OF_STYLE_TEMPLATES.forEach((item) => {
    TEMPLATES_STYLE_MAP[item] = defaultTemplateConfig;
    if (CELL_TEMPLATES_STYLE_MAP_OVERWRITE?.[item]) {
      TEMPLATES_STYLE_MAP[item] = CELL_TEMPLATES_STYLE_MAP_OVERWRITE?.[item];
    }
  });

  return TEMPLATES_STYLE_MAP;
};
export const CELL_TEMPLATES_STYLE_MAP = getCellTemplatesMap();

export const formatAndTruncateText = (value) => {
  if (!value) {
    return " ";
  }
  if (_.isString(value)) {
    if(value === ""){
      return " ";
    }
    return _.truncate(value, {
      length: MAX_CHARACTER_LENGTH_COLLAPSED,
    });
  }
  if (_.isNumber(value)) {
    return _.toString(value.toFixed(2));
  }
  return " ";
};

export const formatDate = (date) => {
  if (_.isString(date) && date.length > 3) {
    return dayjs(date).format(DATE_FORMAT);
  }

  return "";
};
