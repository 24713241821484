import { Box, Button, Text, HStack, Center } from "native-base";
import React from "react";
import { i18n } from "../../../utils/utils";
import { AntDesign } from "@expo/vector-icons";

const SN = "menus.tasksFlow.";

const FlowNavigationDesktop = ({
  title,
  name,
  onNext,
  onConfirm,
  nextNav,
  acknowledgeMode = false,
  onBack,
  previousNav,
  isLoading = false,
}) => {
  const goTo = () => {
    if (!nextNav) {
      return;
    }
    onNext(nextNav, name);
  };

  const onPressConfirm = () => {
    if (!nextNav) {
      return;
    }
    onConfirm(nextNav, name);
  };

  const onPressBack = () => {
    onBack(previousNav);
  };
  return (
    <Box bg="base.0" w="full" mb={3}>
      <Center>
        <HStack
          justifyContent="space-between"
          alignItems="center"
          // w={["sm", "lg", "xl", "2xl"]}
          w="full"
          px={1}
          style={{ marginTop: 40, maxWidth: 672 }}
        >
          <Text
            fontFamily="body"
            fontWeight="medium"
            fontSize={20}
            // letterSpacing="lg"
            lineHeight="lg"
            color="primary.100"
          >
            {title || "No title"}
          </Text>
          {acknowledgeMode && (
            <Button
              startIcon={<AntDesign name="check" size={24} color="black" />}
              variant="outline"
              isLoading={isLoading}
              onPress={onPressConfirm}
              _hover={{ bg: "primary.30" }}
              _pressed={{ bg: "primary.30", color: "white" }}
              borderColor="green.500"
            >
              {i18n.t(`${SN}confirm`)}
            </Button>
          )}
          <HStack space={3}>
            <Button
              onPress={onPressBack}
              bg="base.0"
              style={{ width: 68 }}
              borderRadius="sm"
              borderColor="neutral.40"
              _hover={{ bg: "primary.30" }}
              _pressed={{ bg: "primary.30", color: "base.0" }}
              borderWidth="1"
            >
              <Text
                // letterSpacing="xl"
                lineHeight="md"
                fontSize={16}
                color="base.90"
              >
                {i18n.t(`${SN}back`)}
              </Text>
            </Button>
            <Button
              bg="accent.40"
              borderRadius="sm"
              borderColor="neutral.40"
              borderWidth="1"
              isLoading={isLoading}
              _hover={{ bg: "primary.100" }}
              _pressed={{ bg: "primary.100" }}
              style={{ width: 66 }}
              onPress={goTo}
            >
              <Text color="base.0" lineHeight="md" fontSize={16}>
                {i18n.t(`${SN}next`)}
              </Text>
            </Button>
          </HStack>
        </HStack>
      </Center>
    </Box>
  );
};

export default FlowNavigationDesktop;
