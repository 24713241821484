import { HStack ,Text,Avatar} from 'native-base'
import React from 'react'
import _ from "lodash";

const CellUserWidget = ({
    value,
    dataObject,
    containerStyle
}) => {

  const formatUserComponent = (user) => {
    const firstLetter = user?.firstName?.[0]
      ? user?.firstName?.[0].toUpperCase()
      : "?";

    return (
      <Avatar
        bg="base.20"
        padding={"2px"}
        style={{ height: 40, width: 40 }}
        source={{ uri: user?.avatarPhoto?.url || null }}
      >
        <Text>{firstLetter}</Text>
      </Avatar>
    );
  };

  return (
    <HStack
    style={containerStyle}xw
    alignItems="center"
    justifyContent="flex-end"
    space={1}
  >
    <Text >
      {_.truncate(value?.fullName,{length: 30})}
    </Text>
    {formatUserComponent(
      dataObject?.assignTo || dataObject?.reportsTo || dataObject?.createdBy
    )}
  </HStack>
  )
}

export default CellUserWidget