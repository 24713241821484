import React from "react";
import { Box, HStack, VStack, useTheme } from "native-base";
import { Platform, SafeAreaView, Text } from "react-native";
import {
  ASSETS_TYPES,
  EMPTY_MODE_TYPES,
  useAcrossDevices,

  i18n,
} from "../../utils/utils";
import SelectDocsListModal from "../../components/SelectDocsListModal";
import { useSelector } from "react-redux";
import DisplayQuestionnaire from "./DisplayQuestionnaire";
import Empty from "../../components/Empty";
import _ from "lodash";

const Questionnaires = () => {
  const { colors } = useTheme();
  const data = useSelector(
    (state) => state.ux.viewFilesPages[ASSETS_TYPES.questionner]
  );
  const { isMobile } = useAcrossDevices();
  const renderChooseFile = () => {
    if (!data) {
      return <SelectDocsListModal fileCategory={ASSETS_TYPES.questionner} />;
    }
    return (
      <Box maxHeight={100} w="full" style={{ height: 75 }} py={3} px={3} m={1}>
        <HStack
          w="full"
          mx={1}
          display="flex"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
          flexDirection="row"
        >
          <Box>
            {data?.name && (
              <Text style={{ color: colors.primary[100] }}>
                {!isMobile && (
                  <Text style={{ fontWeight: "bold" }}>
                    {i18n.t("screens.questionnaires.questionnaireName")}:
                  </Text>
                )}

                <Text> {_.truncate(data?.name, { length: 20 })} </Text>
              </Text>
            )}
          </Box>

          <Box>
            <SelectDocsListModal fileCategory={ASSETS_TYPES.questionner} />
          </Box>
        </HStack>
      </Box>
    );
  };

  if (!data || data.length === 0) {
    return (
      <Empty
        mode={EMPTY_MODE_TYPES.selectFile}
        NewItemComponent={renderChooseFile()}
      />
    );
  }
  return (
    <SafeAreaView>
      <VStack>
        {Platform.OS !== "web" && renderChooseFile()}
        <Box
          style={{
            marginTop: 20,
            height: 650,

          }}
        >
          <DisplayQuestionnaire
            data={data}
          />
        </Box>
      </VStack>
    </SafeAreaView>
  );
};

export default Questionnaires;
