import { Button, HStack, Icon, Text } from "native-base";
import React from "react";
import PropTypes from "prop-types";
import { AntDesign } from "@expo/vector-icons";
import { windowWidth } from "../utils/utils";

const UploadLogoButton = ({
  label = "Label missing",
  onPress = () => {},
  isMobile,
}) => {
  const containerWidth = isMobile ? windowWidth - 80 : 240;
  return (
    <Button  style={{height: 45}} width={containerWidth} onPress={onPress} variant="outline">
      <HStack space={2}>
        <Icon as={AntDesign} name="upload" color="primary.100" />
        <Text lineHeight="lg"> {label}</Text>
      </HStack>
    </Button>
  );
};
UploadLogoButton.propTypes = {
  label: PropTypes.string,
  onPress: PropTypes.func,
};
export default UploadLogoButton;
