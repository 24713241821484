import React from "react";
import { ROW_STYLE_TYPES } from "./rows/rowTypes";
import { CELL_TYPES } from "../../utils/types";
import Sheet from "./Sheet";
import _ from "lodash";
const createTableHeadConfig = (data) => {
  return {
    key: "head",
    type: ROW_STYLE_TYPES.head,
    cells: data.map((item, index) => {
      return {
        variant:
          item.cellType === CELL_TYPES.multipleItems ||
          item.cellType === CELL_TYPES.headLong ||
          item.cellType === CELL_TYPES.longText ||
          item.cellType === CELL_TYPES.customComponent
            ? CELL_TYPES.headLong
            : CELL_TYPES.head,
        value: item.title,
        key: `ht-users-${index}`,
      };
    }),
  };
};

export const createTableBluePrint = (data, headCells, keyAttribute) => {
  const tableHeadBluePrint = createTableHeadConfig(headCells);

  const tableColumnsNameList = headCells.map((item) => item.name);

  const tableDataBluePrint = data.map((item) => {
    const status = item?.status || ROW_STYLE_TYPES.basic;

    const cellData = tableColumnsNameList.map((objAttr) => {
      const cellValue = item[objAttr] !== undefined ? item[objAttr] : null;
      return cellValue;
    });
    return {
      key: item[keyAttribute],
      parentObj: item,
      type: status,
      cells: cellData.map((cellValue, index) => {
        return {
          variant: headCells[index]?.cellType,
          cellColor: headCells[index]?.cellColor || null,
          value: cellValue,
          key: item[keyAttribute] + index,
          customComponent: headCells[index]?.customComponent,
        };
      }),
    };
  });

  return [tableHeadBluePrint, ...tableDataBluePrint];
};
const Table = ({
  data = null,
  onPressRow,
  tableHeadConfig,
  bluePrint,
  freezeFirstColumn = true,
  rowsExpandFeature = true,
  isEditModeActive = false,
  numRowStep,
  componentId = _.uniqueId('table-'),
}) => {

  if (!data) {
    return null;
  }

  const dataHead = bluePrint[0]?.cells;

  return (
    <Sheet
      freezeFirstColumn={freezeFirstColumn}
      dataHead={dataHead}
      numRowStep={numRowStep}
      onPressRow={onPressRow}
      componentId={componentId}
      dataGrid={data}
      tableHeadConfig={tableHeadConfig}
      rowsExpandFeature={rowsExpandFeature}
      isEditModeActive={isEditModeActive}
    />
  );
};


export default React.memo(Table, (prevProps, nextProps) => {
  return !_.isEqual(prevProps.dataGrid, nextProps.dataGrid);
});