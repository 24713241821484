import {
  Image,
  Text,
  Button,
  Box,
  HStack,
  VStack,
  Center,
  Input,
  Toast,
  Pressable,
  Modal,
  ScrollView,
} from "native-base";
import {
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
  SafeAreaView,
} from "react-native";

import React from "react";
import {
  useGenerateOTPQuery,
  useActivateOTPMutation,
} from "../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../features/uxSlice";
import LoadingData from "../../components/LoadingData";
import { logOutUserHook } from "../../features/authSlice";
import * as Clipboard from "expo-clipboard";
import TitleLogin from "./TitleLogin";
import {  su, useAcrossDevices, i18n } from "../../utils/utils";

const SN = `screens.configureTwoFactor.`;

const Configure2FA = ({ navigation, mode = "set" }) => {
  const { isMobile } = useAcrossDevices();
  const loggedUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const isActiveTwoFactor = loggedUser?.twoFactorAuthStatus === "active";
  const [otpCode, setOtpCode] = React.useState("");
  const [logOutUserHandler, logOutRequest] = logOutUserHook();
  const [activateOTP] = useActivateOTPMutation();
  const generateSecretCodeInQrReq = useGenerateOTPQuery(
    {
      userId: loggedUser.id,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );
  const [modalVisible, setModalVisible] = React.useState(false);
  if (
    generateSecretCodeInQrReq.isLoading ||
    generateSecretCodeInQrReq.isFetching ||
    generateSecretCodeInQrReq.isError
  ) {
    return (
      <LoadingData
        isFetching={generateSecretCodeInQrReq.isFetching}
        isLoading={generateSecretCodeInQrReq.isLoading}
        isError={generateSecretCodeInQrReq.isError}
        error={generateSecretCodeInQrReq.error}
        refetch={generateSecretCodeInQrReq.refetch}
      />
    );
  }

  const activate2FA = () => {
    var pattern = /^\d+\.?\d*$/;
    const otpCodeClean = otpCode.trim();
    const isFormedOnlyFromNumbers = pattern.test(otpCodeClean);

    if (
      !otpCodeClean ||
      otpCodeClean.length !== 6 ||
      !isFormedOnlyFromNumbers
    ) {
        Toast.show({closeAll: true, description: "Code is not valid!" });
      return;
    }

    activateOTP({ otp: otpCodeClean })
      .then((response) => {
        if (response?.isOtpValid === true) {
          if (
            response?.wasActivated === true ||
            response?.isAlreadyActive === true
          ) {
            if (mode === "change-device") {
              dispatch(closeModal());
            } else {
              navigation.navigate("Tasks");
            }
          }
        }
        if (
          response?.error?.data.isOtpValid === false &&
          Number(response?.error?.data.tries2FA) <= 3
        ) {
            Toast.show({closeAll: true,
            description: `The code is incorrect. You have tried ${response?.error?.data.tries2FA} times. After 3 incorect attepts,the user is blocked.`,
            duration: 10000,
          });
        }

        if (response?.error?.data?.name === "blocked-2fa") {
            Toast.show({closeAll: true,
            description: response?.error?.data?.message,
            duration: 10000,
          });

          logOutUserHandler();
        }
        if (response?.data?.isOtpValid === true) {
          const message =
            mode === "set"
              ? "You have successfully activated the two factor autentification feature. Use the one-time pass code at the next login."
              : `You have successfully updated the one-time password generator app. You MUST use it at the next logged in.`;
            Toast.show({closeAll: true,
            description: message,
            duration: 10000,
            colorScheme: "success",
          });
          navigation.navigate("Tasks");
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const qrUri = generateSecretCodeInQrReq?.data?.qrUri;
  const secret = generateSecretCodeInQrReq?.data?.secret;

  const copyToClipboard = async () => {
    await Clipboard.setStringAsync(secret);
      Toast.show({closeAll: true,
      duration: 5000,
      description:
        "Secret copied in the clipboard! It is important to paste it ONLY in the Google Autentification app.",
    });
  };
  const renderModal = () => {
    return (
      <Modal isOpen={modalVisible} onClose={setModalVisible} size="lg">
        <Modal.Content size="lg">
          <Modal.CloseButton />
          <Modal.Header>{i18n.t(`${SN}modal.title`)}</Modal.Header>
          <Modal.Body>
            <ScrollView>
              <Box>
                <Text>{i18n.t(`${SN}modal.step1`)}</Text>
                <Text>{i18n.t(`${SN}modal.step2`)}</Text>
                <Text>{i18n.t(`${SN}modal.step3`)}</Text>
                <Text>{i18n.t(`${SN}modal.step4`)}</Text>
                <Text>{i18n.t(`${SN}modal.step5`)}</Text>
                <Text>{i18n.t(`${SN}modal.step6`)}</Text>

                <Text bold>Key: </Text>
                <Pressable
      
                  p={3}
                  pb={3}
                  m={3}
                  borderRadius="lg"
                  borderWidth={1}
                  borderColor="neutral.50"
                  variant="outline"
                  _onPress={{bg:"primary.100"}}
                  onPress={copyToClipboard}
                  _text={{color:"primary.100", overflow:"visible"}}
                >
                  <Text overflow="visible">{secret}</Text>
       
                </Pressable>
                <Text italic>{i18n.t(`${SN}modal.suggestTap`)}</Text>
              </Box>
            </ScrollView>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    );
  };

  const screenTitle = !isActiveTwoFactor
    ? i18n.t(`${SN}title.activate`)
    : i18n.t(`${SN}title.registerDevice`);

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        h={{
          base: "500px;",
          lg: "auto",
          // sm: "auto",
        }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <TouchableWithoutFeedback
          onPress={Platform.OS === "web" ? () => {} : Keyboard.dismiss}
        >
          <ScrollView>
            <HStack
              alignItems="center"
              alignContent="center"
              justifyContent="center"
              justifyItems="center"
              flexWrap="wrap"
              mt={10}
              space={3}
            >
              {!isMobile && (
                <Image
                  alt="QR Code"
                  source={{ uri: qrUri }}
                  style={{
                    width: su(200, 250, 250),
                    height: su(200, 250, 250),
                  }}
                  mb={2}
                />
              )}
              <Center>
                <VStack style={{ maxWidth: su(300, 500, 500) }}>
                  <TitleLogin text={screenTitle} />

                  <Box
                    mt={3}
                    p={2}
                    alignItems={isMobile ? "center" : "flex-start"}
                    textAlign="left"
                  >
                    <Text>{i18n.t(`${SN}step1`)}</Text>
                    <Text>{i18n.t(`${SN}step2`)}</Text>
                    {isMobile && (
                      <Image
                        alt="QR Code"
                        source={{ uri: qrUri }}
                        style={{
                          width: su(200, 250, 250),
                          height: su(200, 250, 250),
                        }}
                        mb={2}
                      />
                    )}
                    <Text>{i18n.t(`${SN}step3`)}</Text>
                  </Box>

                  <Text fontSize="sm" borld>
                    {!isActiveTwoFactor && i18n.t(`${SN}warning`)}
                  </Text>
                  <Input
                    mt={3}
                    mb={5}
                    placeholder={i18n.t(`${SN}placeholder`)}
                    onChangeText={setOtpCode}
                  />
                  <Button.Group
                    mt={3}
                    mb={1}
                    alignItems="center"
                    alignContent="center"
                    space={3}
                    flexWrap={isMobile ? "wrap" : "nowrap"}
                  >
                    <Button
                      mb={isMobile ? 4 : "0"}
                      w={isMobile ? "full" : "1/2"}
                      onPress={activate2FA}
                    >
                      <Text color="base.0">
                      {i18n.t(`${SN}buttons.register`)}
                      </Text>
                    </Button>
                    {!isActiveTwoFactor && (
                      <Button
                        borderColor="primary.60"
                        variant="outline"
                        isLoading={logOutRequest.isLoading}
                        isLoadingText="Logging out..."
                        onPress={logOutUserHandler}
                        w={isMobile ? "full" : "1/2"}
                      >
                        <Text color="primary.60">
                          {i18n.t(`${SN}buttons.cancel`)}
                        </Text>
                      </Button>
                    )}
                  </Button.Group>
                  <Button
                    w="full"
                    colorScheme="secondary"
                    variant="link"
                    alignSelf="flex-end"
                    onPress={() => setModalVisible(true)}
                  >
                    {i18n.t(`${SN}unableToScan`)}
                  </Button>
                </VStack>
              </Center>
            </HStack>
          </ScrollView>
        </TouchableWithoutFeedback>
        {renderModal()}
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default Configure2FA;
