import { createSlice } from "@reduxjs/toolkit";
import { userClasses } from "../../assets/constants/userClasses";

import "react-native-get-random-values";
import { api } from "../services/apiService";
import _ from "lodash";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    value: userClasses.super,
    isLoggedIn: false,
    users: [],
    allUsers: [],
    currentUser: null,
  },
  
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getUsers.matchFulfilled,
      (state, { payload }) => {
        if (payload?.docs && payload.page ) {
          

          state.users[payload.page]= payload?.docs.map((item) => {
            let itemCopy = _.cloneDeep(item);

            itemCopy.fullName = `${item?.firstName} ${item?.lastName}`;

            if (
              !_.isUndefined(itemCopy?.reportsTo) &&
              !_.isString(itemCopy?.reportsTo)
            ) {
              let reportsTo = _.cloneDeep(itemCopy?.reportsTo);
              reportsTo.fullName = `${reportsTo?.firstName} ${reportsTo.lastName}`;
            
              itemCopy.reportsTo = reportsTo;
            }

            return itemCopy;
          });
        }
      }
    );
    builder.addMatcher(
      api.endpoints.getAllUsers.matchFulfilled,
      (state, { payload }) => {
        state.allUsers = payload.docs.map((item) => {
          let itemCopy = _.cloneDeep(item);

          itemCopy.fullName = `${item?.firstName} ${item?.lastName}`;
          if (
            !_.isUndefined(itemCopy?.reportsTo) &&
            !_.isString(itemCopy?.reportsTo)
          ) {
            let reportsTo = _.cloneDeep(itemCopy?.reportsTo);
            reportsTo.fullName = `${reportsTo?.firstName} ${reportsTo.lastName}`;
            itemCopy.reportsTo = reportsTo;
          }

          return itemCopy;
        });
      }
    );
  },
});

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount))
//   }, 1000)
// }

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCurUserClass = (state) => state.users.value;

export default usersSlice.reducer;
