import {
  Box,
  Text,
  HStack,
  Heading,
  Center,
  VStack,
  Badge,
  ScrollView,
  Button,
  Divider,
  Toast,
  TextArea,
  Spacer,
  FormControl,
  View,
  Spinner,
} from "native-base";
import React from "react";

import { useRoute } from "@react-navigation/native";
import UserAvatar from "../../../components/UserAvatar";
import * as Crypto from "expo-crypto";
import { createTableBluePrint } from "../../../components/table";
import { ACTIVITIES_TABLE_COLUMNS } from "./tableColumn";
import {
  useAddFinalReportMutation,
  useAddAssetMutation,
  useDeleteAssetMutation,
  useLazyGetFinalReportQuery,
  useLazyGetTaskQuery,
  useLazyGetQuestionsQuery,
} from "../../../services/apiService";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { AntDesign, FontAwesome } from "@expo/vector-icons";
import {
  DATE_FORMAT,
  su,
  useAcrossDevices,
  windowWidth,
  getUserFullName,
  i18n,
  displayServerErrors,
  prepareImagesForUpload,
  fetchImage,
  DATE_FORMAT_WITH_TIME,
  openCamera,
  formatSelectForUploadImagesListForDisplay,
  getImagesOfAnswers,
} from "../../../utils/utils";
import { pickImagesHook } from "../../../utils/utils-files";
import LoadingData from "../../../components/LoadingData";
import ViewMediaCollection from "../../../components/ViewMediaCollection";
import ResolveFlowNavigationDesktop from "../resolve-task/ResolveFlowNavigationDesktop";
import { Platform } from "react-native";
import { saveFile } from "./helpers";
import ReportsLogoRow from "../../../components/reports/ReportsLogoRow";
import ReportRow from "../../../components/reports/ReportRow";
import _ from "lodash";
import InputReport from "../../../components/reports/InputReport";
import Sheet from "../../../components/table/SheetV2";
import { REPORT_FIELDS } from "./fieldTypes";
import ReportRowBasic from "../../../components/reports/ReportRowBasic";

const SN = "screens.finalReport.";

const initialValues = {
  [REPORT_FIELDS.subcontractor]: "",
  [REPORT_FIELDS.mov]: "",
  [REPORT_FIELDS.projectName]: "",
  [REPORT_FIELDS.logo1]: "",
  [REPORT_FIELDS.logo2]: "",
  [REPORT_FIELDS.review]: "",
  [REPORT_FIELDS.images]: [],
};

const Report = ({ navigation }) => {
  const { isMobile, isDesktop, isTablet } = useAcrossDevices();
  const [addFinalReport, addFinalReportRequest] = useAddFinalReportMutation();
  const [addAsset] = useAddAssetMutation();
  const [deleteAsset] = useDeleteAssetMutation();
  const [getFinalReport, getFinalReportRequest] = useLazyGetFinalReportQuery();
  const [getTask, getTaskRequest] = useLazyGetTaskQuery();
  const [getQuestions, getQuestionRequest] = useLazyGetQuestionsQuery();
  const [pickImages] = pickImagesHook();
  const [data, setData] = React.useState(null);
  const [formData, setFormData] = React.useState(initialValues);
  const [formErrors, setFormErrors] = React.useState({});

  const [uploadedLogos, setUploadedLogos] = React.useState({
    [REPORT_FIELDS.logo1]: null,
    [REPORT_FIELDS.logo2]: null,
  });

  const route = useRoute();
  const fromNotifications = route.params?.fromNotifications;
  const setUploadedLogo = (field, value = null) => {
    if (value === null) {
      Toast.show({
        closeAll: true,
        description: "The " + field + " cannot be displayed at this moment.",
      });
      return;
    }
    setUploadedLogos((prev) => ({
      ...prev,
      [field]: { uri: value },
    }));
  };
  const downloadAndSetLogos = async (report) => {
    const logo1 = report?.logo1?.url;
    const logo2 = report?.logo2?.url;

    if (logo1) {
      await fetchImage(logo1, REPORT_FIELDS.logo1, token, setUploadedLogo);
    }
    if (logo2) {
      await fetchImage(logo2, REPORT_FIELDS.logo2, token, setUploadedLogo);
    }
  };

  const showGeneralErrorAndGoToTasks = () => {
    Toast.show({
      closeAll: true,
      description: i18n.t("screens.finalReport.messages.reportFetchError"),
    });
    navigation.navigate("Tasks");
  };

  React.useEffect(() => {
    async function getData() {
      let taskId = route.params?.taskId;
      let reportId = route.params?.reportId;
      if (!taskId && !reportId) {
        showGeneralErrorAndGoToTasks();
        return;
      }

      if (_.isString(taskId) && taskId.length > 0) {
        getTask({ taskId })
          .unwrap()
          .then((response) => {
            if (response) {
              setData({ task: response, onlyTask: true });
            }
            // in case user endup on page by mistake but the final report cannot be submitted
            if (!response?.userCanSubmitFinalReport) {
              console.warn(
                "User cannot submit final report, but somehow endup on ths page."
              );
              navigation.navigate("Tasks");
              return;
            }

            getQuestions(response.activity.id)
              .unwrap()
              .then((responseQuestions) => {
                setData({
                  task: response,
                  onlyTask: true,
                  questions: responseQuestions?.docs,
                });
              })
              .catch(() => {
                showGeneralErrorAndGoToTasks();
              });
          })
          .catch(() => {
            showGeneralErrorAndGoToTasks();
          });
      } else if (_.isString(reportId)) {
        getFinalReport(reportId)
          .unwrap()
          .then(async (response) => {
            if (response) {
              // setData({ ...response, onlyTask: false });
              await downloadAndSetLogos(response);
              getQuestions(response.task.activity.id)
                .unwrap()
                .then((responseQuestions) => {
                  setData({
                    ...response,
                    questions: responseQuestions?.docs,
                    onlyTask: false,
                  });
                })
                .catch(() => {
                  showGeneralErrorAndGoToTasks();
                });
            }
          })
          .catch(() => {
            showGeneralErrorAndGoToTasks();
          });
      }
    }

    getData();
  }, []);

  const isViewMode =
    _.isString(route.params?.reportId) && route.params?.reportId.length > 2;
  const authSlice = useSelector((state) => state.auth);
  const token = authSlice.token;
  const loggedUser = authSlice.user;
  const task = data?.task;

  const handleChange = (value, fieldName) => {
    if (!value) {
      return;
    }
    setFormData((prev) => ({ ...prev, [fieldName]: value }));
  };
  const errors = formErrors;

  const validateForm = () => {
    let errors = {};
    let found = false;

    for (const [key, value] of Object.entries(formData)) {
      if (
        key === REPORT_FIELDS.logo1 ||
        key === REPORT_FIELDS.logo2 ||
        key === REPORT_FIELDS.images
      ) {
        continue;
      }
      if (!value || !_.isString(value) || value === "" || value.length < 2) {
        found = true;
        errors[key] = i18n.t("screens.finalReport.messages.missingFields");
      }
    }
    if (found) {
      setFormErrors(errors);
      return false;
    }
    setFormErrors({});
    return true;
  };

  const getQuestionnaireId = () => {
    const questionnaireId = task?.questionner?.name;
    if (_.isString(questionnaireId)) {
      return questionnaireId;
    } else if (_.isString(task?.questionner)) {
      return task?.questionner;
    }
    return "";
  };

  const fullName = getUserFullName(task?.assignTo);
  const PN = task?.pn || [];
  const questions = data?.questions;
  const project = task?.project;
  const projectNumber = project?.name;
  const areaNumber = task?.area?.name;
  const disName = task?.dis?.name;
  const questionnaireNumber = getQuestionnaireId();
  const createdBy = task?.assignTo;

  const setFromAndUploadLogo = async (fieldName) => {
    const logo = await pickImages();
    if (!_.isArray(logo) || logo.length === 0) return;

    setUploadedLogos((prev) => ({ ...prev, [fieldName]: logo[0] }));
    await uploadLogo(fieldName, logo[0]);
  };

  const uploadLogo = async (fieldName, file) => {
    const preparedFile = prepareImagesForUpload([file]);
    const formData = new FormData();
    const assetName = preparedFile[0][1]
      ? preparedFile[0]?.[1]
      : preparedFile[0]?.[0]?.name;
    formData.append("file", ...preparedFile[0]);
    formData.append("createdBy", loggedUser?.id);
    formData.append("assetFileType", "image");
    formData.append("assetName", assetName);
    addAsset({ form: formData })
      .then((res) => {
        if (displayServerErrors(res)) return;
        handleChange(res.data.doc.id, fieldName);
      })
      .catch(() => {
        Toast.show({
          closeAll: true,
          description: i18n.t("errorsMsg.generalError"),
        });
      });
  };

  const uploadLogo1 = async () => {
    setFromAndUploadLogo(REPORT_FIELDS.logo1);
  };
  const uploadLogo2 = async () => {
    setFromAndUploadLogo(REPORT_FIELDS.logo2);
  };

  const removeLogoFromState = (fieldName) => {
    setUploadedLogos((prev) => ({ ...prev, [fieldName]: null }));
    handleChange("", fieldName);
  };

  const removeLogo = async (fieldName) => {
    removeLogoFromState(fieldName);
    const assetId = formData[fieldName];
    if (!assetId) {
      return;
    }

    deleteAsset({ id: assetId })
      .then((res) => {
        if (displayServerErrors(res)) return;
        Toast.show({
          closeAll: true,
          description: i18n.t("screens.finalReport.messages.logoRemoved"),
        });
        removeLogoFromState(fieldName);
      })
      .catch(() => {
        Toast.show({
          closeAll: true,
          description: i18n.t("errorsMsg.generalError"),
        });
        removeLogoFromState(fieldName);
      });
  };
  const removeLogo1 = async () => {
    removeLogo(REPORT_FIELDS.logo1);
  };
  const removeLogo2 = async () => {
    removeLogo(REPORT_FIELDS.logo2);
  };

  // const generateTableData = (questions) => {
  //   if (!questions || !_.isArray(questions)) {
  //     return [];
  //   }
  //   const data = questions.map((question) => {
  //     return {
  //       activity: question.title,
  //       startDate: dayjs().format(DATE_FORMAT),
  //       endDate: dayjs().format(DATE_FORMAT),
  //       activityStatus: "complete",
  //       error: "complete",
  //       errorStatus: "solved",
  //       comments: question?.comment,
  //     };
  //   });
  //   return data;
  // };
  // const resultsTableData = generateTableData(questions);

  const goToResolveTask = () => {
    if (fromNotifications) {
      if (isMobile) {
        navigation.replace("Tasks", { screen: "NotificationsMobile" });
      } else {
        navigation.replace("Notifications");
      }
    } else {
      navigation.replace("ResolveFlow", {
        screen: "ResolveTask",
        params: { taskId: task.id },
      });
    }
  };

  const downloadPdfReport = () => {
    if (!data?.autogeneratedFile?.url || !data?.id) {
      Toast.show({
        closeAll: true,
        description: i18n.t("errorsMsg.unexpected "),
      });
      return;
    }
    const uri = data?.autogeneratedFile?.url;
    const fileName = data.id + "final-report.pdf";
    saveFile(uri, token, fileName);
  };

  const submitReport = async () => {
    const isValid = validateForm();
    if (!isValid) {
      Toast.show({
        closeAll: true,
        description: i18n.t("screens.finalReport.messages.missingFields"),
      });
      return;
    }

    const form = new FormData();
    form.append("task", task.id);
    form.append("createdBy", loggedUser.id);
    form.append(
      REPORT_FIELDS.projectName,
      formData?.[REPORT_FIELDS.projectName] || ""
    );
    form.append(REPORT_FIELDS.mov, formData?.[REPORT_FIELDS.mov] || "");

    form.append(REPORT_FIELDS.review, formData?.[REPORT_FIELDS.review] || "");

    form.append(
      REPORT_FIELDS.subcontractor,
      formData?.[REPORT_FIELDS.subcontractor] || ""
    );
    form.append("status", "pending");

    form.append(REPORT_FIELDS.logo1, formData?.[REPORT_FIELDS.logo1] || "");
    form.append(REPORT_FIELDS.logo2, formData?.[REPORT_FIELDS.logo2] || "");

    const reportImagesList = formData?.images;
    if (reportImagesList?.length > 0) {
      try {
        const preparedFiles = prepareImagesForUpload(reportImagesList);
        preparedFiles.forEach((file) => {
          form.append("file", ...file);
        });
      } catch (err) {
        console.warn("🚀 ~  err:", err);
      }
    }

    addFinalReport(form)
      .then((response) => {
        const invalidInputResponse = displayServerErrors(response);
        if (invalidInputResponse) {
          return;
        }
        Toast.show({
          closeAll: true,
          description: i18n.t("screens.finalReport.messages.reportSubmitted"),
        });

        navigation.replace("Tasks");
      })
      .catch(() => {
        Toast.show({
          closeAll: true,
          description: i18n.t("errorsMsg.generalError"),
        });
      });
  };

  const distributionList = [
    getUserFullName(task?.project?.manager),
    getUserFullName(task?.project?.teamLeader),
    getUserFullName(task?.assignTo),
  ];

  const renderDownloadButton = () => {
    if (isViewMode && Platform.OS === "web") {
      return (
        <Button
          mr={4}
          px={3}
          onPress={downloadPdfReport}
          py={3}
          variant="outline"
          _pressed={{ bg: "neutral.40" }}
          _android={{ marginRight: 20 }}
          style={{ height: su(60, 60, 60), width: 210 }}
          rightIcon={<AntDesign name="pdffile1" size={24} color="base.0" />}
        >
          <Text color="accent.50">{i18n.t(`${SN}buttons.download`)}</Text>
        </Button>
      );
    }
    return null;
  };

  const renderDesktopNavigation = () => {
    if (isDesktop) {
      return (
        <ResolveFlowNavigationDesktop
          hideNext={true}
          onBack={goToResolveTask}
        />
      );
    }
    return null;
  };

  const renderSubmitButton = () => {
    if (isViewMode) {
      return null;
    }
    return (
      <Button
        mr={4}
        px={3}
        onPress={submitReport}
        py={3}
        isLoading={addFinalReportRequest.isLoading}
        isLoadingText="Submitting..."
        style={{ height: su(50, 60), width: 210 }}
        bg="accent.40"
        _hover={{ bg: "accent.50" }}
        alignSelf={isDesktop ? "flex-end" : "center"}
      >
        <Text color="base.0">{i18n.t(`${SN}buttons.submit`)}</Text>
      </Button>
    );
  };

  const renderHeadline = () => {
    return (
      <Center py={3} px={3}>
        <Heading lineHeight="lg" color="primary.100" fontSize={24}>
          {i18n.t(`${SN}title`)}
        </Heading>
      </Center>
    );
  };

  const isLoadingData =
    getFinalReportRequest.isLoading || getTaskRequest.isLoading;
  getQuestionRequest?.isLoading || !getQuestionRequest?.isSuccess;

  const isFetchingData =
    getFinalReportRequest.isFetching ||
    getTaskRequest.isFetching ||
    getQuestionRequest.isFetching;
  const isUninitialized = isViewMode
    ? getFinalReportRequest.isUninitialized
    : getTaskRequest.isUninitialized;
  if (isLoadingData || isFetchingData) {
    return (
      <LoadingData
        isUninitialized={isUninitialized}
        isFetching={isFetchingData}
        isLoading={isLoadingData}
      />
    );
  }

  const generateResultsData = (questions, task) => {
    if (!questions || !_.isArray(questions)) {
      return [];
    }
    let data = [];

    const subactivityGroups = _.groupBy(questions, "activityName");

    for (const property in subactivityGroups) {
      let ncrStatus = "N/A";
      let ncr = "No";
      let lastStartDate = "";
      let lastEndDate = "";
      const subactivityGroup = subactivityGroups[property];
      subactivityGroup.forEach((question) => {
        if (lastStartDate === "") {
          lastStartDate = question.updatedAt;
        }
        if (lastEndDate === "") {
          lastEndDate = question.updatedAt;
        }

        if (
          new Date(lastStartDate).getTime() >
          new Date(question.updatedAt).getTime()
        ) {
          lastStartDate = question.updatedAt;
        } else if (
          new Date(lastEndDate).getTime() <
          new Date(question.updatedAt).getTime()
        ) {
          lastEndDate = question.updatedAt;
        }

        if (task?.errorReports && task?.errorReports.length > 0) {
          const questionsErrorReports = task?.errorReports.filter((report) => {
            
            return report.question.id === question.id;
          });

          if (questionsErrorReports.length > 0) {
            ncrStatus =
              questionsErrorReports[questionsErrorReports.length - 1]?.status;
            ncr = "Yes";
          }
        }
      });
      const row = {
        activityIndicator: subactivityGroup[0].activityIndicator,
        subactivityName: property,
        ncr,
        ncrStatus,
        startDate: dayjs(lastStartDate).format(DATE_FORMAT_WITH_TIME),
        endDate: dayjs(lastEndDate).format(DATE_FORMAT_WITH_TIME),
        id: _.uniqueId("report_"),
      };
      data.push(row);
    }

    return data.sort(
      (a, b) => Number(a.activityIndicator) - Number(b.activityIndicator)
    );
  };

  const reportTableData = generateResultsData(questions, task);

  const bluePrintFinalResults = createTableBluePrint(
    reportTableData,
    ACTIVITIES_TABLE_COLUMNS,
    "id"
  );
  const buttonsWidth = {
    width: su(inputWidth, 200, 190),
    minWidth: su(300, 200, 190),
  };
  const addImages = async () => {
    let assets = await pickImages();
    if (!_.isArray(assets)) {
      return;
    }
    assets = assets.map((item) => {
      return { ...item, id: Crypto.randomUUID() };
    });
    handleChange(
      [...assets, ...formData[REPORT_FIELDS.images]],
      REPORT_FIELDS.images
    );
  };
  const setSelectedImages = (images) => {
    setData((prev) => ({ ...prev, [REPORT_FIELDS.images]: images }));
  };
  const removeImage = (id) => {
    if (!id) {
      return;
    }
    const images = formData[REPORT_FIELDS.images];

    const updatedImagesList = images.filter((image) => image.id !== id);

    handleChange(updatedImagesList, REPORT_FIELDS.images);
  };
  const takePhoto = async () => {
    const actionAfterPhotoIsTaken = (photo) => {
      let imagesUpdated = prepareImagesForUpload(photo)[0].map((item) => {
        if (item?.uri?.split(",")?.[1]) {
          const key = Crypto.randomUUID();
          return {
            ...item,
            base64: item.uri.split(",")[1],
            key: key,
            id: key,
          };
        }
      });
      imagesUpdated.push(...formData[REPORT_FIELDS.images]);
      handleChange(imagesUpdated, REPORT_FIELDS.images);
    };
    openCamera(actionAfterPhotoIsTaken);
  };

  const renderFooterButtons = () => (
    <Button.Group
      // w="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      flexDirection="row"
    >
      <Button
        _hover={{
          bg: "accent.40",
          color: "base.0",
        }}
        _pressed={{
          bg: "accent.50",
          color: "base.0",
        }}
        style={buttonsWidth}
        _text={{ color: "primary.90" }}
        variant="outline"
        m={2}
        onPress={addImages}
        startIcon={<FontAwesome name="picture-o" size={24} color="black" />}
      >
        {i18n.t(`${SN}buttons.uploadImages`)}
      </Button>
      {(isTablet || isMobile) && (
        <Box>
          {isMobile && <Divider bg="base.0" height={3} />}
          <Button
            _hover={{
              bg: "accent.40",
              color: "base.0",
            }}
            _pressed={{
              bg: "accent.50",
              color: "base.0",
            }}
            style={buttonsWidth}
            _text={{ color: "primary.90" }}
            variant="outline"
            m={2}
            onPress={takePhoto}
            startIcon={<FontAwesome name="camera" size={24} color="black" />}
          >
            {i18n.t(`${SN}buttons.takePhoto`)}
          </Button>
        </Box>
      )}
    </Button.Group>
  );

  const renderDistributionList = () => {
    return (
      <HStack flexWrap="wrap" maxWidth="full" bg="neutral.10" p={2} ml={2}>
        <Text color="primary.90" bold>
          {i18n.t(`${SN}distribution`)}
        </Text>
        {distributionList.map((name, index) => {
          const separator = index === distributionList.length - 1 ? "" : ",";
          const text = `${name}${separator} `;
          const key = `${name}${index}`;
          return (
            <View key={`dl-${index}`}>
              <Text
                ellipsizeMode="tail"
                numberOfLines={3}
                color="primary.100"
                key={key}
              >
                {text}
              </Text>
              {isMobile && <Spacer />}
            </View>
          );
        })}
      </HStack>
    );
  };

  const handleChangeReview = (value) =>
    handleChange(value, REPORT_FIELDS.review);
  const inputWidth = isMobile ? windowWidth - 80 : 250;

  const maxWidthTextArea = 1000;
  const attachedImages = isViewMode
    ? data?.[REPORT_FIELDS.images]
    : formatSelectForUploadImagesListForDisplay(
        formData?.[REPORT_FIELDS.images]
      );

  const getAnswers = (questions) => {
    if (!questions || !_.isArray(questions)) {
      return [];
    }
    const answers = [];
    questions.forEach((question) => {
      if (
        question?.answers &&
        _.isArray(question?.answers) &&
        question?.answers.length > 0
      ) {
        answers.push(...question?.answers);
      }
    });
    return answers;
  };

  const questionsResponses = getAnswers(questions);
  const answerImages = _.uniqBy(getImagesOfAnswers(questionsResponses), "id");
  return (
    <ScrollView
      extraScrollHeight={50}
      extraHeight={50}
      enableResetScrollToCoords={true}
      nestedScrollEnabled={true}
      _contentContainerStyle={{
        backgroundColor: "base.0",
        width: "100%",
      }}
    >
      <VStack
        w="full"
        style={{
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
        space={3}
        pb={3}
      >
        <ReportRow borderLess={isViewMode} isMobile={isMobile}>
          <VStack w={"full"}>
            {renderDesktopNavigation()}
            <Box alignItems="flex-end" w="full">
              {isViewMode ? renderDownloadButton() : renderSubmitButton()}
            </Box>
          </VStack>
          <Box></Box>
        </ReportRow>

        {isViewMode && Platform.OS === "web" && <Divider />}
        <ReportsLogoRow
          isMobile={isMobile}
          isDesktop={isDesktop}
          onPressUpload1={uploadLogo1}
          onPressUpload2={uploadLogo2}
          removeLogo1={removeLogo1}
          removeLogo2={removeLogo2}
          onChangeProjectName={handleChange}
          uploadLabel1={i18n.t(`${SN}labels.uploadLogo1`)}
          uploadLabel2={i18n.t(`${SN}labels.uploadLogo2`)}
          token={token}
          projectNameLabel={i18n.t(`${SN}labels.projectName`)}
          projectNameValue={data?.[REPORT_FIELDS.projectName]}
          isDisabled={isViewMode}
          errorMessageProjectName={errors[REPORT_FIELDS.projectName]}
          data={[
            uploadedLogos[REPORT_FIELDS.logo1],
            uploadedLogos[REPORT_FIELDS.logo2],
          ]}
        />

        {/* <Divider /> */}
        {renderHeadline()}
        <ReportRow isMobile={isMobile}>
          <Box mb={1}>
            <Text
              lineHeight="lg"
              color="primary.100"
              fontWeight="normal"
              fontSize={su(18, 20, 24)}
            >
              {i18n.t(`${SN}labels.projectNumber`)}
            </Text>
            <Text
              lineHeight="lg"
              fontWeight="bold"
              color="primary.100"
              fontSize={su(18, 20, 24)}
            >
              {projectNumber}
            </Text>
          </Box>
          <Box alignItems={isMobile ? "flex-start" : "flex-end"}>
            <VStack>
              <Text
                lineHeight="lg"
                textAlign={isMobile ? "left" : "right"}
                color="primary.100"
                fontSize={20}
              >
                {i18n.t(`${SN}labels.areaNumber`)}
                {}
              </Text>
              <Text fontSize={14} fontWeight="bold" color="primary.100">
                {areaNumber}
              </Text>
            </VStack>

            <Text color="primary.100" bold>
              {dayjs().format(DATE_FORMAT)} {dayjs().format("HH:MM")}
            </Text>
          </Box>
        </ReportRow>

        <ReportRow isMobile={isMobile}>
          <HStack
            mt={1}
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Text color="primary.100">{i18n.t(`${SN}labels.pn`)}</Text>
            {PN.map((item, index) => (
              <Badge m={1} key={`${index}-pn`} px={1}>
                <Text color="primary.100">{item.name}</Text>
              </Badge>
            ))}
          </HStack>

          {isMobile && <Divider my={3} />}
          {createdBy && (
            <HStack space={2} alignItems="center">
              <UserAvatar user={createdBy} />
              <Text color="primary.100" mr={2}>
                {fullName}
              </Text>
            </HStack>
          )}
        </ReportRow>

        <ReportRow isMobile={isMobile}>
          <Box mb={2}>
            <Text fontSize={16} fontWeight="bold" color="primary.100">
              {disName}
            </Text>
            <InputReport
              value={data?.[REPORT_FIELDS.mov]}
              inputName={REPORT_FIELDS.mov}
              isDisabled={isViewMode}
              errorMessage={errors[REPORT_FIELDS.mov]}
              width={inputWidth}
              placeholder={i18n.t(`${SN}labels.mov`)}
              onChangeText={handleChange}
              onBlur={validateForm}
            />
          </Box>
          {isMobile && <Divider my={3} />}
          <Box alignItems={isMobile ? "flex-start" : "flex-end"}>
            <Text lineHeight="lg" textBreakStrategy="balanced">
              <Text
                fontSize={14}
                textAlign={isMobile ? "left" : "right"}
                color="primary.100"
              >
                {i18n.t(`${SN}labels.questionnaireNo`)}
              </Text>

              <Text
                fontWeight="black"
                fontSize={14}
                style={{ fontWeight: "bold" }}
                color="primary.100"
              >
                {questionnaireNumber}
              </Text>
            </Text>
            <InputReport
              errorMessage={errors[REPORT_FIELDS.subcontractor]}
              width={inputWidth}
              alignItems={isMobile ? "flex-start" : "flex-end"}
              placeholder={i18n.t(`${SN}labels.subcontractor`)}
              onChangeText={handleChange}
              isDisabled={isViewMode}
              onBlur={validateForm}
              inputName={REPORT_FIELDS.subcontractor}
              value={
                isViewMode
                  ? data?.[REPORT_FIELDS.subcontractor]
                  : formData?.[REPORT_FIELDS.subcontractor]
              }
            />
          </Box>
        </ReportRow>
        <ReportRow isMobile={isMobile} borderLess={true}>
          <VStack
            p={2}
            style={{
              width:
                windowWidth - 70 > maxWidthTextArea
                  ? maxWidthTextArea - 20
                  : windowWidth - 70,
              maxWidth: maxWidthTextArea,
            }}
            space={3}
          >
            <Center>
              <Heading lineHeight="lg" color="primary.100" fontSize={24}>
                {i18n.t(`${SN}section1Title`)}
              </Heading>
            </Center>
            <FormControl
              isDisabled={isViewMode}
              isInvalid={!!errors?.[REPORT_FIELDS.review]}
            >
              <TextArea
                h="32"
                onBlur={validateForm}
                onChangeText={handleChangeReview}
                isDisabled={isViewMode}
                defaultValue={data?.[REPORT_FIELDS.review]}
              />
              <FormControl.ErrorMessage>
                {errors?.[[REPORT_FIELDS.review]]}
              </FormControl.ErrorMessage>
            </FormControl>
          </VStack>
        </ReportRow>
        {attachedImages &&
          _.isArray(attachedImages) &&
          attachedImages.length >= 1 && (
            <ReportRow
              key="attachedImages"
              isMobile={isMobile}
              borderLess={true}
            >
              <Box bg="neutral.10" w="full">
                <Text fontSize={14} color="base.90" mt={2} ml={3}>
                  {i18n.t("screens.errorReport.labels.attachedImages")}
                </Text>
                <ViewMediaCollection
                  renderAddMoreBtn={false}
                  token={token}
                  items={attachedImages}
                  hasDeleteIcon={!isViewMode}
                  setSelectedItems={setSelectedImages}
                  handleRemove={removeImage}
                />
              </Box>
            </ReportRow>
          )}
        {!isViewMode && (
          <ReportRowBasic>{renderFooterButtons()}</ReportRowBasic>
        )}

        {getQuestionRequest?.isLoading || !getQuestionRequest?.isSuccess ? (
          <Spinner />
        ) : (
          <ReportRow isMobile={isMobile} borderLess={true}>
            <Box>
              <Heading
                px={su(1, 2, 3)}
                mx={su(1, 2, 3)}
                my={su(1, 2, 3)}
                textAlign="left"
                lineHeight="lg"
                color="primary.100"
                fontSize={su(20, 24, 24)}
              >
                {i18n.t(`${SN}section2Title`)}
              </Heading>
              <Box
                display="flex"
                bg="base.0"
                width={windowWidth - 80 > 1000 ? 1000 : windowWidth - 80}
                overflowX="scroll"
              >
                {reportTableData.length > 0 && (
                  <Sheet
                    list={reportTableData}
                    width={Platform.OS === "web" ? 1020 : undefined}
                    height={
                      Platform.OS === "web"
                        ? bluePrintFinalResults.length * 70
                        : bluePrintFinalResults.length * 70
                    }
                    tableColumnsConfig={ACTIVITIES_TABLE_COLUMNS}
                    rowsExpandFeature={false}
                    freezeFirstColumn={false}
                    showCustomHorizontalScroll={false}
                    numRowStep={reportTableData.length}
                  />
                )}
              </Box>
            </Box>
          </ReportRow>
        )}
        {answerImages &&
          _.isArray(answerImages) &&
          answerImages.length >= 1 && (
            <ReportRow key="answerImages" isMobile={isMobile} borderLess={true}>
              <Box bg="neutral.10" w="full">
                <Text fontSize={14} color="base.90" mt={2} ml={3}>
                  {i18n.t("screens.finalReport.labels.answerImages")}
                </Text>
                <ViewMediaCollection
                  renderAddMoreBtn={false}
                  token={token}
                  items={answerImages}
                  hasDeleteIcon={false}
                />
              </Box>
            </ReportRow>
          )}

        <ReportRow isMobile={isMobile} borderLess={true}>
          {renderDistributionList()}
        </ReportRow>

        <Spacer h={12} />
      </VStack>
    </ScrollView>
  );
};

export default Report;
