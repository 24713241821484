import { createSlice, current } from "@reduxjs/toolkit";
import { api } from "../services/apiService";
import {
  getUserFullName,
  concatArraysByIdAndMergeMostRecent,
} from "../utils/utils";
import { revertAll } from "../utils/utils";
const initialState = {
  notifications: [[]],
  notificationsMobileList: [],
  page: 1,
};
export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    updateNotificationStatus: (state, action) => {
      if (action.payload.isMobile !== true) {
        const notificationIndex = state.notifications[state.page].findIndex(
          (item) => item.id === action.payload.notification.id
        );

        if (notificationIndex !== -1) {
          state.notifications[state.page][notificationIndex].status =
            action.payload.notification.status;
        }
      } else {
        const notificationIndex = state.notificationsMobileList.findIndex(
          (item) => item.id === action.payload.notification.id
        );

        if (notificationIndex !== -1) {
          state.notificationsMobileList[notificationIndex].status =
            action.payload.notification.status;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, () => initialState);
    builder.addMatcher(
      api.endpoints.getNotifications.matchFulfilled,
      (state, { payload }) => {
        const { page, docs } = payload;
        if (docs) {
          state.notificationsMobileList = concatArraysByIdAndMergeMostRecent(
            current(state.notificationsMobileList),
            docs
          );
          state.notifications[page] = docs.map((item) => {
            item.createdBy.fullName = getUserFullName(item.createdBy);
            return item;
          });
        }
      }
    );
  },
});

export const { updateNotificationStatus } = notificationsSlice.actions;
export default notificationsSlice.reducer;
