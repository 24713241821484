import { Ionicons, FontAwesome } from "@expo/vector-icons";
import { RESULTS_STATUS_TYPES } from '../../utils/types';
import { NOTIFICATIONS_STATUS_TYPES} from '../../screens/notifications/notificationsStatusTypes'
import { TASKS_STATUS_TYPES } from '../../screens/tasks/tasksStatusTypes'


export const CELL_STYLE_TEMPLATES = {
  default: "default",
  defaultLong: "defaultLong",
  head: "head",
  headQ: "headQ",
  questionner: "questionner",
  horizontalList: "horizontalList",
  ...TASKS_STATUS_TYPES,
  ...RESULTS_STATUS_TYPES,
  ...NOTIFICATIONS_STATUS_TYPES,
};

export const CELL_CONTENT_TYPES = {
  customComponent: "customComponent",
  expand: "expand",
  user: "user",
  text: "text",
  horizontalList: "horizontalList",
};

export const ROW_STYLE_TYPES = {
  head: "head",
  basic: "basic",
  warning: "warning",
  valid: "valid",
  error: "error",
  unread: "unread",
  headQ: "headQ",
  ...TASKS_STATUS_TYPES,
};

export const TASKS_ACCENT_COLORS_BY_STATUS = {
  [TASKS_STATUS_TYPES.complete]: "table.20",
  [TASKS_STATUS_TYPES.incomplete]: "table.80",
  [TASKS_STATUS_TYPES.confirmationRequired]: "table.50",
  [TASKS_STATUS_TYPES.unacknowledged]: "table.110",
};
export const TASKS_BG_COLORS_BY_STATUS = {
  [TASKS_STATUS_TYPES.complete]: "table.10",
  [TASKS_STATUS_TYPES.incomplete]: "table.70",
  [TASKS_STATUS_TYPES.unacknowledged]: "table.100",
  [TASKS_STATUS_TYPES.confirmationRequired]: "table.40",
};

export const TASKS_STATUS_IN_PERCETANGE = {
  [TASKS_STATUS_TYPES.complete]: "100",
  [TASKS_STATUS_TYPES.incomplete]: "50",
  [TASKS_STATUS_TYPES.unacknowledged]: "10",
  [TASKS_STATUS_TYPES.confirmationRequired]: "75",
};

export const MAP_CELL_COLOR_BY_STATUS = {
  ...TASKS_BG_COLORS_BY_STATUS,
  [ROW_STYLE_TYPES.head]: "base.20",
  [ROW_STYLE_TYPES.headQ]: "primary.100",
  [ROW_STYLE_TYPES.basic]: "neutral.10",
  [ROW_STYLE_TYPES.unread]: "table.40",
  [ROW_STYLE_TYPES.warning]: "warning.30",
  [ROW_STYLE_TYPES.error]: "error.20",
  [ROW_STYLE_TYPES.basic]: "neutral.20",
  [NOTIFICATIONS_STATUS_TYPES.read]: "primary.30",
};

const { complete, incomplete, confirmationRequired, unacknowledged } =
  TASKS_STATUS_TYPES;

export const statusColorsMap = {
  [incomplete]: {
    color: TASKS_ACCENT_COLORS_BY_STATUS[incomplete],
    iconFamily: Ionicons,
    iconName: "ios-stop-circle-sharp",
  },
  [complete]: {
    color: TASKS_ACCENT_COLORS_BY_STATUS[complete],
    iconFamily: FontAwesome,
    iconName: "circle",
  },
  [confirmationRequired]: {
    color: TASKS_ACCENT_COLORS_BY_STATUS[confirmationRequired],
    iconFamily: Ionicons,
    iconName: "ios-triangle",
  },
  [unacknowledged]: {
    color: TASKS_ACCENT_COLORS_BY_STATUS[unacknowledged],
    iconFamily: FontAwesome,
    iconName: "circle",
  },
};
