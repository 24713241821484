import React from "react";
import { Text, HStack, VStack } from "native-base";
import { i18n,getUserFullName } from "../utils/utils";

const PreviewFileHeader = ({ file }) => {
  if (!file) {
    return <Text>{i18n.t("components.previewFileHeader.dataUnavailable")} </Text>;
  }

  const userFullName = getUserFullName(file?.createdBy);

  return (
    <HStack
      p={2}
      borderWidth={1}
      borderColor="neutral.40"
      justifyItems="space-between"
      alignItems="space-between"
    >
      <VStack >
        <Text>
          <Text bold> {i18n.t("components.previewFileHeader.fileName")}: </Text>
          {file?.assetName}
        </Text>
        <Text>
          <Text bold>
 
            {i18n.t("components.previewFileHeader.projectName")}:{" "}
          </Text>
          {file?.parent?.value?.name}
        </Text>
        <Text>
          <Text bold>

            {i18n.t("components.previewFileHeader.uploadedBy")}:{" "}
          </Text>
          {userFullName}
        </Text>
      </VStack>
    </HStack>
  );
};

export default PreviewFileHeader;
