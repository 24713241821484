import { Box, Center } from "native-base";
import React from "react";
import Table, { createTableBluePrint } from "../../../components/table";
import { RESULTS_TABLE_COLUMNS } from "./tableColumn";
import {
  RESOLVE_ANSWERS_TYPES,
  TABLE_RESULTS_TYPES,
  calculateResultForResultsTable,
} from "../../../utils/utils";
import { RESULTS_STATUS_TYPES } from '../../../utils/types';

const ResultsTable = ({ dataSource = null,question = null }) => {
  if (dataSource === null || question === null) {
    return null;
  }

  const generateResultsData = () => {
    const data = [];

    question.answers.forEach((a) => {

      const userInput = a.answer;

      let status = RESULTS_STATUS_TYPES.valid;
      if (userInput === RESOLVE_ANSWERS_TYPES.na) {
        status = RESULTS_STATUS_TYPES.warning;
      }

      const input = question.input[0];

      const { unit, sign, firstNumber, secondNumber } = input;

      const result = calculateResultForResultsTable(
        userInput,
        firstNumber,
        secondNumber,
        sign
      );

      if (
        result === TABLE_RESULTS_TYPES.unSat &&
        userInput !== RESOLVE_ANSWERS_TYPES.na
      ) {
        status = RESULTS_STATUS_TYPES.error;
      }
      const row = {
        pn: a?.pn?.name,
        results: userInput,
        acceptance: `${input?.firstNumber} ${input?.sign} ${input?.secondNumber}  ${unit} `,
        result: result,
        status: status,
      };
      data.push(row);
    });

    return data;
  };

  const tableData = generateResultsData();
  const bluePrint = createTableBluePrint(
    tableData,
    RESULTS_TABLE_COLUMNS,
    "id"
  );
  return (
    <Box mt={8} px={4}>
      <Center>
        <Table
          tableHeadConfig={RESULTS_TABLE_COLUMNS}
          bluePrint={bluePrint}
          data={tableData}
          withExpandFeature={false}
          freezeFirstColumn={false}
        />
      </Center>
    </Box>
  );
};



export default ResultsTable;
