import React from "react";
import { HStack, Icon, Text, Pressable, Avatar, Box } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { statusColorsMap } from "../../../components/table/tableRelatedTypes";
import {
  ROW_CARD_MOBILE_VARIANTS,
  TASKS_MAIN_FLOW_SCREEN_STATUS,
} from "../../../utils/utils";
import _ from "lodash";
import UserAvatar from "../../../components/UserAvatar";

const RowCardMobile = ({
  id = null,
  name = "",
  status = null,
  onPress,
  isSelected,
  variant = ROW_CARD_MOBILE_VARIANTS.textOnly,
  listNumber = 1,
  isDisabled = false,
}) => {
  const onPressAction = () => {
    if (!onPress) {
      return;
    }
    onPress(id);
  };
  const getText = () => {
    const text = _.isObject(name) ? name?.name || "" : name || "";
    if(text.length>330){
      return _.truncate(text, {
        length: 330,
        omission: "..",
      }).trim();
    }
    return text.trim();
  };

  const text = getText();

  const renderText = () => {
    return (
      <Text
        font="base"
        fontWeight="400"
        fontStyle="normal"
        fontsize="sm"
        lineHeight="xs"
        color="base.100"
      >
        {text}
      </Text>
    );
  };

  const renderOrderedList = (number) => {
    const BULLET_BG_MAP = {
      [TASKS_MAIN_FLOW_SCREEN_STATUS.current]: "accent.40",
      [TASKS_MAIN_FLOW_SCREEN_STATUS.completed]: "success.30",
      [TASKS_MAIN_FLOW_SCREEN_STATUS.waiting]: "base.0",
    };
    const TEXT_BG_MAP = {
      [TASKS_MAIN_FLOW_SCREEN_STATUS.current]: "base.0",
      [TASKS_MAIN_FLOW_SCREEN_STATUS.completed]: "base.0",
      [TASKS_MAIN_FLOW_SCREEN_STATUS.waiting]: "neutral.60",
    };
    const BORDER_BG_MAP = {
      [TASKS_MAIN_FLOW_SCREEN_STATUS.current]: "base.0",
      [TASKS_MAIN_FLOW_SCREEN_STATUS.completed]: "base.0",
      [TASKS_MAIN_FLOW_SCREEN_STATUS.waiting]: "neutral.60",
    };

    return (
      <HStack
        w="full"
        my={1}
        space={2}
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="center"
      >
        <Avatar
          bg={BULLET_BG_MAP[status]}
          borderWidth={1}
          borderColor={BORDER_BG_MAP[status]}
          // padding={"2px"}
          size={7}
          mx={2}
        >
          <Text fontSize="md" color={TEXT_BG_MAP[status]}>
            {number}
          </Text>
        </Avatar>
        {renderText()}
      </HStack>
    );
  };
  const ROW_CARD_MOBILE_VARIANTSMap = {
    [ROW_CARD_MOBILE_VARIANTS.select]: renderText(),
    [ROW_CARD_MOBILE_VARIANTS.textOnly]: renderText(),
    [ROW_CARD_MOBILE_VARIANTS.orderedList]: renderOrderedList(listNumber),
    [ROW_CARD_MOBILE_VARIANTS.showUser]: (
      <HStack space={1}>
        <UserAvatar ml="1" size="small" user={name} />
        <Text
          font="base"
          fontWeight="400"
          fontStyle="normal"
          fontsize="sm"
          color="base.100"
        >
          {!isDisabled ? name : name.fullName}
        </Text>
      </HStack>
    ),
    [ROW_CARD_MOBILE_VARIANTS.showTask]: (
      <>
        <Icon
          as={statusColorsMap[status]?.iconFamily}
          name={statusColorsMap[status]?.iconName}
          size={"lg"}
          color={statusColorsMap[status]?.color}
        />
        {renderText()}
        <Icon
          as={MaterialIcons}
          name="navigate-next"
          size={"md"}
          color="base.100"
        />
      </>
    ),
    [ROW_CARD_MOBILE_VARIANTS.navigator]: (
      <HStack
        space={1}
        alignItems="center"
        alignContent="center"
        justifyContent="space-around"
        justifyItems="center"
      >
        <Box
          alignItems="center"
          alignContent="center"
          justifyContent="center"
          justifyItems="center"
          w="5/6"
        >
          {renderText()}
        </Box>
        <Icon
          as={MaterialIcons}
          name="navigate-next"
          size={"md"}
          color={status === "normal" ? "neutral.50" : "accent.40"}
        />
      </HStack>
    ),
  };

  const borderProps =
    variant === ROW_CARD_MOBILE_VARIANTS.orderedList
      ? {
          borderBottomWidth: 1,

          borderColor: "neutral.50",
        }
      : {
          borderWidth: 1,
          borderRadius: "sm",
          borderColor: "neutral.50",
        };
  const onPressCard = () => {
    if (isDisabled) {
      return;
    }
    onPressAction();
  };
  const getHeight = () => {

    if (text.length > 90) {
      return 200;
    }
    if (text.length > 80) {
      return 172;
    }
    if (text.length > 50) {
      return 69;
    }

 
    return 49;
  };
  const height = getHeight();
  return (
    <Pressable
      onPress={onPressCard}
      bg={isSelected ? "primary.10" : "transparent"}
      {...borderProps}
      justifyContent="center"
      alignContent="center"
      alignItems="center"
    syle={{     height: height,}}
      // justifyContent="center"
      mt={4}
      mx={3}
    >
      {variant === ROW_CARD_MOBILE_VARIANTS.orderedList ? (
        ROW_CARD_MOBILE_VARIANTSMap[variant]
      ) : (
        <HStack
          justifyContent={status ? "space-between" : "flex-start"}
          alignContent="center"
          alignItems="center"
          style={{
            // width: "100%",
            maxWidth: 336,
            minWidth: 250,
            height: height,
          }}
          h="container"
          px={3}
        >
          {ROW_CARD_MOBILE_VARIANTSMap[variant]}
        </HStack>
      )}
    </Pressable>
  );
};

export default RowCardMobile;
