import React from "react";
import { CELL_TYPES } from "../../utils/types";
import { HStack, Box, Text } from "native-base";
import { USER_CLASSES_TYPES } from "./userTypes";
import { i18n } from "../../utils/utils";

const { shortText, user, customComponentShort,longText } = CELL_TYPES;

const SN = 'screens.users.UserModal.labels.classTypes.'
const CLASS_TYPES_TEXT_MAP = {
  [USER_CLASSES_TYPES.m0]: i18n.t(`${SN}m0`),
  [USER_CLASSES_TYPES.superUser]:
    i18n.t(`${SN}superUser`),
  [USER_CLASSES_TYPES.flex]: i18n.t(`${SN}flex`),
};

export const TABLE_COLUMNS = [
  { title: "First Name", name: "firstName", cellType: shortText },
  { title: "Last Name", name: "lastName", cellType: shortText },
  { title: "Email", name: "email", cellType: longText },
  {
    title: "User class",
    name: "classType",
    cellType: customComponentShort,
    customComponent: (element) => {
      return <Text>{CLASS_TYPES_TEXT_MAP[element.classType]}</Text>;
    },
  },
  { title: "Reports to", name: "reportsTo", cellType: user },
  {
    title: "Account Status",
    name: "status",
    cellType: customComponentShort,
    customComponent: (element) => {
      const colorsMap = {
        active: "success.40",
        inactive: "neutral.70",
        disabled: "accent.100",
      };
      return (
        <HStack
          w="full"
          mr={8}
          justifyItems="flex-end"
          justifyContent="flex-end"
          space={2}
          alignItems="center"
        >
          <Box
            style={{ width: 10, height: 10, borderRadius: 5 }}
            bg={colorsMap[element.status]}
          />
          <Text>{element.status}</Text>
        </HStack>
      );
    },
  },
];
