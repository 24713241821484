import PropTypes from "prop-types";
import React from "react";
import { Text, Box } from "native-base";
import CellUserWidget from "./CellUserWidget";
import CellExpandWidget from "./CellExpandWidget";
import { CELL_CONTENT_TYPES, CELL_STYLE_TEMPLATES } from "../tableRelatedTypes";
import {
  CELL_TEMPLATES_STYLE_MAP,
  formatDate,
  formatAndTruncateText,
  LIST_OF_STYLE_TEMPLATES,
  LIST_OF_CONTENT_TYPES,
} from "./helpers";
import { su } from '../../../utils/utils';
import _ from 'lodash';

const Cell = ({
  value = "",
  contentType = CELL_CONTENT_TYPES.text,
  styleTemplate = CELL_STYLE_TEMPLATES.default,
  customComponentConfig = null,
  isExpanded = false,
  containerStyle,
  expandMethod = () => {},
}) => {
  const cellTemplate =
    CELL_TEMPLATES_STYLE_MAP?.[styleTemplate] || CELL_STYLE_TEMPLATES.default;

  const containerProps = cellTemplate?.container ? cellTemplate?.container : {};

  const textProps = cellTemplate?.text ? cellTemplate?.text : {};

  const renderTextCell = (text) => {
    if(text===""){
        return" "
    }
      if (!text || !_.isString(text)) {
          return null;
        }
    const formatText =
      contentType === CELL_CONTENT_TYPES.date
        ? formatDate
        : formatAndTruncateText;

    const renderedText = formatText(text);

    return (
      <Text style={{fontSize: su(13,16,16)}}  {...textProps}>
        {renderedText}
      </Text>
    );
  };

  const renderCustomComponent = (rowObjectData) => {
    const customComponent = customComponentConfig?.component;

    if (customComponent === null) {
      return null;
    }
    if (_.isFunction(customComponent) && rowObjectData) {
      return customComponent(rowObjectData);
    }
  };

  const contentMap = {
    [CELL_CONTENT_TYPES.text]: renderTextCell(value),
    [CELL_CONTENT_TYPES.expand]: (
      <CellExpandWidget
        onPress={expandMethod}
        isExpanded={isExpanded}
        styleTemplate={styleTemplate}
      />
    ),
    [CELL_CONTENT_TYPES.user]: (
      <CellUserWidget
        value={value}
        containerStyle={containerStyle}
        dataObject={customComponentConfig?.rowObjectData}
      />
    ),

    [CELL_CONTENT_TYPES.customComponent]: renderCustomComponent(
      customComponentConfig?.rowObjectData
    ),
  };

  const contentComponent = contentMap?.[contentType] || null;
  if (contentType === CELL_CONTENT_TYPES.expand) {
    return contentComponent;
  }

  return (
    <Box
      borderBottomColor="base.20"
      borderTopWidth={1}
      borderColor="table.130"
      borderBottomWidth={1}
      borderRightWidth={1}
      {...containerProps}
      style={containerStyle}
    >
      {contentComponent}
    </Box>
  );
};

Cell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isExpanded: PropTypes.bool.isRequired,
  customComponentConfig: PropTypes.shape({
    rowObjectData: PropTypes.object,
    component: PropTypes.func,
  }),
  contentType: PropTypes.oneOf(LIST_OF_CONTENT_TYPES),
  styleTemplate: PropTypes.oneOf(LIST_OF_STYLE_TEMPLATES),
};
export default React.memo(Cell);
