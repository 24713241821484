import {
  Text,
  HStack,
  VStack,
  Box,
  View,
  Button,
  ScrollView,
} from "native-base";
import React from "react";
import { useDispatch } from "react-redux";
import { useGetTaskQuery } from "../../services/apiService";
import {
  startAcknowledgeTask
} from "../../features/tasksSlice";
import NotFound from "../../components/NotFound";
import LoadingData from "../../components/LoadingData";
import {
  DATE_FORMAT,
  useAcrossDevices,
  i18n,
  calculateTaskPercentage,
} from "../../utils/utils";
import { TASKS_ACCENT_COLORS_BY_STATUS } from "../../components/table/tableRelatedTypes";
import { TASKS_STATUS_TYPES } from "./tasksStatusTypes";
import _ from "lodash";
import dayjs from "dayjs";
import ModalRich from "../../components/ModalRich";

const SN = `screens.taskDetails`;
const TasksMobileDetails = ({ route, navigation }) => {
  const dispatch = useDispatch();
  const { isMobile } = useAcrossDevices();

  const taskId = route.params?.taskId;

  if (!taskId) {
    return null;
  }
  const taskRequest = useGetTaskQuery(
    { taskId: taskId },
    {
      refetchOnMountOrArgChange: 60,
    }
  );

  if (taskRequest.isLoading || taskRequest.isFetching) {
    return (
      <LoadingData
        isLoading={taskRequest.isLoading}
        isFetching={taskRequest.isFetching}
      />
    );
  }
  const task = taskRequest.data;

  if (taskId === undefined || taskId.length == 0 || task === undefined) {
    return <NotFound />;
  }

  const {
    project,
    dis,
    pn,
    actualStartDate,
    area,
    status,
    plannedStartDate,
    actualEndDate,
    plannedEndDate,
    finalReport,
  } = task;
  if (!status) {
    return null;
  }

  const areaText = _.truncate(area?.name, { length: 40 });
  const LABEL_BY_STATUS_MAP = {
    [TASKS_STATUS_TYPES.complete]: i18n.t(`${SN}.labels.statuses.complete`),

    [TASKS_STATUS_TYPES.incomplete]: i18n.t(`${SN}.labels.statuses.incomplete`),
    [TASKS_STATUS_TYPES.confirmationRequired]: i18n.t(
      `${SN}.labels.statuses.confirmationRequired`
    ),
    [TASKS_STATUS_TYPES.unacknowledged]: i18n.t(
      `${SN}.labels.statuses.unacknowledged`
    ),
  };

  const topLabelsStyle = {
    fontSize: 18,
    lineHeight: "lg",
    letterSpacing: "lg",
    fontWeight: "semibold",
  };

  const valuesStyle = {
    fontSize: 12,
    lineHeight: "lg",
    letterSpacing: "lg",
    fontWeight: "normal",
    color: "base.90",
  };

  const renderHeader = () => {
    const taskPercentage = calculateTaskPercentage(task);
    return (
      <Box key="header" style={{ height: 194 }} bg="primary.30">
        <VStack style={{ paddingTop: 35 }} space={0}>
          <HStack key="1" h="1/2">
            <Box w="1/2" style={{ ...screenPL, height: 194 }}>
              <Text {...topLabelsStyle}>
                {i18n.t(`${SN}.labels.project`).toUpperCase()}
              </Text>
              <Text {...valuesStyle}>{project?.name}</Text>
            </Box>
            <Box w="1/2" alignItems="flex-end" style={{ paddingRight: 14 }}>
              <Text {...topLabelsStyle}>
                {i18n.t(`${SN}.labels.area`).toUpperCase()}
              </Text>
              <Text {...valuesStyle}>{areaText}</Text>
            </Box>
          </HStack>

          <HStack key="2" h="1/2" alignItems="flex-end">
            <Box w="1/2" justifyContent="flex-end">
              <Box
                justifyContent="center"
                borderBottomLeftRadius="md"
                bg={TASKS_ACCENT_COLORS_BY_STATUS[status]}
                style={{
                  height: 32,
                  minWidth: 174,
                  maxWidth: 190,
                }}
              >
                <Text
                  lineHeight="xs"
                  fontFamily="heading"
                  fontWeight="medium"
                  fontSize={12}
                  color="base.0"
                  style={{ paddingLeft: 19 }}
                >
                  {LABEL_BY_STATUS_MAP[status].toUpperCase()}
                </Text>
              </Box>
            </Box>
            <Box
              w="1/2"
              h="full"
              p={0}
              m={0}
              alignItems="flex-end"
              alignContent="flex-end"
              justifyContent="flex-end"
              style={{ paddingRight: 14 }}
            >
              <Box
                alignItems="center"
                alignContent="space-around"
                justifyItems="space-around"
                justifyContent="space-around"
              >
                <Text
                  fontSize={40}
                  paddingTop={0}
                  pt={3}
                  lineHeight="lg"
                  fontWeight="black"
                  color={TASKS_ACCENT_COLORS_BY_STATUS[status]}
                >
                  {taskPercentage}%
                </Text>
              </Box>
              <Text
                letterSpacing="3xl"
                lineHeight="lg"
                fontSize={16}
                textAlign="right"
                w="lg"
                style={{ maxWidth: 150 }}
                color={TASKS_ACCENT_COLORS_BY_STATUS[status]}
                pb={3}
              >
                {i18n.t(`${SN}.labels.progress`)}
              </Text>
            </Box>
          </HStack>
        </VStack>
      </Box>
    );
  };

  const renderIndependentLabel = (label) => {
    return (
      <Box mb={2} style={{ height: 21, ...screenPL }}>
        <Text
          style={{ fontSize: 16 }}
          letterSpacing="3xl"
          lineHeight="sm"
          color="primary.90"
        >
          {label}
        </Text>
      </Box>
    );
  };

  const renderLabelText = (label) => {
    return (
      <Text
        color="accent.50"
        letterSpacing="lg"
        fontWeight="medium"
        lineHeight="lg"
        fontSize={16}
      >
        {label.toUpperCase()}{" "}
      </Text>
    );
  };
  const latestErrorReport = task?.errorReports?.[task?.errorReports.length - 1];
  const latestErrorReportStatus = latestErrorReport?.status || "N/A";

  const cardsConfig = [
    [
      {
        label: i18n.t(`${SN}.labels.start`),
        value: [{ name: dayjs(plannedStartDate).format(DATE_FORMAT) }],
      },
      {
        label: i18n.t(`${SN}.labels.end`),
        value: [{ name: dayjs(plannedEndDate).format(DATE_FORMAT) }],
      },
    ],
    [
      {
        label: i18n.t(`${SN}.labels.dis`),
        value: [{ name: dis?.name }],
      },
      {
        label: i18n.t(`${SN}.labels.pn`),
        value: [...pn],
      },
    ],
    [
      {
        label: i18n.t(`${SN}.labels.start`),
        value: [
          {
            name: actualStartDate
              ? dayjs(actualStartDate).format(DATE_FORMAT)
              : i18n.t(`${SN}.labels.notStarted`),
          },
        ],
      },
      {
        label: i18n.t(`${SN}.labels.end`),
        value: [
          {
            name: actualEndDate
              ? dayjs(actualStartDate).format(DATE_FORMAT)
              : i18n.t(`${SN}.labels.notFinished`),
          },
        ],
      },
    ],
    [
      {
        label: i18n.t(`${SN}.labels.errorReportStatus`),
        value: [
          {
            name:
              latestErrorReportStatus.toUpperCase() ||
              i18n.t(`${SN}.labels.na`),
          },
        ],
      },
      {
        label: i18n.t(`${SN}.labels.finalReportStatus`),
        value: [
          {
            name:
              finalReport?.status.toUpperCase() || i18n.t(`${SN}.labels.na`),
          },
        ],
      },
    ],
  ];

  const renderPanels = () => {
    return cardsConfig.map((panel, index) => {
      return (
        <View key={`${index + 1}-panel`}>
          {index === 0 &&
            renderIndependentLabel(i18n.t(`${SN}.labels.plannedDates`))}
          {index === 2 &&
            renderIndependentLabel(i18n.t(`${SN}.labels.actualDates`))}
          {index === 3 &&
            renderIndependentLabel(i18n.t(`${SN}.labels.reportsStatus`))}
          <Box style={{ minHeight: 81 }} bg="primary.30">
            <HStack alignItems="center" style={{ minHeight: 81 }}>
              <Box
                bg="accent.50"
                borderLeftRadius="md"
                style={{ width: 16, height: "100%" }}
                h="full"
                w="full"
              />
              <HStack
                py={3}
                w="full"
                justifyItems="center"
                alignItems="flex-start"
                style={{ paddingLeft: 27, paddingRight: 59 }}
              >
                {panel.map((el, i) => {
                  return (
                    <VStack
                      w="1/2"
                      key={`${i}-panel-item`}
                      alignItems={i == 0 ? "flex-start" : "flex-end"}
                    >
                      {renderLabelText(el.label)}
                      {el.value.map((val, index) => {
                        return (
                          <Text key={`${index}-text`} {...valuesStyle}>
                            {val.name}
                          </Text>
                        );
                      })}
                    </VStack>
                  );
                })}
              </HStack>
            </HStack>
          </Box>
        </View>
      );
    });
  };
  const triggerBottomAction = () => {
    if (status === TASKS_STATUS_TYPES.unacknowledged) {
      // navigation.
      navigation.navigate("TasksFlow");
      dispatch(startAcknowledgeTask(task));
    } else if (
      status === TASKS_STATUS_TYPES.confirmationRequired ||
      status === TASKS_STATUS_TYPES.incomplete
    ) {
      navigation.replace("ResolveFlow", {
        screen: "ResolveTask",
        params: { taskId: task.id },
      });
    } else if (status === TASKS_STATUS_TYPES.complete) {
   
      navigation.navigate("ResolveFlow", {
        params: { reportId: task?.finalReport?.id },
        screen: "Report",
      });
    }
  };

  const screenPL = { paddingLeft: 12 };

  const actionBtnStatusMap = {
    [TASKS_STATUS_TYPES.unacknowledged]: i18n.t(
      `${SN}.buttons.acknowledgeTask`
    ),
    [TASKS_STATUS_TYPES.confirmationRequired]: i18n.t(
      `${SN}.buttons.startResolve`
    ),
    [TASKS_STATUS_TYPES.incomplete]: i18n.t(`${SN}.buttons.startResolve`),
    [TASKS_STATUS_TYPES.complete]: i18n.t(`${SN}.buttons.complete`),
  };

  const actionBtn = <Text>{actionBtnStatusMap[status]} </Text>;
  return (
    <ScrollView bg="base.0" w="full">
      <VStack space={3} mb={6}>
        {renderHeader()}

        {renderPanels()}
        <Button
          key="btn"
          justifyContent="center"
          justifyItems="center"
          _hover={{ bg: "accent.50" }}
          _pressed={{ bg: "accent.50" }}
          bg={TASKS_ACCENT_COLORS_BY_STATUS[status]}
          h={12}
          borderRadius="lg"
          mx={3}
          onPress={triggerBottomAction}
        >
          <Text color="base.0">{actionBtn}</Text>
        </Button>
      </VStack>
      <ModalRich isMobile={isMobile} />
    </ScrollView>
  );
};

export default TasksMobileDetails;
