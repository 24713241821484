import { Text, Button, HStack, VStack } from "native-base";
import React from "react";
import { getUserFullName, i18n } from "../utils/utils";
import { logOutUserHook } from "../features/authSlice";
import { useSelector } from "react-redux";
import UserAvatar from "../components/UserAvatar";
import pjson from "../../package.json";

const AccountMobile = () => {
  const [logOutUser, { isLoading }] = logOutUserHook();

  const loggedUser = useSelector((state) => state.auth.user);
  const fullName = getUserFullName(loggedUser);

  return (
    <VStack
      space={6}
      style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
    >
      <UserAvatar size="big" user={loggedUser} />
      <VStack
        w="xs"
        borderColor="neutral.40"
        borderRadius="sm"
        borderWidth={1}
        p={2}
        alignItems="center"
      >
        <HStack space={2}>
          <Text>{i18n.t("screens.accountMobile.title")}</Text>
          <Text bold={true}> {fullName} </Text>
        </HStack>
        <HStack space={1}>
          <Text>{i18n.t("screens.accountMobile.class")}</Text>
          <Text bold={true}> {loggedUser.classType} </Text>
        </HStack>
      </VStack>

      <Button w="xs" onPress={logOutUser} bg="primary.60" isLoading={isLoading}>
        {i18n.t("screens.accountMobile.logout")}
      </Button>
      <Text mt={5} italic>
        {i18n.t("screens.accountMobile.version")}
        <Text bold={true}>{pjson.version} </Text>
      </Text>
    </VStack>
  );
};

export default AccountMobile;
