import { createSlice, current } from "@reduxjs/toolkit";
import "react-native-get-random-values";
import { api } from "../services/apiService";
import { revertAll } from "../utils/utils";
import {
  TREE_LEVELS,
  getUserFullName,
  checkRequestPayload,
  getProjectIndexFromState,
} from "../utils/utils";
import _, { isString } from "lodash";

const getAreaIndeAndParentFromState = (
  state,
  { areaId = null, projectId = null }
) => {
  if (!isString(projectId) || !isString(areaId)) {
    return null;
  }

  const projectIndex = getProjectIndexFromState(state, projectId);

  if (projectIndex === null) {
    return null;
  }

  const areaIndex = current(
    state.projects[state.currentPage][projectIndex].areas
  ).findIndex((item) => item.id === areaId);

  if (areaIndex !== -1) {
    return { areaIndex, projectIndex };
  }
  return null;
};

const getDisIndexAndParentFromState = (
  state,
  { areaId = null, projectId = null, disId = null }
) => {
  if (!isString(projectId) || !isString(areaId) || !isString(disId)) {
    return null;
  }
  const { areaIndex, projectIndex } = getAreaIndeAndParentFromState(state, {
    areaId,
    projectId,
  });
  const disIndex = current(
    state.projects[state.currentPage][projectIndex].areas[areaIndex].dis
  ).findIndex((item) => item.id === disId);

  if (disIndex !== -1) {
    return { areaIndex, projectIndex, disIndex };
  }
  return null;
};
const getPnIndexAndParentFromState = (
  state,
  { areaId = null, projectId = null, disId = null, pnId = null }
) => {
  if (
    !isString(projectId) ||
    !isString(areaId) ||
    !isString(disId) ||
    !isString(pnId)
  ) {
    return null;
  }
  const { areaIndex, projectIndex, disIndex } = getDisIndexAndParentFromState(
    state,
    { areaId, projectId, disId }
  );
  const pnIndex = current(
    state.projects[state.currentPage][projectIndex].areas[areaIndex].dis[
      disIndex
    ].pn
  ).findIndex((item) => item.id === pnId);

  if (pnIndex === -1) {
    return null;
  }
  return { areaIndex, projectIndex, disIndex, pnIndex };
};

const getIndexFromState = (originalArgs, targetName) => {
  if (targetName === TREE_LEVELS.areas) {
    if (!isString(originalArgs)) {
      return null;
    }
    return originalArgs;
  }

  const hasProjectAndAreaId =
    !isString(originalArgs?.projectId) || !isString(originalArgs?.areaId);
  if (targetName === TREE_LEVELS.dis) {
    if (hasProjectAndAreaId) {
      return null;
    }
    return {
      projectId: originalArgs?.projectId,
      areaId: originalArgs?.areaId,
    };
  }

  // let has
  const hasProjectAreaDis =
    hasProjectAndAreaId || !isString(originalArgs?.disId);
  if (targetName === TREE_LEVELS.pn) {
    if (hasProjectAreaDis) {
      return null;
    }
    return {
      projectId: originalArgs?.projectId,
      areaId: originalArgs?.areaId,
      disId: originalArgs?.disId,
    };
  }
};

const initialState = {
  projects: [],
  currentPage: "1",
  expandedKeys: [],
  projectsListMobile: [],
  ux: {
    message: "",
    messageType: "success",
    showMessage: false,
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setExpandedKeys: (state, action) => {
      state.expandedKeys = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, () => initialState);
    builder
      .addMatcher(api.endpoints.addProject.matchFulfilled, (state, req) => {
        const reqArg = checkRequestPayload(req, true);
        if (reqArg === null) {
          return;
        }
        state.projects[state.currentPage].unshift({
          id: req.payload.doc?.id,
          name: req.payload.doc?.name,
          [TREE_LEVELS.areas]: [],
          [TREE_LEVELS.formA]: [],
          [TREE_LEVELS.formB]: [],
          [TREE_LEVELS.keyPeoples]: [],
        });
      })
      .addMatcher(api.endpoints.getAllProjects.matchFulfilled, (state, req) => {
        //reqArg value for success it will be 1 here, because is the index of the page
        const reqArg = checkRequestPayload(req);
        if (reqArg !== null) {
          state.projects[state.currentPage] = req.payload.docs.map((item) => {
            let itemCopy = item;
            itemCopy = { ...item, [TREE_LEVELS.areas]: [] };
            if (_.isObject(item?.manager) && item.manager?.id) {
              itemCopy.manager = {
                ...itemCopy.manager,
                fullName: `${item?.manager?.firstName} ${item?.manager?.lastName}`,
              };
            }

            if (_.isObject(item?.teamLeader) && item.teamLeader?.id) {
              itemCopy.teamLeader = {
                ...itemCopy.teamLeader,
                fullName: `${item?.teamLeader?.firstName} ${item?.teamLeader?.lastName}`,
              };
            }

            if (_.isArray(item?.keyPeople) && item?.keyPeople.length >= 1) {
              itemCopy.keyPeople = item.keyPeople.map((user) => {
                return {
                  ...user,
                  fullName: `${user?.firstName} ${user?.lastName}`,
                };
              });
            }

            return itemCopy;
          });
          // state.projectsListMobile = [
          //   ...req.payload.docs,
          // ];
        }
      })
      .addMatcher(api.endpoints.getProjects.matchFulfilled, (state, req) => {
        const { payload } = req;

        if (payload?.docs && payload.page) {
          state.projects[payload.page] = payload?.docs.map((item) => {
            let itemCopy = item;
            itemCopy = { ...itemCopy, [TREE_LEVELS.areas]: [] };

            if (_.isObject(item?.manager) && item.manager?.id) {
              itemCopy.manager = {
                ...itemCopy.manager,
                fullName: getUserFullName(item?.manager),
              };
            }

            if (_.isObject(item?.teamLeader) && item.teamLeader?.id) {
              itemCopy.teamLeader = {
                ...itemCopy.teamLeader,
                fullName: getUserFullName(item?.teamLeader),
              };
            }

            if (_.isArray(item?.keyPeople) && item?.keyPeople.length >= 1) {
              itemCopy.keyPeople = item.keyPeople.map((user) => {
                return {
                  ...user,
                  fullName: getUserFullName(user),
                };
              });
            }

            return itemCopy;
          });
        }
      })

      .addMatcher(api.endpoints.deleteProject.matchFulfilled, (state, req) => {
        if (!req.payload.id) {
          return;
        }
        const projects = state.projects[state.currentPage];

        if (!projects) {
          return;
        }
        const newArr = _.remove(projects, (p) => p.id !== req.payload.id);

        state.projects[state.currentPage] = newArr;
      })
      .addMatcher(api.endpoints.editProject.matchFulfilled, (state, req) => {
        //reqArg value for success it will be 1 here, because is the index of the page
        const parentsIds = checkRequestPayload(req, true);

        if (parentsIds === null) {
          return;
        }
        const { projectId } = parentsIds;

        const editedProjectIndex = state.projects[state.currentPage].findIndex(
          (p) => p.id === projectId
        );
        if (editedProjectIndex === -1) {
          return;
        }

        state.projects[state.currentPage][editedProjectIndex].name =
          req.payload.doc.name;

        state.projects[state.currentPage][editedProjectIndex].keyPeople = req.payload?.doc?.keyPeople?.length > 0?
          req.payload.doc.keyPeople.map((user) => {
            user.fullName = getUserFullName(user);
            return user;
          }):[];
        state.projects[state.currentPage][editedProjectIndex].manager = {
          ...req.payload.doc.manager,
          fullName: getUserFullName(req.payload.doc.manager),
        };
        state.projects[state.currentPage][editedProjectIndex].teamLeader = {
          ...req.payload.doc.teamLeader,
          fullName: getUserFullName(req.payload.doc.teamLeader),
        };
      })
      .addMatcher(
        api.endpoints.getAreasOfProject.matchFulfilled,
        (state, req) => {
          const { payload } = req;

          const parentsIds = checkRequestPayload(req);

          if (parentsIds === null) {
            return;
          }

          const { projectId } = parentsIds;

          const currentProjectIndex = getProjectIndexFromState(
            state,
            projectId
          );

          if (currentProjectIndex === null) {
            return;
          }

          const projectAreas = payload?.docs.map((item) => {
            item.dis = [];
            return item;
          });

          state.projects[state.currentPage][currentProjectIndex].areas =
            projectAreas;
        }
      )
      .addMatcher(api.endpoints.addArea.matchFulfilled, (state, req) => {
        const parentsIds = checkRequestPayload(req, true);

        if (parentsIds === null) {
          return;
        }
        const { payload } = req;
        const { projectId } = parentsIds;

        const projectIndex = getProjectIndexFromState(state, projectId);

        if (projectIndex === null) {
          return;
        }
        state.projects[state.currentPage][projectIndex]?.areas.push({
          name: payload.doc.name,
          id: payload.doc.id,
          dis: [],
        });
      })
      .addMatcher(api.endpoints.deleteArea.matchFulfilled, (state, req) => {
        const areaId = checkRequestPayload(req, null);
  
        if (areaId === null) {
          return;
        }

        const projectId = req.payload.parentProjectId.id || null;

        if (projectId === null) {
          return;
        }

        const projectIndex = getProjectIndexFromState(state, projectId);

        if (projectIndex === null) {
          return;
        }

        const newArr = _.remove(
          state.projects[state.currentPage][projectIndex]?.areas,
          (area) => area.id !== req.payload.id
        );

        state.projects[state.currentPage][projectIndex].areas = newArr;
      })
      .addMatcher(api.endpoints.editArea.matchFulfilled, (state, req) => {
        const area = req.payload.doc || null;

        if (area === null) {
          return;
        }

        const areaId = area.id;

        const projectId = req.payload.doc.parentProjectId.id || null;

        if (projectId === null) {
          return;
        }

        const projectIndex = getProjectIndexFromState(state, projectId);

        if (projectIndex === null) {
          return;
        }

        const currentProject = state.projects[state.currentPage][projectIndex];

        const areaIndex = currentProject.areas.findIndex(
          (a) => a.id === areaId
        );

        state.projects[state.currentPage][projectIndex].areas[areaIndex] =
          req.payload.doc;
      })
      .addMatcher(api.endpoints.addDis.matchFulfilled, (state, req) => {
        const originalArgs = req?.meta?.arg?.originalArgs;
        const projectId = originalArgs.projectId;
    
        const areaId = originalArgs.areaId;
   
        const projects = state.projects[state.currentPage];

        if (!projects) {
          return;
        }

        const projectIndex = projects.findIndex(
          (item) => item?.id === projectId
        );

        if (projectIndex === -1) {
          return;
        }

        const currentProject = projects[projectIndex];

        const areaIndex = currentProject.areas.findIndex(
          (item) => item.id === areaId
        );
        if (areaIndex === -1) {
          return;
        }

        currentProject?.areas[areaIndex]?.dis.push({
          id: req.payload.doc.id,
          name: req.payload.doc.name,
          pn: [],
        });
      })
      .addMatcher(api.endpoints.deleteDis.matchFulfilled, (state, req) => {
        const originalArgs = req?.meta?.arg?.originalArgs;
        if (originalArgs === null || !originalArgs?.parentKeys) {
          return;
        }
        const projectId = originalArgs.parentKeys.projectKey;
        const areaId = originalArgs.parentKeys.areaKey;
        const disId = originalArgs.parentKeys.disKey;
        const projects = state.projects[state.currentPage];

        if (!projects) {
          return;
        }

        const projectIndex = projects.findIndex(
          (item) => item?.id === projectId
        );

        if (projectIndex === -1) {
          return;
        }

        const currentProject = projects[projectIndex];

        const areaIndex = currentProject.areas.findIndex(
          (item) => item.id === areaId
        );

        if (areaIndex === -1) {
          return;
        }

        const disIndex = currentProject.areas[areaIndex].dis.findIndex(
          (item) => item.id === disId
        );

        if (disIndex === -1) {
          return;
        }
        const newArr = _.remove(
          currentProject.areas[areaIndex].dis,
          (dis) => dis.id !== req.payload.id
        );

        currentProject.areas[areaIndex].dis = newArr;
      })
      .addMatcher(api.endpoints.editDis.matchFulfilled, (state, req) => {
        const originalArgs = req?.meta?.arg?.originalArgs;
        if (originalArgs === null || !originalArgs?.parentKeys) {
          return;
        }
        const projectId = originalArgs.parentKeys.projectKey;
        const areaId = originalArgs.parentKeys.areaKey;
        const disId = originalArgs.parentKeys.disKey;
        const projects = state.projects[state.currentPage];

        if (!projects) {
          return;
        }

        const projectIndex = projects.findIndex(
          (item) => item?.id === projectId
        );

        if (projectIndex === -1) {
          return;
        }

        const currentProject = projects[projectIndex];

        const areaIndex = currentProject.areas.findIndex(
          (item) => item.id === areaId
        );

        if (areaIndex === -1) {
          return;
        }

        const disIndex = currentProject.areas[areaIndex].dis.findIndex(
          (item) => item.id === disId
        );

        if (disIndex === -1) {
          return;
        }

        currentProject.areas[areaIndex].dis[disIndex] = req.payload.doc;
      })
      .addMatcher(api.endpoints.getDisOfArea.matchFulfilled, (state, req) => {
        const { projectId, areaId } = req?.meta?.arg?.originalArgs;
        const originalArgs = checkRequestPayload(req);

        if (originalArgs === null) {
          return;
        }

        const parentsIds = getIndexFromState(originalArgs, TREE_LEVELS.dis);

        if (parentsIds === null) {
          return;
        }

        if (
          !projectId ||
          !isString(projectId) ||
          !projectId ||
          !isString(projectId)
        ) {
          return;
        }

        const projectIndex = state.projects[state.currentPage].findIndex(
          (item) => item.id === projectId
        );

        if (projectIndex === -1) {
          return;
        }

        const areaIndex = current(
          state.projects[state.currentPage][projectIndex].areas
        ).findIndex((item) => item.id === areaId);

        if (areaIndex === -1) {
          return;
        }

        const disItems = req.payload.docs.map((item) => {
          item.pn = [];
          return item;
        });

        state.projects[state.currentPage][projectIndex].areas[areaIndex].dis =
          disItems;
      })
      .addMatcher(api.endpoints.addPn.matchFulfilled, (state, req) => {
        const parentsIds = checkRequestPayload(req, true);

        if (parentsIds === null) {
          return;
        }
        const { projectId, areaId, disId } = parentsIds;

        const parentsIndexes = getDisIndexAndParentFromState(state, {
          projectId,
          areaId,
          disId,
        });

        if (parentsIndexes === null) {
          return;
        }

        const { projectIndex, areaIndex, disIndex } = parentsIndexes;

        state.projects[state.currentPage][projectIndex]?.areas[areaIndex]?.dis[
          disIndex
        ].pn.push({
          id: req.payload.doc.id,
          name: req.payload.doc.name,
          questionner: [],
        });
      })
      .addMatcher(api.endpoints.editPn.matchFulfilled, (state, req) => {
        const originalArgs = req?.meta?.arg?.originalArgs;
        if (originalArgs === null || !originalArgs?.parentKeys) {
          return;
        }
        const projectId = originalArgs.parentKeys.projectKey;
        const areaId = originalArgs.parentKeys.areaKey;
        const disId = originalArgs.parentKeys.disKey;
        const pnId = originalArgs.parentKeys.pnKey;
        const projects = state.projects[state.currentPage];

        if (!projects) {
          return;
        }
        const projectIndex = projects.findIndex(
          (item) => item?.id === projectId
        );

        if (projectIndex === -1) {
          return;
        }

        const currentProject = projects[projectIndex];

        const areaIndex = currentProject.areas.findIndex(
          (item) => item.id === areaId
        );

        if (areaIndex === -1) {
          return;
        }

        const disIndex = currentProject.areas[areaIndex].dis.findIndex(
          (item) => item.id === disId
        );

        if (disIndex === -1) {
          return;
        }

        const pnIndex = currentProject.areas[areaIndex].dis[
          disIndex
        ].pn.findIndex((item) => item.id === pnId);

        if (pnIndex === -1) {
          return;
        }

        currentProject.areas[areaIndex].dis[disIndex].pn[pnIndex] =
          req.payload.doc;
        // currentProject.areas[areaIndex].dis[disIndex] = req.payload.doc;
      })
      .addMatcher(api.endpoints.deletePn.matchFulfilled, (state, req) => {
        const originalArgs = req?.meta?.arg?.originalArgs;
        if (originalArgs === null || !originalArgs?.parentKeys) {
          return;
        }
        const projectId = originalArgs.parentKeys.projectKey;
        const areaId = originalArgs.parentKeys.areaKey;
        const disId = originalArgs.parentKeys.disKey;
        const pnId = originalArgs.parentKeys.pnKey;
        const projects = state.projects[state.currentPage];

        if (!projects) {
          return;
        }
        const projectIndex = projects.findIndex(
          (item) => item?.id === projectId
        );

        if (projectIndex === -1) {
          return;
        }

        const currentProject = projects[projectIndex];

        const areaIndex = currentProject.areas.findIndex(
          (item) => item.id === areaId
        );

        if (areaIndex === -1) {
          return;
        }

        const disIndex = currentProject.areas[areaIndex].dis.findIndex(
          (item) => item.id === disId
        );

        if (disIndex === -1) {
          return;
        }

        const pnIndex = currentProject.areas[areaIndex].dis[
          disIndex
        ].pn.findIndex((item) => item.id === pnId);

        if (pnIndex === -1) {
          return;
        }

        const newArr = _.remove(
          currentProject.areas[areaIndex].dis[disIndex].pn,
          (pn) => pn.id !== req.payload.id
        );

        currentProject.areas[areaIndex].dis[disIndex].pn = newArr;
      })
      .addMatcher(api.endpoints.getPnOfDis.matchFulfilled, (state, req) => {
        const parentsIds = checkRequestPayload(req, false);
        if (parentsIds === null) {
          return;
        }

        
        const { projectId, areaId, disId } = parentsIds;
        if( !projectId||  !areaId || !disId){
          return;
        }
        const parentsIndexes = getDisIndexAndParentFromState(state, {
          projectId,
          areaId,
          disId,
        });

        if (parentsIndexes === null) {
          return;
        }

        const { projectIndex, areaIndex, disIndex } = parentsIndexes;
        const pnItems = req.payload.docs.map((item) => {
          item.questionnaires = [];
          return item;
        });

        state.projects[state.currentPage][projectIndex].areas[areaIndex].dis[
          disIndex
        ].pn = pnItems;
      })
      .addMatcher(
        api.endpoints.getQuestionnersOfPn.matchFulfilled,
        (state, req) => {
          const originalArgs = req?.meta?.arg?.originalArgs;
          if (originalArgs === null) {
            return;
          }

          const projectId = originalArgs.projectId;
          const areaId = originalArgs.areaId;
          const disId = originalArgs.disId;
          const pnId = originalArgs.pnId;
          const projects = state.projects[state.currentPage];

          if (!projects) {
            return;
          }
          const projectIndex = projects.findIndex(
            (item) => item?.id === projectId
          );

          if (projectIndex === -1) {
            return;
          }

          const currentProject = projects[projectIndex];

          const areaIndex = currentProject.areas.findIndex(
            (item) => item.id === areaId
          );

          if (areaIndex === -1) {
            return;
          }

          const disIndex = currentProject.areas[areaIndex].dis.findIndex(
            (item) => item.id === disId
          );

          if (disIndex === -1) {
            return;
          }

          const pnIndex = currentProject.areas[areaIndex].dis[
            disIndex
          ].pn.findIndex((item) => item.id === pnId);

          if (pnIndex === -1) {
            return;
          }

          const docs = req.payload.docs.map((doc) => {
            let docCopy = doc;
            docCopy.activities = doc.activities;
            return docCopy;
          });

          currentProject.areas[areaIndex].dis[disIndex].pn[
            pnIndex
          ].questionnaires = docs;
        }
      )
      .addMatcher(
        api.endpoints.getDocOfProjects.matchFulfilled,
        (state, req) => {
          const { payload } = req;

          const reqArg = checkRequestPayload(req);

          if (reqArg === null) {
            return;
          }

          const { projectId, assetType } = reqArg;

          const currentProjectIndex = getProjectIndexFromState(
            state,
            projectId
          );

          if (currentProjectIndex === null) {
            return;
          }

          const projectDocs = payload?.docs;

          state.projects[state.currentPage][currentProjectIndex][assetType] =
            projectDocs;
        }
      )
      .addMatcher(api.endpoints.addAsset.matchFulfilled, (state, req) => {
        const { payload } = req;

        const reqArg = req?.meta?.arg?.originalArgs;
        if (reqArg === null) {
          return;
        }

        const { projectId, assetType } = reqArg;

        const currentProjectIndex = getProjectIndexFromState(state, projectId);

        if (currentProjectIndex === null) {
          return;
        }

        const doc = payload?.doc;

        state.projects[state.currentPage][currentProjectIndex][assetType].push(
          doc
        );
      })
      .addMatcher(api.endpoints.editAsset.matchFulfilled, (state, req) => {
        const originalArgs = req?.meta?.arg?.originalArgs;
        if (originalArgs === null || !originalArgs?.parentKeys) {
          return;
        }
        const projects = state.projects[state.currentPage];

        if (!projects) {
          return;
        }
        const projectId = originalArgs.parentKeys.projectKey;
        const projectIndex = projects.findIndex(
          (item) => item?.id === projectId
        );

        if (projectIndex === -1) {
          return;
        }

        const currentProject = projects[projectIndex];

        const assetIndex = currentProject?.[
          req.payload.doc.assetType
        ].findIndex((item) => item.id === req.payload.doc.id);
        if (assetIndex === -1) {
          return;
        }
        currentProject[req.payload.doc.assetType][assetIndex] = req.payload.doc;
      })
      .addMatcher(api.endpoints.deleteAsset.matchFulfilled, (state, req) => {
        const originalArgs = req?.meta?.arg?.originalArgs;
        if (originalArgs === null || !originalArgs?.parentKeys) {
          return;
        }
        const projects = state.projects[state.currentPage];

        if (!projects) {
          return;
        }
        const projectId = originalArgs.parentKeys.projectKey;
        const projectIndex = projects.findIndex(
          (item) => item?.id === projectId
        );

        if (projectIndex === -1) {
          return;
        }

        const currentProject = projects[projectIndex];

        const newArr = _.remove(
          currentProject[req.payload.assetType],
          (asset) => asset.id !== req.payload.id
        );

        currentProject[req.payload.assetType] = newArr;
      })
      .addMatcher(
        api.endpoints.addQuestionnaire.matchFulfilled,
        (state, req) => {
          const parentsIds = checkRequestPayload(req, true);

          if (parentsIds === null) {
            return;
          }
          const { projectId, areaId, disId, pnId } = parentsIds;

          const parentsIndexes = getPnIndexAndParentFromState(state, {
            projectId,
            areaId,
            disId,
            pnId,
          });

          if (parentsIndexes === null) {
            return;
          }

          const { projectIndex, areaIndex, disIndex, pnIndex } = parentsIndexes;

          const doc = req.payload.doc;
          if (doc.activities && _.isString(doc.activities)) {
            doc.activities = JSON.parse(doc.activities).map((act) => {
              act.name = act.titles[0];
              return act;
            });
          }

          state.projects[state.currentPage][projectIndex].areas[areaIndex].dis[
            disIndex
          ].pn[pnIndex].questionnaires.push(doc);
        }
      )
      .addMatcher(
        api.endpoints.editQuestionner.matchFulfilled,
        (state, req) => {
          const originalArgs = req?.meta?.arg?.originalArgs;
          if (originalArgs === null || !originalArgs?.parentKeys) {
            return;
          }
          const projectId = originalArgs.parentKeys.projectKey;
          const areaId = originalArgs.parentKeys.areaKey;
          const disId = originalArgs.parentKeys.disKey;
          const pnId = originalArgs.parentKeys.pnKey;
          const questionnaireId = originalArgs.parentKeys.questionnaireKey;
          const projects = state.projects[state.currentPage];

          if (!projects) {
            return;
          }
          const projectIndex = projects.findIndex(
            (item) => item?.id === projectId
          );

          if (projectIndex === -1) {
            return;
          }

          const currentProject = projects[projectIndex];

          const areaIndex = currentProject.areas.findIndex(
            (item) => item.id === areaId
          );

          if (areaIndex === -1) {
            return;
          }

          const disIndex = currentProject.areas[areaIndex].dis.findIndex(
            (item) => item.id === disId
          );

          if (disIndex === -1) {
            return;
          }

          const pnIndex = currentProject.areas[areaIndex].dis[
            disIndex
          ].pn.findIndex((item) => item.id === pnId);

          if (pnIndex === -1) {
            return;
          }

          const questionnaireIndex = currentProject.areas[areaIndex].dis[
            disIndex
          ].pn[pnIndex].questionnaires.findIndex(
            (item) => item.id === questionnaireId
          );

          if (questionnaireIndex === -1) {
            return;
          }

          currentProject.areas[areaIndex].dis[disIndex].pn[
            pnIndex
          ].questionnaires[questionnaireIndex] = req.payload.doc;
        }
      )
      .addMatcher(
        api.endpoints.deleteQuestionner.matchFulfilled,
        (state, req) => {
          const originalArgs = req?.meta?.arg?.originalArgs;
          if (originalArgs === null || !originalArgs?.parentKeys) {
            return;
          }
          const projectId = originalArgs.parentKeys.projectKey;
          const areaId = originalArgs.parentKeys.areaKey;
          const disId = originalArgs.parentKeys.disKey;
          const pnId = originalArgs.parentKeys.pnKey;
          const projects = state.projects[state.currentPage];

          if (!projects) {
            return;
          }
          const projectIndex = projects.findIndex(
            (item) => item?.id === projectId
          );

          if (projectIndex === -1) {
            return;
          }

          const currentProject = projects[projectIndex];

          const areaIndex = currentProject.areas.findIndex(
            (item) => item.id === areaId
          );

          if (areaIndex === -1) {
            return;
          }

          const disIndex = currentProject.areas[areaIndex].dis.findIndex(
            (item) => item.id === disId
          );

          if (disIndex === -1) {
            return;
          }

          const pnIndex = currentProject.areas[areaIndex].dis[
            disIndex
          ].pn.findIndex((item) => item.id === pnId);

          if (pnIndex === -1) {
            return;
          }

          const newArr = _.remove(
            currentProject.areas[areaIndex].dis[disIndex].pn[pnIndex]
              .questionnaires,
            (q) => q.id !== req.payload.id
          );

          currentProject.areas[areaIndex].dis[disIndex].pn[
            pnIndex
          ].questionnaires = newArr;
        }
      );
  },
});

export const { setCurrentPage, setExpandedKeys } = settingsSlice.actions;

export default settingsSlice.reducer;
