import { Box, Text, VStack, Avatar, Pressable, FlatList } from "native-base";
import React from "react";
import { flowScreens } from "../../../tasks/Tasks";
import { useDispatch } from "react-redux";
import { TASKS_MAIN_FLOW_SCREEN_STATUS } from "../../../../utils/utils";
import { setIsCurrent } from "../../../../features/tasksSlice";

const BULLET_BG_MAP = {
  [TASKS_MAIN_FLOW_SCREEN_STATUS.current]: "accent.40",
  [TASKS_MAIN_FLOW_SCREEN_STATUS.completed]: "success.30",
  [TASKS_MAIN_FLOW_SCREEN_STATUS.waiting]: "base.0",
};
const TEXT_BG_MAP = {
  [TASKS_MAIN_FLOW_SCREEN_STATUS.current]: "base.0",
  [TASKS_MAIN_FLOW_SCREEN_STATUS.completed]: "base.0",
  [TASKS_MAIN_FLOW_SCREEN_STATUS.waiting]: "neutral.60",
};
const BORDER_BG_MAP = {
  [TASKS_MAIN_FLOW_SCREEN_STATUS.current]: "base.0",
  [TASKS_MAIN_FLOW_SCREEN_STATUS.completed]: "base.0",
  [TASKS_MAIN_FLOW_SCREEN_STATUS.waiting]: "neutral.60",
};
const ITEM_HEIGHT = 130;
const ITEM_WIDTH = 150;

const FlowProgressTablet = ({ flowData, currentScreenNumber }) => {
  const flatListRef = React.useRef(null);

  React.useEffect(()=>{
    if(currentScreenNumber){
      flatListRef?.current?.scrollToIndex({index: currentScreenNumber > 6 ? 10 : 0})
    }
  },[currentScreenNumber])

  const dispatch = useDispatch();

  const renderItem = ({ item, index }) => {
    const status = flowData[item.name]?.status;
    const isCurrent = flowData[item.name]?.isCurrent;
    const goTo = () => {
      dispatch(
        setIsCurrent({
          step: item.name,
        })
      );
    };

    return (
      <Pressable
        borderWidth={1}
        borderColor="primary.70"
        key={item.name}
        borderRadius="md"
        onPress={goTo}
        style={{ height: ITEM_HEIGHT, width: 150 }}
      >
        <VStack
          h="full"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center"
          space={1}
          mt={5}
        >
          <Avatar
            bg={
              isCurrent
                ? BULLET_BG_MAP[TASKS_MAIN_FLOW_SCREEN_STATUS.current]
                : BULLET_BG_MAP[status]
            }
            borderWidth={1}
            borderColor={
              isCurrent
                ? BORDER_BG_MAP[TASKS_MAIN_FLOW_SCREEN_STATUS.current]
                : BORDER_BG_MAP[status]
            }
            // padding={"2px"}
            size={7}
            mx={2}
          >
            <Text
              fontSize="md"
              color={
                isCurrent
                  ? TEXT_BG_MAP[TASKS_MAIN_FLOW_SCREEN_STATUS.current]
                  : TEXT_BG_MAP[status]
              }
            >
              {index + 1}
            </Text>
          </Avatar>
          <Text p={1} textAlign="center">
            {item.shortTitle}
          </Text>
        </VStack>
      </Pressable>
    );
  };
  const ItemSeparatorComponent = () => <Box style={{ width: 10 }} />;

  return (

    <FlatList
      data={flowScreens}
      renderItem={renderItem}
      style={{ width: "95%" }}
      borderColor="neutral.50"
      height={150}
      ref={flatListRef}
      initialScrollIndex={currentScreenNumber > 7 ? 10 : 0}
      initialNumToRender={flowScreens.length}
      ItemSeparatorComponent={ItemSeparatorComponent}
      getItemLayout={(data, index) => ({
        length: ITEM_WIDTH + 6,
        offset: (ITEM_WIDTH + 7) * index,
        index,
      })}
      horizontal={true}
    />
  );
};

export default FlowProgressTablet;
