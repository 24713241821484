import { Text, Box } from "native-base";
import React from "react";
import ActionButtons from "./ActionButtons";
import { CELL_TYPES } from "../../utils/types";
import ViewReportButton from "./ViewReportButton";
import _ from "lodash";
import {
  CELL_CONTENT_TYPES,
  CELL_STYLE_TEMPLATES,
} from "../../components/table/tableRelatedTypes";
import { createTableColumnConfig } from "../../components/table/SheetV2";
const { user, customComponentShort, customComponent, longText } = CELL_TYPES;

const TitleWidget = (element) => (
  <Box p={3} alignContent="flex-start">
    <Text color="primary.90" fontWeight={400}>
      {_.truncate(element.title, { length: 70 })}
    </Text>
  </Box>
);

const TaskIdWidget = (element) => (
  <Text color="primary.60" underline={true}>
    {element.id}
  </Text>
);

const DetailsWidget = (element) => <ViewReportButton element={element} />;

const ActionButtonsWidget = (element) => (
  <Box h="full" alignItems="center" justifyContent="center">
    <ActionButtons element={element} />
  </Box>
);
const generateConfigList = (data) => {
  return data.map((columnConfig) => {
    return createTableColumnConfig(...columnConfig);
  });
};
const height = 70;
const longCellSize = {
  height,
  width: 320,
};
const defaultCellSize = {
  height,
  width: 200,
};
export const NOTIFICATIONS_TABLE_COLUMNS_CONFIG = generateConfigList([
  [
    "Title",
    "title",
    CELL_STYLE_TEMPLATES.head,
    CELL_CONTENT_TYPES.customComponent,
    CELL_STYLE_TEMPLATES.defaultLong,
    TitleWidget,
    { ...longCellSize, justifyContent: "flex-start", alignItems: "flex-start" },
  ],

  [
    "Regarding",
    "createdBy",
    CELL_STYLE_TEMPLATES.head,
    CELL_CONTENT_TYPES.user,
    CELL_STYLE_TEMPLATES.default,
    null,
    { ...longCellSize, justifyContent: "center", alignItems: "center" },
  ],
  [
    "Task ID",
    "task",
    CELL_STYLE_TEMPLATES.head,
    CELL_CONTENT_TYPES.customComponent,
    CELL_STYLE_TEMPLATES.default,
    TaskIdWidget,
    defaultCellSize,
  ],
  [
    "Details",
    "details",
    CELL_STYLE_TEMPLATES.head,
    CELL_CONTENT_TYPES.customComponent,
    CELL_STYLE_TEMPLATES.default,

    DetailsWidget,
    defaultCellSize,
  ],

  [
    "Actions",
    "action",
    CELL_STYLE_TEMPLATES.head,
    CELL_CONTENT_TYPES.customComponent,
    CELL_STYLE_TEMPLATES.default,
    ActionButtonsWidget,
    longCellSize,
  ],
]);

export const NOTIFICATIONS_TABLE_COLUMNS = [
  {
    title: "Title",
    name: "title",
    cellType: longText,
    customComponent: (element) => (
      <Box p={3} alignContent="center">
        <Text color="primary.90">
          {_.truncate(element.title, { length: 70 })}
        </Text>
      </Box>
    ),
  },
  { title: "Inspector", name: "createdBy", cellType: user },
  {
    title: "Project name",
    name: "task",
    cellType: customComponentShort,
    customComponent: (element) => {


      return (
        <Text color="primary.60" underline={false}>
          {element?.projectName || "N/A"}
        </Text>
      );
    },
  },

  {
    title: "Details",
    name: "details",
    cellType: customComponentShort,
    customComponent: (element) => <ViewReportButton element={element} />,
  },
  {
    title: "Actions",
    name: "action",
    cellType: customComponent,
    customComponent: (element) => (
      <Box h="full" alignItems="center" justifyContent="center">
        <ActionButtons element={element} />
      </Box>
    ),
  },
];
