import React from "react";
import { VStack, Button, Icon, Text, Box } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { initNewTask } from "../../../features/tasksSlice";
import {
  useNavigation,
  useNavigationState,
  useRoute,
} from "@react-navigation/native";
import FlowProgressDesktop from "../../../screens/tasks/main-flow/progress/FlowProgressDesktop";
import { DESKTOP_MENU } from "../../../../assets/constants/menuItems";
import {

  windowHeight,
  SIDE_MENU_WIDTH,
  SIDE_MENU_HEIGHT,
} from "../../../utils/utils";
var pjson = require("../../../../package.json");

function SideMenu() {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [display, setDisplay] = React.useState(false);
  const authSlice = useSelector((state) => state.auth);
  const accesss = authSlice.access;

  const mainFlowDisplayedIn = useNavigationState(
    (state) => state?.routes[0].name === "TasksFlow"
  );
  const route = useRoute();
  React.useEffect(() => {
    const update = () => {
      setDisplay(mainFlowDisplayedIn);
    };
    update();
  }, [mainFlowDisplayedIn]);
  const navigateTo = (path) => {
    if (!path) {
      console.warn("No path in slide menu while trying to navigate.");
      return;
    }
    if (path === "TasksFlow") {
      dispatch(initNewTask());
    }

    navigation.navigate(path);
  };

  const isAbleToCreateTasks =
    accesss?.collections?.tasks?.create?.permission === true;

  const isAbleToCreateUsers =
    accesss?.collections?.users?.create?.permission === true;

  return (
    <VStack h="full" maxHeight={windowHeight}>
      <VStack
        h="full"
        pt={3}
        style={{ width: SIDE_MENU_WIDTH, height: SIDE_MENU_HEIGHT }}
        bg="white"
        shadow="2"
        space={3}
        alignItems="flex-start"
        justifyContent="flex-start"
        justifyItems="center"
      >
        {DESKTOP_MENU.map((item, index) => {
          if (item.path === "TasksFlow" && !isAbleToCreateTasks) {
            return null;
          }

          if (item.path === "Users" && !isAbleToCreateUsers) {
            return null;
          }

          const isFirstRoute = route.name === item.path && index !== 0;

          const textColor =
            route.name === item.path && index !== 0
              ? "primary.60"
              : "primary.80";

          const styleEvents = {
            backgroundColor: "primary.30",
            tintColor: "primary.50",
            _text: {
              color: textColor,
            },
          };

          return (
            <Box w="full" key={item + index}>
              <Button
                w="full"
                h="10"
                _hover={styleEvents}
                _pressed={styleEvents}
                _focus={styleEvents}
                bg={isFirstRoute ? "primary.30" : "base.0"}
                px="3"
                py="2"
                onPress={() => {
                  navigateTo(item.path);
                }}
                startIcon={
                  <Icon color={textColor} size={14} mr={2} {...item} />
                }
                key={`desktop-menu-${index}`}
                alignItems="flex-start"
                justifyContent="flex-start"
                justifyItems="center"
                _text={{
                  fontFamily: "body",
                  fontWeight: "medium",
                  lineHeight: "sm",
                  color: textColor,
                  fontSize: 14,
                }}
              >
                {item.title}
              </Button>
              {display && item.childrens && (
                <Box pl={1}>
                  <FlowProgressDesktop  />
                </Box>
              )}
            </Box>
          );
        })}
      </VStack>
      <Box alignContent="flex-end">
        <Text ml="3" fontSize="sm" color="primary.100">
          App Version {pjson.version}
        </Text>
      </Box>
    </VStack>
  );
}

SideMenu.propTypes = {};

export default SideMenu;
