import { IconButton, HStack, Text, VStack, Badge, Box } from "native-base";
import React from "react";
import { su, useAcrossDevices } from "../../../utils/utils";
import {
  SimpleLineIcons,
  MaterialCommunityIcons,
  Ionicons,
  AntDesign,
} from "@expo/vector-icons";
const RESOLVE_TASK_MENU = [
  {
    name: "images",
    title: "Images",
    icon: "file-image-outline",
    as: MaterialCommunityIcons,
    renderBadge: true,
  },
  {
    name: "questionner",
    title: "ITP",
    icon: "file1",
    as: AntDesign,
    renderBadge: false,
  },
  {
    name: "photo",
    title: "Photo",
    icon: "camera-outline",
    as: Ionicons,
    renderBadge: true,
  },
  {
    name: "docs",
    title: "Docs",
    icon: "docs",
    as: SimpleLineIcons,
    renderBadge: false,
  },
  {
    name: "comment",
    title: "Comment",
    icon: "message1",
    as: AntDesign,
    renderBadge: true,
  },
];
const ResolveTaskBottomMenu = ({ onPress, data }) => {
  const { isMobile, isDesktop } = useAcrossDevices();
  const { areInputBtnsDisabled } = data;
  const MENU_DATA = isDesktop
    ? RESOLVE_TASK_MENU.filter(function (item) {
        return item.name !== "photo";
      })
    : RESOLVE_TASK_MENU;

  return (
    <HStack

      bg={isMobile ? "base.0" : "neutral.10"}
      style={{
        height: su(71, 89, 85),
        paddingLeft: su(0, 40, 120),
        paddingRight: su(0, 40, 120),
        paddingBottom: 5
      }}
      justifyItems="center"
      alignItems="center"
      space={[0, 2, 20]}
      justifyContent={
        isMobile ? "space-around" : !isDesktop ? "center" : "center"
      }
      w="full"
    >
      {MENU_DATA.map((item) => {

        return (
          <Box key={item.name} alignItems="center" justifyItems="center">
            <VStack
              style={{ height: su(71, 71, 85), width: 62}}
              justifyItems="center"
              alignItems="center"
              justifyContent="flex-end"
              mb={1}
            >
              {item.renderBadge && data[item.name] && (
                <Badge
             
                  bg="primary.100"
                  rounded="full"
                  mb={-2}
                  zIndex={1}
                  variant="solid"
                  alignSelf="flex-end"
                  alignItems="center"
                  alignContent="center"
                  textAlign="center"
                  justifyItems="center"
                  w="7"
                  h="7"
                  p={0}
                  justifyContent="center"
                  _text={{
                    fontSize:12,
                  }}
                >
                  <Text color="base.0">{data[item.name]}</Text>
                </Badge>
              )}
              <VStack style={{ height: 50 }} space={[0, 2]} alignItems="center">
                <IconButton
                  onPress={() => onPress(item.name)}
                  style={{ width: su(25, 32), height: su(25, 32) }}
                  key={item.name}
                  isDisabled={item.renderBadge ? areInputBtnsDisabled : false}
                  _icon={{
                    as: item.as,
                    name: item.icon,
                    size: su(6, 8),
                    color: "base.90",
                  }}
                  as={item.as}
                  name={item.icon}
                />
                <Text
                  lineHeight="sm"
                  letterSpacing="md"
                  fontSize={11}
                  color="base.90"
                >
                  {item.title}
                </Text>
              </VStack>
            </VStack>
          </Box>
        );
      })}
    </HStack>
  );
};

export default ResolveTaskBottomMenu;
