
export const REPORT_FIELDS = {
    subcontractor: "subcontractor",
    mov: "mov",
    projectName: "projectName",
    logo1: "logo1",
    logo2: "logo2",
    review: "review",
    images: "images",
  };
  