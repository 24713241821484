import React from "react";
import { NativeBaseProvider, extendTheme } from "native-base";
import { ActivityIndicator, Text, View } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  NavigationContainer,
  createNavigationContainerRef,
} from "@react-navigation/native";
import { Provider } from "react-redux";
import RootStackNavigator from "./navigation/RootStackNavigator";
import { colors } from "../assets/configs/colors";
import { fontConfig, familyFonts } from "../assets/configs/fontsConfig";
import { useAcrossDevices } from "./utils/utils";
import store from "./store";
import { persistorStore } from "./store";
import * as Linking from "expo-linking";
import { linking, PERSISTENCE_KEY } from "./navigation/navigationConfig";
import { useFonts } from "@expo-google-fonts/roboto";
import * as ScreenOrientation from "expo-screen-orientation";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { PersistGate } from "redux-persist/integration/react";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import * as SplashScreen from "expo-splash-screen";

SplashScreen.preventAutoHideAsync();

export const navigationRef = createNavigationContainerRef();
export function navigate(name, params) {
  if (navigationRef.isReady()) {
    // Perform navigation if the react navigation is ready to handle actions
    navigationRef.navigate(name, params);
  } else {
    // You can decide what to do if react navigation is not ready
    // You can ignore this, or add these actions to a queue you can call later
  }
}

function Main() {
  const theme = extendTheme({
    colors,
    components: {},
    ...fontConfig,
    breakpoints: {
      base: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  });
  const { isTablet } = useAcrossDevices();
  const [isReady, setIsReady] = React.useState(false);

  const [initialState, setInitialState] = React.useState();
  let [fontsLoaded] = useFonts(familyFonts);

  React.useEffect(() => {
    const restoreState = async () => {
      setTimeout(() => SplashScreen.hideAsync(), 2000);

      try {
        const initialUrl = await Linking.getInitialURL();

        if (initialUrl == null) {
          // Only restore state if there's no deep link and we're not on web
          const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
          const state = savedStateString
            ? JSON.parse(savedStateString)
            : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);
  React.useEffect(() => {
    async function changeScreenOrientation() {
      let support = await ScreenOrientation.supportsOrientationLockAsync(
        ScreenOrientation.OrientationLock.LANDSCAPE
      );
      if (support) {
        await ScreenOrientation.lockAsync(
          ScreenOrientation.OrientationLock.LANDSCAPE
        );
      }
    }
    if (isTablet) {
      changeScreenOrientation();
    }
  }, [isTablet]);

  if (!isReady || !fontsLoaded) {
    return (
      <View
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <ActivityIndicator />
        <View style={{ height: 5, width: "100%" }} />
        {!isReady && (
          <Text style={{ textAlign: "center", color: "#091E42" }}>
            Getting started...
          </Text>
        )}
        {!fontsLoaded && (
          <Text style={{ textAlign: "center", color: "#091E42" }}>
            Loading graphics...
          </Text>
        )}
      </View>
    );
  }
  const inset = {
    frame: { x: 0, y: 0, width: 0, height: 0 },
    insets: { top: 0, left: 0, right: 0, bottom: 0 },
  };
  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <Provider store={store}>
        <PersistGate
          loading={<Text>Loading...</Text>}
          persistor={persistorStore}
        >
          <SafeAreaProvider>
            <NativeBaseProvider initialWindowMetrics={inset} theme={theme}>
              <NavigationContainer
                linking={linking}
                ref={navigationRef}
                initialState={initialState}
                onStateChange={(state) =>
                  AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state))
                }
              >
                <RootStackNavigator />
              </NavigationContainer>
            </NativeBaseProvider>
          </SafeAreaProvider>
        </PersistGate>
      </Provider>
    </GestureHandlerRootView>
  );
}

export default Main;
