import React from "react";
import { Box, Text, HStack, Progress } from "native-base";
import { CELL_TYPES } from "../../utils/types";
import { colors } from "../../../assets/configs/colors";
import ActionBtsTasksTable from "./tasks-table-components/ActionBtsTasksTable";
import { TASKS_ACCENT_COLORS_BY_STATUS } from "../../components/table/tableRelatedTypes";
import { isArray } from "lodash";
import { TASKS_STATUS_TYPES } from "./tasksStatusTypes";
import { i18n, su, calculateTaskPercentage } from "../../utils/utils";
const {
  multipleItems,
  shortText,
  longText,
  date,
  user,
  customComponent,
  customComponentShort,
} = CELL_TYPES;

const reportsTextColorsMap = {
  pending: colors.table[60],
  rejected: colors.table[90],
  approved: colors.table[30],
};
const SNTA = "screens.tasks.tableActions";
const SNTC = "screens.tasks.tableColumnsNames";

export const TASKS_TABLE_COLUMNS = [
  { title: i18n.t(`${SNTC}.project`), name: "project", cellType: shortText },
  { title: i18n.t(`${SNTC}.area`), name: "area", cellType: longText },
  { title: i18n.t(`${SNTC}.dis`), name: "dis", cellType: longText },
  { title: i18n.t(`${SNTC}.pn`), name: "pn", cellType: multipleItems },
  {
    title: i18n.t(`${SNTC}.questionnaire`),
    name: "questionner",
    cellType: longText,
  },
  { title: i18n.t(`${SNTC}.activity`), name: "activity", cellType: longText },
  {
    title: i18n.t(`${SNTC}.plannedStartDate`),
    name: "plannedStartDate",
    cellType: date,
  },
  {
    title: i18n.t(`${SNTC}.actualStartDate`),
    name: "actualStartDate",
    cellType: date,
  },
  {
    title: i18n.t(`${SNTC}.plannedEndDate`),
    name: "plannedEndDate",
    cellType: date,
  },
  {
    title: i18n.t(`${SNTC}.actualEndDate`),
    name: "actualEndDate",
    cellType: date,
  },
  { title: i18n.t(`${SNTC}.assignTo`), name: "assignTo", cellType: user },
  {
    title: i18n.t(`${SNTC}.status`),
    name: "status",
    cellType: customComponentShort,
    customComponent: (element, height) => {
      const taskPercentage = calculateTaskPercentage(element);
      return (
        <HStack alignItems="center" style={{ height }}>
          <Box
            justifyContent="center"
            style={{ width: 120, height: 10 }}
            maxW="400"
          >
            <Progress
              _filledTrack={{
                bg: TASKS_ACCENT_COLORS_BY_STATUS[element?.status],
              }}
              bg="base.0"
              value={taskPercentage}
              mx="4"
            />
          </Box>

          <Text>{taskPercentage}</Text>
        </HStack>
      );
    },
  },
  {
    title: i18n.t(`${SNTC}.error`),
    name: "error",
    cellType: customComponentShort,
    customComponent: (element, height) => {
      const latestReport =
        element?.errorReports?.[element?.errorReports.length - 1];

      return (
        <Box justifyContent="center" alignContent="flex-end" style={{ height }}>
          <Text fontSize="md" color="base.90" fontWeight="medium">
            {latestReport?.description || "N/A"}
          </Text>
        </Box>
      );
    },
  },
  {
    title: i18n.t(`${SNTC}.errorStatus`),
    name: "errorReport",
    cellType: customComponentShort,
    customComponent: (element, height) => {
      const latestReport =
        element?.errorReports?.[element?.errorReports.length - 1];
      const color =
        reportsTextColorsMap?.[latestReport?.status] || "primary.100";
      const text = latestReport?.status || "N/A";

      return (
        <Box justifyContent="center" alignContent="center" style={{ height }}>
          <Text
            textAlign="center"
            fontWeight="medium"
            color={color}
            fontSize="lg"
          >
            {text.toUpperCase()}
          </Text>
        </Box>
      );
    },
  },
  {
    title: i18n.t(`${SNTC}.actionRequired`),
    name: "actionRequired",
    cellType: customComponentShort,
    customComponent: (element, height) => {
      const getActionName = () => {
        if (
          element?.errorReports &&
          isArray(element?.errorReports) &&
          element?.errorReports.length > 0
        ) {
          if (
            element?.errorReports[element?.errorReports.length - 1].status ===
            "pending"
          ) {
            return i18n.t(`${SNTA}.errorReportSubmitted`);
          }
        }

        if (
          element?.questionsSolvedWithError?.length > 1 ||
          element?.blockedPn?.length > 1
        ) {
          return i18n.t(`${SNTA}.submitErrorReport`);
        }

        if (element?.userCanSubmitFinalReport === true) {
          if (element?.finalReport?.status === "pending") {
            return i18n.t(`${SNTA}.finalReportSubmitted`);
          }
          return i18n.t(`${SNTA}.submitFinalReport`);
        }
        return i18n.t(`${SNTA}.resolveTask`);
      };
      const textMap = {
        [TASKS_STATUS_TYPES.unacknowledged]: i18n.t(`${SNTA}.acknowledgeTask`),
        [TASKS_STATUS_TYPES.confirmationRequired]: getActionName(),
      };

      const text = textMap[element?.status] || "";

      return (
        <Box justifyContent="center" alignContent="flex-end" style={{ height }}>
          <Text fontSize="md" color="accent.40" underline>
            {text}
          </Text>
        </Box>
      );
    },
  },

  {
    title: i18n.t(`${SNTC}.reportStatus`),
    name: "finalReport",
    cellType: customComponentShort,
    customComponent: (element, height) => {
      const color =
        reportsTextColorsMap?.[element?.finalReport?.status] || "primary.100";
      const text = element?.finalReport?.status || "N/A";

      return (
        <Box
          style={{
            minHeight: su(69, 69, 56),
            maxHeight: su(172, 172, 209),
            height: height,
          }}
          alignContent="center"
          justifyContent="center"
        >
          <Text
            textAlign="center"
            fontWeight="medium"
            color={color}
            fontSize="lg"
          >
            {text.toUpperCase()}
          </Text>
        </Box>
      );
    },
  },
  {
    title: i18n.t(`${SNTC}.actions`),
    name: "actions2",
    cellType: customComponent,
    customComponent: (element, height) =>
      React.createElement(ActionBtsTasksTable, {
        element: element,
        height: height,
      }),
  },
];
