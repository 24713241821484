import React from "react";
import Select from "antd/lib/select";
import _ from "lodash";
const { Option } = Select;

const SelectMultifunctional = ({
  multi = false,
  disabled,
  parentKeys,
  defaultValue = null,
  dataSource = [],
  onPress,
  extraData,
  attribute = "name",
  keyAttribute = "id",
}) => {

  const [selected, setSelected] = React.useState(multi ? [] : null);
  //force component to rerender
  if (!dataSource || !_.isArray(dataSource)) {
    console.warn("No data source in SelectMultifunctional. ");
    return null;
  }

  const getDefaultValue = (defaultValue) => {
    if (disabled) {
      if (!multi) {

        if (_.isArray(selected)) {
          return selected[0].id === defaultValue.id;
        }
        if (_.isObject(defaultValue[0])) {
          return defaultValue[0][attribute];
        }
        if (_.isString(defaultValue)) {
          return defaultValue;
        }
        return defaultValue[0];
      }

      if (_.isArray(defaultValue[0])) {
        return defaultValue[0].map((item) => item[attribute]);
      } else {
        return defaultValue.map((item) => item[attribute]);
      }
    } else {
      if (!multi) {
        if (_.isArray(defaultValue) && defaultValue.length > 0) {
          return defaultValue[0][attribute];
        }

        if (_.isObject(defaultValue)) {
          return defaultValue[attribute];
        }

        return "";
      } else {
        if (_.isArray(defaultValue)) {
          return defaultValue.map((item) => { 
            return {"label": item[attribute], "value": item?.id}
        });
        }
        return undefined;
      }
    }
  };


  const setValueDesktop = (val) => {
    console.log("🚀 ~ file: SelectMultifunctional.js:75 ~ setValueDesktop ~ val:", val);
    if (!multi) {
      const selectData = dataSource.find((item) => item[keyAttribute] === val);

      if (selectData) {
        setSelected(selectData[attribute]);
        onPress(selectData, parentKeys, extraData, val);
      }
    } else {
      setSelected(val);
      // const selectedData = _.filter(dataSource, function (item) {
      //   return val.includes(item[keyAttribute]);
      // });
  
      // if (selectedData) {
      //   onPress(selectedData, parentKeys, extraData);
      // }
      // onPress(val, parentKeys, extraData, val);
    }
  };


  const onMultiSelect = () => {
    const multiFunctionalOriginalValues = defaultValue || [];
    const values = [...selected, ...multiFunctionalOriginalValues];
    const selectedData = _.filter(dataSource, function (item) {
      return values.includes(item[keyAttribute]);
    });

    if (selectedData) {
      onPress(selectedData, parentKeys, extraData);
    }

    return values;
  };

  const defValue = getDefaultValue(defaultValue);

  const onDeselect = (value) => {

    if(!defaultValue){
      return;
    }

    const selectedIds = defaultValue.filter(item=> item?.id !== value).map(item=>item?.id);
    const selectedData = _.filter(dataSource, function (item) {
      return selectedIds.includes(item[keyAttribute]);
    });
    setSelected(selectedIds)
    onPress(selectedData, parentKeys, extraData);
  }

  return (
    <Select
      value={selected&&selected.length>0? selected:defValue}
      mode={multi ? "multiple" : "single"}
      style={{
        width: 400,
        height: 35,
      }}
      showSearch={true}
      onChange={setValueDesktop}
      onDeselect={onDeselect}
      onBlur={multi ? onMultiSelect : null}
      disabled={disabled}
    >
      {dataSource.map((item, index) => {
        const keyId = item?.id || item?.key;
        const key = disabled ? `option-${index}` : keyId;
        const value = disabled ? item : item[attribute];

        return (
          <Option key={key} value={key}>
            {value}
          </Option>
        );
      })}
    </Select>
  );
};

export default SelectMultifunctional;