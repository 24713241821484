import { IconButton, Button,Icon } from "native-base";
import { useNavigation } from "@react-navigation/native";
import { TASKS_FLOW_MODES } from "../../utils/utils";
import { setMode } from "../../features/tasksSlice";
import React from "react";
import { useDispatch } from "react-redux";
import {  api } from "../../services/apiService";
const IconMenuBtn = ({
  path,
  as,
  name,
  icon = null,
  color = "white",
  size = 6,
  _onPress,
  isLoading,
}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const navigateTo = () => {
    if (!path) {
      if (_onPress) {
        _onPress();
      }
      return;
    }
    if (path === "TasksFlow") {
      dispatch(setMode(TASKS_FLOW_MODES.newTaskMode));
    }
    navigation.navigate(path);
    dispatch(
      api.util.invalidateTags([
        "NotificationsCount",
      ])
    );
    if(path === "Notifications"){

      dispatch(api.util.invalidateTags([{type: "Notifications", page: 1}]));
    }
  };

  if (icon !== null) {
    return (
      <IconButton
        isLoading={isLoading}
        onPress={navigateTo}
        size={size || 4}
        icon={icon}
      />
    );
  }

  return (
    <Button       onPress={navigateTo} isLoading={isLoading}>
      <Icon       size={size || 4} as={as} name={name} color={color} />
    </Button>
  );
};

export default IconMenuBtn;
