import { create } from "react-native-pixel-perfect";
import { Dimensions, Platform } from "react-native";
import { Toast } from "native-base";
import * as Crypto from "expo-crypto";
import {
  designResolutionDesktop,
  designResolutionTablet,
  designResolutionMobile,
  useIsTablet as ouseIsTablet,
  useAcrossDevices as ouseAcrossDevices,
  useIsDesktop as ouseIsDesktop,
  useIsMobile as ouseIsMobile,
  Tablet as oTablet,
  Desktop as oDesktop,
  Mobile as oMobile,
  NativeDevices as oNativeDevices,
} from "../containers/DeviceTypeContainers";
import { Strings as oStrings } from "../../assets/constants/strings";
import { I18n } from "i18n-js";
import { RowCardMobileVariants } from "../components/table/rows/rowCardMobileVariants";
import _ from "lodash";
import * as ImagePicker from "expo-image-picker";
import { createAction } from "@reduxjs/toolkit";
import { TASKS_STATUS_TYPES } from "../screens/tasks/tasksStatusTypes";

export let areTranslationsAvailable = false;
export let i18n = new I18n(oStrings);

i18n.enableFallback = true;
i18n.locale = "en";
export const t = i18n.t;

export const revertAll = createAction("REVERT_ALL");
// Set the locale once at the beginning of your app.
// When a value is missing from a language it'll fallback to another language with the key present.

export const ROW_CARD_MOBILE_VARIANTS = RowCardMobileVariants;
export const TASKS_MAIN_FLOW_SCREEN_STATUS = {
  waiting: "waiting",
  completed: "completed",
  current: "current",
};
export const FLOW_STATUS_MODES = {
  newTask: "newTask",
  report: "report",
  resolveTask: "resolveTask",
  acknowledge: "acknowledge",
  mustSubmitErroReport: "mustSubmitErroReport",
};

export const mobileMediaQuery = { maxWidth: 767 };
export const tabletMediaQuery = { minWidth: 768 };
export const desktopMediaQuery = { minWidth: 1024 };

//this size is the size that your design is made for (screen size)
export const px2dpd = create(designResolutionDesktop);
export const px2dpt = create(designResolutionTablet);
export const px2dpm = create(designResolutionMobile);
export const windowWidth = Dimensions.get("window").width;
export const windowHeight = Dimensions.get("window").height;
export const isLandscape = windowWidth >= windowHeight;
export const deviceIsTablet = windowWidth <= 1366 && Platform.OS !== "web";
/**
 * Returns true of the screen is in landscape mode
 */

export const su = (sm, md = sm, lg = sm) => {
  const dim = Dimensions.get("screen");
  const isLandscape = dim.width >= dim.height;

  let isDesktopV = false;

  let defaultValue = null;

  if (windowWidth <= mobileMediaQuery.maxWidth) {
    defaultValue = sm;
  } else if (windowWidth >= tabletMediaQuery.minWidth) {
    defaultValue = md;
  }

  if (windowWidth > desktopMediaQuery.minWidth) {
    isDesktopV = lg;
    defaultValue = lg;
  }

  // if (isPortrait && isDesktopV) {
  //   defaultValue = md;
  // }

  if (Platform.OS !== "web" && isDesktopV && isLandscape) {
    defaultValue = md;
  }

  return defaultValue;
};

export function capitalizeFirstLetter(str) {
  if (!str) {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const addFilesToFormData = () => {};

export const KEY_MEMBER_TYPES = {
  manager: "manager",
  teamLeader: "teamLeader",
  others: "keyPeople",
};

export const TASKS_FLOW_MODES = {
  newTaskMode: "newTaskMode",
  acknowledgeMode: "acknowledgeMode",
};

export const Strings = oStrings;
export const useIsTablet = ouseIsTablet;
export const useAcrossDevices = ouseAcrossDevices;
export const useIsDesktop = ouseIsDesktop;
export const useIsMobile = ouseIsMobile;
export const Tablet = oTablet;
export const Desktop = oDesktop;
export const Mobile = oMobile;
export const NativeDevices = oNativeDevices;

export const USER_MODAL_MODES = {
  newUser: "newUser",
  editUser: "editUser",
  viewAccount: "viewAccount",
};
export const MODAL_CONTENT_TYPES = {
  questionner: "questionner",
  reportError: "reportError",
  finalReport: "finalReport",
  locationHistory: "locationHistory",
  editUserModal: "editUserModal",
  editTask: "editTask",
  viewAccountModal: "viewAccountModal",
  docs: "docs",
  resultsTable: "resultsTable",
  images: "images",
  viewMediaCollection: "viewMediaCollection",
  newUserModal: "newUserModal",
  changePassword: "changePassword",
  configure2fa: "configure2fa",
  settingsElementActions: "settingsElementActions",
  warningIrreversibleProcess: "warningIrreversibleProcess",
  comment: "comment",
};
export const ASSETS_TYPES = {
  user: "user",
  task: "task",
  questionner: "questionner",
  finalReport: "finalReport",
  errorReport: "errorReport",
  formA: "formA",
  formB: "formB",
  otherDocs: "otherDocs",
};

export const TREE_LEVELS = {
  projects: "projects",
  areas: "areas",
  dis: "dis",
  pn: "pn",
  questionnaires: "questionnaires",
  otherDocs: "otherDocs",
  keyPeoples: "keyPeoples",
  formA: "formA",
  formB: "formB",
  default: "default",
};

export const PROJECTS_CHILDRENS_PARENT_ID_NAME = {
  [TREE_LEVELS.areas]: "parentProjectId",
  [TREE_LEVELS.dis]: "parentAreaId",
  [TREE_LEVELS.pn]: "parentDisId",
  [TREE_LEVELS.questionnaires]: "parent",
};

export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_FORMAT_WITH_TIME = "MM/DD/YYYY HH:mm:ss";

export const RESOLVE_ANSWERS_TYPES = {
  yes: "yes",
  no: "no",
  na: "na",
  waiting: "waiting",
};
export const INPUT_FIELDS_ERROR_REPORT = {
  description: "description",
  additional: "additional",
  images: "images",
  reference: "reference",
  errorCode: "errorCode",
};
export const MAIN_FLOW_STEPS = {
  project: "project",
  area: "area",
  dis: "dis",
  pn: "pn",
  questionner: "questionner",
  startEndDate: "startEndDate",
  formA: "formA",
  formB: "formB",
  docs: "docs",
  status: "status",
  assignTo: "assignTo",
  submissionDate: "submissionDate",
  activity: "activity",
};

export const ANSWER_DATA_ATTR_NAMES = {
  comment: "comment",
  answer: "answer",
  image: "image",
  errorReport: "errorReport",
};

export const RESOLVE_TASK_FLOW_MODES = {
  question: "default",
  input: "input",
};

export const getExtensionFromFilename = (filename) => {
  if (!filename) {
    return "";
  }
  const lastPoint = filename?.lastIndexOf(".");
  return filename.substring(lastPoint + 1, filename.length);
};

export const formatDocAssetsListForDisplay = (data, type) => {
  if (!data || !_.isArray(data) || data.length === 0) {
    return [];
  }

  return data.map((item) => {
    let r = {};
    let itemC = Object.assign({}, item);

    const filename = itemC.filename;

    if (!filename) {
      return [];
    }

    const lastPoint = filename?.lastIndexOf(".");

    r.type = type;
    r.uri =
      item?.pdfVersionUrl && item?.pdfVersionUrl !== ""
        ? item?.pdfVersionUrl
        : item.url;
    r.filename = filename;
    r.ext = filename.substring(lastPoint + 1, filename.length);
    r.id = itemC.id;

    return r;
  });
};

export const extractImageInfoFromUri = (uri) => {
  if (!uri) {
    return null;
  }
  let mimetype = "image";
  let ext = "png";
  if (uri.substring(0, 4) === "data") {
    const separator = uri.indexOf(";");
    mimetype = uri.substring(5, separator);
    ext = uri.substring(11, separator);
  } else {
    const map = { iVB: "png", "/9j": "jpg" };
    if (map[uri.substring(0, 3)]) {
      ext = map[uri.substring(0, 3)];
    }
  }
  return { mimetype, ext };
};
export function dataUrItoBlob(dataUri) {
  var binary = window.atob(dataUri.split(",")[1]);
  var mimeString = dataUri.split(",")[0].split(":")[1].split(";")[0];
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: mimeString });
}
export const generateUid = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const prepareImagesForUpload = (files) => {
  if (!files || !_.isArray(files) || files.length === 0) {
    return [];
  }

  const results = [];
  for (let i = 0; i < files.length; i++) {
    const fileUri = files[i]?.uri;
    if (!fileUri) {
      break;
    }
    let oFileBase64 = fileUri?.startsWith("file:///")
      ? files[i]?.base64
      : fileUri;
    let fileBase64 = oFileBase64;

    const map = { iVB: "png", "/9j": "jpg" };
    let fileExt = map[fileBase64.substring(0, 3)];
    if (fileBase64.substring(0, 4) !== "data") {
      fileBase64 = `data:image/${fileExt};base64,` + oFileBase64;
    }

    const { mimetype, ext } = extractImageInfoFromUri(fileBase64);
    const uniqueFilename = Crypto.randomUUID();
    const filename = `${uniqueFilename}.${ext}`;

    if (files && filename && fileBase64) {
      if (Platform.OS === "web") {
        results.push([dataUrItoBlob(fileBase64), filename]);
      } else {
        results.push([
          {
            name: filename, // or whatever that URI's file name is
            uri: fileBase64,
            type: mimetype,
          },
        ]);
      }
    }
  }

  return results;
};

export const REPORT_ERROR_MODES = {
  view: "VIEW",
  create: "CREATE",
};

export const calculateResultPercentage = (
  value,
  targetValue,
  deviationPercentage
) => {
  if (_.isNaN(value)) {
    return TABLE_RESULTS_TYPES.unSat;
  }
  const deviationValue = (deviationPercentage / 100) * targetValue;
  const maximumTargetValue = targetValue + deviationValue;

  const minTargetValue = targetValue - deviationValue;

  if (value >= minTargetValue && value <= maximumTargetValue) {
    return TABLE_RESULTS_TYPES.sat;
  }
  return TABLE_RESULTS_TYPES.unSat;
};

export const calculateResultInterval = (value, start, end) => {
  if (value >= start && value <= end) {
    return TABLE_RESULTS_TYPES.sat;
  }
  return TABLE_RESULTS_TYPES.unSat;
};

export const calculateResultForResultsTable = (
  userInput,
  firstNumber,
  secondNumber,
  sign
) => {
  const resultNumber = calculateResultPercentage(
    Number(userInput),
    Number(firstNumber),
    Number(secondNumber)
  );
  const resultInterval = calculateResultInterval(
    Number(userInput),
    Number(firstNumber),
    Number(secondNumber)
  );

  const result = sign !== "to" ? resultNumber : resultInterval;

  return result;
};

export const DOCS_PICKER_MODES = {
  display: "display",
  select: "select",
  pick: "pick",
};

export const pickImages = async (permissionStatus, requestPermission) => {
  // No permissions request is necessary for launching the image library
  if (permissionStatus === "denied") {
    requestPermission();
  }
  let result = null;
  try {
    result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsMultipleSelection: true,
      accessPrivileges: true,
      base64: true,
    });
  } catch (e) {
    Toast.show({ closeAll: true, description: i18n.t("errors.uploadImages") });
    return null;
  }
  if (result === null) {
    Toast.show({ closeAll: true, description: i18n.t("errors.uploadImages") });
    return null;
  }
  if (!result?.canceled) {
    const assets = result.assets.map((item) => {
      item.id = Platform.OS === "web" ? _.uniqueId("image") : item.assetId;
      return item;
    });

    return assets;
  }
  return null;
};

export const readFiles = async (filesData) => {
  const promises = Promise.all(
    Object.keys(filesData).map(
      (_key, i) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          const file = filesData[i];

          reader.onload = () => {
            resolve({
              // uri: new Uint8Array(reader.result),
              uri: reader.result,
              name: file.name,
              size: file.size,
              id: generateUid(),
            });
          };
          reader.readAsDataURL(file);
        })
    )
  );
  let files = null;
  try {
    files = await promises;
  } catch (e) {
    Toast.show("Something went wrong while reading the file/s.");
  }

  if (files !== null) {
    return files;
  }
  return null;
};

export const TABLE_RESULTS_TYPES = {
  sat: "sat",
  unSat: "unSat",
};

export const getTableResultsDataSource = (question, selectedPN) => {
  if (!question || !question.answers || !_.isArray(question.answers)) {
    return null;
  }
  const result = _.cloneDeep(question);
  result.answers = question.answers.filter((item) =>
    selectedPN.includes(item.pn?.id)
  );
  return result;
};

export const displayServerErrors = (response) => {
  if (response?.error?.data?.errors) {
    response?.error?.data?.errors.forEach((err) => {
      Toast.show({
        closeAll: true,
        description: "Request failed. " + err.message,
        duration: 8000,
      });
    });
    return true;
  }

  if (response?.error) {
    if (response?.error?.data?.message) {
      Toast.show({
        closeAll: true,
        description: response.error.data.message,
        duration: 8000,
      });
      return true;
    }

    if (_.isString(response?.error)) {
      Toast.show({
        closeAll: true,
        description:
          i18n.t(`errorsMsg.generalError`) + `Code:${response?.error}`,
        duration: 5000,
      });
      return true;
    }
    Toast.show({
      closeAll: true,
      description: i18n.t(`errorsMsg.generalError`),
      duration: 5000,
    });
    return true;
  }
  return false;
};

export const getUserFullName = (user = {}) => {
  const firstName = user?.firstName || "";
  const lastName = user?.lastName || "";
  return `${firstName} ${lastName}`;
};

export const EMPTY_MODE_TYPES = {
  default: "default",
  selectFile: "selectFile",
};
function isIphoneLandscape() {
  const dimen = Dimensions.get("window");
  return (
    Platform.OS === "ios" &&
    !Platform.isPad &&
    !Platform.isTV &&
    dimen.width >= dimen.height
  );
}
function isIphoneXorAbove() {
  const dimen = Dimensions.get("window");
  return (
    Platform.OS === "ios" &&
    !Platform.isPad &&
    !Platform.isTV &&
    (dimen.height === 812 ||
      dimen.width === 812 ||
      dimen.height === 896 ||
      dimen.width === 896 ||
      dimen.height === 844 ||
      dimen.width === 390)
  );
}
export const getBottomMenuHeight = (inset) => {
  //   59 - iPhone 14 Pro / 14Pro Max
  // 50 - iPhone 13 mini
  // 47 - iPhone 12 / 12Pro / 13 / 13Pro / 13Pro Max / 14 / 14 Plus
  // 44 - on iPhoneX
  // 20 - on iOS device
  if (inset === 47 || inset === 59 || inset === 44) {
    return 90;
  }
  if (isIphoneXorAbove()) {
    return 90;
  } else {
    return isIphoneLandscape() ? 80 : 60;
  }
};

export const addNewUniqueItemsToList = (newList, oldList) => {
  const elementsInBothLists = [];
  const newOrUpdatedDocumentsList = [];

  newList.forEach((item) => {
    const elIdInOldList = oldList.findIndex((task) => task.id === item.id);
    if (elIdInOldList !== -1) {
      const elFromOldList = oldList[elIdInOldList];
      if (elFromOldList?.updatedAt !== item?.updatedAt) {
        elementsInBothLists.push({ ...item, [TREE_LEVELS.areas]: [] });
        oldList.splice(elIdInOldList, 1);
      }
    } else {
      newOrUpdatedDocumentsList.push({ ...item, [TREE_LEVELS.areas]: [] });
    }
  });

  return [...elementsInBothLists, ...newOrUpdatedDocumentsList];
};

export function blobToBase64(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const sortListAlphabeticallyByTag = (list, attribute = "title") => {
  //check if is array, if not return empty array
  if (!_.isArray(list)) {
    return [];
  }
  return list.sort((a, b) => sortAlphabeticallyByAttribute(a, b, attribute));
};

export const fetchImage = async (url, field, token, callback) => {
  fetch(url, {
    method: "GET",
    headers: { Authorization: `JWT ${token}` },
  })
    .then((response) => response.blob())
    .then(async (blob) => {
      const newImg = await blobToBase64(blob);
      callback(field, newImg);
    })
    .catch(() => {
      callback(field, null);
    });
};

const sortAlphabeticallyByAttribute = (a, b, attribute) => {
  //check if a and b are objects, if not return 0
  if (!_.isObject(a) || !_.isObject(b)) {
    return 0;
  }

  // check if a and b have a title property and if they are strings, if not return 0
  if (!_.isString(a?.[attribute]) || !_.isString(b?.[attribute])) {
    return 0;
  }

  // transform the title to lowercase and then compare them
  const wordA = a?.[attribute].toLowerCase();
  const wordB = b?.[attribute].toLowerCase();

  if (wordA < wordB) {
    return -1;
  } else if (wordA > wordB) {
    return 1;
  } else {
    return 0;
  }
};

/**
 * returns originalArgs from the request object passed
 * after checking their types
 * or if conditions aren't met null
 */

export const checkRequestPayload = (req, checkOnlyDoc = false) => {
  if (
    !req?.payload ||
    !_.isObject(req?.payload) ||
    !req?.meta?.arg?.originalArgs
  ) {
    return null;
  }

  if (checkOnlyDoc === null) {
    return req?.meta?.arg?.originalArgs;
  }
  const checkDocs = !_.isArray(req?.payload.docs);

  const checkDoc = !_.isObject(req?.payload.doc);

  const docVerificationFailed = checkOnlyDoc === true ? checkDoc : checkDocs;

  if (docVerificationFailed) {
    return null;
  }

  return req?.meta?.arg?.originalArgs;
};
export const getProjectIndexFromState = (state, projectId) => {
  if (!_.isString(projectId) || !state.projects[state.currentPage]) {
    return null;
  }

  const currentProjectIndex = state.projects[state.currentPage].findIndex(
    (item) => item.id === projectId
  );

  if (currentProjectIndex !== -1) {
    return currentProjectIndex;
  }
  return null;
};

export const SUBHEADER_HEIGHT = 100;
export const SIDE_MENU_WIDTH = 207;
export const SIDE_MENU_HEIGHT = windowHeight - 100;
export const HEADER_HEIGHT = su(68, 80, 55, true);
export const CONTENT_CONTAINER_HEIGHT =
  windowHeight - (HEADER_HEIGHT + SUBHEADER_HEIGHT) - 70;
export function areEqual(prevProps, nextProps) {
  const isEqual = prevProps?.screenName !== nextProps?.screenName;

  return isEqual;
}

export const calculateTaskPercentage = (task) => {
  if (task.status === TASKS_STATUS_TYPES.complete) {
    return 100;
  }
  if (task.status === TASKS_STATUS_TYPES.unacknowledged) {
    return 10;
  }

  const allQuestionsSolvedWithoutFinalReport =
    task.userCanSubmitFinalReport === true && !task?.finalReport;
  const allQuestionsSolvedWithFinalReport =
    task.userCanSubmitFinalReport === true && task?.finalReport;

  //L2 Complete answering all questions
  if (allQuestionsSolvedWithoutFinalReport) {
    return 70;
  }
  //Submit the report
  if (allQuestionsSolvedWithFinalReport) {
    return 80;
  }


  //should return 40 if user submit error report
  if (
    task.errorReports &&
    task.errorReports.length > 0 &&
    task.blockedPn.length > 0
  ) {
    const unsolvedErrorReport = task.errorReports.filter(
      (report) => report.status !== "complete"
    );

    if (unsolvedErrorReport.length > 0) {
      return 40;
    }
  }

  //should return 50 if error report is approved
  if (
    task.blockedPn &&
    task.blockedPn.length === 0 &&
    task.errorReports &&
    task.errorReports.length > 0
  ) {
    return 50;
  }

  if (
    task.status === TASKS_STATUS_TYPES.confirmationRequired ||
    task.status === TASKS_STATUS_TYPES.incomplete
  ) {
    if (task.userStartedSolvingQuestions) {
      return 30;
    }
    return 20;
  }
};
export function concatArraysByIdAndMergeMostRecent(arr1, arr2) {
  // Merge objects based on id and keep the one with the most recent updatedAt date
  const mergedObjects = _.mergeWith(
    _.keyBy(arr1, "id"),
    _.keyBy(arr2, "id"),
    (obj1, obj2) => {
      if (obj1 && obj2) {
        return new Date(obj1.createdAt) < new Date(obj2.createdAt)
          ? obj1
          : obj2;
      } else {
        return obj1 || obj2;
      }
    }
  );

  // Convert the merged object back to an array
  const mergedArray = _.values(mergedObjects);

  return mergedArray;
}

export const  openCamera = async (callback) => {
  if(!callback){
    console.warn('No callback for Open Camera');
    return;
  }
  // Ask the user for the permission to access the camera
  const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
  if (permissionResult?.granted === false) {
    Toast.show({
      closeAll: true,
      description: i18n.t(`utils.toasts.cameraDenied`),
    });
    return;
  }

  let result = false;
  try {
    result = await ImagePicker.launchCameraAsync({
      base64: true,
    });
  } catch (e) {
    Toast.show({
      closeAll: true,
      description: i18n.t(`utils.toasts.cameraError`),
    });
  }
  if (result?.canceled === true) {
    if (
      !result?.assets ||
      !_.isArray(result.assets) ||
      result.assets.length < 1
    ) {
      Toast.show({
        closeAll: true,
        description: i18n.t(`utils.toasts.noPhoto`),
      });
      return;
    }
  }

  let files = result.assets;
  if (Platform.OS === "android") {
    files = result.assets.map((item) => {
      if (item?.uri) {
        const lastPoint = item.uri.lastIndexOf(".");
        let ext = item.uri.substring(lastPoint + 1, item?.uri.length);
        const uniqueFilename = _.uniqueId("PhotoTaken");
        const newItem = {
          fileName: `${uniqueFilename}.${ext}`,
          uri: `data:image/${ext};base64, ${item?.base64}`,
        };
        return newItem;
      }
    });
  }

  callback(files);
};

export const formatSelectForUploadImagesListForDisplay = (data) => {
  if (!data) {
    return [];
  }
  return data.map((item, index) => {
    let r = {};
    let itemC = Object.assign({}, item);

    const filename = Platform.OS === "web" ? "file" + index : itemC.fileName;

    r.type = "image";
    r.uri = `data:image/jpg;base64,${itemC.base64}`;
    r.filename = filename;
    r.ext = "jpg";
    r.id = itemC.id;
    r.key =  Crypto.randomUUID();

    return r;
  });
};

export const getImagesOfAnswers = (answers) => {

  
  let images = [];
  if (answers && _.isArray(answers) && answers.length > 0) {
    for (const answer of answers) {
      if (
        answer?.images &&
        _.isArray(answer?.images) &&
        answer?.images.length > 0
      ) {
        images = [...answer?.images, ...images];
      }
    }
  }
  return images;
};
export const MAX_CHARACTER_LENGTH_COLLAPSED = 90;
export const MAX_CHARACTER_LENGTH_EXPANDED = 278;
export const formatAndTruncateText = (value,isExpanded) => {
  if (!value) {
    return "";
  }
  if (_.isString(value)) {
    return _.truncate(value, {
      length:isExpanded?MAX_CHARACTER_LENGTH_EXPANDED:  MAX_CHARACTER_LENGTH_COLLAPSED,
    });
  }
  if (_.isNumber(value)) {
    return _.toString(value.toFixed(2));
  }
  return "";
};