import { Center, Text,Icon, Divider } from 'native-base'
import React from 'react'
import { i18n } from '../utils/utils'
import { AntDesign } from "@expo/vector-icons";
const NotFound = () => {
  return (
    <Center h="full" w="full">
      <Icon as={AntDesign} size="6xl" name="warning" color="accent.100"/>
      <Divider mt={4}/>
      <Text p={5} color="primary.100" fontSize="md">{i18n.t('components.notFound.message')}</Text>
    </Center>
  )
}

export default NotFound