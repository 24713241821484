
import { VStack, Text, TextArea } from "native-base";
import React from "react";
import { su, i18n, windowWidth } from "../utils/utils";
const AddComment = ({ comment, setComment }) => {
  const setText = (text) => {
    setComment(text);
  };

  return (
    <VStack
      bg="neutral.10"
      borderRadius="sm"
      style={{
        height: su(120, 242, 242),
        paddingLeft: su(5, 48, 48),
        paddingRight: su(5, 48, 48),
        paddingTop: su(10, 37, 37),
      }}
    >
      <Text
        style={{ width: su(windowWidth - 30, 300, 300) }}
        fontSize={14}
        lineHeight="xl"
        color="base.90"
      >
        {i18n.t("screens.resolveTask.placeholders.comment")}
      </Text>
   
        <TextArea
          defaultValue={comment}
          onChange={(e) => setText(e.currentTarget.value)} // for web
          onChangeText={(text) => setText(text)} // for android and ios
          style={{ width: su(200, 400, 475), height: 74 }}
        />
    </VStack>
  );
};

export default AddComment;
