export const saveFile = async (remoteUrl, token, fileName) => {
  fetch(remoteUrl, {
    method: "GET",
    headers: {
      Authorization: `JWT ${token}`,
    },
  }).then(function (t) {
    console.log("🚀 ~ file: helpers.web.js:9 ~ saveFile ~ t", t);
    return t.blob().then((b) => {
      var a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download",fileName);
      a.click();
      // document.body.removeChild(a);
    });
  });
};
