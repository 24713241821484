import { CELL_TYPES } from "../../../utils/types";
const { shortText } = CELL_TYPES;

export const RESULTS_TABLE_COLUMNS = [
  {
    title: "Tag number",
    name: "pn",
    cellType: shortText,
    cellColor: "success.30",
  },
  {
    title: "Results",
    name: "results",
    cellType: shortText,
    cellColor: "success.30",
  },
  { title: "Acceptance", name: "acceptance", cellType: shortText },
  {
    title: "Result",
    name: "result",
    cellType: shortText,
    cellColor: "success.30",
  },
];
