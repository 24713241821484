import {
  Text,
  Box,
  Center,
  HStack,
  Pressable,
  Button,
  VStack,
  Input,
  Icon,
  Divider,
  Toast,
  Badge,
  Modal,
  Spinner,
  ScrollView,
} from "native-base";
import { SafeAreaView } from "react-native-safe-area-context";
import { KeyboardAvoidingView, Platform } from "react-native";
import NavBtn from "../../../components/shared/NavBtn";
import AddComment from "../../../components/AddComment";
import ResultsTable from "../../tasks/results-table/ResultsTable";
import React from "react";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import {
  su,
  useAcrossDevices,
  MODAL_CONTENT_TYPES,
  RESOLVE_ANSWERS_TYPES,
  RESOLVE_TASK_FLOW_MODES,
  i18n,
  formatDocAssetsListForDisplay,
  prepareImagesForUpload,
  calculateResultForResultsTable,
  TABLE_RESULTS_TYPES,
  displayServerErrors,
  windowWidth,
  openCamera,
} from "../../../utils/utils";
import ResolveTaskBottomMenu from "./ResolveTaskBottomMenu";
import ResolveFlowNavigationDesktop from "./ResolveFlowNavigationDesktop";
import * as ImagePicker from "expo-image-picker";
import { useDispatch, useSelector } from "react-redux";
import {
  previousQuestionToResolve,
  nextQuestionToResolve,
  updateQuestion,
  startResolveTask,
} from "../../../features/tasksSlice";
import DisplayQuestionnaire from "../../../screens/questionnaires/DisplayQuestionnaire";
import {
  useUploadTaskAssetsMutation,
  useEditQuestionMutation,
  useTrackLocationMutation,
  useLazyGetTaskQuery,
  api,
} from "../../../services/apiService";
import _ from "lodash";
import { useRoute } from "@react-navigation/native";
import LoadingData from "../../../components/LoadingData";
import ResponseBtns from "./ResponseBtns";
import ViewMediaCollection from "../../../components/ViewMediaCollection";
import * as Location from "expo-location";

const ANSWER_COLOR_MAP = {
  [RESOLVE_ANSWERS_TYPES.na]: "accent.60",
  [RESOLVE_ANSWERS_TYPES.no]: "accent.100",
  [RESOLVE_ANSWERS_TYPES.yes]: "accent.80",
  [RESOLVE_ANSWERS_TYPES.waiting]: "primary.80",
};

const modalInitialState = {
  isOpen: false,
  title: "",
  contentComponent: null,
  size: "full",
};
let compInit = false;
const modalConfigMap = {
  [MODAL_CONTENT_TYPES.questionner]: {
    isOpen: true,
    size: "full",
    title: "View ITP",
    contentComponent: MODAL_CONTENT_TYPES.questionner,
  },
  [MODAL_CONTENT_TYPES.docs]: {
    isOpen: true,
    size: "full",
    title: "View related docs",
    contentComponent: MODAL_CONTENT_TYPES.docs,
  },
  [MODAL_CONTENT_TYPES.comment]: {
    isOpen: true,
    size: "full",
    title: "Question comment",
    submitLabel: "Add comment",
    contentComponent: MODAL_CONTENT_TYPES.comment,
  },
  [MODAL_CONTENT_TYPES.images]: {
    isOpen: true,
    size: "full",
    title: "Images",
    contentComponent: MODAL_CONTENT_TYPES.images,
  },
  [MODAL_CONTENT_TYPES.resultsTable]: {
    isOpen: true,
    size: "full",
    title: "Results",
    contentComponent: MODAL_CONTENT_TYPES.resultsTable,
    submitLabel: "Submit error report",
  },
};
const SN = `screens.resolveTask`;

const getResponseComment = (selectedPN, question) => {
  let selectedAnswer = null;

  if (!selectedPN || !_.isArray(question?.answers)) {
    return selectedAnswer;
  }
  if (selectedPN && _.isArray(selectedPN) && selectedPN.length >= 1) {
    const answersOfSelectedPn = question.answers.filter((item) => {
      const elPn = _.isString(item.pn) ? item.pn : item.pn.id;
      if (selectedPN.includes(elPn)) {
        return item;
      }
    });

    if (answersOfSelectedPn.length < 1) {
      return null;
    }

    selectedAnswer = answersOfSelectedPn[0]?.comment;
    if (!selectedAnswer || selectedAnswer === "") {
      return null;
    }

    const answersWithDifferentComment = answersOfSelectedPn.filter(
      (item) => item?.comment !== selectedAnswer
    );
    if (answersWithDifferentComment.length > 0) {
      return null;
    }
  }

  return selectedAnswer;
};

const getSelectedPNResponsesData = (question, selectedPN) => {
  let images = [];
  let comment = null;
  if (selectedPN && question?.answers) {
    question.answers.forEach((item) => {
      const elPn = _.isString(item.pn) ? item.pn : item.pn.id;
      if (selectedPN.includes(elPn)) {
        if (item.images && item.images.length > 0) {
          item.images.forEach((img) => {
            images.push(img);
          });
        }

        if (item?.comment && item.comment.length > 0) {
          if (!comment) {
            comment = item.comment;
          } else if (comment === item.comment) {
            comment = item.comment;
          } else {
            comment = null;
          }
        }
      }
    });
  }
  return { images: _.uniqBy(images, "id"), comment };
};
let copyLocations = [];
const ResolveTask = ({ navigation }) => {
  const route = useRoute();

  const flowData = useSelector((state) => state.tasks.tasksResolveFlow);

  const [getTask, taskReq] = useLazyGetTaskQuery();

  const { currentQuestionIndex, task } = flowData;
  const questions = task?.activity?.questions;
  const question = questions?.[currentQuestionIndex];
  const [inputText, setTextInput] = React.useState("");

  const initPN = (pn, task) => {
    if (pn && _.isArray(pn) && pn.length > 0) {
      const blockedPn = task?.blockedPn || [];

      const blockedPnIds = blockedPn.map((item) => {
        return item.id;
      });
      if (blockedPnIds.length === 0) {
        return pn.map((item) => item.id);
      }

      const selectedPnList = pn?.filter((item) => {
        if (!blockedPnIds.includes(item?.id)) {
          return item;
        }
      });
      return selectedPnList.map((item) => item.id);
    }
    return [];
  };
  const [selectedPN, setSelectedPN] = React.useState(
    initPN(task?.pn, task) || []
  );
  const selectedResponsesData = getSelectedPNResponsesData(
    question,
    selectedPN
  );
  const responseImages = formatDocAssetsListForDisplay(
    selectedResponsesData.images,
    "image"
  );

  const responseComment = selectedResponsesData.comment;
  const [comment, setComment] = React.useState(responseComment);

  const [trackLocation] = useTrackLocationMutation();
  const [modalParams, setModalParams] = React.useState(modalInitialState);
  // const [resultsTableData, setResultsTableData] = React.useState([]);
  const [variant, setVariant] = React.useState(
    RESOLVE_TASK_FLOW_MODES.question
  );
  const dispatch = useDispatch();
  const [userCanSubmitFinalReport, setUserCanSubmitFinalReport] =
    React.useState(task?.userCanSubmitFinalReport);
  const [status, requestPermission] = ImagePicker.useCameraPermissions();
  const authSlice = useSelector((state) => state.auth);
  const loggedUser = authSlice.user;
  const token = authSlice.token;
  const [editQuestion, editQuestionRequest] = useEditQuestionMutation();
  const [uploadTaskAssets, uploadTaskAssetsRequest] =
    useUploadTaskAssetsMutation();

  const onNextQuestion = () => {
    if (questions && currentQuestionIndex == questions?.length - 1) {
      if (userCanSubmitFinalReport) {
        goToFinalReport();
      } else {
        Toast.show({
          closeAll: true,
          description:
            "All questions for this activity have been answered. Prior to submitting the report, it is necessary to respond to all inquiries.",
        });
        return;
      }
    }
    dispatch(nextQuestionToResolve());
  };

  const goToTasks = () => {
    dispatch(api.util.invalidateTags([{ type: "Tasks", page: 1 }]));
    navigation.replace("Tasks");
  };
  const onBack = () => {
    if (currentQuestionIndex === 0) {
      goToTasks();
    }
    dispatch(previousQuestionToResolve());
  };

  const sendLocationToServer = async (locations) => {
    if (!locations) return;
    const taskId = task?.id ? task.id : route?.params?.taskId;
    if (!taskId) {
      console.warn("to task id to sendlocationto");
      return;
    }

    console.log("sendLocationToServer");

    try {
      await trackLocation({
        taskId: taskId,
        locations: locations,
      });
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(false);
    }
  };

  const getPn = (questionsToBeReported, lastQuestion, skipSelected = false) => {
    if (selectedPN.length > 0 && !skipSelected) {
      return selectedPN;
    }
    if (
      !questionsToBeReported ||
      questionsToBeReported?.length === 0 ||
      !lastQuestion ||
      !lastQuestion?.pn
    ) {
      return null;
    }
    if (lastQuestion?.pn?.length > 0) {
      return lastQuestion.pn.map((item) => {
        if (_.isString(item?.id)) {
          return item.id;
        } else if (item) {
          return item;
        }
      });
    }
    return lastQuestion.pn;
  };
  const openErrorReport = (questionsToBeReported, skipSelected) => {
    let lastQuestion = null;

    if (questionsToBeReported && questionsToBeReported?.length > 0) {
      lastQuestion = questionsToBeReported[questionsToBeReported?.length - 1];
    }

    const pn = getPn(questionsToBeReported, lastQuestion, skipSelected);

    if (pn === null) {
      return;
    }
    navigation.replace("ReportError", {
      ...route?.params,
      pnIdList: pn,
      currentQuestionId: lastQuestion?.id,
    });
    Toast.show({
      closeAll: true,
      description: i18n.t(`${SN}.errors.errorReportRedirect`),
      duration: 8000,
    });
  };

  async function getData() {
    const taskId = route?.params?.taskId;
    getTask({ taskId, isResolve: true })
      .unwrap()
      .then((response) => {
        const isInit = initPN(response?.pn, response);

        if (isInit) {
          setSelectedPN(isInit);
        }
        dispatch(startResolveTask(response));
        init(response?.questionsSolvedWithError);
        setUserCanSubmitFinalReport(response?.userCanSubmitFinalReport);
      })
      .catch(() => {
        Toast.show({
          closeAll: true,
          description:
            "Something went wrong. Please try again or contact your administrator.",
        });
        navigation.navigate("Tasks");
      });
  }
  const { isMobile, isDesktop } = useAcrossDevices();
  // SET NAVIGATION FOR MOBILE
  const setNavigationMobileAtQuestionChange = (currentQuestionIndex) => {
    navigation.setOptions({
      headerRight: () => (
        <NavBtn
          renderText={true}
          onPress={onNextQuestion}
          skipNavigation={true}
          variant="right"
          isDisabled={currentQuestionIndex - 1 === questions?.length}
        />
      ),
      headerTitle: "Resolve",
      headerLeft: () => (
        <NavBtn
          renderText={true}
          onPress={onBack}
          skipNavigation={true}
          variant="left"
          textProp={
            currentQuestionIndex === 0
              ? isMobile
                ? "Back"
                : "Go to tasks"
              : null
          }
        />
      ),
    });
  };
  async function setupAtQuestionChange() {
    if (!isDesktop) {
      setNavigationMobileAtQuestionChange(currentQuestionIndex);
    }

    if (!task?.activity?.questions) {
      return;
    }

    const question = task?.activity?.questions[currentQuestionIndex];
    if (!question) {
      return;
    }
    if (question && question?.type === RESOLVE_TASK_FLOW_MODES.input) {
      setVariant(RESOLVE_TASK_FLOW_MODES.input);
      // setTextInput(question.answer);
    } else {
      setVariant(RESOLVE_TASK_FLOW_MODES.question);
    }
  }

  function init(questionsSolvedWithError = []) {
    const userMustSubmitErrorReport = questionsSolvedWithError?.length > 0;
    console.log(
      "init cmp: userMustSubmitErrorReport",
      userMustSubmitErrorReport
    );

    if (userMustSubmitErrorReport) {
      try {
        const questionsToBeReported = questionsSolvedWithError.map((el) => {
          return { ...el.question, pn: el.pn };
        });
        if (questionsToBeReported) {
          openErrorReport(questionsToBeReported, true);
        }

        initPN(task?.pn, task);
      } catch (err) {
        console.info(err);
      }
    }
  }

  const checkLocationStatus = async () => {
    const hasLocationEnabled = await Location.hasServicesEnabledAsync();
    if (!hasLocationEnabled) {
      Toast.show({
        closeAll: true,
        description:
          "Allow the app the permission to access the location. You cannot resolve task without giving the permission location.",
      });
      if (Platform.OS !== "web") {
        navigation.navigate("Tasks");
      }
      return;
    }
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== "granted") {
      Toast.show({
        closeAll: true,
        description:
          "Allow the app the permission to access the location. Your options is " +
          status,
      });

      if (Platform.OS !== "web") {
        navigation.navigate("Tasks");
      }
    }
  };

  const locCallback = async (loc) => {
    if (copyLocations.length >= 10) {
      let locations = copyLocations;
      copyLocations = [];
      try {
        await sendLocationToServer(locations);
      } catch (err) {
        console.info(err);
      }
    } else {
      if (
        loc?.timestamp &&
        !_.isNull(loc?.timestamp) &&
        loc?.timestamp &&
        !_.isNull(loc?.coords?.accuracy) &&
        loc?.timestamp &&
        !_.isNull(loc?.coords?.longitude) &&
        loc?.timestamp &&
        !_.isNull(loc?.coords?.latitude) &&
        loc?.timestamp &&
        !_.isNull(loc?.coords?.speed)
      ) {
        copyLocations.push({
          timestamp: loc?.timestamp.toString(),
          accuracy: loc?.coords?.accuracy.toString(),
          longitude: loc?.coords?.longitude.toString(),
          latitude: loc?.coords?.latitude.toString(),
          speed: loc?.coords?.speed.toString(),
        });
      }
    }
  };
  React.useEffect(() => {
    const startTrackingLocation = async () => {
      return Location.watchPositionAsync(
        {
          accuracy: Location.Accuracy.Highest,
          distanceInterval: 3,
          timeInterval: 1000 * 60,
        },
        locCallback.bind(this)
      );
    };

    let locationSubscription = Promise.resolve();

    const setup = async () => {
      console.log("run setup");

      await checkLocationStatus();
      if (!compInit) {
        locationSubscription = startTrackingLocation();
        compInit = true;
      }
    };

    setup();
    return () => {
      compInit = false;
      locationSubscription.then((response) => {
        console.log("cleeeannnn ", response);
        if (!response) {
          console.warn("No subscription for location");

          return;
        }
        response.remove();
        if (copyLocations.length === 0) {
          return;
        }
        sendLocationToServer(copyLocations).then((response) => {
          console.log("removed cmp and send location to server");
          if (response) {
            copyLocations = [];
          }
        });
        console.log("calleed remove");
      });
    };
  }, []);

  React.useEffect(() => {
    setupAtQuestionChange();
  }, [
    currentQuestionIndex,
    route?.params?.taskId,
    task?.questionsSolvedWithError?.length,
    task?.blockedPn,
  ]);

  React.useEffect(() => {
    async function setup() {
      await getData();
    }

    setup();
  }, []);
  // if (!flowData || !flowData.task?.pn) {
  //   return null;
  // }
  if (
    taskReq.isLoading ||
    taskReq.isFetching ||
    !flowData ||
    !flowData.task?.pn
  ) {
    return (
      <LoadingData
        isLoading={taskReq.isLoading}
        isFetching={taskReq.isFetching}
        isUninitialized={
          taskReq.isUninitialized || !flowData || !flowData.task?.pn
        }
      />
    );
  }

  if (taskReq.isError) {
    Toast.show({
      closeAll: true,
      description: i18n.t(`${SN}.errors.taskNotFound`),
    });
    navigation.navigate("Tasks");
    return null;
  }

  const renderGoToTasksBtn = () => {
    return (
      <Box
        style={{
          width: su(400, 669, 669),
        }}
        w="full"
        justifyContent="center"
        alignItems="center"
      >
        <HStack style={{ height: 60 }} space={2}>
          {!(currentQuestionIndex === 0) && (
            <Button
              style={{ height: 50 }}
              variant="outline"
              my={1}
              maxWidth={150}
              onPress={goToTasks}
            >
              {i18n.t(`${SN}.buttons.goToTasks`)}
            </Button>
          )}

          {userCanSubmitFinalReport && (
            <Button
              my={1}
              borderWidth="1"
              borderColor="neutral.40"
              variant="outline"
              _hover={{ bg: "accent.50" }}
              w="lg"
              bg="accent.40"
              maxWidth={180}
              onPress={goToFinalReport}
            >
              <Text color="base.0"> {i18n.t(`${SN}.buttons.submit`)} </Text>
            </Button>
          )}
        </HStack>
      </Box>
    );
  };

  const projectName = task?.project.name;
  const areaName = task?.area.name;
  const tasksPN = task?.pn;

  const pickImages = async () => {
    // No permissions request is necessary for launching the image library
    if (status === "denied") {
      requestPermission();
    }
    let result = null;
    try {
      result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsMultipleSelection: true,
        accessPrivileges: true,
        base64: true,
      });
    } catch (e) {
      Toast.show({
        closeAll: true,
        description: i18n.t(`${SN}.errors.pickImagesFromDevice`),
      });
      return;
    }
    if (!result.canceled) {
      if (
        !result.assets ||
        !_.isArray(result.assets) ||
        result.assets.length < 1
      ) {
        Toast.show({
          closeAll: true,
          description: i18n.t(`${SN}erros.noImages`),
        });
        return;
      }
    }

    return result.assets;
  };
  const pickImagesAndUpload = async () => {
    const assets = await pickImages();
    if (assets) {
      uploadImages(assets);
    }
  };

  const uploadImages = async (files) => {
    const filesResult = files;

    const form = new FormData();
    form.append("assetFileType", "image");
    form.append("questionId", question.id);
    form.append("selectedPNIds", JSON.stringify(selectedPN));
    form.append("createdBy", loggedUser.id);
    form.append("answers", JSON.stringify(question.answers));

    try {
      const preparedFiles = prepareImagesForUpload(filesResult);
      preparedFiles.forEach((file) => {
        form.append("file", ...file);
      });
    } catch (err) {
      console.warn(err);
    }

    uploadTaskAssets(form)
      .then((r) => {
        // TODO: find a easy way to do this on the backend..

        if (r && r?.data && r?.data) {
          const payload = {
            questionIndex: currentQuestionIndex,
            answers: r?.data?.answers,
          };
          dispatch(updateQuestion(payload));
          Toast.show({
            closeAll: true,
            description: i18n.t(`${SN}.success.imagesUploaded`),
          });
        }

        if (r.error) {
          Toast.show({
            closeAll: true,
            description: i18n.t(`${SN}.errors.uploadingImages`),
          });
        }
      })
      .catch(() => {
        Toast.show(i18n.t(`${SN}.errors.uploadingImages`));
      });
  };

  const addCommentHandler = async () => {
    if (!comment) {
      Toast.show({ closeAll: true, description: "Cannot add empty comment." });
      return;
    }
    if (!question.answers || question.answers.length == 0) {
      Toast.show({
        closeAll: true,
        description: "Question doesn't have predefined answers.",
      });
      return;
    }

    const editedAnswers = question.answers.map((a) => {
      let answer = Object.assign({}, a);
      const pnElid = _.isString(a.pn) ? a.pn : a.pn.id;
      if (selectedPN.includes(pnElid)) {
        answer.comment = comment;
      }
      answer.createdBy = loggedUser.id;
      //wee need to send only the id to the server
      answer.pn = pnElid;
      if (
        answer.images &&
        _.isArray(answer.images) &&
        answer.images.length > 0
      ) {
        answer.images = answer.images.map((item) => item.id);
      }
      return answer;
    });
    const questionData = {
      id: question.id,
      answers: editedAnswers,
      taskId: task?.id,
      activityId: task?.activity?.id,
    };
    const successCallback = () => {
      closeModal();
      // setComment(null);

      Toast.show({
        closeAll: true,
        description: i18n.t(`${SN}.success.commentSaved`),
      });
    };

    await requestEditQuestion(questionData, successCallback);
  };

  const openErrorReportInModal = () => {
    const questionsToBeReported = [questions[currentQuestionIndex]];
    openErrorReport(questionsToBeReported);
  };

  const taskDocs = formatDocAssetsListForDisplay(task.otherDocs, "document");
  const modalMap = {
    [MODAL_CONTENT_TYPES.comment]: {
      cmp: <AddComment comment={responseComment} setComment={setComment} />,
      title: i18n.t("modalTitles.addComment"),
      hideFooter: false,
      onSubmit: addCommentHandler,
      disableClose: false,
      isLoading: editQuestionRequest.isLoading,
      submitLabel:
        comment !== ""
          ? i18n.t(`${SN}.buttons.updateComment`)
          : i18n.t(`${SN}.buttons.addComment`),
    },
    [MODAL_CONTENT_TYPES.images]: {
      cmp: (
        <React.Suspense>
          <ViewMediaCollection
            onAddMore={pickImagesAndUpload}
            renderAddMoreBtn={true}
            items={responseImages}
            token={token}
            request={uploadTaskAssetsRequest}
          />
        </React.Suspense>
      ),
      title: i18n.t("modalTitles.addImage"),
      hideFooter: true,
      disableClose: false,
    },
    [MODAL_CONTENT_TYPES.questionner]: {
      cmp: (
        <Box
          style={{
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "flex-start",

            height: "auto",
            width: su(windowWidth - 35, windowWidth - 45, windowWidth - 100),
          }}
        >
          <DisplayQuestionnaire data={task.questionner} />
        </Box>
      ),
      title: i18n.t("modalTitles.viewQuestionnaire"),
      hideFooter: true,
      disableClose: false,
    },
    [MODAL_CONTENT_TYPES.docs]: {
      cmp: <ViewMediaCollection renderAddMoreBtn={false} items={taskDocs} />,

      title: i18n.t("modalTitles.reportError"),
      hideFooter: true,
      disableClose: false,
    },
    [MODAL_CONTENT_TYPES.resultsTable]: {
      cmp: <ResultsTable dataSource={question} question={question} />,
      submitLabel: "Issue error report",
      onSubmit: openErrorReportInModal,
      title: i18n.t("modalTitles.resultsTable"),
      hideFooter: false,
      disableClose: true,
    },
  };

  const responseBadgeImagesNumber =
    responseImages && _.isArray(responseImages) && responseImages.length > 0
      ? responseImages.length.toString()
      : null;

  const onPressBtnBottom = (name) => {
    if (name === "photo") {
      openCamera(uploadImages);
    }
    if (name === MODAL_CONTENT_TYPES.images) {
      if (responseBadgeImagesNumber === null) {
        pickImagesAndUpload();
        return;
      }
    }

    if (!_.isUndefined(modalConfigMap[name])) {
      setModalParams(modalConfigMap[name]);
    }
  };

  const closeModal = () => {
    setModalParams(modalInitialState);
  };

  const addResponseToQuestionAnswers = (
    question,
    currentUserId,
    selectedPN,
    userResponse
  ) => {
    const editedAnswers = question.answers.map((a) => {
      let answer = Object.assign({}, a);
      const pnElid = _.isString(a.pn) ? a.pn : a.pn.id;
      if (selectedPN.includes(pnElid)) {
        answer.answer = userResponse;
        answer.updatedAt = new Date().toISOString();
      }
      answer.createdBy = currentUserId;
      //wee need to send only the id to the server
      answer.pn = pnElid;

      if (
        answer.images &&
        _.isArray(answer.images) &&
        answer.images.length > 0
      ) {
        if (_.isObject(answer.images?.[0]) && !_.isNull(answer.images?.[0])) {
          answer.images = answer.images.map((item) => item.id);
        }
      }
      return answer;
    });

    return editedAnswers;
  };
  const checkIfPNIsBlocked = () => {
    let isblocked = false;
    if (task?.blockedPn) {
      const pnIds = task?.blockedPn?.map((item) => item?.id);
      selectedPN.forEach((item) => {
        if (pnIds.includes(item)) {
          isblocked = true;
        }
      });
    }
    return isblocked;
  };

  const saveAnswer = async (response) => {
    if (!response || response.length === 0) {
      Toast.show({
        closeAll: true,
        description: i18n.t(`${SN}.errors.emptyResponse`),
      });
      return;
    }
    const isDisabled = checkIfPNIsBlocked();
    if (isDisabled) {
      Toast.show({
        closeAll: true,
        description: i18n.t(`${SN}.errors.pnBlocked`),
      });
      return;
    }

    //uncheck checkboxes

    if (!question.answers || question.answers.length == 0) {
      Toast.show({
        closeAll: true,
        description: i18n.t(`${SN}.errors.noPredefined`),
      });
    }

    const editedAnswers = addResponseToQuestionAnswers(
      question,
      loggedUser.id,
      selectedPN,
      response
    );
    requestEditQuestion(
      {
        id: question.id,
        answers: editedAnswers,
        taskProjectId: task.project.id,
        taskId: task.id,
        response: response,
        pn: JSON.stringify(selectedPN),
      },
      () => {
        if (question.type === "input") {
          setTextInput("");
        }

        if (question.type === RESOLVE_TASK_FLOW_MODES.input) {
          const { firstNumber, secondNumber, sign } = question.input[0];
          const responseResult = calculateResultForResultsTable(
            response,
            firstNumber,
            secondNumber,
            sign
          );

          if (responseResult === TABLE_RESULTS_TYPES.unSat) {
            openResultsTable();
            Toast.show({
              closeAll: true,
              description: i18n.t(`${SN}.success.answerSaved`),
            });
            return;
          }
        }
        if (response === RESOLVE_ANSWERS_TYPES.no) {
          openErrorReport([question]);
        }
        Toast.show({
          closeAll: true,
          description: i18n.t(`${SN}.success.answerSaved`),
        });
      }
    ).catch(() => {});
  };

  const requestEditQuestion = async (data, successCallback) => {
    editQuestion(data)
      .then((response) => {
        const isResponseWithErros = displayServerErrors(response);

        if (isResponseWithErros) {
          return;
        }

        if (!response?.data?.doc) {
          return;
        }
        // const data = getTableResultsDataSource(
        //   { ...question, answers: response?.data?.doc?.answers },
        //   selectedPN
        // );
        // setResultsTableData(data);
        setUserCanSubmitFinalReport(
          response?.data?.doc?.userCanSubmitFinalReport
        );

        const payload = {
          questionIndex: currentQuestionIndex,
          answers: response?.data?.doc?.answers,
        };

        dispatch(updateQuestion(payload));
        successCallback();
      })

      .catch(() => {
        closeModal();
        Toast.show({
          closeAll: true,
          description:
            "An error occurred during the last request. Please try again.",
        });
      });
  };

  const openResultsTable = () => {
    setModalParams(modalConfigMap[MODAL_CONTENT_TYPES.resultsTable]);
  };

  const renderPNItems = () => {
    if (tasksPN === undefined || !_.isArray(tasksPN)) {
      return <Text>No PN.</Text>;
    }

    return (
      <ScrollView
        contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
        }}
        style={{ maxHeight: 180 }}
        width="100%"
        alwaysBounceVertical={false}
      >
        <HStack

          flexWrap="wrap"
          flexDirection="row"
          gap={2}
          justifyItems="center"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          {tasksPN.map((currentPnObject) => {
            const answerData = question?.answers?.find((item) => {
              if (
                item.pn?.id === currentPnObject.id ||
                item.pn === currentPnObject.id
              ) {
                return item;
              }
            });

            if (
              !answerData ||
              question?.answers.length === 0 ||
              !answerData?.answer
            ) {
              return null;
            }
            const isSolved =
              answerData.answer !== RESOLVE_ANSWERS_TYPES.waiting;
            const isDisabled =
              task?.blockedPn
                ?.map((item) => item?.id)
                .includes(currentPnObject.id) || false;

            const onSelect = () => {
              if (isDisabled) {
                Toast.show({
                  closeAll: true,
                  description: i18n.t(`${SN}.errors.pnBlocked`),
                });
                return;
              }
              let newSelection = [];

              if (!selectedPN || !currentPnObject?.id) {
                return;
              }

              const isAlreadySelected = selectedPN.findIndex(
                (pn) => pn === currentPnObject?.id
              );
              if (isAlreadySelected === -1) {
                newSelection = [currentPnObject.id, ...selectedPN];
              } else {
                newSelection = selectedPN.filter(
                  (pn) => pn !== currentPnObject.id
                );
                setComment(null);
              }
              setSelectedPN(newSelection);
              const comment = getResponseComment(newSelection, question);
              setComment(comment);
            };

            const getIconColor = () => {
              if (question.type === RESOLVE_TASK_FLOW_MODES.question) {
                return ANSWER_COLOR_MAP[answerData.answer];
              }

              if (
                answerData.answer === RESOLVE_ANSWERS_TYPES.waiting ||
                answerData.answer === ""
              ) {
                return ANSWER_COLOR_MAP[RESOLVE_ANSWERS_TYPES.waiting];
              } else {
                const { firstNumber, secondNumber, sign } = question.input[0];
                const responseResult = calculateResultForResultsTable(
                  answerData.answer,
                  firstNumber,
                  secondNumber,
                  sign
                );
                if (responseResult === TABLE_RESULTS_TYPES.unSat) {
                  return ANSWER_COLOR_MAP[RESOLVE_ANSWERS_TYPES.no];
                }
                return ANSWER_COLOR_MAP[RESOLVE_ANSWERS_TYPES.yes];
              }
            };

            const colorIcon = getIconColor();
            const isSelected = selectedPN.find(
              (pn) => pn === currentPnObject?.id
            );

            return (
              <Pressable key={currentPnObject.id} onPress={onSelect}>
                <Badge
                  bg={isSelected ? "primary.90" : "neutral.40"}
                  m={1}
                  px={2}
                  py={2}
                  mx={3}
                >
                  <HStack space={2}>
                    <Icon
                      as={isSolved ? Ionicons : AntDesign}
                      name={isSolved ? "checkmark-done" : "bulb1"}
                      size={7}
                      color={colorIcon}
                    />
                    <Text color={isSelected ? "base.0" : "primary.90"}>
                      {currentPnObject.name.trim()}
                    </Text>
                  </HStack>
                </Badge>
              </Pressable>
            );
          })}
        </HStack>
      </ScrollView>
    );
  };

  const renderCenterContentCmp = (renderInput) => {
    const getTextValue = () => {
      let text = "";
      let areDifferentResponses = false;

      if (selectedPN && selectedPN.length > 0) {
        // only one PN is selected
        if (selectedPN.length === 1) {
          const answer = question.answers.find(
            (item) => item.pn.id === selectedPN[0]
          );
          if (answer) {
            if (answer.answer === RESOLVE_ANSWERS_TYPES.waiting) {
              return i18n.t(`${SN}.placeholders.inputEmpty`);
            }
            return answer.answer;
          }
        }
        //multiple pn selected

        // 1. get all answer for selected pn
        // 2. if all the answer are the same, the text value will be the answer itself
        // 3. if the text is waiting replace with Type value

        const answersOfSelectedPn = question.answers.filter((currentAnswer) => {
          const elPn = _.isString(currentAnswer.pn)
            ? currentAnswer.pn
            : currentAnswer.pn.id;
          if (selectedPN.includes(elPn)) {
            return currentAnswer;
          }
        });
        // find unique values

        for (let i = 0; i < answersOfSelectedPn.length; i++) {
          if (i + 1 < answersOfSelectedPn.length) {
            if (
              answersOfSelectedPn[i].answer !==
              answersOfSelectedPn[i + 1].answer
            ) {
              areDifferentResponses = true;
            }
          }
        }

        text = answersOfSelectedPn?.[0]?.answer;

        if (text === RESOLVE_ANSWERS_TYPES.waiting) {
          return i18n.t(`${SN}.placeholders.inputEmpty`);
        }

        if (!areDifferentResponses) {
          return text;
        }
        return i18n.t(`${SN}.placeholders.differentValues`);
      }

      return "";
    };

    const placeholder = getTextValue();
    return (
      <VStack space={2}>
        <Text
          letterSpacing="sm"
          lineHeight="md"
          color="primary.100"
          fontSize={16}
          textAlign="center"
        >
          {_.unescape(question?.title)}
        </Text>
        {renderInput && (
          <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : "height"}
            style={{ flex: 1 }}
            keyboardVerticalOffset={100}
          >
            <Input
              placeholder={placeholder}
              value={inputText}
              onChangeText={setTextInput}
              size="lg"
              style={{ maxWidth: 200 }}
            />
          </KeyboardAvoidingView>
        )}
      </VStack>
    );
  };

  const renderContent = {
    [RESOLVE_TASK_FLOW_MODES.question]: {
      center: renderCenterContentCmp(false),
    },
    [RESOLVE_TASK_FLOW_MODES.input]: {
      center: renderCenterContentCmp(true),
    },
  };

  const renderHeader = () => {
    return (
      <HStack
        w="full"
        display="flex"
        // px={2}
        reversed={isMobile}
        alignItems="center"
        style={{ height: su(30, 54) }}
        bg={!isMobile ? "base.10" : "base.0"}
        justifyContent="space-between"
      >
        {!isMobile && (
          <Text color="primary.90">
            Task no. {currentQuestionIndex + 1}/{questions?.length || "#"}
          </Text>
        )}
        <Text color="primary.90"> {areaName}</Text>
        <Text
          lineHeight="xl"
          style={{ fontSize: isMobile ? 16 : 16 }}
          color="primary.90"
        >
          {projectName}
        </Text>
      </HStack>
    );
  };

  const renderModal = () => {
    if (!modalParams.isOpen) {
      return null;
    }
    const renderModalBody = () => {
      if (
        modalParams.contentComponent &&
        modalParams.contentComponent === null
      ) {
        return null;
      }
      if (!modalMap[modalParams.contentComponent]) {
        return null;
      }

      return modalMap[modalParams.contentComponent].cmp;
    };

    const isLoading =
      modalMap[modalParams.contentComponent]?.isLoading || false;

    return (
      <Modal
        size="full"
        overlayVisible={true}
        closeOnOverlayClick={false}
        avoidKeyboard={true}
        isKeyboardDismissable={true}
        isOpen={modalParams.isOpen}
        onClose={closeModal}
        _backdrop={{
          bg: "base.90",
          opacity: 0.6,
        }}
      >
        <KeyboardAvoidingView
          style={{ width: "100%" }}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          <Modal.Content>
            <Modal.Header>
              {modalParams.title}
              <Modal.CloseButton
                isDisabled={
                  (Boolean(
                    taskReq?.data?.questionsSolvedWithError?.length > 0
                  ) &&
                  task?.questionMustBeReported &&
                  modalParams.contentComponent ===
                    MODAL_CONTENT_TYPES.reportError) || modalMap[modalParams.contentComponent]?.disableClose
                }
              />
            </Modal.Header>
            <Modal.Body
              // height={
              //   modalParams.contentComponent === MODAL_CONTENT_TYPES.comment
              //     ? 200
              //     : "auto"
              // }
              _scrollview={{
                height:
                  modalParams.contentComponent === MODAL_CONTENT_TYPES.comment
                    ? 200
                    : "auto",
                horizontal:
                  modalParams.contentComponent ===
                  MODAL_CONTENT_TYPES.questionner,
                scrollEnabled:
                  modalParams.contentComponent !==
                  MODAL_CONTENT_TYPES.questionner,
              }}
              overflow="hidden"
              alignItems="center"
            >
              <Box
                alignItems="center"
                height={
                  modalParams.contentComponent === MODAL_CONTENT_TYPES.comment
                    ? 150
                    : "auto"
                }
                maxWidth={windowWidth}
              >
                {renderModalBody()}
              </Box>
            </Modal.Body>
            {modalMap[modalParams.contentComponent]?.hideFooter === false && (
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button
                    mt={4}
                    bg="accent.40"
                    alignItems="center"
                    justifyContent="center"
                    mx={6}
                    mr={4}
                    isLoading={isLoading}
                    style={{ height: su(42, 42, 32), width: 210 }}
                    onPress={modalMap[modalParams.contentComponent]?.onSubmit}
                  >
                    {modalMap[modalParams.contentComponent]?.submitLabel
                      ? modalMap[modalParams.contentComponent]?.submitLabel
                      : i18n.t("screens.users.buttons.save")}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            )}
          </Modal.Content>
        </KeyboardAvoidingView>
      </Modal>
    );
  };

  const goToFinalReport = () => {
    navigation.push("Report", {
      taskId: task?.id,
    });
  };

  return (
    <SafeAreaView style={{ backgroundColor: "#FFF", height: "100%" }}>
      <Center
        display="flex"
        justifyContent={!isMobile ? "space-around" : "space-between"}
        h="full"
        bg="base.0"
      >
        <VStack
          justifyItems="end"
          display="flex"
          alignItems={"center"}
          bg={"base.0"}
          w="full"
        >
          {isDesktop && (
            <ResolveFlowNavigationDesktop
              onNext={onNextQuestion}
              onBack={onBack}
            />
          )}

          <VStack
            bg="white"
            borderWidth={isMobile ? 0 : 1}
            borderColor={"base.10"}
            borderStyle="solid"
            justifyContent={"space-between"}
            justifyItems={"space-between"}
            w="full"
            px={[6, 0, 0]}
            alignContent={"center"}
            alignItem={"center"}
            style={{
              width: su(windowWidth - 20, 669, 669),
            }}
          >
            <VStack
              justifyItems="flex-end"
              alignContent="flex-end"
              alignItems="center"
              space={2}
              w="full"
              justifyContent="flex-end"
            >
              {renderHeader()}
              <Divider w="full" style={{ height: 1 }} bg="neutral.20" />
              {tasksPN && renderPNItems()}
              <Box
                borderWidth="1"
                borderStyle="solid"
                borderColor={isMobile ? "base.0" : "neutral.10"}
                justifyContent="center"
                alignItems="center"
                justifyItems="center"
                mt={4}
                style={{ height: su(100, 150, 150), width: su(284, 478) }}
              >
                <Center>{renderContent[variant].center}</Center>
              </Box>

              <Center w="full" mt={isMobile ? 5 : 36}>
                <ResponseBtns
                  selectedPN={selectedPN}
                  saveAnswer={saveAnswer}
                  flowType={variant}
                  question={question}
                  inputText={inputText}
                  navigation={navigation}
                  isLoading={editQuestionRequest.isLoading}
                />
              </Center>
            </VStack>
          </VStack>
          {renderGoToTasksBtn()}
        </VStack>

        {uploadTaskAssetsRequest.isLoading && <Spinner />}

        <ResolveTaskBottomMenu
          data={{
            images: responseBadgeImagesNumber,
            photo: null,
            comment: responseComment !== null ? 1 : null,
            areInputBtnsDisabled: selectedPN && selectedPN.length === 0,
          }}
          onPress={onPressBtnBottom}
        />
      </Center>
      {renderModal()}
    </SafeAreaView>
  );
};

export default ResolveTask;
