import React from "react";
import FlowProgressMobile from "./progress/FlowProgressMobile";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";
import { InstanceTasksMainFlowModel } from "./TaskFlowObj";
import { useIsDesktop } from "../../../utils/utils";
import FlowInput from "./FlowInput";

// const FlowInput = React.lazy(() => import("./FlowInput"));
const Stack = createStackNavigator();
export const flowScreens = InstanceTasksMainFlowModel.getFlowNavigatorConfig();

const TasksMainFlowNavigator = () => {
  const isDesktop = useIsDesktop();

  return (
    <Stack.Navigator
      style={{ backgroundColor: "black" }}
      initialRouteName={"Main"}
    >
      <Stack.Screen
        name="Main"
        component={FlowInput}
        options={{ headerShown: !isDesktop }}
      />
      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          options={{
            headerShown: false,
            ...TransitionPresets.BottomSheetAndroid,
          }}
          name="FlowProgressMobile"
          component={FlowProgressMobile}
        />
      </Stack.Group>
    </Stack.Navigator>
  );

  // return (
  //   <Stack.Navigator
  //     style={{ backgroundColor: "black" }}
  //     initialRouteName={"project"}
  //   >
  //     <Stack.Screen options={{ headerShown: !isDesktop }}>
  //       {() => (
  //         <React.Suspense>
  //           <FlowInput />
  //         </React.Suspense>
  //       )}
  //     </Stack.Screen>
  //     {/* <Stack.Group>
  //       {CURRENT_FLOW_SCREENS.map((item, index) => {
  //         const previousNav =
  //           index === 0 ? null : CURRENT_FLOW_SCREENS[index - 1]?.name;

  //         return (
  //           <Stack.Screen
  //             key={item?.name}
  //             options={{ headerShown: !isDesktop }}
  //             name={item?.name}
  //           >
  //             {() => (
  //               <React.Suspense>
  //                 <FlowInput
  //                   title={item.title}
  //                   screenName={item.name}
  //                   nextNav={item.nextNav}
  //                   previousNav={previousNav}
  //                   screenNumber={index + 1}
  //                   variant={item.inputDataType}
  //                 />
  //               </React.Suspense>
  //             )}
  //           </Stack.Screen>
  //         );
  //       })}

  //     </Stack.Group> */}
  //     <Stack.Group screenOptions={{ presentation: "modal" }}>
  //       <Stack.Screen
  //         options={{
  //           headerShown: false,
  //           ...TransitionPresets.BottomSheetAndroid,
  //         }}
  //         name="FlowProgressMobile"
  //         component={FlowProgressMobile}
  //       />
  //     </Stack.Group>
  //   </Stack.Navigator>
  // );
};

export default TasksMainFlowNavigator;
