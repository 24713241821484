import { createSlice } from "@reduxjs/toolkit";
import { api, useLogoutMutation } from "../services/apiService";
import { useDispatch } from "react-redux";
import { Toast } from "native-base";
import { getUserFullName,revertAll } from "../utils/utils";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";

const initialState = {
  user: null,
  token: null,
  loggedIn: false,
  access: {},
  tokenExp: 0,
  lastInteraction: null,
};
const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthUser: (state) => {
      state.user = initialState.user;
      state.token = initialState.token;
      state.loggedIn = initialState.loggedIn;
      state.access = initialState.access;
      state.tokenExp = initialState.tokenExp;
      state.lastInteraction = initialState.lastInteraction;
    },
    resetLastInteraction: (state) => {

      state.lastInteraction = new Date();
    }
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, () => initialState);
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload.user.twoFactorAuthStatus === "blocked") {
          state = initialState;
          return;
        }
        state.token = payload.token;
        state.tokenExp = payload.exp;
        let user = _.cloneDeep(payload.user);
        if (user?.id) {
          user.fullName = getUserFullName(user);
          if (user?.reportsTo && !_.isString(user?.reportsTo)) {
            user.reportsTo.fullName = getUserFullName(user.reportsTo);
          }

          state.user = user;

          state.loggedIn = false;
          state.lastInteraction = new Date();
        } else {
          state = initialState;
        }
      }
    ),
      builder.addMatcher(
        api.endpoints.verifyOTP.matchFulfilled,
        (state) => {
          state.loggedIn = true;
        }
      ),
      builder.addMatcher(
        api.endpoints.activateOTP.matchFulfilled,
        (state) => {
          state.loggedIn = true;
        }
      ),
      builder.addMatcher(
        api.endpoints.activateOTP.matchRejected,
        (state, { payload }) => {
          const isActiveTwoFactor =
            state.user?.twoFactorAuthStatus === "active";
          if (!isActiveTwoFactor) {
            state.loggedIn = false;
          } else {
            if (payload.data.name === "blocked-2fa") {
              state = initialState;
            }
          }
        }
      ),
      builder.addMatcher(
        api.endpoints.getMe.matchFulfilled,
        (state, { payload }) => {
          if (payload?.user?.twoFactorAuthStatus == "blocked") {
            state = initialState;
          } else {
            state.token = payload.token;
            let user = payload.user;
            user.fullName = getUserFullName(payload.user);
            user.reportsTo.fullName = getUserFullName(payload.user.reportsTo);
            state.user = user;
          }
        }
      ),
      builder.addMatcher(
        api.endpoints.getMe.matchRejected,
        (state) => {
                 /* eslint-disable no-unused-vars*/
          state = initialState;
        }
      ),
      builder.addMatcher(
        api.endpoints.getAccess.matchFulfilled,
        (state, { payload }) => {
          state.access = payload;
        }
      ),
      builder.addMatcher(
        api.endpoints.logout.matchFulfilled,
        (state) => {
          /* eslint-disable no-unused-vars*/

          state = initialState;
        }
      );
  },
});
export const { resetAuthUser,resetLastInteraction } = slice.actions;
export default slice.reducer;
const clearAll = async () => {
  try {
    await AsyncStorage.clear()
  } catch(e) {
    // clear error
  }

  console.log('Done.')
}
export const logOutUserHook = () => {
  const dispatch = useDispatch();


  const [logOut, logOutRequest] = useLogoutMutation();
  const logOutUserHandler = async () => {
    try {
      await logOut();
      dispatch(api.util.resetApiState());
      dispatch(api.util.invalidateTags([{type: "Notifications", page: 1},"NotificationsCount",{type: "Projects", page: 1},"Notifications"]));
      dispatch(resetAuthUser());
      dispatch(revertAll());
      clearAll();
 
  
    } catch (err) {
      Toast.show("An error occurred while logging out. Try again");
    }
  };

  return [logOutUserHandler, logOutRequest];
};
