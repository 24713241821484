import { Box, Text, Button, HStack, IconButton, Icon } from "native-base";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import { AntDesign } from "@expo/vector-icons";

const Pagination = ({ totalPages, currentPage, setPage, refetch = null }) => {
  const navigation = useNavigation();
  if (!totalPages || totalPages.length === 0) {
    return null;
  }

  const setActualPage = (page) => {
    setPage(page);
    navigation.setParams({
      page: page,
    });
  };
  const goNext = () => {
    const next = (currentPage + 1).toString();
    if (next > totalPages) {
      setActualPage(totalPages);
    } else {
      setActualPage(next);
    }
  };
  const goBack = () => {
    const prev = (currentPage - 1).toString();
    if (prev > 0) {
      setActualPage(prev);
    } else {
      setActualPage(1);
    }
  };

  const goToFirstPage = () => {
    setActualPage(1);
  };
  const goToLastPage = () => {
    setActualPage(totalPages);
  };
  let pagePerSection = 5;
  let startPage = Math.max(1, currentPage - pagePerSection);
  let endPage = Math.min(totalPages, currentPage + pagePerSection);

  const generatePagination = () => {
    let pages = [];
    for (let i = startPage; i < endPage + 1; i++) {
      pages.push(i);
    }

    return pages;
  };

  const pagination = generatePagination();
  return (
    <Box>
      <HStack space={2}>
        {refetch !== null && (
          <IconButton
            alignSelf="flex-start"
            color="accent.50"
            onPress={refetch}
            style={{ maxWidth: 30 }}
            _pressed={{ background: "neutral.30" }}
            icon={<Icon as={AntDesign} name="reload1" color="accent.50" />}
            size="lg"
          />
        )}
        <Button.Group>
          <Button
            _hover={{
              bg: "accent.40",
              color: "base.0",
            }}
            _pressed={{
              bg: "accent.50",
              color: "base.0",
            }}
            onPress={goToFirstPage}
            variant="solid"
            disabled={currentPage === 1}
            bg="lightgray"
          >
            <Text>First Page</Text>
          </Button>
          <Button
            _hover={{
              bg: "accent.40",
              color: "base.0",
            }}
            _pressed={{
              bg: "accent.50",
              color: "base.0",
            }}
            onPress={goBack}
            variant="solid"
            disabled={currentPage === 1}
            bg="lightgray"

            // key={pageNumber}
          >
            <Text>Prev</Text>
          </Button>
          {pagination.map((pageNumber) => {
            // let pageNumber = (pageIndex + 1).toString();

            const onPress = () => {
              setActualPage(pageNumber);
            };

            const btnStyle = {
              backgroundColor:
                currentPage.toString() === pageNumber.toString()
                  ? "lightblue"
                  : "lightgray",
            };

            return (
              <Button
                _hover={{
                  bg: "accent.40",
                  color: "base.0",
                }}
                _pressed={{
                  bg: "accent.50",
                  color: "base.0",
                }}
                variant="solid"
                style={btnStyle}
                onPress={onPress}
                key={pageNumber}
              >
                <Text key={pageNumber + "text"}>{pageNumber}</Text>
              </Button>
            );
          })}
          <Button
            _hover={{
              bg: "accent.40",
              color: "base.0",
            }}
            _pressed={{
              bg: "accent.50",
              color: "base.0",
            }}
            onPress={goNext}
            variant="solid"
            disabled={currentPage === totalPages}
            bg="lightgray"
          >
            <Text>Next</Text>
          </Button>
          <Button
            _hover={{
              bg: "accent.40",
              color: "base.0",
            }}
            _pressed={{
              bg: "accent.50",
              color: "base.0",
            }}
            onPress={goToLastPage}
            disabled={currentPage === totalPages}
            variant="solid"
            bg="lightgray"
          >
            <Text>Last Page</Text>
          </Button>
        </Button.Group>
      </HStack>
    </Box>
  );
};

export default Pagination;
