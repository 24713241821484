import React, { Suspense } from "react";
import {
  Animated,
  ActivityIndicator,
  FlatList,
  ScrollView,
  StyleSheet,
  View,
  Pressable,
} from "react-native";
import { Box, HStack } from "native-base";
import { su } from "../../utils/utils";
import { CELL_TYPES } from "../../utils/types";
import { ROW_STYLE_TYPES, MAP_CELL_COLOR_BY_STATUS } from "./tableRelatedTypes";
import Cell from "./cell/Cell";
import CellExpand from "./cell/CellExpand";
import _ from "lodash";
import { colors } from "../../../assets/configs/colors";
import AsyncStorage from "@react-native-async-storage/async-storage";
const NUM_ROWS_STEP = 7;
const CELL_WIDTH = 200;
const CELL_HEIGHT = 60;
const getWidthContainer = (variant) => {
  if (
    variant === CELL_TYPES.multipleItems ||
    variant === CELL_TYPES.headLong ||
    variant === CELL_TYPES.longText
  ) {
    return 328;
  } else {
    if (variant === CELL_TYPES.customComponent) {
      return 368;
    }
    return 250;
  }
};
const getHeight = (isExpanded, cell) => {
  if (cell.attributeObj === "actions2") {
  }

  let height = su(69, 69, 84);
  if (cell?.variant == ROW_STYLE_TYPES.head) {
    return height;
  } else {
    if (isExpanded) {
      height = su(172, 172, 209);
    }
  }

  return height;
};

const colorTable130 = colors.table[130];
const colorBase10 = colors.base[10];

const styles = StyleSheet.create({
  container: {
    backgroundColor: colorBase10,
  },
  text: {
    fontSize: su(16),
    paddingRight: su(16),
    paddingLeft: su(16),
    paddingTop: su(8),
    paddingBottom: su(8),
  },
  header: {
    flexDirection: "row",
  },
  identity: {
    position: "absolute",
    backgroundColor: colorBase10,
    width: CELL_WIDTH,
  },
  body: { marginLeft: CELL_WIDTH },
  cell: {
    width: CELL_WIDTH,
    height: CELL_HEIGHT,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: colorTable130,
  },
  column: { flexDirection: "column" },
});

class Sheet extends React.Component {
  constructor(props) {
    super(props);

    this.headerScrollView = null;
    this.bodyScrollView = null;
    this.isPressable = _.isFunction(this.props.onPressRow);
    this.numCols = this.props.tableHeadConfig.length;
    this.scrollPosition = new Animated.Value(0);
    this.scrollEvent = Animated.event(
      [{ nativeEvent: { contentOffset: { x: this.scrollPosition } } }],
      { useNativeDriver: false }
    );
    this.numRowStep = this.props.numRowStep
      ? this.props.numRowStep
      : NUM_ROWS_STEP;
    this.state = {
      count:
        this.props.dataGrid.length < this.numRowStep
          ? this.props.dataGrid.length
          : this.numRowStep,
      loading: false,
      countLoading: 0,
      index: 0,
      cellsExpandControl: this.props.dataGrid.map(() => false),
      lastExpandedRow: null,
    };

    this.scrollLoad.bind(this);
  }

  async handleScroll(e) {
    if (this.headerScrollView !== null) {
      let scrollX = e.nativeEvent.contentOffset.x;
      this.headerScrollView.scrollTo({ x: scrollX, animated: false });
    }
  }

  scrollLoad() {
    let count = this.state?.count;
    if (!count) {
      return;
    }
    if (this.state.count + 1 < this.props.dataGrid.length) {
      count = count + this.numRowStep;
    } else {
      count = this.props.dataGrid.length;
    }

    this.setState({ loading: false, count: count });
  }

  handleScrollEndReached = () => {
    if (!this.state?.loading && this.props.dataGrid.length > this.numRowStep) {
      this.setState({ loading: true }, () => setTimeout(this.scrollLoad, 500));
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    const result = !_.isEqual(this.props.dataGrid, nextProps.dataGrid);
    return result;
  }

  updateCount() {
    this.setState({ count: this.props.dataGrid.length });
    this.forceUpdate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataGrid.length !== this.props.dataGrid.length) {
      this.updateCount();
    }
  }

  onPressCell(cell) {
    if (this.isPressable) {
      let payload = cell?.parentObj;

      if (this.props.isEditModeActive === true) {
        payload = {
          task: cell?.parentObj,
          selectedAttribute: cell?.attributeObj,
        };
      }

      this.props.onPressRow(payload);
    }
  }

  formatCell(cell, index) {
    const handleOnPress = () => this.onPressCell(cell);
    const onPressExpand = () => {
      cell.onExpand();
    };

    const renderExpander = cell?.expander || false;

    const classColor = cell.cellColor ? cell.cellColor.split(".") : [,];

    const cellWidth = getWidthContainer(cell?.variant);
    const cellColorProp = cell.cellColor
      ? colors[classColor[0]][classColor[1]]
      : colors.table[130];

    const height = getHeight(cell.expanded, cell);
    const style = {
      minHeight: su(69, 69, 56),
      height: height,
      maxHeight: su(172, 172, 209),
      width: !renderExpander ? cellWidth : cellWidth + 82,
      backgroundColor: cellColorProp,
      marginBottom: 1,
      borderRightWidth: 1,
      borderRightColor: colorTable130,
    };
    const isExpanded = this.state.cellsExpandControl?.[index];
    const renderCell = () => {
      return (
        <HStack
          style={style}
          height={height}
          space={0}
          key={_.uniqueId("cr")}
          p={0}
          m={0}
          borderWidth={0}
        >
          {renderExpander && (
            <CellExpand
              height={height}
              variant={cell.variant}
              expanded={isExpanded}
              onPress={onPressExpand}
            />
          )}
          <Suspense>
            <Cell
              id={cell?.id}
              key={_.uniqueId("c")}
              value={cell?.value}
              customComponent={cell?.customComponent}
              variant={cell?.variant}
              ref={cell?.ref}
              isExpanded={isExpanded}
              height={height}
              rowVariant={cell.rowVariant}
              parentObj={cell?.parentObj}
              onExpand={onPressExpand}
            />
          </Suspense>
        </HStack>
      );
    };
    if (this.isPressable) {
      const onPress = () => {
        handleOnPress(cell.parentObj);
      };

      return (
        <Pressable
          style={style}
          height={height}
          key={cell?.key || cell?.id}
          onPress={onPress}
        >
          {renderCell()}
        </Pressable>
      );
    } else return renderCell();
  }
  onExpand(i) {
    const newState = this.state.cellsExpandControl.map((value, index) => {
      if (index === i) {
        return !value;
      } else {
        return false;
      }
    });
    this.setState({ cellsExpandControl: newState });
    this.forceUpdate();
  }

  formatColumn = (section) => {
    let cells = [];

    const index = this.props.freezeFirstColumn
      ? section.index + 1
      : section.index;

    for (let i = 0; i < this.state.count; i++) {
      const columnConfig = this.props.tableHeadConfig[index];

      const columnItemsName = columnConfig?.name;
      const columnItemType = columnConfig?.type;
      const variant = columnConfig?.cellType;
      const rowDataObject = this.props.dataGrid[i];

      let cellValue = rowDataObject?.[columnItemsName];

      if (
        cellValue !== null &&
        !_.isString(cellValue) &&
        !_.isArray(cellValue) &&
        _.isObject(cellValue)
      ) {
        if (_.isString(cellValue?.name)) {
          cellValue = cellValue?.name;
        }
      }
      const status = rowDataObject?.status;
      const cellColor =
        MAP_CELL_COLOR_BY_STATUS[status] ||
        MAP_CELL_COLOR_BY_STATUS[ROW_STYLE_TYPES.basic];

      const expanded = this.props.rowsExpandFeature
        ? this.state.cellsExpandControl?.[i] || false
        : false;

      const cellConfig = {
        key: rowDataObject?.id + _.uniqueId("key") + i,
        value: cellValue || "",
        onExpand: this.onExpand.bind(this, i),
        parentObj: rowDataObject,
        variant: variant,
        attributeObj: columnItemsName,
        attributeType: columnItemType,
        expanded: expanded,
        rowVariant: variant,
        cellColor: cellColor,
        customComponent: columnConfig?.customComponent,
      };
      cells.push(this.formatCell(cellConfig, i));
    }

    return <View style={{ ...styles.column, borderWidth: 0 }}>{cells}</View>;
  };

  formatHeader() {
    let cols = [];
    const head = this.props.dataHead;

    const startAt = this.props.freezeFirstColumn ? 1 : 0;

    for (let i = startAt; i < this.numCols; i++) {
      cols.push(
        this.formatCell({
          value: head[i]?.value,
          variant: head[i]?.variant,
          onExpand: this.onExpand.bind(this, i),
          key: _.uniqueId("header-" + i),
          cellColor: MAP_CELL_COLOR_BY_STATUS[ROW_STYLE_TYPES.head],
        })
      );
    }

    const headCells = {
      ...this.props.dataHead[0],
      cellColor: MAP_CELL_COLOR_BY_STATUS[ROW_STYLE_TYPES.head],
      expander: false,
    };

    const listKey = (item, index) => "kj" + index.toString();

    return (
      <View
        style={{
          ...styles.header,
          paddingLeft:
            this.props.rowsExpandFeature && this.props.freezeFirstColumn
              ? 82
              : 0,
        }}
      >
        {this.props.freezeFirstColumn && this.formatCell(headCells)}
        <ScrollView
          ref={(ref) => (this.headerScrollView = ref)}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          persistentScrollbar={true}
          scrollEnabled={false}
          scrollEventThrottle={5}
          listKey={listKey}
        >
          {cols}
        </ScrollView>
      </View>
    );
  }

  formatIdentityColumn() {
    let cells = [];
    const columnConfig = this.props.tableHeadConfig[0];

    const columnItemsName = columnConfig?.name;
    const columnItemType = columnConfig?.type;

    for (let i = 0; i < this.state.count; i++) {
      const rowDataObject = this.props.dataGrid[i];
      let cellValue = rowDataObject?.[columnItemsName];
      const variant = rowDataObject?.status || ROW_STYLE_TYPES.basic;

      if (
        cellValue !== null &&
        !_.isString(cellValue) &&
        !_.isArray(cellValue) &&
        _.isObject(cellValue)
      ) {
        if (_.isString(cellValue?.name)) {
          cellValue = cellValue?.name;
        }
      }
      const cellConfig = {
        value: cellValue,
        key: "head-" + rowDataObject?.id,
        attributeObj: columnItemsName,
        attributeType: columnItemType,
        variant: variant,
        parentObj: rowDataObject,
        expanded: this.state.cellsExpandControl?.[i],
        onExpand: this.onExpand.bind(this, i),
        expander: true,
        cellColor: MAP_CELL_COLOR_BY_STATUS[rowDataObject?.status],
      };

      cells.push(this.formatCell(cellConfig));
    }

    return <View style={styles.identity}>{cells}</View>;
  }

  formatBody() {
    // iterate all column and create cells
    ///create columns
    let data = [];

    //format each cell of each row

    const startAt = this.props.freezeFirstColumn ? 1 : 0;

    for (let i = startAt; i < this.numCols; i++) {
      data.push({});
    }

    const styleFlatList = this.props.freezeFirstColumn
      ? { marginLeft: getWidthContainer(CELL_TYPES.basic) + 82 }
      : {};

    const listKey = (item, index) => "e" + index.toString();
    return (
      <View>
        <View position="absolute">
          {this.props.freezeFirstColumn && this.formatIdentityColumn()}
        </View>
        <FlatList
          style={styleFlatList}
          horizontal={true}
          showsHorizontalScrollIndicator={true}
          persistentScrollbar={true}
          data={data}
          listKey={listKey}
          ref={(ref) => (this.bodyFlatListRef = ref)}
          initialNumToRender={20}
          renderItem={this.formatColumn}
          stickyHeaderIndices={[0]}
          onScroll={this.scrollEvent}
          scrollEventThrottle={5}
          extraData={this.state}
          maxToRenderPerBatch={20}
        />
      </View>
    );
  }

  formatRowForSheet = (section) => {
    let { item } = section;

    return item.render;
  };

  async componentDidMount() {
    this.listener = this.scrollPosition.addListener(
      async (position) => {
        if (!position.value) {
          return;
        }
        this.headerScrollView.scrollTo({ x: position.value, animated: false });
      },
      { passive: true, once: true }
    );

    const valueScroll = await AsyncStorage.getItem(
      "scrollPosition" + this.props.componentId
    );
    if (valueScroll && this.bodyFlatListRef !== null) {
      this.bodyFlatListRef.scrollToOffset({
        offset: valueScroll,
        animated: false,
      });
    }
  }

  render() {
    let body = this.formatBody();
    let data = [{ key: "body", render: body }];
    const listKey = (item, index) => "p" + index.toString();
    return (
      <View style={styles.container}>
        <Box bg={MAP_CELL_COLOR_BY_STATUS[ROW_STYLE_TYPES.head]}>
          {this.formatHeader()}
        </Box>

        <FlatList
          data={data}
          renderItem={this.formatRowForSheet}
          onEndReached={this.handleScrollEndReached}
          listKey={listKey}
          onEndReachedThreshold={0.005}
          maxToRenderPerBatch={20}
        />
        {this.state?.loading && <ActivityIndicator />}
      </View>
    );
  }
}

export default Sheet;
