import React from "react";
import { FlatList, View } from "react-native";
import RowCardMobile from "../../components/table/rows/RowCardMobile";
import { RowCardMobileVariants } from "../../components/table/rows/rowCardMobileVariants";
import {
  useGetTasksOfQuestionnerQuery,
  useLazyGetTasksOfQuestionnerQuery,
} from "../../services/apiService";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import ReachedFooter from "../../components/ReachedFooter";
import Empty from "../../components/Empty";
const TasksMobileScreen = ({ navigation }) => {
  const insets = useSafeAreaInsets();
  const [page, setPage] = React.useState(1);
  const tasksFilters = useSelector((state) => state.ux.tasksFilters);
  const activityId = tasksFilters?.activity?.id;

  const getTasksRequest = useGetTasksOfQuestionnerQuery(
    { page, activityId },
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );
  const [getLazyTasks, request] = useLazyGetTasksOfQuestionnerQuery({
    page,
    activityId,
  });

  const data = useSelector((state) => state.tasks.tasksMobileList);
  
  const onEndReached = () => {
    if (getTasksRequest?.hasNextPage) {
      setPage(getTasksRequest?.data?.nextPage);
    }
  };
  const ListFooterComponent = () => <ReachedFooter request={getTasksRequest} />;
  const goTo = (taskId) => {
    navigation.navigate("TasksDetails", { taskId });
  };

  const renderItem = ({ item, index }) => {
    const id = item?.id.substring(item?.id.length - 4, item?.id.length);
    const name = `#${id} |  ${item?.project?.name}`;
    return (
      <RowCardMobile
        variant={RowCardMobileVariants.showTask}
        status={item?.status}
        name={name}
        onPress={goTo}
        id={index}
      />
    );
  };

  return (
    <View
      style={{
        paddingTop: insets.top,
        paddingBottom: insets.bottom,
        flex: 1,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <FlatList
        refreshing={request?.isFetching}
        onRefresh={getLazyTasks}
        data={data}
        ListEmptyComponent={Empty}
        ListFooterComponent={ListFooterComponent}
        renderItem={renderItem}
        onEndReached={onEndReached}
        onEndReachedThreshold={2}
        keyExtractor={(item) => item?.id}
      />
    </View>
  );
};

export default TasksMobileScreen;
