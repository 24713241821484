import { Box, Text } from "native-base";
import React from "react";
const TitleLogin = ({ text = null }) => {
  if (text === null) {
    return null;
  }
  return (
    <Box borderRadius="sm" alignItems="center" px={3} py={3} bg="neutral.80">
      <Text
        mt="1"
        color="base.90"
        _dark={{
          color: "warmGray.200",
        }}
        alignText="center"
        fontWeight="medium"
        fontSize="md"
      >
        {text}
      </Text>
    </Box>
  );
};

export default TitleLogin;
