import React from "react";
import { Box, IconButton, HStack } from "native-base";
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { ROW_STYLE_TYPES } from "../rows/rowTypes";

const nubColorMap = {
  [ROW_STYLE_TYPES.complete]: "table.20",
  [ROW_STYLE_TYPES.incomplete]: "table.90",
  [ROW_STYLE_TYPES.unacknowledged]: "table.110",
  [ROW_STYLE_TYPES.confirmationRequired]: "table.50",
  [ROW_STYLE_TYPES.head]: "neutral.10",
  ["headLong"]: "neutral.10",
};
const CellExpandWidget = ({ onPress, isExpanded, styleTemplate }) => {
  const boxColor = nubColorMap[styleTemplate];
  return (
    <HStack
      alignItems="center"
      justifyContent="center"
      p={0}
      h={0}
      borderWidth={1}
      borderColor={'red'}
      style={{ width: 82}}
    >
      <Box
        bg={boxColor}
        borderLeftRadius="md"
        style={{ width: 16}}
      />
      <IconButton
        onPress={onPress}
        size={8}
        _icon={{
          as: isExpanded ? FontAwesome : MaterialCommunityIcons,
          name: isExpanded ? "expand" : "arrow-collapse",
        //   width: 40,
        //   height: 40,

          color: boxColor,
        }}
      />
    </HStack>
  );
};

export default CellExpandWidget;
