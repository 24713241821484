import { HStack, Text, Pressable } from "native-base";
import React from "react";
import { Ionicons } from "@expo/vector-icons";
const ResolveFlowNavigationDesktop = ({
  onNext,
  onBack,
  hideNext = false,
  wFull = false,
}) => {

  const maxWidth = !wFull ? hideNext?100: 667 : "100%";
  return (
    <HStack
      w="full"
    
      style={{ maxWidth: maxWidth, height: 35 }}
      justifyContent="space-between"
    >
      <Pressable onPress={onBack}>
        <HStack
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
          alignContent="center"
          space={2}
        >
          <Ionicons name="ios-chevron-back" size={16} color="primary.100" />
          <Text fontSize="sm" color="primary.100">
            Back
          </Text>
        </HStack>
      </Pressable>
      {!hideNext && (
        <Pressable onPress={onNext}>
          <HStack
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            alignContent="center"
            space={2}
          >
            <Text fontSize="sm" color="primary.100">
              Next
            </Text>
            <Ionicons
              name="ios-chevron-forward-sharp"
              size={16}
              color="primary.100"
            />
          </HStack>
        </Pressable>
      )}
    </HStack>
  );
};

export default ResolveFlowNavigationDesktop;
