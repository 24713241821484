import React from "react";
import { Toast } from "native-base";
import { TASKS_TABLE_COLUMNS } from "./tableColumns";
import Table, { createTableBluePrint } from "../../components/table";
import { TASKS_STATUS_TYPES } from "./tasksStatusTypes";
import { useSelector, useDispatch } from "react-redux";
import {
  FLOW_STATUS_MODES,
  useAcrossDevices,
  MODAL_CONTENT_TYPES,
} from "../../utils/utils";
import { startAcknowledgeTask } from "../../features/tasksSlice";
import { openModalAndSetContent } from "../../features/uxSlice";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  useGetTasksQuery,
  useLazyGetTasksQuery,
  api,
} from "../../services/apiService";
import Empty from "../../components/Empty";
import LoadingData from "../../components/LoadingData";
import LayoutPagination from "../../containers/LayoutPagination";
import { InstanceTasksMainFlowModel } from "../../screens/tasks/main-flow/TaskFlowObj";
import TasksMobileScreen from "./TasksMobile";
import { TASKS_FLOW_SCREENS_DATA_TYPES } from "../../../assets/constants/tasksFlowDataType";
import _ from "lodash";
import { useRoute } from "@react-navigation/native";
import PropTypes from "prop-types";


export const flowScreens = InstanceTasksMainFlowModel.getFlowNavigatorConfig();
const TasksScreen = ({ navigation }) => {
  const route = useRoute();
  const [page, setPage] = React.useState(route?.params?.page || "1");
  const data = useSelector((state) => state.tasks.tasks[page], _.isEqual);
  const { isMobile } = useAcrossDevices();

  const isEditModeActive = useSelector(
    (state) => state.ux?.isEditModeActive,
    _.isEqual
  );

  const getTasksRequest = useGetTasksQuery(page);
  const [getLazyTasks] = useLazyGetTasksQuery(page);

  const dispatch = useDispatch();

  if (
    getTasksRequest?.isLoading ||
    getTasksRequest?.isFetching ||
    getTasksRequest?.isError
  ) {
    return (
      <LoadingData
        isFetching={getTasksRequest.isFetching}
        isLoading={getTasksRequest.isLoading}
        isError={getTasksRequest.isError}
        error={getTasksRequest.error}
        refetch={getLazyTasks}
      />
    );
  }
  if (!data || data.length === 0) {
    return <Empty />;
  }

  if (isMobile) {
    return <TasksMobileScreen tasks={data} navigation={navigation} />;
  }

  const onPressRow = (payload) => {
    if (!payload) {
      return;
    }
    //TODO: TEST ONLY, uncommnet for prod
    // if (isEditModeActive) {
    if (isEditModeActive) {
      if (!payload?.selectedAttribute || !payload?.task) {
        Toast.show({
          closeAll: true,
          description:
            "Something went wrong and the edit modal cannot be displayed.",
        });
        return;
      }

      const unEditableAttriubtes = [
        "error",
        "errorReport",
        "actionRequired",
        "finalReport",
        "actions",
      ];

      if (unEditableAttriubtes.includes(payload.selectedAttribute)) {
        Toast.show({
          closeAll: true,
          description: "This field is not editable.",
        });
        return;
      }

      const title = "Edit";
      // elementType: flowScreens[cell?.attributeObj],
      const { task, selectedAttribute } = payload;
      let elementTypeIndex = flowScreens.findIndex(
        (item) => item.name === selectedAttribute
      );
      let elementType = TASKS_FLOW_SCREENS_DATA_TYPES.oneItem;
      if (elementTypeIndex === -1) {
        elementType = flowScreens[elementTypeIndex]?.inputDataType;
      }

      dispatch(
        openModalAndSetContent({
          contentBody: MODAL_CONTENT_TYPES.editTask,
          submitLabel: "Save edit",
          content: { task, selectedAttribute, elementType },
          title: title,
          size: "xl",
        })
      );
      return;
    }

    const task = payload;

    if (task.status === TASKS_STATUS_TYPES.unacknowledged) {
      dispatch(startAcknowledgeTask(task));
      navigation.navigate("TasksFlow", {
        mode: FLOW_STATUS_MODES.acknowledge,
      });
    }
    if (
      task.status === TASKS_STATUS_TYPES.confirmationRequired ||
      task.status === TASKS_STATUS_TYPES.incomplete
    ) {
      dispatch(api.util.invalidateTags([{ type: "Task", id: task.id }]));
      navigation.replace("ResolveFlow", {
        screen: "ResolveTask",
        params: { taskId: task.id },
      });
    } else if (task.status === TASKS_STATUS_TYPES.complete) {
      navigation.push("ResolveFlow", {
        screen: "Report",
        params: { reportId: task.finalReport?.id },
      });
    }
  };

  const bluePrint = createTableBluePrint(data, TASKS_TABLE_COLUMNS, "id");

  return (
    <SafeAreaView>
      <LayoutPagination
        totalPages={getTasksRequest?.data?.totalPages}
        currentPage={getTasksRequest?.data?.page}
        setPage={setPage}
        refetch={getTasksRequest.refetch}
      >
        <Table
          onPressRow={onPressRow}
          freezeFirstColumn={true}
          data={data}
          numRowStep={data.length <= 6 ? data.length : 6}
          bluePrint={bluePrint}
          tableHeadConfig={TASKS_TABLE_COLUMNS}
          rowsExpandFeature={true}
          isEditModeActive={isEditModeActive}
          componentId={"tasks"}
        />
      </LayoutPagination>
    </SafeAreaView>
  );
};
TasksScreen.propTypes = {
  navigation: PropTypes.object,
};

export default TasksScreen;
