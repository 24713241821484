// MainFlowModel.getNewTasks();

// language structure
// -- screens
// ---- title : "" / {}
// ---- labels : {}
// ---- placeholders : {}
// ---- buttons : {}
// ---- errors : {}
// ---- success : {}
// ---- text: {}
// ---- modal: {}
// ---- components: {}
// ---- tableColumnsNames: {}
// -- globalComponents

export const BasicStrings = {
  en: {
    errorsMsg: {
      unexpected: "An unexpected error occurred.",
      generalError:
        "An error occurred while attempting to process your request. We apologize for the inconvenience. Please try again later or reach out to your administrator for further assistance.",
    },
    screens: {
      login: {
        title: "Sign in to continue!",
        labels: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          signIn: "Sign in",
        },
        errors: {
          incorectData: "The email or password provided is incorrect",
        },
      },
    },
  },
};

export const Strings = {
  en: {
    screens: {
      changePassword: {
        labels: {
          newPassword: "New password",
          repeatNewPassword: "Repeat new password",
        },
      },
      login: {
        title: "Sign in to continue!",
        labels: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          signIn: "Sign in",
        },
        errors: {
          incorectData: "The email or password provided is incorrect",
        },
      },
      twoFactorAuth: {
        title: "Two-factor Authentication ",

        description:
          "Your account has been configured to use Two-factor Authentication . Type your 6-digit code from your mobile app below to verify your identity.",
        buttons: {
          verify: "Verify",
          cancel: "Cancel",
        },
      },
      questionnaires: {
        title: "Questionnaires",
        questionnaireName: "Questionnaire name",
      },
      formA: {
        title: "Form A Documents",
      },
      formB: {
        title: "Form B Documents",
      },
      OtherDocuments: {
        title: "Form Other Documents",
      },
      account: {
        title: "Account",
      },
      notifications: {
        titleMobile: "Updates",
        title: "Notifications",
        buttons: {
          approve: "Approve",
          approved: "Approved",
          reject: "Reject",
          rejected: "Rejected",
          markRead: "Mark as read",
          markUnread: "Mark as unread",
          read: "Read",
          unread: "Unread",

          deny: "Deny",
          denied: "Denied",
        },
        messages: {
          approved: 'The report was marked as "Approved".',
          rejected: 'The report was marked as "Rejected".',
          read: 'The notification was marked as  "Read".',
          unread: 'The notification was marked as  "Unread".',
        },
      },
      users: {
        UserModal: {
          title: "Create new account",
          labels: {
            firstName: "First Name",
            lastName: "Last Name",
            email: "Email",
            classType: "User class",
            reportsTo: "Reports To",
            reportsToPlaceholder: "Choose a user",
            classTypes: {
              superUser: "Super User",
              m0: "Project Manager",
              flex: "Flexible",
            },
          },
          errors: {
            invalidField: "Should contain at least 2 characters.",
          },
        },
        title: "Users Accounts",
        buttons: {
          new: "Create new account",
          save: "Save",
        },
      },
      taskDetails: {
        title: "Task Details",
        labels: {
          project: "Project name",
          area: "Area",
          progress: "progress",
          plannedDates: "Plannned Dates",
          actualDates: "Actual Dates",
          reportsStatus: "Reports status",
          start: "start",
          end: "end",
          errorReportStatus: "Error",
          finalReportStatus: "Final",
          dis: "dis",
          pn: "pn",
          notStarted: "Not started",
          notFinished: "Not finished",
          na: "N/A",

          statuses: {
            complete: "Completed",
            unacknowledged: "Unacknowledged",
            confirmationRequired: "Resolve Task",
            incomplete: "Deadline overdue",
          },
        },
        buttons: {
          startResolve: "Start Resolve task",
          acknowledgeTask: "Start Acknowledge task",
          confirmationRequired: "Start Acknowledge task",
          complete: "View final report",
        },
      },
      tasks: {
        title: "Tasks status",
        tableColumnsNames: {
          project: "Project",
          area: "Area",
          dis: "DIS",
          pn: "PN",
          questionnaire: "ITP",
          activity: "Activity",
          plannedStartDate: "Planned start date",

          plannedEndDate: "Planned end date",
          actualStartDate: "Actual start date",
          actualEndDate: "Actual end date",
          assignTo: "Assign to",
          status: "Task Status",
          error: "NCR",
          errorStatus: "NCR Status",
          actionRequired: "Action Required",
          reportStatus: "Report Status",
          actions: "Actions",
        },
        tableActions: {
          submitErrorReport: "Submit NCR Report",
          errorReportSubmitted: "NCR Submitted",
          submitFinalReport: "Submit Final Report",
          finalReportSubmitted: "Final Report awaiting approval",
          resolveTask: "Resolve Task",
          acknowledgeTask: "Acknowledge task",
          noLocationAvailable:
            "There is no recorded location available for this task.",
        },
        buttons: {
          locationsHistory: "View locations",
          deleteTask: "Delete",
          editTask: "Edit",
          exitEditTask: "Exit edit mode",
        },
      },

      mainTasksFlow: {
        flowName: "Add new task",
        title: "Task details",
        progress: "progress",
        project: "Project",
        area: "Area",
        dis: "DIS",
        pn: "PN",
        questionner: "ITP",
        activity: "ITP's activities",
        startEndDate: "Start and end date",
        formA: "Form A document",
        docs: "related documentation",
        formB: "Form B document",
        submissionDate: "Submission date",
        assignTo: "Team member",
        flowTitles: { newTask: "Add task", acknowledge: "Acknowledge task" },
        messages: {
          pressAgainForAknowledge:
            "The last step was confirmed. Press on more time to submit the task akwnowledgment.",
        },
        warnings: {
          noProjectManagement:
            "Select another project. The selected project does not have a project manager or/and team leader. ",
          noAreas:
            " Select another project. The selected project does not have any areas, so you cannot go further. ",
          missingSteps:
            "You must complete the following steps before you can go further:.",
          projectRequired:
            "A project must be selected to be able to selected an area.",
          noDataSelected: "You must select a option to continue.",
          noFormAFiles:
            "The selected project does not have any Form A documents defined. You may want to select another project.",
          noFormBFiles:
            "The selected project does not have any Form B documents defined. You may want to select another project.",
          notEnoughData:
            "The element you selected doesn't have any other item associated with it as children. Please choose another option to continue the operation or contact your administrator for more information.",
        },
        select: "Select",
        acknowledge: "Acknowledge",
        labels: {
          date: "Select date",
          startDate: "Tap here to choose start date",
          endDate: "Tap here to choose end date",
        },
      },
      flowStatus: {
        successNewTask: "A new task was created.",

        successAcknowledge: "All tasks elements were acknowledged.",
        successResolveTask: "The report is ready to be submited!",
        successReport: "The report was succefully submited!",
        failNewTask:
          "The system encountered an issue while trying to create the task. Please try again later.",
        buttons: {
          goToTasks: "Go to Tasks",
          newTask: "Add a new task",
          submitReport: "Submit report",
          viewReport: "View report",
          startTask: "Start task",
        },
      },
      settings: {
        buttons: {
          newQuestionner: "Add new ITP file",
          newProject: "Add new project",
          newArea: "Add new area",
          newDis: "Add new dis",
          newPn: "Add new PN",
          newFormA: "Add new Form A",
          newFormB: "Add new Form B",
          newDoc: "Add new doc",
          save: "Save",
          setProjectManager: "Choose project manager",
          setTeamLeader: "Choose team leader",
          setOtherKeyPeoples: "Choose key members",
          placeHolderInput: "type item name...",
          keyPeoples: "Project Key Members",
          docs: "Related docs",
          editName: "Edit {{elementType}} name",
          defaultTitleModal: "Operations on the  {{elementType}} name",
          deleteItem: "Delete {{elementType}}",
          elProject: "project",
          elArea: "area",
          elDis: "dis",
          el: "element",
          elPn: "pn",
          elQuestionnaire: "ITP",
          elFormA: "form A",
          elFormB: "form B",
          elRelatedDocs: "related file",
          cancel: "Cancel",
          done: "Done",
          selectFile: " Select file",
          deletePermanently: "Delete permanently",
        },
        dialogsContent: {
          deleteWarning:
            "This action will permanently delete all data associated with: **{{title}}**. Please note that this process is irreversible, and deleted data cannot be recovered.",
        },
        treeItemsTitles: {
          formA: "Form A",
          formB: "Form B",
          otherDocs: "Other docs",
          relatedDocs: "Related docs",
        },
        toasts: {
          deleteItem: "The {{itemName}} was successfully deleted.",
          editItem: "The {{itemName}} was successfully renamed.",
        },
      },
      configureTwoFactor: {
        title: {
          activate: "Activate Two-Factor Authentication",
          registerDevice: "Register device for Two-Factor Authentication",
        },
        step1:
          "1. Open Google Authenticator on your smartphone (android or iOs)",
        step2:
          '2. Press on the "Plus" button placed at the bottom of the screen and then tap on "Scan QR code" option',
        step3:
          '3. Enter bellow a one-time passcode from the new row of temporary codes and then press the button "Register device"',
        warning:
          "Remember: Once you register a new one-time passcode generator app, you can no longer use the old one.",
        unableToScan:
          "Are you unable to scan the QR code? Click here for a manual setup.",
        buttons: {
          cancel: "Cancel",
          register: "Register device",
        },
        placeholder: "Type here one-time pass-code",
        modal: {
          title: "Manual setup instructions",
          step1:
            "1. Open Google Authenticator on your smartphone (android or iOs)",
          step2:
            '2. Press on the "Plus" button placed at the bottom of the screen and then tap on "Enter a setup key" option',
          step3: '3. Write "Anuu Inc App" in the Account Name field',
          step4:
            '4. Type the key from below in the field "your key". If you are using the app on the same device where you have opened the Google Authenticator app, you can tap or click on the key and then paste it.',
          step5: '5.The type of key must be "Time-based" (default choice).',
          step6: '6. Press "Add" button',
          suggestTap: "Tap on the key to copy it",
        },
      },
      finalReport: {
        title: "Inspection Report",
        section1Title: "Quick Review",
        section2Title: "Report Details",
        distribution: "Distribution: ",
        labels: {
          pn: "Tag number",
          mov: "MOV",
          projectNumber: "Project Number",
          questionnaireNo: "ITP (Q1) number: ",
          areaNumber: "Area Number",
          projectName: "Project name",
          uploadLogo1: "Upload logo 1",
          uploadLogo2: "Upload logo 2",
          subcontractor: "Subcontractor/Supplier",
          answerImages: "Images uploaded before submission",
        },
        buttons: {
          submit: "Submit report",
          download: "Download PDF",
          uploadImages: "Add photos",
          takePhoto: "Take photo",
        },
        messages: {
          logoRemoved: "Logo removed successfully.",
          missingFields:
            "You must fill all the fields with at least two characters to submit the report.",
          reportSubmitted: "Report submitted successfully.",
          reportFetchError:
            "The required resources to prepare the report aren't available. Please contact the administrator.",
        },
      },
      errorReport: {
        title: "Nonconformance Report",
        labels: {
          pn: "Product numbers",
          attachedImages: "Attached Images",
          answerImages: "Images uploaded before submission",
          errorCode: "Error code",
          describeError: "Describe error:",
          questionnaireRequest: "ITP Request",
          additional: "Additional information",
          subcontractor: "Subcontractor/Supplier:",
          contractNo: "Contract number:",
          ncrNo: "NCR No",
          qty: "QTY",
          tag: "The following tags  are the subject of the report:",
          location: "Location",
          ref: "Ref: DOC/DRW",

          tagNumber: "Tag number",
          projectName: "Project name",
          uploadLogo1: "Upload logo 1",
          uploadLogo2: "Upload logo 2",
        },
        buttons: {
          submit: "Submit report",
          addAdditional: "Add additional info",
          uploadImages: "Add photos",
          takePhoto: "Take photo",
          uploadOtherImages: "Upload other images",
          referenceDocuments: "Reference Documents",
        },
        errors: {
          invalidForm: "Please fill all required fields",
        },
        success: {
          reportSubmitted: "Report submitted successfully.",
        },
      },
      resolveTask: {
        title: "Resolve Task",
        success: {
          answerSaved: "Your response has been saved successfully.",
          commentSaved: " Your comment has been saved successfully.",
          imagesUploaded: "The upload of images was completed successfully.",
        },
        errors: {
          taskNotFound: "The task could not be located.",
          pnBlocked:
            "Until an error report is submitted and approved, it is not possible to select this item.",
          errorReportRedirect:
            "In order to proceed, it is mandatory to submit an error report, as an invalid response has been detected in a prior submission. Therefore, we will redirect you to the report submission screen.",
          noPredefined: "Question doesn't have predefined answers.",
          emptyResponse: "An empty response is not allowed",
          uploadingImages:
            "An error occurred during the file upload process. Please attempt again.",
          noPhoto: "You did not take or selected any images. ",
          cameraDenied:
            "It appears that you have declined permission for the app to access your camera. Consequently, you will be unable to utilize this feature.",
          cameraError:
            "During the attempt to launch the device's camera, an error occurred.",
          noImages: "No images were chosen by you.",
          pickImagesFromDevice:
            "An error occurred while attempting to retrieve the images from your device. Please make another attempt.",
        },
        placeholders: {
          comment: "Write your comment",
          inputEmpty: "Type value",
          differentValues: "Individual values",
        },
        buttons: {
          submitResponse: "Submit",
          submit: "Submit Final report",
          goToTasks: "Go to Tasks",
          updateComment: "Update comment",
          addComment: "Add comment",
          yes: "Yes",
          no: "No",
          na: "N/A",
        },
      },
      loadingData: {
        title1: "The setup process is starting, just a moment...",
        title2: "Information is being loaded, thank you for your patience...",
        title3: "We are currently fetching your data, please hold on...",
        descriptionDefault:
          "Something went wrong while trying to fetch the data required to display this page",
        description403:
          "The user you are logged in is not allowed to access this page or resource.",

        labels: {
          technicalErrorCode: " Error technical code",
        },
        buttons: {
          retry: "Retry",
          signOut: "Sign out",
        },
      },
      accountMobile: {
        title: "Logged as",
        class: "Class",
        logout: "Logout",
        version: " App version ",
      },
    },
    empty: {
      noData:
        "The current page appears to be empty. To begin, you can add a new item. If you believe you shouldn't be seeing this page, please contact the administrator for further assistance and information.",
      selectFile:
        "Select the file you want to view by pressing the button from bellow",
    },
    menus: {
      header: {
        account: "Account",
        changePassword: "Change password",
        configure2fa: "Change 2FA device",
      },
      side: {
        project: "Project",
        area: "Area",
        dis: "DIS",
        pn: "PN",
        questionner: "ITP",
        activity: "ITP's activities",
        startEndDate: "Start and end date",
        formA: "Form A document",
        docs: "Related documentation",
        formB: "Form B document",
        submissionDate: "Submission date",
        assignTo: "Team member",
      },
      tasksFlow: {
        back: "Back",
        next: "Next",
        confirm: "Confirm",
        done: "Done",
      },
    },
    modalTitles: {
      addImage: "Add image",
      resultsTable: "Show results",
      viewQuestionnaire: "View questionnaire",
      reportError: "Report error",
      finalError: "Task final report",
      locationHistory: "Location history",
      addComment: "Add comment",
      viewDocs: "View docs",
      newUser: "New user",
      editUser: "Edit user",
      accountDetails: "Account details",
      changePassword: "Change password",
      configure2fa: "Configure two-factor Authentication",
      settingsElementActions: "Operations on the {{elementType}}: {{title}}",
    },
    errorsMsg: {
      atLoadingTranslations:
        "The app encountered an error while attempting to load the most recent translations. As a result, it will resort to using a previous version of the translations.",
      unexpected: "An unexpected error occurred.",
      generalError:
        "An error occurred while attempting to process your request. We apologize for the inconvenience. Please try again later or reach out to your administrator for further assistance.",
    },
    treeItems: {
      project: { one: "project", other: "projects" },
      area: { one: "area", other: "areas" },
      dis: { one: "dis", other: "dises" },
      pn: { one: "pn", other: "pns" },
      questionnaire: { one: "questionnaire", other: "questionnaires" },
    },
    components: {
      mobileBottomMenu: {
        account: "Account",
        surveys: "ITP",
        notifications: "Updates",
        tasks: "Tasks",
      },
      reachedFooter: {
        reachedEnd: "You reached the end of the list",
        totalItems: "Total items",
      },
      previewFileHeader: {
        fileName: "File name",
        projectName: "Project name",
        uploadedBy: "Uploaded by",
        dataUnavailable: "The metadata for this file is not available",
      },
      selectDocsListModal: {
        title: "Select document",
        selectFile: "Select document",
        noFiles: "No files were found at this moment for your account. ",
      },
      notFound: {
        message:
          "We were unable to locate the resource required to display this page. If you continue to encounter this issue, please reach out to the platform administrator for assistance.",
      },
      viewMediaCollection: {
        buttons: {
          uploadFiles: "Upload files",
          previewFile: "Preview file",
        },
      },
    },
    errors: {
      uploadImages:
        "Something went wrong while attempting to get the images from the device. Please try again.",
    },
    utils: {
      toasts: {
        uploadingImages:
          "An error occurred during the file upload process. Please attempt again.",
        noPhoto: "You did not take or selected any images. ",
        cameraDenied:
          "It appears that you have declined permission for the app to access your camera. Consequently, you will be unable to utilize this feature.",
        cameraError:
          "During the attempt to launch the device's camera, an error occurred.",
      },
    },
  },
};
