import { TASKS_FLOW_SCREENS_DATA_TYPES } from "../../../../assets/constants/tasksFlowDataType";
import {
  capitalizeFirstLetter,
  MAIN_FLOW_STEPS,
  i18n
} from "../../../utils/utils";
import { appRoutes } from "../../../navigation/navigationConfig";

export class TasksMainFlowModel {
  constructor(
    project,
    area,
    dis,
    pn,
    questionner,
    activity,
    startEndDate,
    formA,
    docs,
    formB,
    submissionDate,
    assignTo
  ) {
    this[MAIN_FLOW_STEPS.project] = project;
    this[MAIN_FLOW_STEPS.area] = area;
    this[MAIN_FLOW_STEPS.dis] = dis;
    this[MAIN_FLOW_STEPS.pn] = pn;
    this[MAIN_FLOW_STEPS.questionner] = questionner;
    this[MAIN_FLOW_STEPS.activity] = activity;
    this[MAIN_FLOW_STEPS.startEndDate] = startEndDate;
    this[MAIN_FLOW_STEPS.formA] = formA;
    this[MAIN_FLOW_STEPS.docs] = docs;
    this[MAIN_FLOW_STEPS.formB] = formB;
    this[MAIN_FLOW_STEPS.submissionDate] = submissionDate;
    this[MAIN_FLOW_STEPS.assignTo] = assignTo;
  }
  _getBusinessKeys() {
    return Object.keys(this).filter(
      (item) =>
        item.substring(0, 2) !== "get" || item.substring(0, 3) !== "_get"
    );
  }

  _getFlowInputTypesMap(acknowledgeMode) {
    const obj = {};
    const { oneItem, multipleItems, date, interval } =
      TASKS_FLOW_SCREENS_DATA_TYPES;

    this._getBusinessKeys().forEach((key) => {
      switch (key) {
        case "startEndDate":
          obj[key] = interval;
          break;
        case "submissionDate":
          obj[key] = date;
          break;
        case "pn":
          obj[key] =multipleItems;
          break;
        case "formA":
        case "formB":
          obj[key] = acknowledgeMode
            ? TASKS_FLOW_SCREENS_DATA_TYPES.docsViewer
            : oneItem;
          break;
        case "docs":
          obj[key] = acknowledgeMode
            ? TASKS_FLOW_SCREENS_DATA_TYPES.docsViewer
            : TASKS_FLOW_SCREENS_DATA_TYPES.docsPicker;
          break;
        case "assignTo":
          obj[key] = "user";
          break;
        case "date":
          obj[key] = "date";
          break;
        default:
          obj[key] = oneItem;
          break;
      }
    });

    return obj;
  }
  getFlowNavigatorConfig(acknowledgeMode) {
    const businessKeys = this._getBusinessKeys();
    const inputTypeMap = this._getFlowInputTypesMap(acknowledgeMode);
    const flowRoutes = appRoutes.screens.TasksFlow.screens;

    const flowTitles =  i18n.t('menus.side')  ;

    const result = businessKeys.map((key, index) => {
      const nextNav =
        businessKeys.length === index + 1
          ? MAIN_FLOW_STEPS.status
          : flowRoutes[capitalizeFirstLetter(businessKeys[index + 1])]?.path;

          return {
        name: key,
        path: flowRoutes[capitalizeFirstLetter(businessKeys[index])]?.path,
        title: i18n.t('screens.mainTasksFlow.select') + ` ${flowTitles[key]}`,
        inputDataType: inputTypeMap[key],
        nextNav,
        number: index + 1,
        shortTitle: flowTitles[key],
      };
    });

    return result;
  }
}

export const InstanceTasksMainFlowModel = new TasksMainFlowModel();
