import React from "react";
import {
  Box,
  VStack,
  FormControl,
  Button,
  Text,
  Spacer,
  Toast,
} from "native-base";
import { USER_MODAL_MODES, i18n } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { USER_CLASSES_TYPES } from "./userTypes";
import {
  useUnlockPasswordMutation,
  useUnlockTwoFactorMutation,
  useDeleteUserMutation,
  useDisableAcountMutation,
  useActivateAccountMutation,
  useResetAccountPasswordMutation,
  useGetAllUsersQuery,
} from "../../services/apiService";
import { closeModal } from "../../features/uxSlice";
import { NEW_USER_FORM_FIELDS } from "./formFields";
import { useFormik } from "formik";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import * as Yup from "yup";
import { setContent } from "../../features/uxSlice";
import _ from "lodash";
const SELECT_USER_CLASSES_TYPES_OPTIONS = [
  {
    label: i18n.t("screens.users.UserModal.labels.classTypes.superUser"),
    value: USER_CLASSES_TYPES.superUser,
  },
  {
    label: i18n.t("screens.users.UserModal.labels.classTypes.m0"),
    value: USER_CLASSES_TYPES.m0,
  },
  {
    label: i18n.t("screens.users.UserModal.labels.classTypes.flex"),
    value: USER_CLASSES_TYPES.flex,
  },
];
const { firstName, lastName, email, classType, reportsTo } =
  NEW_USER_FORM_FIELDS;

const NewUserSchema = Yup.object().shape({
  [firstName]: Yup.string()
    .min(2, "Too Short!")
    .max(60, "Too Long!")
    .required("Required"),
  [lastName]: Yup.string()
    .min(2, "Too Short!")
    .max(60, "Too Long!")
    .required("Required"),
  [email]: Yup.string().email("Invalid email").required("Required"),
  [classType]: Yup.string()
    .min(2, "Too Short!")
    .max(60, "Too Long!")
    .required("Required"),
  [reportsTo]: Yup.string()
    .min(2, "Too Short!")
    .max(60, "Too Long!")
    .required("Required"),
});

const EditUserrSchema = Yup.object().shape({
  [firstName]: Yup.string()
    .min(2, "Too Short!")
    .max(60, "Too Long!")
    .required("Required"),
  [lastName]: Yup.string()
    .min(2, "Too Short!")
    .max(60, "Too Long!")
    .required("Required"),
  [email]: Yup.string().email("Invalid email").required("Required"),
  [classType]: Yup.string()
    .min(2, "Too Short!")
    .max(60, "Too Long!")
    .required("Required"),
  [reportsTo]: Yup.lazy((val) =>
    _.isString(val) ? Yup.string() : Yup.object()
  ),
});

const UserModal = ({ data, mode }) => {
  const dispatch = useDispatch();
  useGetAllUsersQuery();

  const users = useSelector((state) => state.users.allUsers);
  const loggedUser = useSelector((state) => state.auth.user);

  const [unlockPassword, unlockPasswordRequest] = useUnlockPasswordMutation();
  const [deleteUser, deleteUserRequest] = useDeleteUserMutation();
  const [unlockTwoFactor, unlockTwoFactorRequest] =
    useUnlockTwoFactorMutation();

  const [disableAcount, disableAcountRequest] = useDisableAcountMutation();
  const [resetAccountPassword, resetAccountPasswordRequest] =
    useResetAccountPasswordMutation();
  const [activateAccount, activateAccountRequest] =
    useActivateAccountMutation();
  const setInitialValues = (data) => {
    if (data) {
      let dataCopy = {};
      dataCopy = _.cloneDeep(data);
      // eslint-disable-next-line no-self-assign
      dataCopy[reportsTo] = dataCopy[reportsTo];
      return dataCopy;
    }
  };
  const initialValues = data ? { ...setInitialValues(data) } : {};

  const handlerResetPassword = () => {
    const userId = data?.id;
    const userEmail = data?.email;
    if (!userId || !userEmail) {
      Toast.show(
        "An error occurred while trying to reset password. Contact your administrator."
      );
      return;
    }
    resetAccountPassword({ userId, userEmail })
      .then((response) => {
        if (response.error?.data?.errors) {
          response.error?.data?.errors.forEach((err) => {
            Toast.show({
              closeAll: true,
              description: "Request failed. " + err.message,
            });
          });
          return;
        }

        if (response?.error?.data?.message) {
          Toast.show({
            closeAll: true,
            description: response.error.data.message,
          });
          return;
        }

        if (response.data.passwordReseted) {
          Toast.show({
            closeAll: true,
            description:
              "User account password has been reseted successfully. An email was sent to " +
              data?.email,
          });
          return;
        } else {
          Toast.show({
            closeAll: true,
            description:
              "An error occurred while trying to reset password. Please try again.",
          });
        }
      })
      .catch((err) => {
        if (err?.data?.message) {
          Toast.show({ closeAll: true, description: err?.data?.message });
        }
      });
    dispatch(closeModal());
  };

  const handlerActivateAcount = () => {
    const userId = data?.id;
    if (!userId) {
      Toast.show(
        "An error occurred while trying to disable user. Contact your administrator."
      );
      return;
    }
    activateAccount({ userId })
      .then((response) => {
        if (response.error?.data?.errors) {
          response.error?.data?.errors.forEach((err) => {
            Toast.show({
              closeAll: true,
              description: "Request failed. " + err.message,
            });
          });
          return;
        }

        if (response?.error?.data?.message) {
          Toast.show({
            closeAll: true,
            description: response.error.data.message,
          });
          return;
        }

        if (response) {
          Toast.show({
            closeAll: true,
            description: "User account has been activated successfully.",
          });
          return;
        }
      })
      .catch((err) => {
        if (err?.data?.message) {
          Toast.show({ closeAll: true, description: err?.data?.message });
        }
      });
    dispatch(closeModal());
  };

  const handlerDisableAcount = () => {
    const userId = data?.id;
    if (!userId) {
      Toast.show(
        "An error occurred while trying to activate user. Contact your administrator."
      );
      return;
    }
    disableAcount({ userId })
      .then((response) => {
        if (response.error?.data?.errors) {
          response.error?.data?.errors.forEach((err) => {
            Toast.show({
              closeAll: true,
              description: "Request failed. " + err.message,
            });
          });
          return;
        }

        if (response?.error?.data?.message) {
          Toast.show({
            closeAll: true,
            description: response.error.data.message,
          });
          return;
        }

        if (response) {
          Toast.show({
            closeAll: true,
            description: "User account has been disabled successfully.",
          });
          return;
        }
      })
      .catch((err) => {
        if (err?.data?.message) {
          Toast.show({ closeAll: true, description: err?.data?.message });
        }
      });
    dispatch(closeModal());
  };

  const handlerDeleteUser = () => {
    const userId = data?.id;
    if (!userId) {
      Toast.show(
        "An error occurred while trying to disable user. Contact your administrator."
      );
      return;
    }
    deleteUser({ userId, page: data?.page })
      .then((response) => {
        if (response?.error?.data?.errors) {
          response.error?.data?.errors.forEach((err) => {
            Toast.show({
              closeAll: true,
              description: "Request failed. " + err.message,
            });
          });
          return;
        }

        if (response?.error?.error) {
          Toast.show({
            closeAll: true,
            description: i18n.t("errorsMsg.generalError"),
          });
          return;
        }

        if (response?.data) {
          Toast.show({
            closeAll: true,
            description: "User account has been deleted successfully.",
          });
          return;
        }
      })
      .catch(() => {});

    dispatch(closeModal());
  };

  const handleUnlockPassword = () => {
    if (!data?.email) {
      Toast.show(
        "An error occurred while trying to unlock. Contact your administrator."
      );
      return;
    }
    unlockPassword({
      email: data.email,
    })
      .then((response) => {
        if (response?.data?.unlocked === true) {
          Toast.show({
            closeAll: true,
            description: "User password lock has been lifted.",
          });
          return;
        } else {
          Toast.show({
            closeAll: true,
            description: "An error occurred while trying to unlock. Try again.",
          });
        }
      })
      .catch(() => {});
    dispatch(closeModal());
  };

  const handleUnlockTwoFactor = () => {
    if (!data?.id) {
      Toast.show(
        "An error occurred while trying to unlock. Contact your administrator."
      );
      return;
    }
    unlockTwoFactor({
      userId: data.id,
    })
      .then((response) => {
        if (response?.data?.unlocked === true) {
          Toast.show({
            closeAll: true,
            description: "User account has been unlocked.",
          });
          return;
        } else {
          Toast.show({
            closeAll: true,
            description: "An error occurred while trying to unlock. Try again.",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(closeModal());
  };
  const { values, handleBlur, setFieldValue, errors } = useFormik({
    initialValues: initialValues,
    validationSchema: data ? EditUserrSchema : NewUserSchema,
    validateOnChange: true,
  });
  const setText = async (fieldName, text) => {
    setFieldValue(fieldName, text).then((r) => {
      dispatch(
        setContent({
          ...values,
          [fieldName]: text,
          createdBy: loggedUser.id,
          isValid: _.isEmpty(r),
        })
      );
    });
  };

  const isFieldDisabled = mode === USER_MODAL_MODES.viewAccount;
  return (
    <Box alignItems="center">
      <VStack
        style={{
          width: 430,
        }}
        borderWidth="1"
        borderColor="neutral.50"
        px={3}
        py={3}
        mx="3"
        maxW="600px"
      >
        <FormControl isRequired isInvalid={errors[firstName]}>
          <FormControl.Label
            _text={{
              bold: true,
            }}
          >
            {i18n.t("screens.users.UserModal.labels.firstName")}
          </FormControl.Label>

          <Input
            value={values?.firstName}
            disabled={isFieldDisabled}
            // onBlur={handleBlur(firstName)}
            onChange={(e) => setText(firstName, e.target.value)}
          />
          {NEW_USER_FORM_FIELDS.firstName in errors ? (
            <FormControl.ErrorMessage>
              {errors[NEW_USER_FORM_FIELDS.firstName]}
            </FormControl.ErrorMessage>
          ) : (
            <FormControl.HelperText>
              {i18n.t("screens.users.UserModal.errors.invalidField")}
            </FormControl.HelperText>
          )}
        </FormControl>

        <FormControl isRequired isInvalid={errors[lastName]}>
          <FormControl.Label
            _text={{
              bold: true,
            }}
          >
            {i18n.t("screens.users.UserModal.labels.lastName")}
          </FormControl.Label>
          <Input
            style={{
              width: 400,
            }}
            disabled={isFieldDisabled}
            value={values?.lastName}
            onChange={(e) => setText(lastName, e.target.value)}
            onBlur={handleBlur(lastName)}
          />
          {[lastName] in errors ? (
            <FormControl.ErrorMessage>
              {errors[lastName]}
            </FormControl.ErrorMessage>
          ) : (
            <FormControl.HelperText>
              {i18n.t("screens.users.UserModal.errors.invalidField")}
            </FormControl.HelperText>
          )}
        </FormControl>

        <FormControl isRequired isInvalid={errors[email]}>
          <FormControl.Label
            _text={{
              bold: true,
            }}
          >
            {i18n.t("screens.users.UserModal.labels.email")}
          </FormControl.Label>
          <Input
            value={values?.email}
            onBlur={handleBlur(email)}
            disabled={isFieldDisabled}
            onChange={(e) => setText(email, e.target.value)}
          />
          {email in errors && (
            <FormControl.ErrorMessage>{errors[email]}</FormControl.ErrorMessage>
          )}
        </FormControl>

        <FormControl isRequired isInvalid={classType in errors}>
          <FormControl.Label
            _text={{
              bold: true,
              borderRadius: 4,
            }}
          >
            {i18n.t("screens.users.UserModal.labels.classType")}
          </FormControl.Label>
          <Select
            style={{
              width: 400,
              borderRadius: 4,
            }}
            disabled={isFieldDisabled}
            value={values?.classType}
            onBlur={handleBlur(classType)}
            onSelect={(value) => {
              setText(classType, value);
            }}
          >
            {SELECT_USER_CLASSES_TYPES_OPTIONS.map((item) => {
              return (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              );
            })}
          </Select>

          {classType in errors && (
            <FormControl.ErrorMessage>
              {errors[classType]}
            </FormControl.ErrorMessage>
          )}
        </FormControl>

        <FormControl
          isRequired
          isInvalid={[NEW_USER_FORM_FIELDS.reportsTo] in errors}
        >
          <FormControl.Label
            _text={{
              bold: true,
            }}
          >
            {i18n.t("screens.users.UserModal.labels.reportsTo")}
          </FormControl.Label>
          <Select
            style={{
              width: 400,
            }}
            disabled={isFieldDisabled}
            value={values?.reportsTo?.id}
            // onBlur={handleBlur(reportsTo)}
            onSelect={(value) => {
              setText(reportsTo, value);
            }}
          >
            {users.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item?.fullName}
                </Select.Option>
              );
            })}
          </Select>
          {[NEW_USER_FORM_FIELDS.reportsTo] in errors && (
            <FormControl.ErrorMessage>
              {errors[reportsTo]}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </VStack>
      {mode === USER_MODAL_MODES.editUser &&
        loggedUser?.classType === USER_CLASSES_TYPES.superUser && (
          <Button.Group
            style={{
              width: 430,
            }}
            mt={5}
            m={1}
            borderWidth="1"
            borderColor="neutral.50"
            flexWrap="wrap"
            px={3}
            py={3}
          >
            <Button
              onPress={handlerDeleteUser}
              isLoading={deleteUserRequest.isLoading}
              isLoadingText="Deleting user account..."
              bg="accent.100"
            >
              <Text color="base.0">Delete Account</Text>
            </Button>

            {data.status === "disabled" ? (
              <Button
                onPress={handlerActivateAcount}
                isLoading={activateAccountRequest.isLoading}
                isLoadingText="Activating user account..."
                variant="outline"
                bg="base.0"
              >
                <Text>Activate account</Text>
              </Button>
            ) : (
              <Button
                onPress={handlerDisableAcount}
                isLoading={disableAcountRequest.isLoading}
                isLoadingText="Disabling user account..."
                variant="outline"
                bg="base.0"
              >
                <Text>Disable account</Text>
              </Button>
            )}

            <Button
              onPress={handlerResetPassword}
              isLoading={resetAccountPasswordRequest.isLoading}
              isLoadingText="Reseting user password..."
              variant="outline"
              bg="base.0"
            >
              <Text>Reset password</Text>
            </Button>

            <Spacer size="sm" style={{ height: 5 }} />
            <Button
              onPress={handleUnlockTwoFactor}
              isLoading={unlockTwoFactorRequest.isLoading}
              isLoadingText="Reseting user 2FA..."
              variant="outline"
              bg="base.0"
            >
              <Text>Reset 2FA Feature</Text>
            </Button>
            <Button
              onPress={handleUnlockPassword}
              isLoading={unlockPasswordRequest.isLoading}
              variant="outline"
              isLoadingText="Unlocking user password..."
              bg="base.0"
            >
              <Text>Force unlock temporary block</Text>
            </Button>
          </Button.Group>
        )}
    </Box>
  );
};

export default UserModal;
