import React from "react";
import { Platform } from "react-native";
import Sheet, { createTableColumnConfig } from "../../components/table/SheetV2";
import {
  CELL_CONTENT_TYPES,
  CELL_STYLE_TEMPLATES,
} from "../../components/table/tableRelatedTypes";
import {
  su,
  getBottomMenuHeight,
  windowHeight,
  SIDE_MENU_WIDTH,
  windowWidth,
  HEADER_HEIGHT,
} from "../../utils/utils";

const DisplayQuestionnaire = ({ data }) => {
  if (!data) {
    return null;
  }
  // const maxHeight =  windowHeight - getBottomMenuHeight() - HEADER_HEIGHT - 125;
  const maxHeight = windowHeight - getBottomMenuHeight() - HEADER_HEIGHT - 150;
  const maxWidth =
    Platform.OS === "web" ? windowWidth - SIDE_MENU_WIDTH - 50 : windowWidth;
  const extractTableDataFromRequest = (data, element) => {
    let result = [];
    if (data[element]) {
      try {
        result = JSON.parse(data[element]);
      } catch (e) {
        console.log(e);
      }
    }
    if (element === "tableColumns") {
      const rows = extractTableDataFromRequest(data, "tableRows");
      const columns = result.map((column, index) => {
        const row = rows[index];
        let rowTextMaxLength = 0;
       /* eslint-disable no-unused-vars*/
        Object.entries(row).forEach(([key, value]) => {
          if (value.length > rowTextMaxLength) {
            rowTextMaxLength = value.length;
          }
        });

        const columnTitle = column.title;
        const attributeName = column.name;
        const headStyleTemplate = CELL_STYLE_TEMPLATES.headQ;
        const cellContentType = CELL_CONTENT_TYPES.text;
        const cellStyleTemplate = CELL_STYLE_TEMPLATES.questionner;

        const customComponentConfig = null;

        const height = su(70, 80, 80);

        const heightStyle = {
          maxHeight: height,
          height: height,
          minHeigh: height,
        };
        const defaultStyle = {
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 5,
          paddingBottom: 5,
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
          display: "flex",
          ...heightStyle,
        };
        let cellContainerStyle = {
          minWidth: 250,
          width: 250,
          maxWidth: 250,

          ...defaultStyle,
        };
        if (index === 0) {
          cellContainerStyle = {
            minWidth: 60,
            width: 60,
            maxWidth: 60,
            ...defaultStyle,
          };
        }
        if (index === 7 || index === 8 || index === 9) {
          cellContainerStyle = {
            minWidth: 70,
            width: 70,
            ...defaultStyle,
          };
        }
        if (index === 10 || index === 11 || index === 12) {
          cellContainerStyle = {
            minWidth: 350,
            width: 350,
            ...defaultStyle,
          };
        }

        let columnConfig = createTableColumnConfig(
          columnTitle,
          attributeName,
          headStyleTemplate,
          cellContentType,
          cellStyleTemplate,
          customComponentConfig,
          cellContainerStyle
        );

        return columnConfig;
      });
      return columns;
    }

    return result;
  };

  const tableColumns = extractTableDataFromRequest(data, "tableColumns");
  const tableRows = extractTableDataFromRequest(data, "tableRows");

  return (
    <Sheet
      freezeFirstColumn={false}
      headStyleTemplate={CELL_STYLE_TEMPLATES.head}
      list={tableRows}
      tableColumnsConfig={tableColumns}
      rowsExpandFeature={true}
      height={maxHeight}
      width={maxWidth}
      isEditModeActive={false}
      showCustomHorizontalScroll={Platform.OS === "web" ? true : false}
    />
  );
};

export default DisplayQuestionnaire;
