import { registerRootComponent } from 'expo';
import { Platform } from 'react-native';
import Main from './src/Main';
import { createRoot } from "react-dom/client";
import React from "react"
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately

// @see https://github.com/expo/expo/issues/18485
if (Platform.OS === "web") {
    const root = createRoot(
      document.getElementById("root") ?? document.getElementById("main")
    );
    root.render(<Main />);
  } else {
    registerRootComponent(Main);
    // AppRegistry.registerComponent('main', () => App)
  }