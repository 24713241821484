import React from "react";

import { Box, VStack } from "native-base";

import { useSelector } from "react-redux";
import PreviewFile from "../components/PreviewFile";
import SelectDocsListModal from "../components/SelectDocsListModal";
import { Platform } from "react-native";
import Empty from "../components/Empty";
import { EMPTY_MODE_TYPES } from "../utils/utils";

const FileLibrary = ({ route }) => {
  if (!route.params || !route.params.fileCategory) {
    return null;
  }

  const { fileCategory } = route.params;

  const data = useSelector((state) => state.ux.viewFilesPages[fileCategory]);
  const renderChooseFile = () => {
    return (
      <Box w="full" alignItems="flex-end" py={3} px={3} m={1}>
        <SelectDocsListModal fileCategory={fileCategory} />
      </Box>
    );
  };
  if (data === null) {
    return (
      <Empty
        mode={EMPTY_MODE_TYPES.selectFile}
        NewItemComponent={renderChooseFile()}
      />
    );
  }

  return (
    <VStack space={2}>
      {Platform.OS !== "web" && (
        <Box py={3}>
          <SelectDocsListModal fileCategory={fileCategory} />
        </Box>
      )}
      <PreviewFile file={data} />
    </VStack>
  );
};

export default FileLibrary;
