
import React from "react";
import { Box } from "native-base";
import { su } from "../../utils/utils";
const paddingQuestionerRequestContainer = su(10, 48, 48);
const ReportRowBasic = ({ children }) => {
    return (
      <Box
        bg="neutral.10"
        w="full"
        borderRadius="sm"
        alignContent="center"
        style={{
          paddingLeft: paddingQuestionerRequestContainer,
          paddingRight: paddingQuestionerRequestContainer,
          paddingTop: 5,
        }}
      >
        {children}
      </Box>
    );
  };


  export default ReportRowBasic;
  