import React, { Suspense } from "react";
import { Box, VStack, Text, HStack, Center, Modal, Icon } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import LoadingData from "./LoadingData";
import _ from "lodash";
import { i18n } from "../utils/utils";
// import MediaCard from "./MediaCard";
import PreviewFile from "./PreviewFile";
const MediaCard = React.lazy(() => import("./MediaCard"));

const ViewMediaCollection = ({
  items = null,
  handleRemove = () => {},
  renderAddMoreBtn = true,
  hasDeleteIcon = false,
  onAddMore,
  selectMode = false,
  request = null,
  setSelectedItems = () => {},
  selectedItems = [],
  token,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const rowData = items === null ? selectedItems : items;
  if (
    !rowData ||
    !_.isArray(rowData) ||
    (rowData.length === 0 && !renderAddMoreBtn)
  ) {
    return <Text>No files available. </Text>;
  }
  const showFilePreview = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  if (request && request?.isLoading) {
    return (
      <LoadingData
        isFetching={request.isFetching}
        isLoading={request.isLoading}
        isError={request.isError}
        error={request.error}
      />
    );
  }

  const onPressCard = (item) => {
    setSelectedItem(item);

    if (selectMode) {
      const isItemAlreadySelected = selectedItems.findIndex(
        (el) => item.id === el.id
      );

      const newSelection =
        isItemAlreadySelected === -1
          ? [...selectedItems, item]
          : selectedItems.filter((el) => el.id !== item.id);

      setSelectedItems(newSelection);
      return;
    }
    showFilePreview();
  };
  return (
    <Box>
      <Center>
        <HStack
          display="flex"
          justifyItems="center"
          justifyContent="center"
          space={4}
          flexWrap="wrap"
          mb={10}
        >
          {rowData.map((item) => {
            let isSelected = false;

            if (selectMode) {
              isSelected = selectedItems.find((el) => item.id === el.id);
              if (!_.isUndefined(isSelected)) {
                isSelected = true;
              }
            }

            const isCardSelected = selectedItems.find((el) => item.id === el.id)
              ? true
              : false;

            return (
              <Box key={item.id} m={1}>
                <Suspense>
                  <MediaCard
                    hasDeleteIcon={hasDeleteIcon}
                    handleRemove={handleRemove}
                    key={item.id}
                    token={token}
                    item={item}
                    onPress={onPressCard}
                    isSelected={selectMode ? isCardSelected : false}
                  />
                </Suspense>
              </Box>
            );
          })}
          {renderAddMoreBtn && (
            <Box m={1}>
              <Suspense>
                <MediaCard
                  key="add-btn"
                  onPress={onAddMore}
                  item={{ type: "children" }}
                  assetId="btn-add"
                >
                  <VStack
                    alignItems="center"
                    color="primary.100"
                    space={0}
                    p={1}
                  >
                    <Icon
                      as={AntDesign}
                      size={30}
                      name="clouduploado"
                      color="primary.100"
                    />
                    <Text
                      color="primary.100"
                      textAlign="center"
                      lineHeight="sm"
                      fontWeight="bold"
                    >
                      {i18n.t(
                        "components.viewMediaCollection.buttons.uploadFiles"
                      )}
                    </Text>
                  </VStack>
                </MediaCard>
              </Suspense>
            </Box>
          )}
        </HStack>
        <Modal
          justifyContent="center"
          alignItems="center"
          size="full"
          isOpen={showModal}
          onClose={closeModal}
        >
          <Modal.Content>
            <Modal.CloseButton />
            <Modal.Header>
              {i18n.t("components.viewMediaCollection.buttons.previewFile")}
            </Modal.Header>
            <Modal.Body
              alignItems="center"
              justifyContent="center"
              alignContent="center"
              alignSelf="center"
              _scrollview={{
                horizontal: false,
                scrollEnabled: true,
              }}
            >
              <Box size="lg">
                <Center>
                  {selectedItem !== null && (
                    <PreviewFile showHeader={false} file={selectedItem} />
                  )}
                </Center>
              </Box>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      </Center>
    </Box>
  );
};

export default ViewMediaCollection;
