import React from "react";
import { Box } from "native-base";
import { useSelector } from "react-redux";
import Table, { createTableBluePrint } from "../../components/table/Table";
import { NOTIFICATIONS_TABLE_COLUMNS } from "./tableColumns";
import { useGetNotificationsQuery } from "../../services/apiService";
import LoadingData from "../../components/LoadingData";
import LayoutPagination from "../../containers/LayoutPagination";
import Empty from "../../components/Empty";
import {
  useRoute,
} from "@react-navigation/native";
import {  useDispatch } from "react-redux";
import { api } from "../../services/apiService";
const Notifications = () => {
  const route = useRoute();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(route?.params?.page || "1");
  const loggedUser = useSelector((state) => state.auth.user);
  const notificationsListReq = useGetNotificationsQuery(
    { page: route?.params?.page || "1", createdFor: loggedUser.id },
    {
      refetchOnFocus: false,
    }
  );

  const notifications = useSelector(
    (state) => state.notifications.notifications?.[page]);

  if (
    notificationsListReq.isLoading ||
    notificationsListReq.isFetching ||
    notificationsListReq.isError
  ) {
    return (
      <LoadingData
        isFetching={notificationsListReq.isFetching}
        isLoading={notificationsListReq.isLoading}
        isError={notificationsListReq.isError}
        error={notificationsListReq.error}
      />
    );
  }

  if (!notifications || notifications.length === 0) {
    return <Empty />;
  }
  const bluePrint = createTableBluePrint(
    notifications,
    NOTIFICATIONS_TABLE_COLUMNS,
    "notificationId"
  );

  const refetchNotificationsAndCount = () =>{
    notificationsListReq.refetch();
    dispatch(api.util.invalidateTags(["NotificationsCount"]));
  }
  return (
    <LayoutPagination
      currentPage={notificationsListReq?.data?.page}
      setPage={setPage}
      totalPages={notificationsListReq?.data?.totalPages}
      refetch={refetchNotificationsAndCount}
    >
       <Box pt={0} pb={5} style={{maxWidth:1447 }}>

        <Table
          freezeFirstColumn={false}
          data={notifications}
          numRowStep={6}
          tableHeadConfig={NOTIFICATIONS_TABLE_COLUMNS}
          bluePrint={bluePrint}
          rowsExpandFeature={false}
          isEditModeActive={false}
        />
      </Box>
    </LayoutPagination>
  );
};

export default Notifications;
