import { Box, Text, HStack, Progress, Avatar } from "native-base";
import React from "react";
import { ROW_STYLE_TYPES } from "../rows/rowTypes";
import { su, DATE_FORMAT,formatAndTruncateText } from "../../../utils/utils";
import { CELL_TYPES } from "../../../utils/types";
import _ from "lodash";
import {
  TASKS_ACCENT_COLORS_BY_STATUS,
  TASKS_STATUS_IN_PERCETANGE,
} from "../tableRelatedTypes";
import CellMultipleItems from "./CellMultipleItems";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import CellExpand from "./CellExpand";



const getWidthContainer = (variant) => {
  if (
    variant === CELL_TYPES.multipleItems ||
    variant === CELL_TYPES.headLong ||
  variant === CELL_TYPES.longText
  ) {
    return 328;
  } else {
    if (variant === CELL_TYPES.customComponent) {
      return 368;
    }
    return 250;
  }
};


const formatDate = (date) => {
  if (_.isString(date) && date.length > 3) {
    return dayjs(date).format(DATE_FORMAT);
  }

  return "";
};

const CellUserWidget = ({ variant, parentObj, value }) => {
  const formatUserComponent = (user) => {
    const firstLetter = user?.firstName?.[0]
      ? user?.firstName?.[0].toUpperCase()
      : "?";

    return (
      <Avatar
        bg="base.20"
        padding={"2px"}
        style={{ height: 40, width: 40 }}
        source={{ uri: user?.avatarPhoto?.url || null }}
      >
        <Text>{firstLetter}</Text>
      </Avatar>
    );
  };

  return (
    <HStack
      ml="2"
      p={1}
      pl={2}
      mr="3"
      alignItems="center"
      justifyContent="flex-end"
      style={{ width: getWidthContainer(variant) }}
      space={1}
    >
      <Text style={{ width: getWidthContainer(variant), flex: 1, flexWrap: 'wrap',flexShrink:1 }}>
        {value?.fullName}
      </Text>
      {formatUserComponent(
        parentObj?.assignTo || parentObj?.reportsTo || parentObj?.createdBy
      )}
    </HStack>
  );
};

const CELL_TYPE_BY_CONTENT = {
  progress: "progress",
  customComponent: "customComponent",
  expand: "expand",
  user: "user",
  text: "text",
};

const CELL_TEXT_FORMAT_TYPES = {
  date: "date",
  default: "default", //short text
  longText: "longText",
  head: "head",
  headQ: "headQ",
  questionner: "questionner",
};

const defaultTextPadding = {
  paddingRight: 16,
  paddingLeft: 16,
  paddingTop: 8,
  paddingBottom: 8,
};


const defaultTextStyle = {
  ...defaultTextPadding,
  letterSpacing: "lg",
  lineHeight: "xs",
  fontFamily: "heading",
  color: "primary.100",
  textAlign: "center",
  fontSize: "lg",
};

const MAP_TEXT_FORMAT_TYPE = {
  [CELL_TEXT_FORMAT_TYPES.date]: {
    ...defaultTextStyle,
  },
  [CELL_TEXT_FORMAT_TYPES.longText]: {
    ...defaultTextStyle,
    fontWeight: "400",
  },
  [CELL_TEXT_FORMAT_TYPES.head]: {
    ...defaultTextStyle,
    fontWeight: "500",
  },
  [CELL_TEXT_FORMAT_TYPES.default]: {
    ...defaultTextStyle,
  },
};

const Cell = (props) => {
  const {
    variant = CELL_TYPES.basic,
    cellColor,
    rowVariant,
    customComponent = null,
    //new version of props
    isExpanded = false,
    height = null,
    value = "",
    parentObj,
    textFormatType,
    expandRowMethod,
    onExpand
  } = props;

  let text = formatAndTruncateText(value, isExpanded);
  const styleText = {
    fontSize: 16,
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: su(8),
    paddingBottom: su(8),
  };

  const customCoponentMethod = (parentObj,height) => {
    if (customComponent === null) {
      return null;
    }
    if (_.isFunction(customComponent) && parentObj) {
     
      return customComponent(parentObj,height);
    }
  };

  const renderProgressCell = (value) => {
    return (
      <HStack alignItems="center">
        <Box style={{ width: 120, height: 10 }} maxW="400">
          <Progress
            _filledTrack={{
              bg: TASKS_ACCENT_COLORS_BY_STATUS[value],
            }}
            bg="base.0"
            value={TASKS_STATUS_IN_PERCETANGE[value]}
            mx="4"
          />
        </Box>

        <Text>{TASKS_STATUS_IN_PERCETANGE[value]}</Text>
      </HStack>
    );
  };

  const renderTextCell = (text) => {
    const textProps = MAP_TEXT_FORMAT_TYPE[textFormatType];

    const formatText =
      textFormatType === CELL_TEXT_FORMAT_TYPES.date
        ? formatDate
        : formatAndTruncateText;

    const renderedText = formatText(text);

    <Text style={styleText} {...textProps}>
      {renderedText}
    </Text>;
  };

  const contentMap = {
    [CELL_TYPE_BY_CONTENT.text]: renderTextCell(value),

    [CELL_TYPE_BY_CONTENT.progress]: renderProgressCell(value),
    [CELL_TYPE_BY_CONTENT.expand]: (
      <CellExpand
        variant={variant}
        isExpanded={isExpanded}
        onPress={expandRowMethod}
      />
    ),
    [CELL_TYPE_BY_CONTENT.user]: (
      <CellUserWidget variant={variant} parentObj={parentObj} value={value} />
    ),

    [CELL_TYPES.multipleItems]: (
      <CellMultipleItems
        isExpanded={isExpanded}
        onExpand={onExpand}
        data={value}
      />
    ),
    [CELL_TYPES.longText]: (
      <Text
        style={styleText}
        letterSpacing="lg"
        lineHeight="xs"
        fontFamily="heading"
        color="primary.100"
        textAlign="center"
        fontWeight="400"
        fontSize="lg"
      >
        {text}
      </Text>
    ),
    [CELL_TYPES.progress]: renderProgressCell(value),
    [CELL_TYPES.head]: (
      <Text
        style={styleText}
        letterSpacing="lg"
        lineHeight="xs"
        fontFamily="heading"
        textAlign="center"
        fontWeight="500"
      >
        {value}
      </Text>
    ),
    [CELL_TYPES.date]: (
      <Text
        style={styleText}
        letterSpacing="lg"
        lineHeight="xs"
        fontFamily="heading"
        textAlign="center"
      >
        {_.isString(value) && value.length > 3
          ? dayjs(value).format(DATE_FORMAT)
          : ""}
      </Text>
    ),
    [CELL_TYPES.user]: (
      <CellUserWidget variant={variant} parentObj={parentObj} value={value} />
    ),
    [CELL_TYPES.customComponent]: (
      <Box
        h="full"
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        style={{height: height}}
      >
        {customCoponentMethod(parentObj,height)}
      </Box>
    ),
    [CELL_TYPES.customComponentShort]: (
      <Box
        h="full"
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        style={{height: height}}
      >
        {customCoponentMethod(parentObj,height)}
      </Box>
    ),
    [CELL_TYPES.basic]: (
      <Text
        style={styleText}
        letterSpacing="lg"
        lineHeight="xs"
        fontFamily="heading"
        textAlign="center"
      >
        {text}
      </Text>
    ),
  };

  const renderContent = () => {
    if (contentMap[variant]) {
      return contentMap[variant];
    }
    return contentMap[CELL_TYPES.basic];
  };

  const bg =
    cellColor && rowVariant === ROW_STYLE_TYPES.valid ? { bg: cellColor } : {};

  const borderTopWidth = rowVariant === ROW_STYLE_TYPES.basic ? 1 : 0;
  const borderBottomColor =
    rowVariant === ROW_STYLE_TYPES.basic ? "base.20" : cellColor;
  return (
    <Box
      borderBottomWidth={borderTopWidth}
      borderBottomColor={borderBottomColor}
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      {...bg}
      style={{
        minHeight: su(69, 69, 56),
        // height: 69,
        maxHeight: su(172, 172, 209),
        width: getWidthContainer(variant),
      }}
    >
      {renderContent()}
    </Box>
  );
};
Cell.propTypes = {
  textByContent: PropTypes.oneOf([
    CELL_TYPE_BY_CONTENT.progress,
    CELL_TYPE_BY_CONTENT.customComponent,
    CELL_TYPE_BY_CONTENT.expand,
    CELL_TYPE_BY_CONTENT.user,
    CELL_TYPE_BY_CONTENT.text,
  ]),
  textFormatType: PropTypes.oneOf([
    CELL_TEXT_FORMAT_TYPES.default,
    CELL_TEXT_FORMAT_TYPES.date,
    CELL_TEXT_FORMAT_TYPES.longText,
    CELL_TEXT_FORMAT_TYPES.head,
    CELL_TEXT_FORMAT_TYPES.headQ,
    CELL_TEXT_FORMAT_TYPES.questionner,
  ]),
  parentObj: PropTypes.object,
  expandRowMethod: PropTypes.func,
  isExpanded: PropTypes.bool,
  value: PropTypes.any.isRequired,
  bgColor: PropTypes.string,
};

export default Cell;
