import React from "react";
import { Box, Text, HStack, Button } from "native-base";
import {
  FLOW_STATUS_MODES,
  ASSETS_TYPES,
  i18n,
  MODAL_CONTENT_TYPES,
  SUBHEADER_HEIGHT,
} from "../utils/utils";
import { useNavigation } from "@react-navigation/native";
import SelectDocsListModal from "../components/SelectDocsListModal";
import { useDispatch } from "react-redux";
import { openModalAndSetContent } from "../features/uxSlice";

const Subheader = ({ routeName = "" }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const screenNamesMap = {
    ["ResultsTable"]: "Results",
    ["Report"]: "Final Report",
    ["ResolveTask"]: "Resolve Task",
    ["Users"]: i18n.t("screens.users.title"),
    ["Notifications"]: i18n.t("screens.notifications.title"),
    ["Questionnaires"]: i18n.t("screens.questionnaires.title"),
    ["OtherDocuments"]: i18n.t("screens.OtherDocuments.title"),
    ["TasksFlow"]: i18n.t("screens.mainTasksFlow.title"),
    ["Tasks"]: i18n.t("screens.tasks.title"),
    ["FormA"]: i18n.t("screens.formA.title")
  };

  const openModalToCreateAccount = () => {
    dispatch(
      openModalAndSetContent({ contentBody: MODAL_CONTENT_TYPES.newUserModal })
    );
  };
  const rightPlaceholderMap = {
    Report: (
      <Button
        mx={6}
        _hover={{
          bg: "accent.40",
          color: "base.0",
        }}
        _pressed={{
          bg: "accent.50",
          color: "base.0",
        }}
        onPress={goBackToSubmit}
        mr={4}
        style={{ height: 32, width: 210 }}
        bg="accent.40"
      >
        <Text color="base.0"> Go back to submit the report</Text>
      </Button>
    ),
    Questionnaires: (
      <SelectDocsListModal fileCategory={ASSETS_TYPES.questionner} />
    ),
    FormA: <SelectDocsListModal fileCategory={ASSETS_TYPES.formA} />,
    FormB: <SelectDocsListModal fileCategory={ASSETS_TYPES.formB} />,
    OtherDocuments: (
      <SelectDocsListModal fileCategory={ASSETS_TYPES.otherDocs} />
    ),
    Users: (
      <Button
        _hover={{
          bg: "accent.40",
          color: "base.0",
        }}
        _pressed={{
          bg: "accent.50",
          color: "base.0",
        }}
        onPress={openModalToCreateAccount}
        bg="accent.40"
        style={{ width: 176, height: 39 }}
        size="sm"
      >
        <Text color="base.0" style={{ fontSize: 16 }}>
          {i18n.t(`screens.users.buttons.new`)}
        </Text>
      </Button>
    ),
  };

  const pageTitle = screenNamesMap[routeName]
    ? screenNamesMap[routeName]
    : routeName;

  const goBackToSubmit = () => {
    navigation.navigate("TasksFlow", {
      screen: "Status",
      params: { mode: FLOW_STATUS_MODES.resolveTask },
    });
  };
  const routesWithRightPlaceholder = [
    "Report",
    "Questionnaires",
    "FormA",
    "FormB",
    "OtherDocuments",
    "Users",
  ];
  const renderRightPlaceHolder = routesWithRightPlaceholder.includes(routeName);
  return (
    <Box style={{ height: SUBHEADER_HEIGHT, width: "100%" }} bg="base.0">
      <HStack h="full" alignItems="center" justifyContent="space-between">
        <Text fontSize={20} fontWeight="medium" pl={3}>
          {pageTitle}
        </Text>
        <Box mr={5}>
          {renderRightPlaceHolder && rightPlaceholderMap[routeName]}
        </Box>
      </HStack>
    </Box>
  );
};

export default Subheader;
