import {
  Pressable,
  HStack,
  Avatar,
  Box,
  Text,
  Menu,
} from "native-base";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { openModalAndSetContent } from "../../features/uxSlice";
import { MODAL_CONTENT_TYPES, i18n } from "../../utils/utils";
// const isDesktop = true;
function AccountMenu(props) {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.auth.user);

  const navigateTo = () => {
    if (!props.goTo) {
      return;
    }
    navigation.navigate(!props.goTo);
  };

  const viewAccount = () => {
    dispatch(
      openModalAndSetContent({
        contentBody: MODAL_CONTENT_TYPES.viewAccountModal,
        data: loggedUser,
      })
    );
  };

  const changePassword = () => {
    dispatch(
      openModalAndSetContent({
        contentBody: MODAL_CONTENT_TYPES.changePassword,
        size: "full",
        submitLabel: 'Change password',
      })
    );
  };

  const configure2fa = () => {
    navigation.navigate('ChangeDevice')
  };
  const firstLetter = loggedUser?.firstName?.[0]
  ? loggedUser?.firstName?.[0].toUpperCase()
  : "?";

  return (
    <Box alignItems="flex-start">
      <Menu
        shadow={2}
        w="auto"
        h="auto"
        trigger={(triggerProps) => {
          return (
            <Pressable
              onPress={navigateTo}
              overflow="hidden"
              maxW="96"
              p="2"
              {...triggerProps}
            >
              <HStack alignItems="center" py={4} space={2}>
              <Avatar
                  bg="white"
                  padding={"2px"}
                  size={7}
              
                >
                  <Text fontSize="md" color="primary.100">
                  {firstLetter}
                  </Text>
                </Avatar>
                <Box width="full">
                  <Text fontSize="md" color="base.0">
                    {loggedUser?.firstName}
                  </Text>
                </Box>
              </HStack>
            </Pressable>
          );
        }}
      >
        <Menu.Item onPress={viewAccount}>
          {i18n.t("menus.header.account")}
        </Menu.Item>
        <Menu.Item onPress={changePassword}>
          {i18n.t("menus.header.changePassword")}
        </Menu.Item>
        <Menu.Item onPress={configure2fa}>
          {i18n.t("menus.header.configure2fa")}
        </Menu.Item>
      </Menu>
    </Box>
  );
}
export default AccountMenu;
