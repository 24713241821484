import { pickImages } from "./utils";
import * as ImagePicker from "expo-image-picker";

// const pickImages = async (requestPermission, permissionStatus) => {
//   // No permissions request is necessary for launching the image library
//   if (permissionStatus === "denied") {
//     requestPermission();
//   }
//   let result = null;
//   try {
//     result = await ImagePicker.launchImageLibraryAsync({
//       mediaTypes: ImagePicker.MediaTypeOptions.Images,
//       allowsMultipleSelection: true,
//       accessPrivileges: true,
//       base64: true,
//     });
//   } catch (e) {
//       Toast.show({closeAll: true,
//       description:
//         "Something went wrong while trying to get the images from your device. Please try again.",
//     });
//     return;
//   }
//   if (!result.canceled) {
//     if (
//       !result.assets ||
//       !_.isArray(result.assets) ||
//       result.assets.length < 1
//     ) {
//         Toast.show({closeAll: true,
//         description: "You did not select any images.",
//       });
//       return;
//     }
//   }

//   return result.assets;
// };

/* ask for permission and export the function to pick images */
export const pickImagesHook =  () => {
  const [permissionStatus, requestPermission] =ImagePicker.useCameraPermissions();
  const pickImagesExp = async () => await pickImages(requestPermission, permissionStatus);
  return [pickImagesExp,permissionStatus,requestPermission];
};
