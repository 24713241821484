export const CELL_TYPES = {
    tags: "tags",
    shortText: "shortText",
    longText: "longText",
    headQ: "headQ",
    date: "date",
    user: "user",
    progress: "progress",
    status: "status",
    actions: "actions",
    customComponent: "customComponent",
    customComponentShort: "customComponentShort",
  
    basic:"basic",
    multipleItems:"multipleItems",
    head: "head",
    headLong: "headLong",
    multipleItemsHead: "multipleItemsHead",
    link: "link",
  };
  export const RESULTS_STATUS_TYPES = {
    valid: "valid",
    warning: "warning",
    error: "error",
  };
  