import React from "react";
import { Box, IconButton, HStack } from "native-base";
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { ROW_STYLE_TYPES } from "../rows/rowTypes";
import { su } from "../../../utils/utils";

const nubColorMap = {
  [ROW_STYLE_TYPES.complete]: "table.20",
  [ROW_STYLE_TYPES.incomplete]: "table.90",
  [ROW_STYLE_TYPES.unacknowledged]: "table.110",
  [ROW_STYLE_TYPES.confirmationRequired]: "table.50",
  [ROW_STYLE_TYPES.head]: "neutral.10",
  ["headLong"]: "neutral.10",
};
const CellExpand = ({
  variant,
  onPress,
  isExpanded,
  height,
  isEmptyCell = false,
}) => {
  const boxColor = nubColorMap[variant];
  return (
    <HStack
      alignItems="center"
      justifyContent="center"
      p={0}
      h={0}
      style={{ width: 82, height: height }}
    >
      {!isEmptyCell && (
        <Box
          bg={boxColor}
   
          borderLeftRadius="md"
          style={{ width: 16, height: height }}
        />
      )}
      {!isEmptyCell && (
        <IconButton
          onPress={onPress}
          size={16}
  
          _icon={{
            as: isExpanded ? FontAwesome : MaterialCommunityIcons,
            name: isExpanded ? "expand" : "arrow-collapse",
            width: su("40"),
            height: su("40"),

            color: boxColor
          }}
        />
      )}
    </HStack>
  );
};

export default CellExpand;
