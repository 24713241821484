import { Box, Text, Pressable, HStack } from "native-base";
import { Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { su } from "../../utils/utils";
import NavBtn from "../../components/shared/NavBtn";
import React from "react";

const LeftSideHeader = ({
  title = "No title",
  isDesktop,
  isTablet,
  isMobile,
  renderBackBtn = false,
}) => {
  const styleLogo = {
    width: 34,
    height: 34,
    marginLeft: su(8),
  };

  const navigation = useNavigation();
  const goToHome = () => navigation.replace("Tasks");
  return (
    <Pressable
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      onPress={goToHome}
    >
      <Box
        h="full"
        ml={2}
        alignItems="center"
        justifyContent="center"
        alignContent="center"
      >
        <HStack>
          {renderBackBtn && !isDesktop && <NavBtn variant="left" />}
          {(isMobile || isTablet) && (
            <Text color="white" lineheight="md" fontSize={16}>
              {title}
            </Text>
          )}
          {isDesktop && (
            <Image
              style={styleLogo}
              alt="logo"
            
              source={require('../../../assets/logo.png')}
            />
          )}
        </HStack>
      </Box>
    </Pressable>
  );
};
export default LeftSideHeader;
