import { Box, FormControl, Input, Toast, Text, Button } from "native-base";
import React from "react";
import LoginLayout from "./LoginLayout";
import { i18n, displayServerErrors } from "../../utils/utils";
import { useFormik } from "formik";
import {
  useLoginMutation,
  useLazyGetAccessQuery,
  useLazyGetTranslationsQuery,
} from "../../services/apiService";
import { LOGIN_FIELDS } from "./loginFields";
import * as Yup from "yup";
import { EXPO_PUBLIC_APP_ENV } from "@env";

console.log("🚀 ~ file: Login.js:15 ~ EXPO_PUBLIC_APP_ENV:", EXPO_PUBLIC_APP_ENV);
const LoginFormValidationSchema = Yup.object().shape({
  [LOGIN_FIELDS.email]: Yup.string()
    .email("Invalid email")
    .required("Required"),
  [LOGIN_FIELDS.password]: Yup.string().required("Required"),
});
const initialValues =
  EXPO_PUBLIC_APP_ENV === "production" || !EXPO_PUBLIC_APP_ENV || EXPO_PUBLIC_APP_ENV === "staging"
    ? {
        [LOGIN_FIELDS.email]: "",
        [LOGIN_FIELDS.password]: "",
      }
    : {
        [LOGIN_FIELDS.email]: "contact@webhustler.ro",
        [LOGIN_FIELDS.password]: "12344",
      };

const Login = () => {
  const [login, loginRequest] = useLoginMutation();
  const [getAccess] = useLazyGetAccessQuery();
  const [getTranslations] = useLazyGetTranslationsQuery();
  const isLoading = loginRequest?.isLoading;
  const [isBtnDisabled, setIsBtnDisabled] = React.useState(false);

  const {
    submitForm,
    validateForm,
    values,
    handleChange,
    errors,
    isValid,
    isValidating,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: LoginFormValidationSchema,
    onSubmit: async (values) => {
      // validateForm();

      if (isValid && !isValidating) {
        login(values)
          .then(async (response) => {
            const isResponseWithErros = displayServerErrors(response);

            if (isResponseWithErros) {
              return;
            }

            const result = response?.data;

            if (result?.user?.twoFactorAuthStatus === "blocked") {
              Toast.show({
                closeAll: true,
                description:
                  "This user is blocked. Contact your administrator for details.",
                isClosable: true,
              });
              setIsBtnDisabled(true);
              return;
            }

            if (result?.user?.status === "disabled") {
              Toast.show({
                closeAll: true,
                description:
                  "This user is disabled. Contact your administrator for details.",
                isClosable: true,
              });
              setIsBtnDisabled(true);
              return;
            }
            //make the access request

            const showErrorLoadingAccesss = () => {
              Toast.show({
                closeAll: true,
                description:
                  "Warning! Failed fetched the accounts roles. Some features might be unaccessible. If you encounter any issues, try to login again.",
              });
            };
            try {
              await getAccess();
            } catch (err) {
              showErrorLoadingAccesss();
            }

            const showErrorLoadingTranslations = () => {
              Toast.show({
                closeAll: true,
                description: i18n.t("errorsMsg.atLoadingTranslations"),
              });
            };

            try {
              const translationResponse = await getTranslations();

              if (
                translationResponse?.data.translations &&
                translationResponse?.data?.translations.en
              ) {
                i18n.store(translationResponse?.data.translations);
              } else {
                showErrorLoadingTranslations();
              }

              console.info("translations loaded.");
            } catch (err) {
              showErrorLoadingTranslations();
            }

            Toast.show({
              closeAll: true,
              status: "success",
              description: "You have successfully logged in!",
              isClosable: true,
            });
          })
          .catch((error) => {
            console.log("🚀 ~ onSubmit login ~ error", error);

            let errorMsg = i18n.t("errorsMsg.generalError");

            if (error?.data?.errors && error?.data?.errors.length > 0) {
              errorMsg = error.data.errors[0]?.message;
            }
            Toast.show({
              closeAll: true,
              status: "error",
              description: errorMsg,
              isClosable: true,
            });
          });
      }
    },
  });

  const passwordFieldRef = React.createRef();

  const goToPasswordField = () => {
    validateForm();
    passwordFieldRef.current.focus();
  };

  return (
    <LoginLayout>
      <Box borderRadius="sm" alignItems="center" px={3} py={3} bg="neutral.80">
        <Text
          mt="1"
          color="base.90"
          _dark={{
            color: "warmGray.200",
          }}
          alignText="center"
          fontWeight="medium"
          fontSize="md"
        >
          {i18n.t("screens.login.title")}
        </Text>
      </Box>

      <FormControl isInvalid={LOGIN_FIELDS.email in errors}>
        <FormControl.Label>
          {i18n.t("screens.login.labels.email")}
        </FormControl.Label>
        <Input
          value={values[LOGIN_FIELDS.email]}
          isDisabled={isBtnDisabled}
          onChangeText={handleChange(LOGIN_FIELDS.email)}
          onEndEditing={goToPasswordField}
        />
        <FormControl.ErrorMessage>
          {errors[LOGIN_FIELDS.email]}
        </FormControl.ErrorMessage>
      </FormControl>

      <FormControl isInvalid={LOGIN_FIELDS.password in errors}>
        <FormControl.Label>
          {i18n.t("screens.login.labels.password")}
        </FormControl.Label>
        <Input
          ref={passwordFieldRef}
          isDisabled={isBtnDisabled}
          value={values[LOGIN_FIELDS.password]}
          onChangeText={handleChange(LOGIN_FIELDS.password)}
          type="password"
        />
        <FormControl.ErrorMessage>
          {errors[LOGIN_FIELDS.password]}
        </FormControl.ErrorMessage>
      </FormControl>

      <Button
        isLoading={isLoading}
        isDisabled={!isValid || isBtnDisabled}
        onPress={submitForm}
        mt="2"
        bg="primary.60"
      >
        {i18n.t("screens.login.buttons.signIn")}
      </Button>
    </LoginLayout>
  );
};
export default Login;
