import "react-native-get-random-values";
import { generateUid} from '../utils/utils';
import { TASKS_MAIN_FLOW_SCREEN_STATUS } from "../utils/utils";
export default class TaskModel {
  constructor(
    projectName,
    area,
    dis,
    pn,
    questionner,
    activity,
    plannedStartDate,
    actualStartDate,
    plannedEndDate,
    actualEndDate,
    submissionDate,
    status,
    assignTo,
    error,
    errorStatus,
    reportStatus,
    formA,
    docs,
    formB
  ) {
    this.id = generateUid();
    this.projectName = projectName;
    this.area = area;
    this.pn = pn;
    this.dis = dis;
    this.questionner = questionner;
    this.activity = activity;
    this.plannedStartDate = plannedStartDate;
    this.actualStartDate = actualStartDate;
    this.plannedEndDate = plannedEndDate;
    this.actualEndDate = actualEndDate;
    this.submissionDate = submissionDate;
    this.status = status;
    this.assignTo = assignTo;
    this.error = error;
    this.errorStatus = errorStatus;
    this.reportStatus = reportStatus;
    this.formA = formA;
    this.docs = docs;
    this.formB = formB;
  }

  getPlainObject() {
    return this;
  }
  _getBusinessKeys() {
    return Object.keys(this).filter(
      (item) =>
        item.substring(0, 2) !== "get" || item.substring(0, 3) !== "_get"
    );
  }

  getNewTaskFlowInitialStateData() {
    const businessKeys = this._getBusinessKeys();
    let result = {};
    businessKeys.forEach((key, index) => {
      const flowKey = key === "projectName" ?  "project": key;
      if(key === "id"){
        return;
      }
      result[flowKey] = {
        status:
        index === 0
        ? TASKS_MAIN_FLOW_SCREEN_STATUS.current
        : TASKS_MAIN_FLOW_SCREEN_STATUS.waiting,
        value: null,
        dataSource: [],
      };
    });
    return result;
  }
}
