import { Box, Center } from "native-base";
import React from "react";
import {
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
} from "react-native";

const LoginLayout = ({ children }) => {
  return (
    <KeyboardAvoidingView
      // h={{
      //   base: "400px",
      //   lg: "auto",
      // }}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <TouchableWithoutFeedback
        onPress={Platform.OS === "web" ? () => {} : Keyboard.dismiss}
      >
        <Box h="100%" pt={12}>
          <Center flex={1} px="3">
            <Center w="100%">
              <Box safeArea p="2" py="8" w="90%" maxW="290">
                {children}
              </Box>
            </Center>
          </Center>
        </Box>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
};
export default LoginLayout;
