import { updateNotificationStatus } from "../../features/notificationsSlice";
import { useDispatch,useSelector } from "react-redux";
import { Toast } from "native-base";
import { useMarkNotificationMutation } from "../../services/apiService";
import { NOTIFICATIONS_STATUS_TYPES } from "./notificationsStatusTypes";
import { useAcrossDevices,  } from "../../containers/DeviceTypeContainers";
import { displayServerErrors, i18n } from '../../utils/utils';
export const useNotificationsActions = (element) => {
  if(!element?.id){
    Toast.show('An unexpected error occurred.')
    return ;
  }
  const [markNotification, markNotificationRequest] =
    useMarkNotificationMutation();
  const dispatch = useDispatch();
  const page = useSelector (notifications=>notifications.notifications.page);
 const { isMobile} = useAcrossDevices();
  const updateNotificationsStatus = (notification, page) => {
    if (!notification) {
      return;
    }
    markNotification(notification)
      .then((response) => {
        const isDisplayedErros = displayServerErrors(response);
        if(isDisplayedErros){
          return;
        }
        if(response?.data?.doc){
          const messageMap = {
            [NOTIFICATIONS_STATUS_TYPES.approved]:i18n.t('screens.notifications.messages.approved'),
            [NOTIFICATIONS_STATUS_TYPES.rejected]:i18n.t('screens.notifications.messages.rejected'),
            [NOTIFICATIONS_STATUS_TYPES.read]:i18n.t('screens.notifications.messages.read'),
            [NOTIFICATIONS_STATUS_TYPES.unread]:i18n.t('screens.notifications.messages.unread'),
          };
         
            const notificationStatus = response?.data?.doc?.status;
            if(notificationStatus){
              
              const message = messageMap?.[notificationStatus];
              if(message){
                  Toast.show({closeAll: true,
                  description: message,
                });
              }
            }

 
          dispatch(updateNotificationStatus({notification, page,isMobile: isMobile}));
        }
   
      })
      .catch(() => {
          Toast.show({closeAll: true,
          description:
            "Your approval was not saved. Please try again or contact your administrator.",
        });
      });
  };
  const handleApprove = () => {
    const notification = {
      id: element?.id,
      status: NOTIFICATIONS_STATUS_TYPES.approved,
    };
    updateNotificationsStatus(notification, page);
  };

  const handleReject = () => {
    const notification = {
      id: element.id,
      status: NOTIFICATIONS_STATUS_TYPES.rejected,
    };
    updateNotificationsStatus(notification, page);
  };
  const handleMarkRead = () => {
    const notification = {
      id: element.id,
      status:
        element.status === NOTIFICATIONS_STATUS_TYPES.read
          ? NOTIFICATIONS_STATUS_TYPES.unread
          : NOTIFICATIONS_STATUS_TYPES.read,
    };
    updateNotificationsStatus(notification, page);
  };

  return {
    handleApprove,
    handleReject,
    handleMarkRead,
    markNotificationRequest,
  };
};
