import React from "react";

import { HStack, Box } from "native-base";
import PropTypes from "prop-types";
import RightSideHeader from "./RightSideHeader";
import LeftSideHeader from "./LeftSideHeader";
import { useAcrossDevices } from "../../containers/DeviceTypeContainers";
import { su, HEADER_HEIGHT } from "../../utils/utils";
import { StatusBar } from "expo-status-bar";
function Header(props) {
  const { isDesktop, isTablet, isMobile } = useAcrossDevices();

  const {
    renderBackBtn = false,
    title = "No title",
    renderPlusBtn = false,
  } = props;

  return (
    <>
      <StatusBar style="light" />
      <Box safeAreaTop bg="primary.100" color="base.0" />
      <HStack
        style={{
          height: HEADER_HEIGHT,
        }}
        w="full"
        pr={su(8, 36, 36)}
        bg="primary.100"
        justifyContent="space-between"
        alignItems="center"
      >
        <LeftSideHeader
          renderBackBtn={renderBackBtn}
          isMobile={isMobile}
          isTablet={isTablet}
          isDesktop={isDesktop}
          title={title}
        />
        <RightSideHeader
          isDesktop={isDesktop}
          isTablet={isTablet}
          isMobile={isMobile}
          renderPlusBtn={renderPlusBtn}
        />
      </HStack>
    </>
  );
}

Header.propTypes = {
  renderBackBtn: PropTypes.bool,
  title: PropTypes.string,
  renderPlusBtn: PropTypes.bool,
};
export default Header;
