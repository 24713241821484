import React, { Suspense } from "react";

import {
  Button,
  Modal,
  Text,
  FlatList,
  Box,
  Divider,
  VStack,
  Spinner,
  Center,
  IconButton,Icon,
  HStack
} from "native-base";
import { useState } from "react";
import { AntDesign } from "@expo/vector-icons";
import {
  useGetQuestionnersQuery,
  useGetAssetsQuery,
} from "../services/apiService";
import { setSelectedFile } from "../features/uxSlice";
import { useDispatch } from "react-redux";
import {
  ASSETS_TYPES,
  formatDocAssetsListForDisplay,
  su,
  i18n,
} from "../utils/utils";
import _ from "lodash";

// import MediaCard from "./MediaCard";
const MediaCard = React.lazy(() => import("./MediaCard"));

function useFilesHook(fileCategory, page, ) {
  const [mainData, setMainData] = React.useState([]);

  let data,
    isLoading,
    isFetching,
    isError,
    nextPage,
    hasNextPage = null;

  const request =
  fileCategory === ASSETS_TYPES.questionner
  ? useGetQuestionnersQuery(page)
  : useGetAssetsQuery({ page, fileCategory });

  data = request.data;
  isLoading = request.isLoading;
  isFetching = request.isFetching;
  isError = request.isError;
  nextPage = data?.nextPage;
  hasNextPage = data?.hasNextPage;

  React.useEffect(() => {
    if (data?.docs && !isLoading && !isError) {
      setMainData(data?.docs);
    }
  }, [data]);

  return {
    data,
    mainData,
    isLoading,
    isFetching,
    isError,
    hasNextPage,
    nextPage,
    refetch: request?.refetch
  };
}
const SelectDocsListModal = ({ fileCategory }) => {
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const request = useFilesHook(fileCategory, page);

  const dispatch = useDispatch();
  const displayModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  if (request?.isLoading || request?.isFetching) {
    return <Spinner />;
  }
  const renderTrigger = () => (
    <Button
      style={{ width: 150 }}
      _hover={{
        bg: "accent.40",
        color: "base.0",
      }}
      _pressed={{
        bg: "accent.50",
        color: "base.0",
      }}
      bg="accent.40"
      borderRadius="sm"
      borderColor="neutral.40"
      onPress={displayModal}
    >
      <Text color="white">
        {i18n.t("components.selectDocsListModal.selectFile")}
      </Text>
    </Button>
  );

  const onEndReached = () => {
    if (request?.hasNextPage) {
      setPage(request?.nextPage);
    }
  };

  const selectItem = (item) => {
    //dispatch in store
    dispatch(
      setSelectedFile({
        fileCategory: fileCategory,
        selectedItem: item,
      })
    );
    closeModal();
  };

  const renderItem = ({ item, index }) => {
    const renderItem = ({ item, index }) => {
      const file = item;
      const select = () => {
        selectItem(item);
      };
      const prepareDItem = formatDocAssetsListForDisplay(
        [fileCategory === ASSETS_TYPES.questionner ? file?.asset : file],
        "document"
      );

      const itemTitle =
        fileCategory === ASSETS_TYPES.questionner ? file.name : file.assetName;

      const mediaItem = {
        ...prepareDItem[0],
        filename: itemTitle,
        type: "document",
        // ext: "xlsx",
      };
      console.log(
        "🚀 ~ file: SelectDocsListModal.js:155 ~ renderItem ~ file?.id:",
        file?.id
      );
      return (
        <Box m={2} key={file?.id || index}>
          <Suspense>
            <MediaCard item={mediaItem} onPress={select} />
          </Suspense>
        </Box>
      );
    };

    const columnsCount = 3;

    const ItemSeparatorComponent = () => {
      return <Box size={1}></Box>;
    };
    const listKey = (item, index) => item?.id + index;
    return (
      <Box key={item.title + index}>
        {renderSectionHeader(item?.title)}
        <FlatList
          _contentContainerStyle={{
            alignItems: "center",
            justifyContent: "center",
            justifyItems: "center",
          }}
        
          data={item.data}
          renderItem={renderItem}
          numColumns={columnsCount}
          keyExtractor={listKey}
          maxToRenderPerBatch={3}
          ItemSeparatorComponent={ItemSeparatorComponent}
        />
      </Box>
    );
  };
  const keyExtractor = (item, index) => {
    return item?.title + index;
  };
  const prepareDataForDisplay = (data, fileCategory) => {
    const dataGrouped = _.groupBy(data, (item) => {
      if (fileCategory === ASSETS_TYPES.questionner) {
        return item.parent.name;
      } else {
        return item.parent?.value?.name;
      }
    });

    const sectionData = _.flatMap(dataGrouped, (collectionOfQuestionners) => {
      if (
        !collectionOfQuestionners ||
        _.isUndefined(collectionOfQuestionners[0])
      ) {
        return [];
      }
      let title = "";
      if (fileCategory === ASSETS_TYPES.questionner) {
        title = collectionOfQuestionners[0]?.parent?.name;
      } else {
        title = collectionOfQuestionners[0].parent?.value?.name;
      }
      return {
        title: title,
        data: collectionOfQuestionners,
      };
    });

    return sectionData;
  };

  if (request.mainData.length === 0) {
    return (
      <Text fontSize="sm" color="primary.80">
        {i18n.t("components.selectDocsListModal.noFiles")}
      </Text>
    );
  }
  const sectionData = prepareDataForDisplay(request.mainData, fileCategory);

  const renderSectionHeader = (title) => {
    return (
      <Box px={2} py={3}>
        <Text color="primary.100" fontSize="xl" w="full" textAlign="left">
          {title}
        </Text>
        <Divider />
      </Box>
    );
  };

  const listKey = (item, index) => "doc-list-" + index.toString();

  const renderList = () => {
    return (
      <FlatList
        keye={listKey}
        w="full"
        onEndReached={onEndReached}
        data={sectionData}

        keyExtractor={keyExtractor}
        renderItem={renderItem}
        maxToRenderPerBatch={2}
      />
    );
  };

  // if (sectionData.length === 0) {
  //   return (
  //     <Empty
  //       message={`No ${fileCategory} type of file was found. Contact your
  //   administrator for more information.`}
  //     />
  //   );
  // }
  return (
    <VStack w="full" minWidth={su(50, 150, 150)}>
      <Box m="1" alignContent="center" display="flex" alignItems="center">
        <HStack space={1} mr={2}>
    
            <IconButton
        alignSelf="flex-start"
        color="accent.50"
        onPress={request.refetch}
        style={{ maxWidth: 30 }}
        _pressed={{ background: "neutral.30" }}
        icon={<Icon as={AntDesign} name="reload1" color="accent.50" />}
        size="lg"
      />
        {renderTrigger()}  
        </HStack>
     
   
      </Box>

      {showModal && sectionData.length > 0 && (
        <Modal
          size={su("full", "xl", "xl")}
          isOpen={showModal}
          onClose={closeModal}
        >
          <Modal.Content>
       
            <Modal.CloseButton />
      
            <Modal.Header>
          
      
      
        
              <Text>{i18n.t("components.selectDocsListModal.title")}</Text>
        
            </Modal.Header>
            <Modal.Body
              alignItems="center"
              justifyContent="center"
              justifyItems="center"
              _scrollview={{
                horizontal: true,
                scrollEnabled: false,
              }}
              w="full"
            >
              <Center
      
                alignItems="center"
                justifyContent="center"
                alignSelf="center"
                justifyItems="center"
                style={{ maxHeight: 500, width: su(320,520,520)}}
              >
                {renderList()}
              </Center>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      )}
    </VStack>
  );
};

export default SelectDocsListModal;
