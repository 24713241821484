import {
  Modal,
  Text,
  Box,
  Pressable,
  Center,
  VStack,
} from "native-base";
import {Platform } from 'react-native';
import React from "react";
import { i18n, DATE_FORMAT } from "../../../../utils/utils";
import _ from "lodash";
import dayjs from "dayjs";
// import DateSelectNativeWidget from "./DateSelectNativeWidget";
import DateTimePicker from "@react-native-community/datetimepicker";
const DateSelectNative = ({ multi,  setDate, defaultValue }) => {
  const [show, setShow] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const FIRST_DATE = 0;
  const SECOND_DATE = 1;
  const [selectedInput, setSelectedInput] = React.useState(FIRST_DATE);
  const isAndroid = Platform.OS === "android";
  const isIos = Platform.OS === "ios";

  const saveDate = (date) => {
    setDate(date);
    // setInternalDate(date);
    setShowModal(false);
  };

  const showNativePicker = (dateIndex) => {
    setShow(true);
    setSelectedInput(dateIndex);
    if(isIos){
      setShowModal(true);
    }
  };

  const hidePicker = () => {
    setShowModal(false);
  };

  const changeDate = (event, date) => {
    if (event?.type === "dismissed" && isAndroid) {
      setShow(false);
      return;
    }
    const newDate = [];
     newDate[selectedInput] = date.toJSON();
     const secondDateIndex = selectedInput === FIRST_DATE? SECOND_DATE:FIRST_DATE;
  
     if(_.isObject(defaultValue[secondDateIndex])){

       newDate[secondDateIndex] = defaultValue[secondDateIndex].toJSON();
      } else{
        newDate[secondDateIndex] = defaultValue[secondDateIndex];
        
      }
    
      setShow(false);
    saveDate(newDate);
  };

  const renderNativeDatePicker = () => {

    const getDate = () =>{
      if(_.isArray(defaultValue) && defaultValue?.[selectedInput]){
              return  new Date(Date.parse(defaultValue?.[selectedInput]));
      } else if(_.isString(defaultValue)){
        if(defaultValue.length === 10){

          return   dayjs(defaultValue,DATE_FORMAT).toDate();
        }
        return  dayjs(defaultValue).toDate();
      }
      return new Date();
    }
    const value = getDate()
    if (Platform.OS === "ios") {
      return (
        <Modal isOpen={showModal} onClose={hidePicker} size={"lg"}>
          <Modal.Content maxH={"90%"}>
            <Modal.CloseButton />
            <Modal.Header>
              {i18n.t('screens.mainTasksFlow.labels.date')}

            </Modal.Header>
            <Modal.Body>
              <Center>
                {show && (
                  <DateTimePicker
                    style={{ height: "100%" }}
                    testID="dateTimePicker"
                    value={value}
                    mode={"date"}
                    is24Hour={true}
                    onChange={changeDate}
                  />
                )}
              </Center>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      );
    } else {
      return (
        show && (
          <DateTimePicker
            style={{ height: "100%" }}
            testID="dateTimePicker"
            value={value}
            mode={"date"}
            is24Hour={true}
            onChange={changeDate}
          />
        )
      );

    }
  };

  const RenderDateInput = ({ dateIndex, label, date, defaultValue = null }) => {
    const onPress = () => {
      showNativePicker(dateIndex);
    };

    const getDisplayedData = (date) => {
      if (!date || date === undefined) {
        if (defaultValue === null) {
          return label;
        } else {
          return dayjs(defaultValue).format(DATE_FORMAT);
        }
      } else {
        if(date.length === 10){
          return date;
        }
        return dayjs(date).format(DATE_FORMAT);
      }
    };
    const displayedText = getDisplayedData(date);
    return (
      <Pressable onPress={onPress}>
        <Box
          p={2}
          borderColor="neutral.20"
          borderWidth="1"
          borderStyle="solid"
          style={{ height: 60 }}
          alignItems="center"
          justifyContent="center"
        >
          <Text
            px={4}
            color="base.90"
            style={{ fontSize: 16 }}
            fontWeight="semibold"
          >
            {displayedText}
          </Text>

          {defaultValue !== null && <Text>Tap to select another date </Text>}
        </Box>
      </Pressable>
    );
  };

  const getDate = (dateIndex) => {
    if (_.isUndefined(defaultValue) || defaultValue === null) {
      return new Date();
    }
    if(!_.isArray(defaultValue)){
      if(defaultValue){
        return defaultValue;
      }
    } else {
      if(defaultValue[dateIndex]){
        return defaultValue[dateIndex];
      }

    }
    return new Date();
  };
  const selectedFirstDate = getDate(FIRST_DATE);
  const selectedSecondDate = getDate(SECOND_DATE);
  return (
    <VStack space={3}>
      <RenderDateInput
        label={
          i18n.t('screens.mainTasksFlow.labels.startDate')
}
        date={selectedFirstDate}
        dateIndex={FIRST_DATE}
        defaultValue={defaultValue?.[0]}
      />

      {multi && (
        <RenderDateInput
          label={i18n.t('screens.mainTasksFlow.labels.endDate')}
          date={selectedSecondDate}
          dateIndex={SECOND_DATE}
          defaultValue={defaultValue?.[1]}
        />
      )}
      {renderNativeDatePicker()}
    </VStack>
  );
};

export default DateSelectNative;
