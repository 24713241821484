import { Text, FlatList, Box, VStack, Center, IconButton } from "native-base";
import { StatusBar } from "expo-status-bar";
import React from "react";
import { AntDesign } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import {
  i18n,
  su,
  windowHeight,
  ROW_CARD_MOBILE_VARIANTS,
  TASKS_MAIN_FLOW_SCREEN_STATUS,
  TASKS_FLOW_MODES,
} from "../../../../utils/utils";
import RowCardMobile from "../../../../components/table/rows/RowCardMobile";
import { flowScreens } from "../../../tasks/Tasks";
import { useDispatch } from "react-redux";
import { setIsCurrent } from "../../../../features/tasksSlice";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {  CommonActions } from "@react-navigation/native";
const SN = `screens.mainTasksFlow.`;

const FlowProgressMobile = ({ navigation }) => {
  const flowState = useSelector((state) => state.tasks.tasksMainFlow);
  const dispatch = useDispatch();
  const insets = useSafeAreaInsets();
  const flowData = flowState.data;
  const renderItem = (element) => {
    const status = flowData?.[element.item.name]?.isCurrent
      ? TASKS_MAIN_FLOW_SCREEN_STATUS.current
      : flowData[element.item.name]?.status;

    const onPressRow = () => {
      console.log("row pressed");
      if (flowState.mode === TASKS_FLOW_MODES.newTaskMode) {
        return;
      }

      dispatch(
        setIsCurrent({
          step: element.item.name,
        })
      );
      navigation.dispatch(CommonActions.goBack());
    };
    return (
      <RowCardMobile
        onPress={onPressRow}
        listNumber={element.item.number}
        variant={ROW_CARD_MOBILE_VARIANTS.orderedList}
        name={element.item.shortTitle}
        status={status}
      />
    );
  };

  return (
    <Box bg="base.0" style={{ marginTop: insets.top }}>
      <StatusBar style="dark" />
      <Box bg="base.0" w="full" h="full">
        <VStack bg="base.0">
          <Box bg="base.0" style={{ height: su(83 + 37) }}>
            <IconButton
              ml={3}
              mt={1}
              onPress={navigation.goBack}
              style={{
                width: 50,
                height: 50,
              }}
              _icon={{
                as: AntDesign,
                name: "close",
                size: 8,
                width: 40,
                height: 40,
                color: "base.90",
              }}
            />

            <Center mt={5}>
              <Text
                fontWeight="medium"
                lineHeight="xs"
                letterSpacing="lg"
                fontSize={16}
                color="base.90"
              >
                {i18n.t(`${SN}flowName`)}
              </Text>
              <Text
                py={2}
                fontWeight="medium"
                lineHeight="xs"
                letterSpacing="lg"
                fontSize={20}
                color="base.90"
              >
                {i18n.t(`${SN}progress`).toUpperCase()}
              </Text>
            </Center>
          </Box>
          <Box bg="base.0">
            <FlatList
              height={windowHeight - 150}
              scrollEnabled={true}
              bg="base.0"
              data={flowScreens}
              renderItem={renderItem}
            />
          </Box>
        </VStack>
      </Box>
    </Box>
  );
};

export default FlowProgressMobile;
