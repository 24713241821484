import { Box,Text,Spinner } from 'native-base'
import React from 'react'
import {i18n} from '../utils/utils'

const ReachedFooter = ({request}) => {
  return (
    <Box px={2} py={2}>
      {(request.isLoading&&request?.data?.hasNextPage) && <Spinner />}
      {!request?.data?.hasNextPage && (
        <Box pl={3} alignItems="center" justifyContent="center">
          <Text color="base.100" letterSpacing="md" fontSize="sm">
            {i18n.t('components.reachedFooter.reachedEnd')}
          </Text>
          <Text color="neutral.50" letterSpacing="sm" fontSize="sm">
          {i18n.t('components.reachedFooter.totalItems')}: {request?.data?.totalDocs}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default ReachedFooter