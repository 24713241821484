import React from "react";
import { Button, Text, Toast } from "native-base";
import { Platform } from "react-native";
import { i18n, MODAL_CONTENT_TYPES } from "../../../utils/utils";
import {
  openModalAndSetContent,
  setEditModeStatus,
} from "../../../features/uxSlice";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

const ActionBtsTasksTable = ({ element, height }) => {
  const dispatch = useDispatch();
  const authSlice = useSelector((state) => state.auth);
  const isEditModeActive = useSelector((state) => state.ux?.isEditModeActive);

  const accesss = authSlice.access;

  const editTask = () => {
    dispatch(setEditModeStatus());
  };
  const showLocationHistory = () => {
    if (
      element?.locationHistory === null ||
      !_.isArray(element?.locationHistory)
    ) {
      Toast.show({
        closeAll: true,
        description: "No location data for this task.",
      });
      return;
    }
    const content = element?.locationHistory
      .map((item) => {
        let copyItem = _.cloneDeep(item);
        // var deltaX = (maxX - minX) * 1.25;   var deltaY = (maxY - minY) * 1.25
        copyItem.latitude = Number(copyItem.latitude);
        copyItem.longitude = Number(copyItem.longitude);
        copyItem.latitudeDelta = 0.0421;
        copyItem.longitudeDelta = 0.0421;

        return copyItem;
      })
      .sort(function compare(a, b) {
        var dateA = new Date(a.date);
        var dateB = new Date(b.date);
        return dateA - dateB;
      });

    dispatch(
      openModalAndSetContent({
        contentBody: MODAL_CONTENT_TYPES.locationHistory,
        content: { locationHistory: content, isValid: true },
        size: "full",
      })
    );
  };
  const reqDeleteTask = () => {
    dispatch(
      openModalAndSetContent({
        contentBody: MODAL_CONTENT_TYPES.warningIrreversibleProcess,
        submitLabel: "Yes, delete task.",
        content: element?.id,
      })
    );

    // deleteTask()
  };

  const isAbleToEdit = accesss?.collections?.tasks?.update?.permission === true;

  const isAbleToDelete =
    accesss?.collections?.tasks?.delete?.permission === true;

  const isShowingLocationBtn = isAbleToDelete;

  const isShowingDeleteTaskBtn = Platform.OS === "web" && isAbleToDelete;

  const isShowingEditTaskBtn = Platform.OS === "web" && isAbleToEdit;

  const haveNothingToShow =
    !isShowingLocationBtn && !isShowingDeleteTaskBtn && !isShowingEditTaskBtn;

  if (haveNothingToShow) {
    return (
      <Button.Group
        mt={4}
        justifyItems="center"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        style={{ maxWidth: 368,height:height }}
        space={2}
        flexWrap="wrap"
      >
        <Text
          textAlign="center"
          fontWeight="medium"
          fontSize="lg"
          color="primary.100"
        >
          N/A
        </Text>
      </Button.Group>
    );
  }

  return (
    <Button.Group
      mt={4}
      justifyItems="center"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      style={{ maxWidth: 368, height:height }}
      space={2}
      flexWrap="wrap"
    >
      {isShowingLocationBtn && (
        <Button
          _hover={{
            bg: "accent.40",
            color: "base.0",
          }}
          _pressed={{
            bg: "accent.50",
            color: "base.0",
          }}
          onPress={showLocationHistory}
          bg="accent.10"
        >
          <Text color="base.0">
            {i18n.t("screens.tasks.buttons.locationsHistory")}
          </Text>
        </Button>
      )}
      {isShowingEditTaskBtn && (
        <Button
          _hover={{
            bg: "accent.40",
            color: "base.0",
          }}
          _pressed={{
            bg: "accent.50",
            color: "base.0",
          }}
          onPress={editTask}
          bg={isEditModeActive === true ? "accent.80" : "neutral.50"}
        >
          <Text color="base.0">
            {isEditModeActive === true
              ? i18n.t("screens.tasks.buttons.exitEditTask")
              : i18n.t("screens.tasks.buttons.editTask")}
          </Text>
        </Button>
      )}
      {isShowingDeleteTaskBtn && (
        <Button
          _hover={{
            bg: "accent.40",
            color: "base.0",
          }}
          _pressed={{
            bg: "accent.50",
            color: "base.0",
          }}
          onPress={reqDeleteTask}
          bg="accent.100"
        >
          <Text color="base.0">
            {i18n.t("screens.tasks.buttons.deleteTask")}
          </Text>
        </Button>
      )}
    </Button.Group>
  );
};

export default ActionBtsTasksTable;
