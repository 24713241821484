import React from "react";
import {Text, Spinner,  VStack } from "native-base";
import {  Form,  Select, DatePicker } from "antd";
import dayjs from "dayjs";
import { TASKS_FLOW_SCREENS_DATA_TYPES } from "../../../../assets/constants/tasksFlowDataType";
import {  setContent } from "../../../features/uxSlice";
import { flowScreens } from "../Tasks";
import _ from "lodash";
import { useDispatch } from "react-redux";
import {
  //projects
  useGetAllProjectsQuery,
  useLazyGetAllProjectsQuery,
  //areas
  useLazyGetAreasOfProjectQuery,
  useGetAreasOfProjectQuery,
  //questionner
  useLazyGetQuestionnersOfPnQuery,
  //dis
  useLazyGetDisOfAreaQuery,
  useGetDisOfAreaQuery,
  //pn
  useGetPnOfDisQuery,
  useLazyGetPnOfDisQuery,
  useGetQuestionnersOfPnQuery,
  //activity
  useLazyGetActivitiesQuery,
  useGetActivitiesQuery,
  //docs: formA,formB,otherDocs
  useLazyGetDocOfProjectsQuery,
  useGetDocOfProjectsQuery,

  //members
  useGetAllUsersQuery,
} from "../../../services/apiService";
import {
  ASSETS_TYPES,
  MAIN_FLOW_STEPS,
  getUserFullName,
} from "../../../utils/utils";
import { TASKS_STATUS_TYPES } from '../tasksStatusTypes';
//project - show: project,area,dis,pn, questionner,activity,form A, form B,relatedDocs
//area - show: area,dis,pn,questionner,activity
//dis - show:  dis,pn,questionner,activity
//pn - show: pn,questionner,activity
//pn - show: questionner,activity

const EditTaskModal = ({
  task,
  selectedAttribute,
  elementType = TASKS_FLOW_SCREENS_DATA_TYPES.oneItem,
}) => {
  const dispatch = useDispatch();
  const formRef = React.useRef(null);

  const [getAreasOfProject] = useLazyGetAreasOfProjectQuery();
  const [getDisOfArea] = useLazyGetDisOfAreaQuery();
  const [getAllProjects] = useLazyGetAllProjectsQuery();
  const [getQuestionnersOfPn] = useLazyGetQuestionnersOfPnQuery();
  const [getPnOfDis] = useLazyGetPnOfDisQuery();
  const [getDocOfProjects] = useLazyGetDocOfProjectsQuery();
  const [getActivities] = useLazyGetActivitiesQuery();

  const elementsConfigMap = {
    project: {
      method: useGetAllProjectsQuery,
      lazyMethod: getAllProjects,
      paramaters: { projectId: task?.project?.id },
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.oneItem,
      extraParameters: null,
      parametersAttributes: ["projectId"],
      dependentChildren: [
        MAIN_FLOW_STEPS.area,
        MAIN_FLOW_STEPS.dis,
        MAIN_FLOW_STEPS.pn,
        MAIN_FLOW_STEPS.questionner,
        "activity",
        "formA",
        "formB",
      ],
    },
    area: {
      method: useGetAreasOfProjectQuery,
      lazyMethod: getAreasOfProject,
      paramaters: { projectId: task?.project?.id },
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.oneItem,
      extraParameters: null,
      parametersAttributes: ["projectId"],
      dependentChildren: ["dis", "pn", "questionner", "activity"],
    },
    dis: {
      method: useGetDisOfAreaQuery,
      lazyMethod: getDisOfArea,
      paramaters: { areaId: task?.area?.id },
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.oneItem,
      extraParameters: null,
      parametersAttributes: ["areaId"],
      dependentChildren: ["pn", "questionner", "activity"],
    },
    pn: {
      method: useGetPnOfDisQuery,
      lazyMethod: getPnOfDis,
      paramaters: { disId: task?.dis?.id },
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.multipleItems,
      extraParameters: null,
      parametersAttributes: ["disId"],
      dependentChildren: ["questionner", "activity"],
    },
    questionner: {
      method: useGetQuestionnersOfPnQuery,
      lazyMethod: getQuestionnersOfPn,
      paramaters: task?.pn,
      dependentChildren: ["activity"],
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.oneItem,
      extraParameters: null,
      parametersAttributes: null,
    },
    activity: {
      method: useGetActivitiesQuery,
      lazyMethod: getActivities,
      paramaters: { questionnaireId: task?.questionner?.id },
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.oneItem,
      dependentChildren: [],
      parametersAttributes: ["questionnaireId"],
      extraParameters: null,
    },
    formA: {
      method: useGetDocOfProjectsQuery,
      lazyMethod: getDocOfProjects,
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.oneItem,
      paramaters: {
        projectId: task?.project?.id,
        assetType: ASSETS_TYPES.formA,
      },
      dependentChildren: [],
      parametersAttributes: ["projectId", "assetType"],
      extraParameters: { assetType: ASSETS_TYPES.formA },
    },
    formB: {
      method: useGetDocOfProjectsQuery,
      lazyMethod: getDocOfProjects,
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.oneItem,
      paramaters: {
        projectId: task?.project?.id,
        assetType: ASSETS_TYPES.formB,
      },
      dependentChildren: [],
      extraParameters: { assetType: ASSETS_TYPES.formB },
      parametersAttributes: ["projectId", "assetType"],
    },
    actualEndDate: {
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.date,
      paramaters: {
        projectId: task?.project?.id,
      },
      extraParameters: null,
      dependentChildren: [],
      parametersAttributes: ["projectId", "assetType"],
    },
    actualStartDate: {
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.date,
      paramaters: {
        projectId: task?.project?.id,
        assetType: ASSETS_TYPES.formB,
      },
      extraParameters: null,
      dependentChildren: [],
      parametersAttributes: ["projectId", "assetType"],
    },
    plannedStartDate: {
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.date,
      paramaters: {
        projectId: task?.project?.id,
      },
      extraParameters: null,
      dependentChildren: [],
      parametersAttributes: ["projectId"],
    },
    plannedEndDate: {
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.date,
      paramaters: {
        projectId: task?.project?.id,
      },
      extraParameters: null,
      dependentChildren: [],
      parametersAttributes: ["projectId"],
    },
    [MAIN_FLOW_STEPS.assignTo]: {
      method: useGetAllUsersQuery,
      lazyMethod: getDocOfProjects,
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.oneItem,
      paramaters: {
        projectId: task?.project?.id,
      },
      extraParameters: null,
      dependentChildren: [],
      parametersAttributes: ["projectId"],
    },
    [MAIN_FLOW_STEPS.status]: {
      paramaters: null,
      extraParameters: null,
      dependentChildren: [],
      elementType: TASKS_FLOW_SCREENS_DATA_TYPES.oneItem,
      parametersAttributes: ["projectId"],
      staticOptions: [
        {
          label: TASKS_STATUS_TYPES.complete,
          value: TASKS_STATUS_TYPES.complete,
        },
        {
          label: TASKS_STATUS_TYPES.confirmationRequired,
          value: TASKS_STATUS_TYPES.confirmationRequired,
        },
        {
          label: TASKS_STATUS_TYPES.incomplete,
          value: TASKS_STATUS_TYPES.incomplete,
        },
        {
          label: TASKS_STATUS_TYPES.unacknowledged,
          value: TASKS_STATUS_TYPES.unacknowledged,
        },
      ],
    },
  };
  const currentElement = elementsConfigMap?.[selectedAttribute];
  const dataSourceMethod = currentElement?.method;
  const mainAttributeConfig =
    flowScreens.find((item) => item.name === selectedAttribute) || {};


  const dataSourceMainElementParameters = currentElement?.paramaters;
  let dataSourceMainElementRequest= {};

  if (_.isFunction(dataSourceMethod)) {
    dataSourceMainElementRequest = dataSourceMethod(
      dataSourceMainElementParameters
    )
     }


  if (dataSourceMainElementRequest?.isLoading || dataSourceMainElementRequest?.isFetching) {
    return (
      <VStack>
        <Spinner />
        <Text>Fetching data...</Text>
      </VStack>
    );
  }

  const elementsOfTypeDate = [
    "actualEndDate",
    "actualEndDate",
    "actualStartDate",
    "plannedStartDate",
    "plannedEndDate",
  ];
  const elementsWithoutChildrens = [
    "formA",
    "formB",
    "actualEndDate",
    "actualStartDate",
    "plannedStartDate",
    "plannedEndDate",
    MAIN_FLOW_STEPS.status,
  ];
  if (!elementsWithoutChildrens.includes(selectedAttribute)) {
    if (
      !dataSourceMainElementRequest?.data?.docs ||
      dataSourceMainElementRequest?.data?.totalDocs === 0
    ) {
      //   Toast.show({closeAll: true,description: 'An expected error occured. Please try again or contact your administrator.'});
      // dispatch(closeModal());
      return null;
    }
  }

  const getDataSourceElement = () => {
    if (dataSourceMainElementRequest?.data?.docs) {
      return dataSourceMainElementRequest?.data?.docs;
    }
    if (selectedAttribute === MAIN_FLOW_STEPS.status) {
      return elementsConfigMap[selectedAttribute].staticOptions;
    }
    return [];
  };

  const dataSourceMainElement = getDataSourceElement();
  const defaultProps = {
    datasource: dataSourceMainElement,
    multiple: elementType === TASKS_FLOW_SCREENS_DATA_TYPES.multipleItems,
    name: selectedAttribute,
    label: mainAttributeConfig?.shortTitle,
  };

  const optionsMainElement = dataSourceMainElement.map((item) => {
    if (selectedAttribute === MAIN_FLOW_STEPS.assignTo) {
      return {
        value: item?.id,
        label: getUserFullName(item),
      };
    }

    if (selectedAttribute === MAIN_FLOW_STEPS.status) {
      return item;
    }

    return {
      value: item?.id,
      label: item?.name,
    };
  });
  const handleChangeMainElement = (value) => {
    const editedAtr = { [selectedAttribute]: value };

    dispatch(
      setContent({
        isValid: true,
        id: task.id,
        ...editedAtr,
      })
    );
  };



  const componentsMap = {
    [TASKS_FLOW_SCREENS_DATA_TYPES.oneItem]: (
      <Select
        placeholder={"Select " + selectedAttribute}
        onChange={handleChangeMainElement}
        allowClear
        options={optionsMainElement}
        style={{ width: 250 }}
      />
    ),
    [TASKS_FLOW_SCREENS_DATA_TYPES.multipleItems]: (
      <Select
        placeholder={"Select " + selectedAttribute}
        onChange={handleChangeMainElement}
        allowClear
        mode={'multiple'}
        options={optionsMainElement}
        style={{ width: 250 }}
      />
    ),
    [TASKS_FLOW_SCREENS_DATA_TYPES.date]: (
      <DatePicker
        style={{
          width: 400,
        }}
        onChange={handleChangeMainElement}
        picker="day"
        // defaultValue={defaultValue ? dayjs(defaultValue) : null}
      />
    ),
  };

  const elementCurrentValue = task?.[selectedAttribute];
  const getInitialValue = (el, elementCurrentValue) => {
    if (elementsWithoutChildrens.includes(el)) {
      if (elementsOfTypeDate.includes(el)) {
        return dayjs(elementCurrentValue);
      } else {
        return elementCurrentValue;
      }
    }
    if (el === MAIN_FLOW_STEPS.pn) {
      return elementCurrentValue.map((item) => item.id);
    }
    return elementCurrentValue?.id;
  };

  const initialValue = getInitialValue(selectedAttribute, elementCurrentValue);
  return (
    <VStack h="sm" space={2} maxW="lg">
      <Form
        ref={formRef}
        name="control-ref"
        style={{
          maxWidth: 700,
        }}
      >
        <Form.Item
          name={selectedAttribute}
          initialValue={initialValue}
          key={"main-element"}
          {...defaultProps}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {componentsMap[elementsConfigMap[selectedAttribute].elementType]}
        </Form.Item>
      </Form>
    </VStack>
  );
};

export default EditTaskModal;
