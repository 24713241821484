import {  HStack } from "native-base";
import React from "react";
import { windowWidth } from "../../utils/utils";
import Proptypes from "prop-types";
  /* eslint-disable react/display-name */

const ReportRow = React.memo(({ children, isMobile,borderLess=false }) => {
  const flexDirection = !isMobile ? "row" : "column";
  const justifyContent = !isMobile ? "space-between" : "flex-start";
  const alignItems = !isMobile ? "center" : "flex-start";
  const maxWidth = 1000;
  return (
    <HStack
      p={3}
      style={{width:windowWidth - 50 > maxWidth ? maxWidth : windowWidth - 50,maxWidth: maxWidth}}
      alignItems={alignItems}
      justifyContent={justifyContent}
      flexDirection={flexDirection}
      // flexWrap={flexWrap}
      borderStyle="solid"
      space={2}
      borderWidth={borderLess? 0:1}
      borderColor="neutral.50"
    
    >
      {children}
    </HStack>
  );
});

ReportRow.propTypes = {
  children: Proptypes.node.isRequired,
  isMobile: Proptypes.bool.isRequired,
  borderLess: Proptypes.bool,
};

export default ReportRow;
