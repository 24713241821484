import { Button, Input, Text, Toast, VStack, Box } from "native-base";
import React from "react";
import { SafeAreaView } from "react-native";
import { i18n } from "../../utils/utils";
import {
  useVerifyOTPMutation,
} from "../../services/apiService";
import { logOutUserHook } from "../../features/authSlice";
import LoginLayout from "./LoginLayout";

const TwoFactorAuth = () => {

  const [otpCode, setOtpCode] = React.useState("");
  const [verifyIsDisabled, setVerifyIsDisabled] = React.useState(false);

  const [verifyOTP, verifyOTPRequest] = useVerifyOTPMutation();
  const [logOutUserHandler, logOutRequest] = logOutUserHook();
  const validateCode = () => {
    var pattern = /^\d+\.?\d*$/;
    const isFormedOnlyFromNumbers = pattern.test(otpCode);

    if (!otpCode || otpCode.length !== 6 || !isFormedOnlyFromNumbers) {
        Toast.show({closeAll: true, description: "Code is not valid!" });
      return;
    }

    const disableBtnAndLogOut = () => {
      setVerifyIsDisabled(true);
      logOutUserHandler().then(() => {
          Toast.show({closeAll: true,
          description:
            "Your user has been successfully logged you automatically by the app. Contact your administrator to unlock your 2FA feature.",
          duration: 10000,
        });
      });
    };

    verifyOTP({ otp: otpCode })
      .then((response) => {
        if (response.error) {
          if (
            response?.error?.data.isOtpValid === false &&
            Number(response?.error?.data.tries2FA) <= 3
          ) {
              Toast.show({closeAll: true,
              description: `The code is incorrect. You have tried ${response?.error?.data.tries2FA} times. After 3 incorect attepts,the user is blocked.`,
              duration: 10000,
            });

            return;
          }

          if (response?.error?.data?.message)
              Toast.show({closeAll: true,
              description: response?.error?.data?.message,
              duration: 8000,
            });

          if (response.error.data.name === "blocked-2fa") {
            console.log(
              "🚀 ~ .then ~ response.error.data.name",
              response.error.data.name
            );
            disableBtnAndLogOut();
          }

          return;
        }

        if (response?.data.isOtpValid === true) {
            Toast.show({closeAll: true,
            description: `Logged in succefully with 2FA Autentification.`,
            duration: 10000,
            colorScheme: "success",
          });
        }

        // navigation.navigate('Tasks')
      })
      .catch((err) => {
        console.log("🚀 ~ validateCode ~ err", err);
          Toast.show({closeAll: true, description: "Somewthing went wrong!" });
      });
  };
  return (
    <SafeAreaView>
      <LoginLayout>
        <VStack space={3}>
          <Box
            borderRadius="sm"
            alignItems="center"
            px={3}
            py={3}
            bg="neutral.80"
          >
            <Text
              mt="1"
              color="base.90"
              _dark={{
                color: "warmGray.200",
              }}
              alignText="center"
              fontWeight="medium"
              fontSize="md"
            >
              {i18n.t('screens.twoFactorAuth.title')}
            </Text>
          </Box>
          <Text fontWeight="thin" fontSize="md">
            {i18n.t('screens.twoFactorAuth.description')}
          </Text>
          <Input
            keyboardType="number-pad"
            isDisabled={verifyIsDisabled}
            placeholder="Enter one time pass"
            onChangeText={setOtpCode}
          />
          <Button.Group>
            <Button
              isLoading={verifyOTPRequest.isLoading}
              isLoadingText="Verifying..."
              onPress={validateCode}
              isDisabled={verifyIsDisabled}
              bg="primary.60"
            >
              <Text color="base.0">
                {i18n.t('screens.twoFactorAuth.buttons.verify')}
              </Text>
            </Button>
            <Button
              borderColor="primary.60"
              variant="outline"
              isLoading={logOutRequest.isLoading}
              isLoadingText="Logging out..."
              onPress={logOutUserHandler}
            >
              <Text color="primary.60">
                {i18n.t('screens.twoFactorAuth.buttons.cancel')}
              </Text>
            </Button>
          </Button.Group>
        </VStack>
      </LoginLayout>
    </SafeAreaView>
  );
};

export default TwoFactorAuth;
