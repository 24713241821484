import React from "react";
import {
  Spinner,
  Center,
  Heading,
  Alert,
  HStack,
  VStack,
  Text,
  Button,
  Spacer,
  Box,
} from "native-base";
import { logOutUserHook } from "../features/authSlice";
import { i18n, windowHeight } from "../utils/utils";

const LoadingData = ({
  error,
  isUninitialized,
  isLoading,
  isFetching,
  isError
}) => {
  // const [logOut, logOutRequest] = useLogoutMutation();
  const [logOutUserHandler, logOutRequest] = logOutUserHook();

  // logOutUserHandler, logOutRequest]
  const errorStatusMap = {
    default: i18n.t("screens.loadingData.descriptionDefault"),
    403: i18n.t("screens.loadingData.description403"),
  };

  if (isError) {
    return (
      <Center w="full" h="100%" mt={3}>
        <Alert w={{ sm: "100%", md: "50%" }} status={"error"}>
          <VStack space={4} flexShrink={1} w="100%">
            <HStack flexShrink={1} space={2} justifyContent="space-between">
              <HStack space={2} flexShrink={1}>
                <Alert.Icon mt="1" />
                <Text fontSize="md">
                  {errorStatusMap[error?.status] || errorStatusMap.default}
                </Text>
              </HStack>
            </HStack>
            <Text mt={4} textAlign="right" fontSize="sm">
              {i18n.t("screens.loadingData.labels.technicalErrorCode")}
              <Text italic> {error?.status}</Text>
            </Text>
            <Button.Group space={2}>
              <Button
                onPress={logOutUserHandler}
                isLoading={logOutRequest?.isLoading}
                borderColor="primary.100"
                variant="outline"
              >
                {i18n.t("screens.loadingData.buttons.signOut")}
              </Button>
            </Button.Group>
          </VStack>
        </Alert>
      </Center>
    );
  }

  return (
    <Box
      justifyItems="center"
      alignContent="center"
      justifyContent="center"
      alignItems="center"
      marginTop={windowHeight / 3}
    >
      <VStack
        justifyItems="center"
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        display="flex"
        p={1}
        m={1}
        space={1}
        maxHeight={20}
      >
        <Box>
          <Spinner
            tintColor={"primary.100"}
            color="primary.100"
            accessibilityLabel="LoadingData data..."
          />
        </Box>

        <Box
          justifyContent={"center"}
          alignContent="center"
          alignItems={"center"}
          display="flex"

        >
         <Heading
              numberOfLines={1}
              ellipsizeMode="tail"
              color="primary.500"
              fontSize={14}

            >
              {isUninitialized && !isLoading && !isFetching && (
                <Text
                  fontSize={14}
                  height={14}
                  numberOfLines={1}
                  lineHeight="3xl"
                  ellipsizeMode="tail"
                >
                  {i18n.t("screens.loadingData.title1")}
                </Text>
              )}
                     {isFetching && (
                <Text
                  fontSize={14}
                  numberOfLines={1}
                  lineHeight="3xl"
                  ellipsizeMode="tail"
                >
                  {i18n.t("screens.loadingData.title3")}
                </Text>
              )}
              {isLoading && !isFetching && (
                <Text
                  fontSize={14}

                  numberOfLines={1}
                  lineHeight="3xl"
                  ellipsizeMode="tail"
                >
                  {i18n.t("screens.loadingData.title2")}
                </Text>
              )}
              {isLoading && isFetching  &&<Spacer />}
       
            </Heading>
        </Box>
      </VStack>
    </Box>
  );
};

export default LoadingData;
