import React from "react";
import { FlatList } from "react-native";
import { Toast } from "native-base";
import Empty from "../../components/Empty";
import ReachedFooter from "../../components/ReachedFooter";
import RowCardMobile from "../../components/table/rows/RowCardMobile";
import { RowCardMobileVariants } from "../../components/table/rows/rowCardMobileVariants";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetProjectsQuery,
  useLazyGetProjectsQuery,
  useGetAreasOfProjectQuery,
  useLazyGetAreasOfProjectQuery,
  useGetDisOfAreaQuery,
  useLazyGetQuestionnersOfPnQuery,
  useGetTasksOfQuestionnerQuery,
  useLazyGetTasksOfQuestionnerQuery,
  useLazyGetDisOfAreaQuery,
  useGetTasksOfDisQuery,
  useLazyGetTasksOfDisQuery,
} from "../../services/apiService";
import { setTaskFilter } from "../../features/uxSlice";
import {
  MAIN_FLOW_STEPS,
  i18n,
  sortListAlphabeticallyByTag,
} from "../../utils/utils";
import _ from "lodash";
import { filtersList } from "./taskFilters.constant";
import LoadingData from "../../components/LoadingData";
import { useFocusEffect } from "@react-navigation/native";
const getScreenResources = (filter, tasksFilters) => {
  if (!filter) {
    return null;
  }
  const [currentPage, setCurrentPage] = React.useState("1");
  const [data, setData] = React.useState(null);

  const [getProjects] = useLazyGetProjectsQuery(currentPage || "1");
  const [getAreasOfProject] = useLazyGetAreasOfProjectQuery();
  const [getDisOfArea] = useLazyGetDisOfAreaQuery();

  const [getQuestionnersOfPn] = useLazyGetQuestionnersOfPnQuery();
  const [getTasksOfQuestionner] = useLazyGetTasksOfQuestionnerQuery();
  const [getTasksOfDis] = useLazyGetTasksOfDisQuery();

  const methodsMap = {
    project: {
      requestMethod: useGetProjectsQuery,
      onRefresh: getProjects,
      requestParams: currentPage,
    },
    area: {
      requestMethod: useGetAreasOfProjectQuery,
      requestParams: { projectId: tasksFilters?.project?.id },
      onRefresh: getAreasOfProject,
    },
    dis: {
      requestMethod: useGetDisOfAreaQuery,
      onRefresh: getDisOfArea,
      requestParams: {
        areaId: tasksFilters?.area?.id,
        projectId: tasksFilters?.project?.id,
      },
    },
    pn: {
      requestMethod: useGetTasksOfDisQuery,
      onRefresh: getTasksOfDis,
      requestParams: {
        disId: tasksFilters?.dis?.id,
        areaId: tasksFilters?.area?.id,
        projectId: tasksFilters?.project?.id,
      },
    },
    questionner: {
      requestMethod: useGetTasksOfDisQuery,
      onRefresh: getQuestionnersOfPn,
      requestParams: {
        disId: tasksFilters?.dis?.id,
        areaId: tasksFilters?.area?.id,
        projectId: tasksFilters?.project?.id,
        pnId: tasksFilters?.pn?.id,
        requestParam: [tasksFilters?.pn],
        questionnaireId: tasksFilters?.questionner?.id,
      },
    },
    activity: {
      onRefresh: getTasksOfQuestionner,
      requestMethod: useGetTasksOfQuestionnerQuery,
      requestParams: {
        disId: tasksFilters?.dis?.id,
        areaId: tasksFilters?.area?.id,
        projectId: tasksFilters?.project?.id,
        pnId: tasksFilters?.pn?.id,
        questionnaireId: tasksFilters?.questionner?.id,
      },
    },
  };
  const filtersListEnriched = filtersList.map((item) => {
    
    const extraProps = methodsMap[item?.name];
    if(extraProps){
      return {
        ...extraProps,
        ...item,
      };
    }
 
  });

  let filterData = filtersListEnriched.find((item) => item.name === filter);
  

  if (filterData === undefined) {
    return null;
  }

  filterData = {
    ...filterData,
    currentPage,
    setCurrentPage,
    filtersList,
    data,
    setData,
  };

  return filterData;
};

const TaskFilterScreen = (props) => {
  const {
    navigation,
    route: {
      params: { screenName },
    },
  } = props;

  const tasksFilters = useSelector((state) => state.ux.tasksFilters);
  const [isFocused, setIsFocus] = React.useState(false);
  useFocusEffect(
    React.useCallback(() => {
      // Do something when the screen is focused
      setIsFocus(true);

      return () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions
        setIsFocus(false);
      };
    }, [])
  );
  const screenRes = getScreenResources(screenName, tasksFilters);
  const dispatch = useDispatch();

  const {
    setCurrentPage = () => {},
    nextScreen = "",
    requestParams = null,
  } = screenRes;

  if (!requestParams) {
    navigation.replace("Tasks");
    return null;
  }
  const request = screenRes.requestMethod(requestParams, {
    refetchOnMountOrArgChange: true,
    skip: !isFocused,
  });

  const projects = tasksFilters.data.projects;

  if (!isFocused) {
    return null;
  }

  const onEndReached = () => {
    if (request?.data?.hasNextPage) {
      setCurrentPage(request?.data?.nextPage);
    }
  };

  const areas = projects?.[tasksFilters?.project?.index]?.areas;

  const disItems = areas?.[tasksFilters?.area?.index]?.dis;

  const pnItems = disItems?.[tasksFilters?.dis?.index]?.pn;

  const questionnaires = pnItems?.[tasksFilters?.pn?.index]?.questionnaires;

  const activities =
    questionnaires?.[tasksFilters?.questionner?.index]?.activities;

  const dataMap = {
    [MAIN_FLOW_STEPS.project]: projects,
    [MAIN_FLOW_STEPS.area]: areas,
    [MAIN_FLOW_STEPS.dis]: disItems,
    [MAIN_FLOW_STEPS.pn]: pnItems,
    [MAIN_FLOW_STEPS.questionner]: questionnaires,
    [MAIN_FLOW_STEPS.activity]: activities,
  };

  const data = dataMap[screenName];
  if (request?.isLoading || request?.isFetching) {
    return (
      <LoadingData
        isLoading={request.isLoading}
        isFetching={request.isFetching}
      />
    );
  }
  const goTo = (id) => {
    const selectedElementIndex = data.findIndex((item) => item.id === id);
    if (selectedElementIndex === -1) {
      Toast.show({
        closeAll: true,
        description: i18n.t("errorsMsg.unexpected"),
      });
      return;
    }
    const selectedElement = data?.[selectedElementIndex];
    const selectedElementId = selectedElement?.id;

    if (!selectedElement || !selectedElementId) {
      Toast.show({
        closeAll: true,
        description: i18n.t("errorsMsg.unexpected"),
      });
      return;
    }

    dispatch(
      setTaskFilter({
        attributeName: screenName,
        attributeValue: { index: selectedElementIndex, id: selectedElementId },
      })
    );

    if (screenName === "activity") {
      if (!tasksFilters?.questionner?.id) {
        Toast.show({
          closeAll: true,
          description: i18n.t("errorsMsg.unexpected"),
        });
      }

      if (
        !_.isArray(selectedElement.tasks) ||
        selectedElement.tasks.length === 0
      ) {
        Toast.show({
          closeAll: true,
          description: "This activity does not have any tasks.",
        });

        return;
      }
      navigation.navigate("TasksDetails", { taskId: selectedElement.tasks?.[0]?.id });
      return;
    }
    navigation.navigate("Tasks", {
      screen: "TasksSelector",
      params: {
        screen: nextScreen,
        params: {
          screenName: nextScreen,
        },
      },
    });
  };
  const renderItem = ({ item }) => {
    const name = `${item?.name}`;
    let status = "special";
    if (screenName === MAIN_FLOW_STEPS.activity) {
      status =
        !_.isArray(item.tasks) || item.tasks.length === 0
          ? "normal"
          : "special";
    }
    return (
      <RowCardMobile
        variant={RowCardMobileVariants.navigator}
        name={name}
        status={status}
        onPress={goTo}
        id={item.id}
      />
    );
  };
  const requestFooterObj =
    screenName !== "activity"
      ? request
      : { data: { totalDocs: data?.length - 1 } };
  const ListFooterComponent = () => (
    <ReachedFooter request={requestFooterObj} />
  );
  const dataSortedAlphabetically = sortListAlphabeticallyByTag(
    _.cloneDeep(data),
    "name"
  );

  return (
    <FlatList
      refreshing={request?.isFetching || false}
      onRefresh={request?.refetch}
      data={dataSortedAlphabetically}
      ListEmptyComponent={Empty}
      ListFooterComponent={ListFooterComponent}
      renderItem={renderItem}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.9}
      keyExtractor={(item) => item?.id}
    />
  );
};

export default TaskFilterScreen;
