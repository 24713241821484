import {
  Box,
  Text,
  HStack,
  VStack,
  Center,
  Badge,
  Toast,
  Button,
  Divider,
  TextArea,
  Spacer,
  FormControl,
} from "native-base";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useSelector } from "react-redux";
import * as ImagePicker from "expo-image-picker";
import LoadingData from "../../components/LoadingData";
import ViewMediaCollection from "../../components/ViewMediaCollection";
import daysjs from "dayjs";

import React from "react";
import * as Crypto from "expo-crypto";
import {
  su,
  useAcrossDevices,
  windowWidth,
  DATE_FORMAT,
  formatDocAssetsListForDisplay,
  REPORT_ERROR_MODES,
  getTableResultsDataSource,
  getUserFullName,
  i18n,
  pickImages,
  prepareImagesForUpload,
  displayServerErrors,
  fetchImage,
  openCamera,
  formatSelectForUploadImagesListForDisplay,
  getImagesOfAnswers,
} from "../../utils/utils";
import { FontAwesome } from "@expo/vector-icons";
import _ from "lodash";
import UserAvatar from "../../components/UserAvatar";
import ReportsLogoRow from "../../components/reports/ReportsLogoRow";
import InputReport from "../../components/reports/InputReport";
import ReportRow from "../../components/reports/ReportRow";
import NavBtn from "../../components/shared/NavBtn";
import { useRoute } from "@react-navigation/native";
import {
  useLazyGetTaskQuery,
  useLazyGetErrorReportQuery,
  useAddAssetMutation,
  useDeleteAssetMutation,
  useAddErrorReportMutation,
} from "../../services/apiService";
import ReportRowBasic from "../../components/reports/ReportRowBasic";
import ResolveFlowNavigationDesktop from "../../screens/tasks/resolve-task/ResolveFlowNavigationDesktop";
const REPORT_FIELDS = {
  projectName: "projectName",
  logo1: "logo1",
  logo2: "logo2",
  subcontractor: "subcontractor",
  contractNo: "contractNo",
  ncrNo: "ncrNo",
  qty: "qty",
  ref: "ref",
  errorCode: "errorCode",
  description: "description",
  additional: "additional",
  images: "images",
  reference: "reference",
  showAdditional: "showAdditional",
  showReference: "showReference",
  resultsTable: "resultsTable",
};

const initialValues = {
  [REPORT_FIELDS.projectName]: "",
  [REPORT_FIELDS.logo1]: null,
  [REPORT_FIELDS.logo2]: null,
  [REPORT_FIELDS.subcontractor]: "",
  [REPORT_FIELDS.contractNo]: "",
  [REPORT_FIELDS.ncrNo]: "",
  [REPORT_FIELDS.qty]: "",
  [REPORT_FIELDS.ref]: "",
  [REPORT_FIELDS.description]: "",
  [REPORT_FIELDS.additional]: "",
  [REPORT_FIELDS.images]: [],
  [REPORT_FIELDS.reference]: [],
  [REPORT_FIELDS.showAdditional]: false,
  [REPORT_FIELDS.showReference]: false,
};

const SN = "screens.errorReport.";
const paddingQuestionerRequestContainer = su(10, 48, 48);

// let imagesAreInit = false;

const getAttachedImages = (reportImages, formImages, isViewMode) => {
  let images = [];
  if (!isViewMode) {
    images = [
      ...formatSelectForUploadImagesListForDisplay(formImages),
      ...images,
    ];
    return images;
  }
  if (reportImages && _.isArray(reportImages) && reportImages.length > 0) {
    images = [...reportImages, ...images];
  }
  return images;
};
const ReportError = ({ navigation }) => {
  const route = useRoute();
  const fromNotifications = route.params?.fromNotifications;
  const { isMobile, isDesktop, isTablet } = useAcrossDevices();
  const goToResolveTask = () => {
    if (fromNotifications) {
      if (isMobile) {
        navigation.replace("Tasks", { screen: "NotificationsMobile" });
      } else {
        navigation.replace("Notifications");
      }
    } else {
      navigation.replace("Tasks");
    }
  };
  React.useEffect(() => {
    function init() {
      navigation.setOptions({
        headerLeft: () => (
          <NavBtn
            renderText={true}
            onPress={goToResolveTask}
            skipNavigation={true}
            variant="left"
            textProp={fromNotifications ? "Go back" : "Go to tasks "}
          />
        ),
      });
    }
    init();
  }, []);

  const mode = route.params?.reportId
    ? REPORT_ERROR_MODES.view
    : REPORT_ERROR_MODES.create;

  const isViewMode = mode === REPORT_ERROR_MODES.view;
  const [showHiddenFields, setShowHiddenFields] = React.useState({
    [REPORT_FIELDS.showAdditional]: false,
    [REPORT_FIELDS.showReference]: isViewMode ? true : false,
  });

  const [uploadedLogos, setUploadedLogos] = React.useState({
    [REPORT_FIELDS.logo1]: null,
    [REPORT_FIELDS.logo2]: null,
  });

  const [data, setData] = React.useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
  const [formData, setFormData] = React.useState(initialValues);
  const [formErrors, setFormErrors] = React.useState({});

  const authSlice = useSelector((state) => state.auth);
  const token = authSlice.token;
  const loggedUser = authSlice.user;

  const [addErrorReport, requestAddErrorReport] = useAddErrorReportMutation();

  const PN = data?.pn;

  const [deleteAsset] = useDeleteAssetMutation();

  const [getErrorReport, requestErrorReport] = useLazyGetErrorReportQuery();
  const [getTask, requestTask] = useLazyGetTaskQuery();
  const [addAsset] = useAddAssetMutation();

  let selectableReferences =
    formatDocAssetsListForDisplay(data?.task?.otherDocs, "document") || [];

  const showGeneralErrorAndGoToTasks = () => {
    Toast.show({
      closeAll: true,
      description: i18n.t("screens.finalReport.messages.reportFetchError"),
    });
    navigation.navigate("Tasks");
  };
  const task = data?.task;
  // const currentQuestionIndex = questions?.findIndex(item=>item.id === route.params?.currentQuestionId);

  const questions = task?.activity?.questions;

  const question = isViewMode
    ? data?.question || null
    : questions?.[currentQuestionIndex] || null;

  const setUploadedLogo = (field, value = null) => {
    if (value === null) {
      Toast.show({
        closeAll: true,
        description: "The " + field + " cannot be displayed at this moment.",
      });
      return;
    }
    setUploadedLogos((prev) => ({
      ...prev,
      [field]: { uri: value },
    }));
  };
  const downloadAndSetLogos = async (report) => {
    const logo1 = report?.logo1?.url;
    const logo2 = report?.logo2?.url;

    if (logo1) {
      await fetchImage(logo1, REPORT_FIELDS.logo1, token, setUploadedLogo);
    }
    if (logo2) {
      await fetchImage(logo2, REPORT_FIELDS.logo2, token, setUploadedLogo);
    }
  };
  React.useEffect(() => {
    async function getInitialData() {
      const taskId = route.params?.taskId;

      const reportId = route.params?.reportId;
      const pnIdListUrl = route.params?.pnIdList;
      if (!taskId && !reportId) {
        showGeneralErrorAndGoToTasks();
        return;
      }

      if (!pnIdListUrl && !reportId) {
        showGeneralErrorAndGoToTasks();
        return;
      }
      let pnIdListArray = [];
      if (_.isString(pnIdListUrl)) {
        pnIdListArray = pnIdListUrl.split(",");
      } else {
        pnIdListArray = pnIdListUrl;
      }

      if (_.isString(taskId)) {
        getTask({ taskId })
          .unwrap()
          .then((response) => {
            if (response) {
              const pn = response?.pn.filter((item) =>
                pnIdListArray.includes(item.id)
              );
              const pnIds = pn.map((item) => item.id);

              const questions = response?.activity?.questions;

              const questionNowIndex = questions.findIndex(
                (item) => item.id === route.params?.currentQuestionId
              );

              const questionNow = _.cloneDeep(questions?.[questionNowIndex]);

              setCurrentQuestionIndex(questionNowIndex);

              const enrichedQuestion = questionNow?.answers?.map((item) => {
                return {
                  ...item,
                  pn: response?.pn.filter((pnItem) => pnItem.id === item.pn)[0],
                };
              });
              questionNow.answers = enrichedQuestion;

              const processed = getTableResultsDataSource(questionNow, pnIds);
              let newData = {
                ...data,
                task: response,
                pn,
                onlyTask: true,
                [REPORT_FIELDS.resultsTable]: processed,
                question: questionNow,
              };

              setData(newData);
            }
          })
          .catch(() => {
            showGeneralErrorAndGoToTasks();
          });
      } else if (_.isString(reportId)) {
        getErrorReport(reportId)
          .unwrap()
          .then(async (response) => {
            if (response) {
              const processed = getTableResultsDataSource(
                question,
                response.pn
              );
              setData({
                ...response,
                onlyTask: false,
                [REPORT_FIELDS.resultsTable]: processed,
              });
              if (
                response?.[REPORT_FIELDS.additional] &&
                response?.[REPORT_FIELDS.additional].length > 0
              ) {
                setShowHiddenFields({
                  ...showHiddenFields,
                  [REPORT_FIELDS.showAdditional]: true,
                });
              }
              await downloadAndSetLogos(response);
            }
          })
          .catch(() => {
            showGeneralErrorAndGoToTasks();
          });
      }
    }

    getInitialData();
  }, []);

  const [status, requestPermission] = ImagePicker.useCameraPermissions();
  const removeLogoFromState = (fieldName) => {
    handleChange("", fieldName);
    setUploadedLogos((prev) => ({
      ...prev,
      [fieldName]: null,
    }));
  };

  const isLoadingData = requestErrorReport.isLoading || requestTask.isLoading;
  const isFetchingData =
    requestErrorReport.isFetching || requestTask.isFetching;
  const isUninitializedData =
    requestErrorReport.isUninitialized || requestTask.isUninitialized;

  const handleChange = (value, fieldName) => {
    if (!value) {
      return;
    }
    setFormData((prev) => ({ ...prev, [fieldName]: value }));
  };
  const validateForm = () => {
    //iterate thourgh object formData
    let errors = {};
    let found = false;
    const requiredFields = [
      REPORT_FIELDS.projectName,
      REPORT_FIELDS.description,
      REPORT_FIELDS.subcontractor,
      REPORT_FIELDS.contractNo,
      REPORT_FIELDS.ncrNo,
      REPORT_FIELDS.qty,
      REPORT_FIELDS.ref,
      REPORT_FIELDS.description,
    ];
    Object.keys(formData).forEach((key) => {
      // we skip the logos from validation
      if (!requiredFields.includes(key)) {
        return;
      }
      const value = formData[key];

      if (!value || !_.isString(value) || value === "" || value.length < 2) {
        found = true;
        errors[key] = i18n.t("screens.finalReport.messages.missingFields");
      }
    });
    if (found) {
      setFormErrors(errors);
      return false;
    }
    setFormErrors({});
    return true;
  };
  const errors = formErrors;

  if (isLoadingData || isFetchingData || !questions) {
    return (
      <LoadingData
        isUninitialized={isUninitializedData}
        isLoading={isLoadingData}
        isFetching={isFetchingData}
      />
    );
  }

  const removeLogo = async (fieldName) => {
    const assetId = formData[fieldName];
    removeLogoFromState(fieldName);
    if (!assetId) return;
    deleteAsset({ id: assetId })
      .then((res) => {
        if (displayServerErrors(res)) return;
        Toast.show({
          closeAll: true,
          description: i18n.t("screens.finalReport.messages.logoRemoved"),
        });
      })
      .catch(() => {
        Toast.show({
          closeAll: true,
          description: i18n.t("errorsMsg.generalError"),
        });
      });
  };
  const removeLogo1 = async () => {
    removeLogo(REPORT_FIELDS.logo1);
  };
  const removeLogo2 = async () => {
    removeLogo(REPORT_FIELDS.logo2);
  };
  const setSelectedReferences = (refDocList) => {
    handleChange(refDocList, REPORT_FIELDS.reference);
  };

  const submitReport = async () => {
    const isValid = validateForm();
    if (!isValid) {
      Toast.show({
        closeAll: true,
        description: i18n.t(`${SN}errors.invalidForm`),
      });
      return;
    }

    if (!question) return;

    const form = new FormData();
    form.append("task", task.id);
    form.append("createdBy", loggedUser.id);
    form.append("question", question.id);
    const questionPNIds = data?.pn.map((item) => item.id);
    form.append("pn", JSON.stringify(questionPNIds));

    const referenceDoc = formData?.[REPORT_FIELDS.reference];
    if (referenceDoc?.length > 0) {
      const refDocIdList = referenceDoc.map((item) => item.id);
      form.append("reference", JSON.stringify(refDocIdList));
    }

    const reportImagesList = formData?.images;

    if (reportImagesList?.length > 0) {
      try {
        const preparedFiles = prepareImagesForUpload(reportImagesList);
        preparedFiles.forEach((file) => {
          form.append("file", ...file);
        });
      } catch (err) {
        console.warn("🚀 ~  err:", err);
      }
    }

    form.append("description", formData?.[REPORT_FIELDS.description] || "");

    form.append("additional", formData?.[REPORT_FIELDS.additional] || "");
    form.append(REPORT_FIELDS.logo1, formData?.[REPORT_FIELDS.logo1] || "");
    form.append(REPORT_FIELDS.logo2, formData?.[REPORT_FIELDS.logo2] || "");
    form.append(
      REPORT_FIELDS.projectName,
      formData?.[REPORT_FIELDS.projectName] || ""
    );
    form.append(
      REPORT_FIELDS.subcontractor,
      formData?.[REPORT_FIELDS.subcontractor] || ""
    );
    form.append(
      REPORT_FIELDS.contractNo,
      formData?.[REPORT_FIELDS.contractNo] || ""
    );
    form.append(REPORT_FIELDS.ncrNo, formData?.[REPORT_FIELDS.ncrNo] || "");
    form.append(REPORT_FIELDS.qty, formData?.[REPORT_FIELDS.qty] || "");
    form.append(REPORT_FIELDS.ref, formData?.[REPORT_FIELDS.ref] || "");

    addErrorReport(form)
      .then((result) => {
        if (displayServerErrors(result)) {
          return;
        }

        if (result.data) {
          Toast.show({
            closeAll: true,
            description: i18n.t(`${SN}success.reportSubmitted`),
          });
          navigation.navigate("Tasks");
        }
      })
      .catch(() => {
        Toast.show({
          closeAll: true,
          description: i18n.t(`${SN}errors.generalError`),
        });
      });
  };
  const removeImage = (id) => {
    if (!id) {
      return;
    }
    const images = formData[REPORT_FIELDS.images];

    const updatedImagesList = images.filter((image) => image.id !== id);

    handleChange(updatedImagesList, REPORT_FIELDS.images);
  };
  const displayReferenceDocuments = () => {
    setShowHiddenFields({
      ...showHiddenFields,
      [REPORT_FIELDS.showReference]: true,
    });
  };
  const addImages = async () => {
    const assets = await pickImages(status, requestPermission);
    if (!assets) {
      return;
    }

    const imagesUpdated = [...assets, ...formData[REPORT_FIELDS.images]];
    handleChange(imagesUpdated, REPORT_FIELDS.images);
  };

  const takePhoto = async () => {
    const actionAfterPhotoIsTaken = (photo) => {
      let imagesUpdated = prepareImagesForUpload(photo)[0].map((item) => {
        if (item?.uri?.split(",")?.[1]) {
          const key = Crypto.randomUUID();
          return {
            ...item,
            base64: item.uri.split(",")[1],
            key: key,
            id: key,
          };
        }
      });
      imagesUpdated.push(...formData[REPORT_FIELDS.images]);

      handleChange(imagesUpdated, REPORT_FIELDS.images);
    };
    openCamera(actionAfterPhotoIsTaken);
  };

  const displayAdditionalInformationInput = () => {
    setShowHiddenFields({
      ...showHiddenFields,
      [REPORT_FIELDS.showAdditional]: true,
    });
  };

  const buttonsWidth = {
    width: su(inputWidth, 200, 190),
    minWidth: su(300, 200, 190),
  };

  const renderFooterButtons = () => (
    <Button.Group
      // w="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      flexDirection="row"
    >
      <Button
        _hover={{
          bg: "accent.40",
          color: "base.0",
        }}
        _pressed={{
          bg: "accent.50",
          color: "base.0",
        }}
        onPress={displayReferenceDocuments}
        style={buttonsWidth}
        bg="accent.30"
        m={2}
        borderColor="neutral.40"
        _text={{ color: "base.0" }}
      >
        {i18n.t(`${SN}buttons.referenceDocuments`)}
      </Button>
      {isMobile && <Divider bg="base.0" height={3} />}

      <Button
        _hover={{
          bg: "accent.40",
          color: "base.0",
        }}
        _pressed={{
          bg: "accent.50",
          color: "base.0",
        }}
        style={buttonsWidth}
        _text={{ color: "primary.90" }}
        variant="outline"
        m={2}
        onPress={addImages}
        startIcon={<FontAwesome name="picture-o" size={24} color="black" />}
      >
        {i18n.t(`${SN}buttons.uploadImages`)}
      </Button>
      {(isTablet || isMobile) && (
        <>
          {isMobile && <Divider bg="base.0" height={3} />}
          <Button
            _hover={{
              bg: "accent.40",
              color: "base.0",
            }}
            _pressed={{
              bg: "accent.50",
              color: "base.0",
            }}
            style={buttonsWidth}
            _text={{ color: "primary.90" }}
            variant="outline"
            m={2}
            onPress={takePhoto}
            startIcon={<FontAwesome name="camera" size={24} color="black" />}
          >
            {i18n.t(`${SN}buttons.takePhoto`)}
          </Button>
        </>
      )}

      {isMobile && <Divider bg="base.0" height={3} />}
      <Button
        _hover={{
          bg: "accent.40",
          color: "base.0",
        }}
        _pressed={{
          bg: "accent.50",
          color: "base.0",
        }}
        m={2}
        onPress={displayAdditionalInformationInput}
        style={buttonsWidth}
        variant="outline"
        borderColor="neutral.40"
        _text={{ color: "primary.90" }}
      >
        {i18n.t(`${SN}buttons.addAdditional`)}
      </Button>
    </Button.Group>
  );

  const renderAdditionalInformationInput = () =>
    showHiddenFields[REPORT_FIELDS.showAdditional] ? (
      <ReportRow
        key="additionalInformation"
        isMobile={isMobile}
        borderLess={true}
      >
        <Box w="100%">
          <Text fontSize={14} lineHeight="xl" color="base.90">
            {i18n.t("screens.errorReport.labels.additional")}
          </Text>
          <FormControl isInvalid={!!errors?.[REPORT_FIELDS.additional]}>
            <TextArea
              defaultValue={data?.[REPORT_FIELDS.additional]}
              onChangeText={(value) =>
                handleChange(value, REPORT_FIELDS.additional)
              }
              onBlur={validateForm}
              isDisabled={isViewMode}
              style={{ width: 475, height: 74 }}
            />
            <FormControl.ErrorMessage>
              {errors?.[REPORT_FIELDS.additional]}
            </FormControl.ErrorMessage>
          </FormControl>
        </Box>
      </ReportRow>
    ) : null;

  const renderTaskInfo = () => {
    return (
      <ReportRow isMobile={isMobile} isDesktop={isDesktop}>
        <VStack>
          <HStack
            borderStyle="solid"
            borderWidth="1"
            borderColor="base.10"
            height={60}
            width={isMobile ? "full" : 250}
            alignItems="center"
            justifyContent="space-around"
            space={1}
          >
            <Box>
              <UserAvatar size="medium" user={task?.assignTo} />
            </Box>
            <Box>
              <Text
                w="full"
                fontSize={14}
                fontWeight="medium"
                color="primary.100"
              >
                {_.truncate(getUserFullName(task?.assignTo), {
                  length: 28,
                })}
              </Text>
            </Box>
          </HStack>
        </VStack>

        <VStack display="flex" flexDirection={isMobile ? "row" : "column"}>
          <Text
            textAlign="right"
            w="full"
            fontSize={14}
            fontWeight="medium"
            color="primary.100"
          >
            {daysjs().format(DATE_FORMAT)}
            <Text
              textAlign="right"
              fontSize={14}
              fontWeight="thin"
              color="primary.100"
            >
              {daysjs().format("HH:MM A")}
            </Text>
          </Text>
        </VStack>
      </ReportRow>
    );
  };

  const uploadLogo = async (fieldName, file) => {
    const preparedFile = prepareImagesForUpload([file]);

    const formData = new FormData();
    formData.append("file", preparedFile[0][0]);
    formData.append("createdBy", loggedUser?.id);
    formData.append("assetFileType", "image");
    formData.append(
      "assetName",
      isMobile ? preparedFile[0][0]?.name : preparedFile[0][1]
    );
    addAsset({ form: formData })
      .then((res) => {
        if (displayServerErrors(res)) return;
        handleChange(res.data.doc.id, fieldName);
      })
      .catch(() => {
        Toast.show({
          closeAll: true,
          description: i18n.t("errorsMsg.generalError"),
        });
      });
  };
  const setFromAndUploadLogo = async (fieldName) => {
    let logo = null;
    try {
      logo = await pickImages();
    } catch (e) {
      console.warn(e);
    }

    if (!_.isArray(logo) || logo.length === 0) return;

    setUploadedLogos((prev) => ({ ...prev, [fieldName]: logo[0] }));
    uploadLogo(fieldName, logo[0]);
  };
  const uploadLogo1 = async () => {
    setFromAndUploadLogo(REPORT_FIELDS.logo1);
  };
  const uploadLogo2 = async () => {
    setFromAndUploadLogo(REPORT_FIELDS.logo2);
  };

  const setSelectedImages = (images) => {
    setData((prev) => ({ ...prev, [REPORT_FIELDS.images]: images }));
  };

  const inputWidth = isMobile ? windowWidth - 70 : 250;

  const attachedImages = getAttachedImages(
    data?.[REPORT_FIELDS.images],
    formData?.[REPORT_FIELDS.images],
    isViewMode
  );

  const answerImages = _.uniqBy(getImagesOfAnswers(question.answers), "id");

  return (
    <KeyboardAwareScrollView
      extraScrollHeight={50}
      extraHeight={50}
      enableResetScrollToCoords={true}
      nestedScrollEnabled={true}
      alignItems="center"
    >
      <VStack
        space={3}
        px={su(4, 3, 3)}
        py={su(4, 8, 8)}
        bg="white"
        alignItems="center"
        w="full"
        mb={8}
        pb={10}
        h="full"
      >
        <ReportRow borderLess={true} isMobile={isMobile} isDesktop={isDesktop}>
          {isDesktop && (
            <ResolveFlowNavigationDesktop
              hideNext={true}
              onBack={goToResolveTask}
            />
          )}
          {!isViewMode && (
            <Box
              w="100%"
              alignItems={isMobile ? "center" : "flex-end"}
              justifyItems="center"
            >
              <Button
                px={3}
                onPress={submitReport}
                py={3}
                style={{ height: su(50, 60), width: 210 }}
                bg="accent.40"
                isLoading={requestAddErrorReport?.isLoading}
              >
                <Text color="base.0">{i18n.t(`${SN}buttons.submit`)}</Text>
              </Button>
            </Box>
          )}
        </ReportRow>

        <ReportsLogoRow
          isMobile={isMobile}
          isDesktop={isDesktop}
          onPressUpload1={uploadLogo1}
          onPressUpload2={uploadLogo2}
          removeLogo1={removeLogo1}
          removeLogo2={removeLogo2}
          errorMessageProjectName={errors[REPORT_FIELDS.projectName]}
          token={token}
          uploadLabel2={i18n.t(`${SN}labels.uploadLogo2`)}
          uploadLabel1={i18n.t(`${SN}labels.uploadLogo1`)}
          projectNameLabel={i18n.t(`${SN}labels.projectName`)}
          isDisabled={isViewMode}
          projectNameValue={data?.[REPORT_FIELDS.projectName]}
          onChangeProjectName={handleChange}
          data={[
            uploadedLogos[REPORT_FIELDS.logo1],
            uploadedLogos[REPORT_FIELDS.logo2],
          ]}
        />
        <Center style={{ marginTop: 47 }}>
          <Text fontSize={24} fontWeight="medium" underline color="primary.100">
            {i18n.t(`${SN}title`)}
          </Text>
        </Center>
        <ReportRow isMobile={isMobile} borderLess={true}>
          <HStack
            flex={1}
            flexDirection="row"
            w="full"
            alignItems="flex-end"
            justifyItems="flex-end"
            justifyContent="flex-end"
            flexWrap="wrap"
            space={2}
            mb={{ lg: 6 }}
          >
            <HStack
              flexWrap="wrap"
              alignContent="center"
              alignItems="center"
              justifyItems="flex-end"
              justifyContent="flex-end"
              space={3}
            >
              <FormControl.Label>{i18n.t(`${SN}labels.tag`)}</FormControl.Label>
              {PN &&
                PN.length > 0 &&
                PN.map((item, index) => {
                  return (
                    <Badge key={`pn-${index}`} my={3} bg="primary.10">
                      <Text color="accent.40">{item.name}</Text>
                    </Badge>
                  );
                })}
            </HStack>
          </HStack>
        </ReportRow>
        {renderTaskInfo()}
        <ReportRow isMobile={isMobile}>
          <VStack space={2}>
            <InputReport
              value={data?.[REPORT_FIELDS.subcontractor]}
              isDisabled={isViewMode}
              inputName={REPORT_FIELDS.subcontractor}
              errorMessage={errors[REPORT_FIELDS.subcontractor]}
              width={inputWidth}
              placeholder={i18n.t(`${SN}labels.subcontractor`)}
              onChangeText={handleChange}
              onBlur={validateForm}
            />
            <InputReport
              value={data?.[REPORT_FIELDS.contractNo]}
              isDisabled={isViewMode}
              inputName={REPORT_FIELDS.contractNo}
              errorMessage={errors[REPORT_FIELDS.contractNo]}
              width={inputWidth}
              placeholder={i18n.t(`${SN}labels.contractNo`)}
              onChangeText={handleChange}
              onBlur={validateForm}
            />
          </VStack>
          <VStack mt={2} space={2}>
            <InputReport
              value={data?.[REPORT_FIELDS.ncrNo]}
              inputName={REPORT_FIELDS.ncrNo}
              isDisabled={isViewMode}
              errorMessage={errors[REPORT_FIELDS.ncrNo]}
              width={inputWidth}
              placeholder={i18n.t(`${SN}labels.ncrNo`)}
              onChangeText={handleChange}
              onBlur={validateForm}
            />
            <InputReport
              value={data?.[REPORT_FIELDS.qty]}
              isDisabled={isViewMode}
              inputName={REPORT_FIELDS.qty}
              errorMessage={errors[REPORT_FIELDS.qty]}
              width={inputWidth}
              placeholder={i18n.t(`${SN}labels.qty`)}
              onChangeText={handleChange}
              onBlur={validateForm}
            />
          </VStack>
        </ReportRow>
        <ReportRow isMobile={isMobile}>
          <HStack
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            height={20}
          >
            <Text fontSize={14} fontWeight="xl" color="primary.100">
              {i18n.t(`${SN}labels.location`)}:
            </Text>
            <Text fontSize={14} fontWeight="bold" color="primary.100">
              {` `}
              {task?.area?.name}
            </Text>
          </HStack>
          <VStack>
            <InputReport
              value={data?.[REPORT_FIELDS.ref]}
              isDisabled={isViewMode}
              inputName={REPORT_FIELDS.ref}
              errorMessage={errors[REPORT_FIELDS.ref]}
              width={inputWidth}
              placeholder={i18n.t(`${SN}labels.ref`)}
              onChangeText={handleChange}
              onBlur={validateForm}
            />
          </VStack>
        </ReportRow>
        <ReportRow isMobile={isMobile} borderLess={true}>
          <VStack
            bg="neutral.10"
            borderRadius="sm"
            style={{
              minHeight: 250,
              width: "100%",

              paddingLeft: paddingQuestionerRequestContainer,
              paddingRight: paddingQuestionerRequestContainer,
              paddingTop: 37,
              marginBottom: 2,
              paddingBottom: 10,
            }}
          >
            <Text fontSize={14} lineHeight="xl" color="base.90">
              {i18n.t("screens.errorReport.labels.questionnaireRequest")}
            </Text>
            <Text mt={1} fontSize={20} lineHeight="xl" color="accent.30">
              {_.unescape(question?.title)}
            </Text>
            <Text fontSize={14} lineHeight="xl" color="base.90">
              {i18n.t("screens.errorReport.labels.describeError")}
            </Text>
            <FormControl isInvalid={!!errors?.[REPORT_FIELDS.description]}>
              <TextArea
                defaultValue={data?.[REPORT_FIELDS.description]}
                isDisabled={isViewMode}
                onBlur={validateForm}
                onChangeText={(value) =>
                  handleChange(value, REPORT_FIELDS.description)
                }
                style={{ width: 475, height: 74 }}
              />
              <FormControl.ErrorMessage>
                {errors?.[REPORT_FIELDS.description]}
              </FormControl.ErrorMessage>
            </FormControl>
          </VStack>
        </ReportRow>

        {attachedImages &&
          _.isArray(attachedImages) &&
          attachedImages.length >= 1 && (
            <ReportRow key="img" isMobile={isMobile} borderLess={true}>
              <Box bg="neutral.10" w="full">
                <Text fontSize={14} color="base.90" mt={2} ml={3}>
                  {i18n.t("screens.errorReport.labels.attachedImages")}
                </Text>
                <ViewMediaCollection
                  renderAddMoreBtn={false}
                  token={token}
                  items={attachedImages}
                  hasDeleteIcon={!isViewMode}
                  setSelectedItems={setSelectedImages}
                  handleRemove={removeImage}
                />
              </Box>
            </ReportRow>
          )}
        {answerImages &&
          _.isArray(answerImages) &&
          answerImages.length >= 1 && (
            <ReportRow key="img" isMobile={isMobile} borderLess={true}>
              <Box bg="neutral.10" w="full">
                <Text fontSize={14} color="base.90" mt={2} ml={3}>
                  {i18n.t("screens.errorReport.labels.answerImages")}
                </Text>
                <ViewMediaCollection
                  renderAddMoreBtn={false}
                  token={token}
                  items={answerImages}
                  hasDeleteIcon={false}
                />
              </Box>
            </ReportRow>
          )}

        {showHiddenFields[REPORT_FIELDS.showReference] &&
          (isViewMode ? data?.[REPORT_FIELDS.reference]?.length > 0 : true) && (
            <ReportRow key="ref" isMobile={isMobile} borderLess={true}>
              <Box w="100%" bg="neutral.10">
                <Text color="primary.90" mt={2} ml={3}>
                  {i18n.t("screens.errorReport.buttons.referenceDocuments")}
                </Text>
                <ViewMediaCollection
                  renderAddMoreBtn={false}
                  selectMode={!isViewMode}
                  items={selectableReferences}
                  hasDeleteIcon={false}
                  setSelectedItems={setSelectedReferences}
                  selectedItems={formData.reference}
                />
              </Box>
            </ReportRow>
          )}
        {renderAdditionalInformationInput()}
        {!isViewMode && (
          <ReportRowBasic>{renderFooterButtons()}</ReportRowBasic>
        )}
        {/* <ReportRowBasic> */}
        {/* {question?.type === "input" && (
            <ResultsTable
              question={data?.question}
              dataSource={data?.[REPORT_FIELDS.resultsTable]}
            />
          )} */}
        {/* </ReportRowBasic> */}
        <Spacer h={3} />
      </VStack>
    </KeyboardAwareScrollView>
  );
};

export default ReportError;
