import {
  Input,
  Box,
  VStack,
  Stack,
  FormControl,
  WarningOutlineIcon,
} from "native-base";
import { i18n } from "../../utils/utils";
import React from "react";
import YupPassword from "yup-password";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setContent } from "../../features/uxSlice";
import _ from "lodash";

YupPassword(Yup);
const RESET_PASSWORD_FIELDS = {
  newPassword: "newPassword",
  repeatNewPassword: "repeatNewPassword",
};
const ChangePasswordValidationSchema = Yup.object().shape({
  [RESET_PASSWORD_FIELDS.newPassword]: Yup.string()
    .min(12)
    .minLowercase(1, "Should contain at least 1 lowercase characters")
    .minUppercase(1, "Should contain at least 1 uppercase characters")
    .minSymbols(1, "Should contain at least 1 symbol (like: !?@$#")
    .minNumbers(1, "Should contain at least 1 number")
    .required("Required").label('New password'),
  [RESET_PASSWORD_FIELDS.repeatNewPassword]: Yup.string()
    .min(12)
    .minLowercase(1, "Should contain at least 1 lowercase characters")
    .minUppercase(1, "Should contain at least 1 uppercase characters")
    .minSymbols(1, "Should contain at least 1 symbol (like: !?@$#")
    .minNumbers(1, "Should contain at least 1 number")
    .required("Required").label('Confirm password')
});

const ChangePassword = () => {
  const dispatch = useDispatch();

  const { values, errors, setFieldValue } = useFormik({
    initialValues: {
      [RESET_PASSWORD_FIELDS.newPassword]: "",
      [RESET_PASSWORD_FIELDS.repeatNewPassword]: "",
    },
    validationSchema: ChangePasswordValidationSchema,
  });

  const setText = async (fieldName, text) => {
    setFieldValue(fieldName, text).then((r) => {
      dispatch(
        setContent({
          password: text,
          isValid: _.isEmpty(r),
        })
      );
    });
  };

  return (
    <Box size="sm">
      <VStack>
        <FormControl isInvalid={RESET_PASSWORD_FIELDS.newPassword in errors}>
          <Stack mx="4">
            <FormControl.Label>
              {i18n.t("screens.changePassword.labels.newPassword")}
            </FormControl.Label>
            <Input
              onChange={(e) =>
                setText(RESET_PASSWORD_FIELDS.newPassword, e?.target?.value)
              }
              value={values[RESET_PASSWORD_FIELDS.newPassword]}
              // onChangeText={handleChange(RESET_PASSWORD_FIELDS.newPassword)}
              type="password"
              placeholder="password"
            />
            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" />}
            >
              {errors[RESET_PASSWORD_FIELDS.newPassword]}
            </FormControl.ErrorMessage>
          </Stack>
        </FormControl>

        <FormControl
          isInvalid={RESET_PASSWORD_FIELDS.repeatNewPassword in errors}
        >
          <Stack mx="4">
            <FormControl.Label>
              {i18n.t("screens.changePassword.labels.repeatNewPassword")}
            </FormControl.Label>
            <Input
              type="password"
              onChange={(e) =>
                setText(
                  RESET_PASSWORD_FIELDS.repeatNewPassword,
                  e?.target?.value
                )
              }
              placeholder="password"
              value={values[RESET_PASSWORD_FIELDS.repeatNewPassword]}
            />
            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" />}
            >
              {errors[RESET_PASSWORD_FIELDS.repeatNewPassword]}
            </FormControl.ErrorMessage>
          </Stack>
        </FormControl>
      </VStack>
    </Box>
  );
};

export default ChangePassword;
