export const colors = {

  base: {
    0: "#FFFFFF",
    10: "#F5F5F5", //cultured
    20: "#E4E5E8", //
    90: "#42526E", // independence
    100: "#172B4D", //oxford blue
  },
  primary: {
    10: "#E9F3FF", //azure light
    20: "#DEEBFF", // sky blue
    30: "#CCECFC",
    40: "#89CFF0", //baby blue
    50: "#00C7E6", //sky blue
    60: "#2684FF",
    70: "#0052CC", //cerulean blue
    80: "#42526E", // independence
    90: "#172B4D", //Lavender Soft
    100: "#091E42", //oxford blue
    600: "#091E42", //oxford blue
  },
  neutral: {
    10: "#FAFAFA", //white almost
    20: "#ECEEF1", // ?
    30: "#DFE1E6", //boro
    40: "#D9D9D9", //gray lines
    50: "#B9BFCA", //space for icons
    60: "#929BAB", //oxford 2
    70: "#8D96A6", //space 2
    80: "#EAE6FF", //Lavender Soft
    90: "##2E405F", //Lavender Soft
  },
  accent: {
    10: "#89CFF0",
    20: "#00C7E6",
    30: "#6554C0",
    40: "#2684FF",
    50: "#0052CC",
    60: "#FFBF00",
    70: "#FF948D", //Vermilion 2
    80: "#57D9A3", // Aquamarine Medium
    90: "#36B37E", // azure light
    100: "#DE350B", // baby blue soft
  },
  success: {
    10: "#FFFFFF",
    20: "#EEFBF6", // green soft
    30: "#57D9A3", // Aquamarine Medium
    40: "#36B37E", // Green Crayola
  },
  error: {
    10: "#FFF1F0", //Vermilion soft
    20: "#FF948D", //Vermilion 2
    30: "#DE350B", //Vermilion
  },
  warning: {
    10: "#FFF3CF", //Yellow Chrome Soft
    20: "#FFF1D3", //Yellow Chrome
    30: "#FFD554", //Vermilion soft
    40: "#FFBF00", //Vermilion 2
    50: "#FFAB00", //Vermilion
    60: "#E9F3FF", //White almost
    70: "#6554C0", //azure light
  },
  table: {
    10: "#EEFBF6", // green soft,
    20: "#57D9A3", // Aquamarine Medium
    30: "#36B37E", // Green Crayola
    40: "#FFF3CF", // azure
    50: "#FFBF00", // azure
    60: "#FFAB00", // azure
    70: "#FFF1F0", // azure
    80: "#FF948D", // azure
    90: "#DE350B", // azure
    100: "#EAE6FF", // azure
  110: "#6554C0", // independence
    120: "#2684FF", // independence
    130: "#E4E5E8", // independence
    140: "#42526E", // independence
    150: "#091E42", // independence
  },
  // Redefining only one shade, rest of the color will remain same.
  amber: {
    700: "#FFBF00",
  },
  uniqueColors: {
    blue: {
      azureLight: "#E9F3FF",
      babySoft: "#CCECFC",
      baby: "#89CFF0",
      sky: "#00C7E6",
      azure: "#00C7E6",
    },
  },
};

export function getUniqueColors() {
  let colorsFamilies = Object.entries(colors);
  colorsFamilies = colorsFamilies.map((item) => {
    item = item.slice(1, item.length);
    return item;
  });


  const uniqueColors = [];

  const addColor = (newC) => {
    //isInList?
    if (uniqueColors.length === 0) {
      uniqueColors.push(newC);
    }
    uniqueColors.forEach((c) => {
      if (c !== newC) {
        uniqueColors.push(newC);
      }
    });
    //add or not to list
  };
  let familyValues = [];
  colorsFamilies.map((family) => {
    familyValues = Object.entries(family[0]);
    familyValues = familyValues.map((item) => item[1]);
    //add color
    familyValues.forEach((c) => addColor(c));
  });
  console.log(uniqueColors);
}
