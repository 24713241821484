import React from "react";
import { VStack, Button, Icon, Text } from "native-base";

import { useSelector, useDispatch } from "react-redux";
import { useRoute } from "@react-navigation/native";
import {
  AntDesign,
  FontAwesome5,
  MaterialCommunityIcons,
} from "@expo/vector-icons";

import { setIsCurrent } from "../../../../features/tasksSlice";
import { TASKS_MAIN_FLOW_SCREEN_STATUS } from "../../../../utils/utils";
import { flowScreens } from "../../../../screens/tasks/main-flow/TasksMainFlowNavigator";

function FlowProgressDesktop() {
  const dispatch = useDispatch();
  const route = useRoute();
  const flowData = useSelector((state) => state.tasks.tasksMainFlow.data);

  const navigateTo = (path) => {
    if (!path) {
      console.warn("No path in slide menu while trying to navigate.");
      return;
    }
    dispatch(
      setIsCurrent({
        step: path,
      })
    );
  };

  const ICON_COLOR_MAP = {
    [TASKS_MAIN_FLOW_SCREEN_STATUS.completed]: "success.40",
    [TASKS_MAIN_FLOW_SCREEN_STATUS.current]: "accent.40",
    [TASKS_MAIN_FLOW_SCREEN_STATUS.waiting]: "neutral.50",
  };
  return (
    <VStack
      pt={100 + "px"}
      style={{ width: 207, height: 900 }}
      bg="white"
      shadow="2"
      space={3}
      alignItems="flex-start"
      justifyContent="flex-start"
      justifyItems="center"
    >
      <Button
        w="full"
        h="10"
        bg={"primary.30"}
        _pressed={{
          bg: "accent.50",
          color: "base.0",
        }}
        px="3"
        _hover={{ bg: "primary.30", tintColor: "primary.30" }}
        py="2"
        startIcon={
          <Icon
            color={"primary.60"}
            name="tasks"
            size={14}
            mr={2}
            as={FontAwesome5}
          />
        }
        alignItems="flex-start"
        justifyContent="flex-start"
        justifyItems="center"
      >
        <Text
          color="primary.60"
          _hover={{ tintColor: "accent.40" }}
          fontSize={14}
          fontFamily="body"
          fontWeight="medium"
          // letterSpacing="md"
          lineHeight="sm"
        >
          Flow Progress
        </Text>
      </Button>

      {flowScreens.map((item, index) => {
        const isCurrent = flowData[item.name]?.isCurrent;

        const isDone =
          flowData?.[item.name]?.status ==
          TASKS_MAIN_FLOW_SCREEN_STATUS.completed;
        const textColor =
          route.name === item.path && index !== 0 ? "primary.60" : "primary.80";

        const styleEvents = {
          backgroundColor: "primary.30",
          tintColor: "primary.50",
          _text: {
            color: textColor,
            tintColor: "primary.50",
          },
        };

        const onPress = () => navigateTo(item.path);

        const text = `${(index + 1).toString()}. ${item.shortTitle}`;
        return (
          <Button
            w="full"
            h="10"
            bg={isCurrent ? "primary.30" : "base.0"}
            _hover={styleEvents}
            _pressed={styleEvents}
            _focus={styleEvents}
            onPress={onPress}
            startIcon={
              <Icon
                color={
                  isCurrent
                    ? "primary.60"
                    : ICON_COLOR_MAP[flowData?.[item.name]?.status]
                }
                size={5}
                name={isDone ? "check" : "clock-time-five-outline"}
                as={isDone ? AntDesign : MaterialCommunityIcons}
              />
            }
            key={`desktop-menu-${index}`}
            alignItems="flex-start"
            justifyContent="flex-start"
            justifyItems="center"
            _text={{
              fontFamily: "body",
              fontWeight: "medium",
              lineHeight: "sm",
              color: textColor,
              fontSize: 14,
            }}
          >
            {text}
          </Button>
        );
      })}
    </VStack>
  );
}

FlowProgressDesktop.propTypes = {};

export default FlowProgressDesktop;
